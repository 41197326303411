import * as React from 'react';
import { PatternFormat } from 'react-number-format';
import { Box, Theme, Typography } from '@mui/material';

import { AccountDetailsResponse } from 'api/account-details-api/AccountDetailsResponse';

interface Props {
  accountInfo?: AccountDetailsResponse;
  accountNumber?: number;
}

const sx = {
  accountInfoWrapper: {
    display: 'flex',
    width: '100%',
    margin: '40px 0px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75em',
    border: 1,
    borderColor: (theme: Theme) => theme.palette.dteGrey.main,
    padding: '20px 30px',
    width: '100%',
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.1em',
    width: '100%',
  },
  title: {
    color: (theme: Theme) => theme.palette.primary.main,
    fontWeight: 600,
  },
  body: {
    color: (theme: Theme) => theme.palette.secondary.main,
  },
} as const;

const AccountInformationDesktop: React.FC<Props> = (props: Props): JSX.Element => {
  const { accountInfo, accountNumber } = props;
  const address = accountInfo?.accounts[0].serviceAddresses[0];

  return (
    <Box data-testid="account-info-desktop" sx={sx.accountInfoWrapper}>
      <Box sx={sx.row}>
        <Box sx={sx.columns}>
          <Typography data-testid="account-info-title" sx={sx.title} variant="body2">
            {accountInfo?.customerName}
          </Typography>
          <Typography data-testid="account-info-body" sx={sx.body} variant="body1">
            {address?.addressLine1}
          </Typography>
          {address?.addressLine2 && (
            <Typography sx={sx.body} variant="body1">
              {address?.addressLine2}
            </Typography>
          )}
          <Typography sx={sx.body} variant="body1">
            {`${address?.city}, ${address?.state} ${address?.zipcode}`}
          </Typography>
        </Box>
        <Box sx={sx.columns}>
          <Typography sx={sx.title} variant="body2">
            Account Number
          </Typography>
          <Typography sx={sx.body} variant="body1">
            <PatternFormat displayType="text" value={accountNumber} format="#### ### #### #" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountInformationDesktop;
