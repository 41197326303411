import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Box, SxProps, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useAccountDetails } from 'api/account-details-api/account-details-api';
import { AccountDetailsResponse } from 'api/account-details-api/AccountDetailsResponse';
import { useEligibility } from 'api/eligibility-api/eligibility-api';
import { PaymentCenterLambda, paymentCenterLambda } from 'api/payment-center-lambda-api/payment-center-lambda-api';
import { useUserState } from 'auth/authenticate';
import { PaymentServiceInterruptionBanner } from 'component-library/banners/PaymentServiceInterruptionBanner';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import ViewCurrentBillButton from 'component-library/buttons/ViewCurrentBillButton';
import { offeredPeScheduledDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { paymentExtension } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { PaymentExtensionTitle } from 'component-library/header/ServiceTitle';
import ViewPaymentOffersLink from 'component-library/links/ViewPaymentOffersLink';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import getFlagsConfig from 'config/getFlagsConfig';
import { eligibilityTypes } from 'constants/';
import { trackGA4Event } from 'gtm/gtag';
import { useRefactoredEligibility } from 'hooks';

import {
  peEligibleDateViewEvent,
  peEligibleHeaderCancelClickEvent,
  peEligiblePastDueBalanceViewEvent,
  peEligibleSchedulePaymentClickEvent,
  peEligibleViewCurrentBillClickEvent,
} from '../gtm/gtmEventsPaymentExtension';
import { useResponsiveRendering, useResponsiveSx } from '../theme/breakpoints/responsiveHooks';
import {
  currentBillUrl,
  lambdaPaymentExtensionUrl,
  paymentCenterPaymentExtensionUrl,
  paymentExtensionScheduledPath,
  peGenericErrorPath,
} from '../urls-and-paths';

import PaymentExtensionAccountInformation from './components/PaymentExtensionAccountInformation';
import { PaymentExtensionPastDueBalance } from './components/PaymentExtensionPastDueBalance';

const headerCancelButtonHandler = (): void => {
  trackGA4Event(peEligibleHeaderCancelClickEvent);
  window.location.href = currentBillUrl;
};
const mobileHeader = (
  <HeaderMobile titleText={paymentExtension} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
const desktopHeader = (
  <HeaderDesktop titleText={paymentExtension} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);

const PaymentExtensionEligiblePage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated, getBpId } = useUserState();
  const { enablePaymentAgreement } = getFlagsConfig();
  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<AccountDetailsResponse>) => {
      const { data } = httpResponse;
      if (!data.accounts[0].accountNumber) {
        console.log('accountDetails response is missing AccountNumber');
        navigate(peGenericErrorPath);
      }
    },
    onError: (error: unknown) => {
      /* navigate to error page */
      console.log('accountDetails Error', error);
      navigate(peGenericErrorPath);
    },
  });

  const { isEligibilitySuccess, eligibilityResponse } = useEligibility({
    queryKey: ['payment-extension-eligible-eligibility'],
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<CombinedEligibilityCheckResponse>) => {
      const { data } = httpResponse;

      const sumOfAllPayments = data?.paymentExtensionDetails?.sumOfAllPayments;
      const pastDueAmount = data?.paymentExtensionDetails?.pastDueAmount;
      const daysLeftInLock = data?.paymentExtensionDetails?.daysLeftInLock;

      trackGA4Event(peEligiblePastDueBalanceViewEvent(pastDueAmount!));
      trackGA4Event(peEligibleDateViewEvent(offeredPeScheduledDate().format('YYYY-MM-DD')));

      if (
        sumOfAllPayments &&
        pastDueAmount &&
        data.eligible.paymentExtension === 'Y' &&
        parseFloat(sumOfAllPayments) >= parseFloat(pastDueAmount) &&
        daysLeftInLock === undefined
      ) {
        navigate(`/payment-extension-scheduled-payment`);
      }

      if (data.eligible.paymentExtension === 'S') {
        navigate(`/payment-extension`);
      }

      if (data.eligible.paymentExtension !== 'Y') {
        console.log('Not Eligible for Payment Extension -> routing to error page');
        // navigate to Generic Error Page
        navigate(peGenericErrorPath);
      }
    },
    onError: (error: unknown) => {
      console.log('combinedEligibility', error);
      // navigate to Generic Error Page
      navigate(peGenericErrorPath);
    },
  });

  const { mutate: mutateLambda } = useMutation({
    mutationKey: ['payment-extension-lambda'],
    mutationFn: (lambdaRequest: PaymentCenterLambda) => paymentCenterLambda(lambdaRequest),
    onSuccess: () => {
      window.location.href = paymentCenterPaymentExtensionUrl;
    },
    onError: (error: unknown) => {
      console.log('lambda not successful', error);
      navigate(peGenericErrorPath);
    },
  });

  const { eligibilityType: refactoredEligibilityType } = useRefactoredEligibility({
    shouldPreventRedirect: true,
    errorRedirectPath: peGenericErrorPath,
    requiredEligibilityTypes: [],
  });

  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  const accountNumber = Number(accountDetailsResponse?.data.accounts[0].accountNumber);
  const peDetails = eligibilityResponse?.data.paymentExtensionDetails;

  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    peAccountInfoAndPastDueBalanceAdapter: {
      display: 'flex',
      border: `1px solid ${theme.palette.dteGrey.main}`,
      margin: '40px 0px 32px 0px',
    },
    peAccountInfoAndPastDueBalanceAdapterMobile: {
      flexDirection: 'column',
    },
    peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop: {
      flexDirection: 'row',
    },
    viewPaymentOffersLinkContainer: {
      marginBottom: '24px',
    },
  };

  const peAccountInfoAndPastDueBalanceMobileSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterMobile,
  };
  const peAccountInfoAndPastDueBalanceTabletAndDesktopSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop,
  };

  const peAccountInfoAndPastDueBalanceAdapterResponsiveSx = useResponsiveSx(
    peAccountInfoAndPastDueBalanceMobileSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
  );

  const redirectToCurrentBillHandler = (): void => {
    trackGA4Event(peEligibleViewCurrentBillClickEvent);
    window.location.href = currentBillUrl;
  };

  const scheduleAPaymentHandler = (): void => {
    trackGA4Event(peEligibleSchedulePaymentClickEvent);

    if (
      eligibilityResponse?.data.paymentExtensionDetails?.pastDueAmount &&
      accountDetailsResponse?.data.accounts[0].accountNumber
    ) {
      const formData = new URLSearchParams();
      formData.append('pastDueAmount', eligibilityResponse?.data.paymentExtensionDetails?.pastDueAmount);
      formData.append('peScheduledDate', offeredPeScheduledDate().format('YYYY-MM-DD'));
      formData.append('peEligibleUrl', window.location.hostname + window.location.pathname);
      formData.append('peScheduledUrl', window.location.hostname + paymentExtensionScheduledPath);
      formData.append('accounts', accountDetailsResponse?.data.accounts[0].accountNumber);

      const bpId = getBpId();
      formData.append('bpId', `${bpId}`);

      mutateLambda({
        url: lambdaPaymentExtensionUrl,
        formData,
      });
    } else {
      console.info('Error - can not find eligible data to pass to Payment Center');
    }
  };

  const scheduleAPaymentButton = <DtePrimaryButton name="Schedule a Payment" onClick={scheduleAPaymentHandler} />;

  return (
    <>
      <Helmet>
        <title>Payment Extension Eligible | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="16px"
        tabletDesktopContentWidth="740px"
        dataTestId="payment-extension-eligible-page"
      >
        {isEligibilitySuccess && isAccountDetailsSuccess ? (
          <>
            {enablePaymentAgreement && refactoredEligibilityType === eligibilityTypes.PaymentOffers ? (
              <Box sx={sx.viewPaymentOffersLinkContainer}>
                <ViewPaymentOffersLink />
              </Box>
            ) : (
              <ViewCurrentBillButton onClick={redirectToCurrentBillHandler} />
            )}
            <PaymentExtensionTitle />
            <Box
              data-testid="account-info-past-due-balance-adapter"
              sx={peAccountInfoAndPastDueBalanceAdapterResponsiveSx}
            >
              <PaymentExtensionAccountInformation
                accountInfo={accountDetailsResponse?.data}
                accountNumber={accountNumber}
              />
              <PaymentExtensionPastDueBalance paymentExtensionDetails={peDetails} />
            </Box>
            <Box sx={sx.rootWrapper}>{scheduleAPaymentButton}</Box>
            <PaymentServiceInterruptionBanner />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionEligiblePage;
