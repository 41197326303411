/* eslint-disable no-console */

const ConsoleLogWriter = (logLevel?: LogLevel): ILogger => {
  const shouldWriteDebugMessages = logLevel === 'debug';
  const shouldWriteInformationalMessages = ['debug', 'info'].indexOf(logLevel) !== -1;
  const shouldWriteWarningMessages = ['debug', 'info', 'warn'].indexOf(logLevel) !== -1;

  const debug = (message: string, ...optionalParams: unknown[]): void => {
    if (shouldWriteDebugMessages) {
      console.debug(message, optionalParams);
    }
  };

  const error = (message: string, ...optionalParams: unknown[]): void => {
    console.error(message, optionalParams);
  };

  const info = (message: string, ...optionalParams: unknown[]): void => {
    if (shouldWriteInformationalMessages) {
      console.info(message, optionalParams);
    }
  };

  const warn = (message: string, ...optionalParams: unknown[]): void => {
    if (shouldWriteWarningMessages) {
      console.warn(message, optionalParams);
    }
  };

  return {
    debug,
    info,
    error,
    warn,
  };
};

export default ConsoleLogWriter;
