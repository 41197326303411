import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const GatherDocumentsIcon = ({
  containerSx,
  iconWidth = '119',
  iconHeight = '61',
}: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="gather-documents-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 119 61" fill="none">
      <rect
        x="16.6064"
        y="11.2539"
        width="44.8606"
        height="29.1594"
        rx="3"
        transform="rotate(34.3255 16.6064 11.2539)"
        fill="white"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0466 18.5872C19.8827 18.8272 19.6745 18.9611 19.4221 18.9891C19.1696 19.017 18.9033 18.9354 18.6233 18.7442C18.3433 18.553 18.1465 18.3491 18.0328 18.1325L18.3177 17.7153C18.3911 17.8502 18.4797 17.9775 18.5835 18.0971C18.6884 18.2174 18.7956 18.315 18.9051 18.3897C19.0654 18.4992 19.2061 18.5465 19.3271 18.5317C19.4493 18.5177 19.5486 18.4547 19.6251 18.3426C19.694 18.2417 19.7174 18.1323 19.6953 18.0143C19.6732 17.8964 19.5854 17.7146 19.4319 17.4691C19.2742 17.2152 19.1927 16.9926 19.1876 16.8014C19.1824 16.6102 19.2411 16.4247 19.3638 16.245C19.5176 16.0198 19.7121 15.8926 19.9471 15.8636C20.1821 15.8346 20.4232 15.9045 20.6705 16.0733C20.9076 16.2352 21.1049 16.453 21.2624 16.7266L20.8879 16.9987C20.7366 16.7474 20.5746 16.5628 20.4019 16.4449C20.2709 16.3555 20.1501 16.319 20.0395 16.3355C19.9298 16.3508 19.84 16.4095 19.7703 16.5117C19.7224 16.5818 19.6947 16.6514 19.6874 16.7204C19.6808 16.7881 19.6935 16.8653 19.7253 16.952C19.7572 17.0388 19.829 17.1735 19.9409 17.3564C20.0664 17.563 20.1471 17.7309 20.183 17.8601C20.2189 17.9893 20.226 18.1123 20.2041 18.2291C20.1823 18.346 20.1298 18.4653 20.0466 18.5872Z"
        fill="#1E3575"
      />
      <path
        d="M23.1476 19.795C22.8476 20.2344 22.5134 20.507 22.1449 20.6127C21.7775 20.7192 21.4092 20.6464 21.04 20.3943C20.6663 20.1391 20.4613 19.8223 20.4251 19.4438C20.3908 19.0649 20.5258 18.6527 20.83 18.2071C21.1343 17.7616 21.4684 17.488 21.8324 17.3864C22.1975 17.2857 22.5669 17.3628 22.9406 17.618C23.3087 17.8693 23.5104 18.1848 23.5458 18.5645C23.5812 18.9442 23.4485 19.3544 23.1476 19.795ZM21.2577 18.5045C21.0307 18.8369 20.92 19.1312 20.9254 19.3875C20.9316 19.6426 21.0556 19.8527 21.2972 20.0177C21.5377 20.1819 21.7769 20.2207 22.0148 20.1341C22.2538 20.0482 22.4881 19.8373 22.7175 19.5013C22.9436 19.1702 23.054 18.8775 23.0489 18.6232C23.0449 18.3696 22.9227 18.1607 22.6822 17.9965C22.4395 17.8307 22.1976 17.791 21.9566 17.8773C21.7167 17.9644 21.4837 18.1734 21.2577 18.5045Z"
        fill="#1E3575"
      />
      <path
        d="M25.4198 19.8712C25.1873 19.7124 24.943 19.6773 24.6871 19.7661C24.4312 19.8548 24.1952 20.0573 23.9792 20.3737C23.7531 20.7047 23.6459 20.9988 23.6575 21.2557C23.6702 21.5135 23.798 21.7252 24.0407 21.891C24.1457 21.9627 24.2549 22.021 24.3682 22.0659C24.4824 22.1096 24.6048 22.1498 24.7353 22.1866L24.4768 22.565C24.228 22.5125 23.9806 22.4021 23.7344 22.2341C23.372 21.9866 23.1752 21.6772 23.1441 21.3058C23.1138 20.9332 23.2507 20.5241 23.555 20.0786C23.7466 19.7979 23.9611 19.5844 24.1985 19.4379C24.4371 19.2922 24.6838 19.2242 24.9386 19.234C25.1935 19.2438 25.4412 19.3308 25.6817 19.495C25.9346 19.6677 26.1288 19.8851 26.2643 20.1473L25.8678 20.4152C25.8136 20.3151 25.7506 20.2171 25.6788 20.1211C25.6089 20.0246 25.5226 19.9413 25.4198 19.8712Z"
        fill="#1E3575"
      />
      <path d="M24.9536 23.0124L26.7966 20.3132L27.2014 20.5895L25.3583 23.2887L24.9536 23.0124Z" fill="#1E3575" />
      <path
        d="M27.5498 24.7851L27.8143 23.8667L26.8676 23.2203L26.1137 23.8045L25.6869 23.5131L28.4639 21.4353L28.9042 21.736L27.9799 25.0788L27.5498 24.7851ZM27.9673 23.4135L28.237 22.5203C28.2537 22.4595 28.283 22.3657 28.3248 22.2391C28.3677 22.1132 28.4003 22.0218 28.4226 21.9648C28.2888 22.0954 28.1311 22.2305 27.9497 22.3701L27.2459 22.9209L27.9673 23.4135Z"
        fill="#1E3575"
      />
      <path
        d="M28.3068 25.302L30.1498 22.6028L30.5546 22.8791L28.97 25.1999L30.0183 25.9156L29.7598 26.2941L28.3068 25.302Z"
        fill="#1E3575"
      />
      <path
        d="M33.0157 27.4426C32.8518 27.6826 32.6436 27.8165 32.3912 27.8445C32.1387 27.8724 31.8724 27.7908 31.5924 27.5996C31.3124 27.4084 31.1156 27.2045 31.0019 26.9879L31.2868 26.5707C31.3602 26.7056 31.4488 26.8329 31.5526 26.9524C31.6575 27.0728 31.7647 27.1704 31.8742 27.2451C32.0345 27.3546 32.1752 27.4019 32.2962 27.3871C32.4184 27.3731 32.5177 27.31 32.5942 27.198C32.6631 27.0971 32.6865 26.9877 32.6644 26.8697C32.6423 26.7518 32.5545 26.57 32.401 26.3244C32.2433 26.0706 32.1618 25.848 32.1567 25.6568C32.1515 25.4656 32.2103 25.2801 32.333 25.1004C32.4867 24.8752 32.6812 24.748 32.9162 24.719C33.1512 24.69 33.3923 24.7599 33.6396 24.9287C33.8767 25.0906 34.074 25.3084 34.2315 25.5819L33.857 25.8541C33.7057 25.6028 33.5437 25.4182 33.371 25.3003C33.24 25.2109 33.1192 25.1744 33.0086 25.1909C32.8989 25.2062 32.8091 25.2649 32.7394 25.3671C32.6915 25.4372 32.6638 25.5068 32.6565 25.5758C32.6499 25.6435 32.6626 25.7207 32.6944 25.8074C32.7263 25.8941 32.7981 26.0289 32.91 26.2118C33.0355 26.4184 33.1162 26.5863 33.1521 26.7155C33.188 26.8447 33.1951 26.9677 33.1732 27.0845C33.1514 27.2014 33.0989 27.3207 33.0157 27.4426Z"
        fill="#1E3575"
      />
      <path
        d="M34.395 29.4591L32.9945 28.5028L34.8375 25.8036L36.2381 26.7599L35.9834 27.1328L34.9876 26.4529L34.4859 27.1877L35.4191 27.8248L35.1669 28.1941L34.2338 27.5569L33.6552 28.4043L34.651 29.0843L34.395 29.4591Z"
        fill="#1E3575"
      />
      <path
        d="M37.5964 28.1854C37.3638 28.0266 37.1195 27.9916 36.8636 28.0803C36.6077 28.169 36.3717 28.3715 36.1557 28.6879C35.9297 29.019 35.8224 29.313 35.834 29.57C35.8468 29.8277 35.9745 30.0394 36.2172 30.2052C36.3222 30.2769 36.4314 30.3352 36.5448 30.3801C36.659 30.4238 36.7813 30.464 36.9118 30.5008L36.6534 30.8793C36.4046 30.8267 36.1571 30.7163 35.911 30.5483C35.5485 30.3008 35.3518 29.9914 35.3206 29.62C35.2903 29.2474 35.4273 28.8383 35.7315 28.3928C35.9231 28.1121 36.1376 27.8986 36.375 27.7521C36.6136 27.6064 36.8603 27.5384 37.1152 27.5482C37.37 27.558 37.6177 27.645 37.8582 27.8092C38.1111 27.9819 38.3053 28.1993 38.4408 28.4615L38.0443 28.7295C37.9902 28.6293 37.9272 28.5313 37.8553 28.4353C37.7854 28.3388 37.6991 28.2555 37.5964 28.1854Z"
        fill="#1E3575"
      />
      <path
        d="M40.9139 29.9526L39.7214 31.6991C39.5852 31.8985 39.4264 32.0463 39.2448 32.1425C39.0652 32.2382 38.8732 32.2759 38.6687 32.2553C38.4662 32.2343 38.2616 32.1533 38.055 32.0122C37.7479 31.8026 37.5696 31.5509 37.5201 31.2572C37.4707 30.9636 37.5518 30.6617 37.7636 30.3515L38.9511 28.6123L39.3576 28.8899L38.1915 30.5977C38.0394 30.8204 37.9746 31.0171 37.9972 31.1877C38.0197 31.3583 38.1292 31.5107 38.3257 31.6449C38.7073 31.9054 39.0473 31.8172 39.3456 31.3803L40.5092 29.6762L40.9139 29.9526Z"
        fill="#1E3575"
      />
      <path
        d="M41.0949 31.9169L41.376 32.1088C41.5645 32.2376 41.7272 32.2927 41.864 32.2742C42.0008 32.2557 42.1204 32.1714 42.2229 32.0212C42.3272 31.8686 42.3562 31.7278 42.3102 31.5989C42.2641 31.47 42.149 31.3427 41.965 31.2171L41.6974 31.0344L41.0949 31.9169ZM40.8453 32.2824L40.109 33.3606L39.7043 33.0843L41.5473 30.385L42.2468 30.8626C42.5663 31.0808 42.7583 31.3075 42.8227 31.5428C42.8872 31.7782 42.8295 32.0275 42.6496 32.2909C42.4202 32.6269 42.0964 32.7568 41.6783 32.6807L41.5621 34.3528L41.1015 34.0383L41.2449 32.5553L40.8453 32.2824Z"
        fill="#1E3575"
      />
      <path d="M41.9228 34.5991L43.7659 31.8999L44.1706 32.1762L42.3276 34.8755L41.9228 34.5991Z" fill="#1E3575" />
      <path
        d="M43.8382 35.9069L43.4335 35.6306L45.0181 33.3098L44.2898 32.8126L44.5483 32.4341L46.4095 33.705L46.151 34.0834L45.4228 33.5862L43.8382 35.9069Z"
        fill="#1E3575"
      />
      <path
        d="M46.6491 35.6742L48.0759 34.8428L48.5162 35.1435L46.5632 36.2327L45.8484 37.2795L45.442 37.002L46.1467 35.97L46.4637 33.742L46.904 34.0426L46.6491 35.6742Z"
        fill="#1E3575"
      />
      <path d="M13.5742 31.5977L20.7345 36.4867" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <line
        x1="22.4126"
        y1="37.537"
        x2="26.8234"
        y2="40.5487"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="28.5215"
        y1="41.7089"
        x2="35.5506"
        y2="46.5084"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8345 38.2281L16.3954 38.0581C16.981 37.8807 17.5754 38.3073 17.5947 38.9188L17.5955 38.9439C17.6133 39.5064 18.1327 39.9177 18.6843 39.806V39.806C19.1652 39.7087 19.6366 40.0106 19.7493 40.4882L20.0017 41.558C20.1083 42.0099 20.512 42.329 20.9763 42.3284L21.8723 42.3271C22.3366 42.3265 22.7403 42.6456 22.8469 43.0975L23.1195 44.2527C23.2261 44.7046 23.6298 45.0237 24.0941 45.023L25.4731 45.0212C25.6747 45.0209 25.8718 45.0816 26.0383 45.1953L29.7275 47.7143"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="1.80229"
        y1="33.8143"
        x2="38.0245"
        y2="58.5471"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2454 21.1922C28.6549 26.6823 35.1025 31.1743 44.8838 41.4296"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="11.5382"
        y1="24.523"
        x2="5.96394"
        y2="32.6867"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10.15"
        y1="23.5738"
        x2="4.57576"
        y2="31.7375"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.03125"
        y="32.582"
        width="6.72909"
        height="1.08853"
        transform="rotate(34.3255 2.03125 32.582)"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10.0151"
        y="20.8984"
        width="5.04682"
        height="14.1509"
        transform="rotate(34.3255 10.0151 20.8984)"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8535 18.1992L17.4107 21.9937C16.4638 23.1431 15.8313 23.6207 14.3144 23.8338L10.0121 20.8961L11.8535 18.1992Z"
        fill="#F5F5F5"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="10.3853"
        y2="-0.5"
        transform="matrix(-0.563894 0.825847 0.825847 0.563894 43.8281 45.957)"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="10.3853"
        y2="-0.5"
        transform="matrix(-0.563894 0.825847 0.825847 0.563894 45.2202 46.9102)"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        width="6.72909"
        height="1.08853"
        transform="matrix(-0.825847 -0.563894 -0.563894 0.825847 39.0869 57.8789)"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        width="5.04682"
        height="14.1509"
        transform="matrix(-0.825847 -0.563894 -0.563894 0.825847 47.0669 46.1914)"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.9092 43.4961L43.352 39.7016C42.6262 41.002 42.4116 41.7649 42.7654 43.2553L47.0677 46.193L48.9092 43.4961Z"
        fill="#F5F5F5"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="20.1075"
        y1="29.8746"
        x2="19.0904"
        y2="31.3642"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="26.13"
        y1="33.9878"
        x2="25.1129"
        y2="35.4775"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="24.2031"
        y1="34.0294"
        x2="23.6396"
        y2="33.6447"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="28.8334"
        y1="37.1935"
        x2="28.27"
        y2="36.8087"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="31.2194"
        y1="37.4644"
        x2="30.2022"
        y2="38.9541"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="33.9996"
        y1="39.3628"
        x2="32.9825"
        y2="40.8525"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="21.4952"
        y1="30.8238"
        x2="20.4781"
        y2="32.3134"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="27.5143"
        y1="34.9332"
        x2="26.4971"
        y2="36.4228"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="32.6114"
        y1="38.4136"
        x2="31.5943"
        y2="39.9033"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="35.3873"
        y1="40.3121"
        x2="34.3702"
        y2="41.8017"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="22.8834"
        y1="31.773"
        x2="21.8663"
        y2="33.2626"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.1531 49.0472C78.1531 49.0472 78.1531 44.5428 78.1531 43.3267V15.5184H68.4016C66.1007 15.5184 64.1285 13.6198 64.1285 11.1629V1H43.3108C40.1333 1 37.6133 3.56864 37.6133 6.80736V49.2458C37.6133 52.4845 40.1333 55.0531 43.3108 55.0531H72.3617C75.2574 55.0531 78.1531 52.0502 78.1531 49.0472Z"
        fill="white"
      />
      <path
        d="M78.1531 15.5184V43.3267C78.1531 44.5428 78.1531 49.0472 78.1531 49.0472C78.1531 52.0502 75.2574 55.0531 72.3617 55.0531H43.3108C40.1333 55.0531 37.6133 52.4845 37.6133 49.2458V6.80736C37.6133 3.56864 40.1333 1 43.3108 1H64.1285M78.1531 15.5184H68.4017C66.1007 15.5184 64.1285 13.6198 64.1285 11.1629V1M78.1531 15.5184L64.1285 1"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2593 44.6176L43.5185 44.1343C43.7548 43.6938 44.3907 43.7062 44.6095 44.1557L44.6429 44.2243C44.8369 44.6228 45.3917 44.6569 45.633 44.2851V44.2851C45.8503 43.9503 46.3356 43.9369 46.571 44.2593L46.9485 44.7763C47.2798 45.23 47.9395 45.2767 48.3313 44.8742V44.8742C48.7232 44.4717 49.3829 44.5184 49.7142 44.9721L49.8812 45.2008C50.2463 45.7009 50.9735 45.7523 51.4054 45.3087L51.7837 44.9201C51.972 44.7267 52.2304 44.6176 52.5003 44.6176H55.5752"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="44.0132"
        y1="26.9336"
        x2="67.145"
        y2="26.9336"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="44.0132"
        y1="32.0664"
        x2="67.145"
        y2="32.0664"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="44.0132"
        y1="37.1992"
        x2="67.145"
        y2="37.1992"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="44.0093"
        y1="19.75"
        x2="54.8252"
        y2="19.75"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="60.7334"
        y="31.1172"
        width="49.2636"
        height="32.0213"
        rx="3"
        transform="rotate(-21.937 60.7334 31.1172)"
        fill="white"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="67.9556"
        y="40.8203"
        width="11.7001"
        height="13.5475"
        rx="1"
        transform="rotate(-21.937 67.9556 40.8203)"
        fill="white"
        stroke="#1E3575"
      />
      <path
        d="M74.8191 56.2009L74.9507 55.1233C75.017 54.5804 75.6554 54.3233 76.0794 54.6687L76.1067 54.6909C76.4871 55.0007 77.0605 54.8121 77.1827 54.3369V54.3369C77.2916 53.9133 77.7695 53.7047 78.1542 53.913L78.8211 54.2742C79.3221 54.5455 79.9482 54.3441 80.1969 53.8316L80.2853 53.6496C80.5341 53.1371 81.1601 52.9356 81.6611 53.207L82.1659 53.4803C82.6669 53.7516 83.2929 53.5502 83.5417 53.0377L83.9182 52.2621C84.0266 52.0388 84.2141 51.8639 84.4442 51.7712L87.7652 50.4337"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.1482 45.4884C77.5679 44.9167 78.2552 43.3024 77.6834 41.8828C77.1117 40.4632 75.4974 39.7758 74.0778 40.3476C72.6581 40.9193 71.9708 42.5336 72.5426 43.9532C73.1143 45.3729 74.7286 46.0602 76.1482 45.4884Z"
        fill="white"
        stroke="#1E3575"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.0463 50.9819C73.2854 49.0926 74.332 46.8837 76.3789 46.0593C78.4257 45.235 80.7111 46.1019 81.472 47.9912"
        fill="white"
      />
      <path
        d="M74.0463 50.9819C73.2854 49.0926 74.332 46.8837 76.3789 46.0593C78.4257 45.235 80.7111 46.1019 81.472 47.9912"
        stroke="#1E3575"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.302 21.3201C93.0389 23.2028 92.4819 27.4241 90.3466 28.8171"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.1277 20.9622C99.1965 21.918 99.1802 23.9653 98.5643 24.5084"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.5845 20.4009C95.6347 22.3529 95.5794 25.4541 94.1127 26.6365"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.5882 20.4007C96.4962 21.6086 98.5924 24.0296 99.7135 24.0501"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.415 20.0407C101.4 21.0518 101.133 23.1719 100.28 23.817"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.414 20.0413C102.065 21.107 103.999 23.0122 105.195 22.8339"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="82.526"
        y1="37.7322"
        x2="92.4514"
        y2="33.7348"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="84.3644"
        y1="42.3025"
        x2="90.8625"
        y2="39.6854"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="85.2897"
        y1="44.5877"
        x2="93.5014"
        y2="41.2804"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="86.2067"
        y1="46.8728"
        x2="96.1321"
        y2="42.8754"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="83.4474"
        y1="40.0213"
        x2="91.6592"
        y2="36.714"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="102.298"
        y1="32.4275"
        x2="109.938"
        y2="29.3503"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="101.376"
        y1="30.1424"
        x2="109.017"
        y2="27.0652"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.759 22.6026C100.083 22.9627 96.8283 25.2937 92.7639 27.6139C90.1542 29.1037 77.7831 35.5362 67.4418 38.7054"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.06 28.1748C95.1397 25.89 93.2993 21.3203 93.2993 21.3203L95.5842 20.4001L98.3448 27.2546"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.2 26.1018C100.51 24.3882 99.1299 20.9609 99.1299 20.9609L101.415 20.0407L103.485 25.1816"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M105.595 23.0078L106.17 24.4358" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.1753 29.2188L90.6354 30.3612" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <line
        x1="85.9704"
        y1="31.4489"
        x2="86.287"
        y2="32.2349"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="81.6301"
        y1="33.8629"
        x2="81.7166"
        y2="34.0778"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.2704 33.9035L64.9145 30.5752L65.4586 30.3535L66.6244 33.2152L68.0336 32.6411L68.2237 33.1078L66.2704 33.9035Z"
        fill="#1E3575"
      />
      <path d="M68.7974 32.8741L67.4415 29.5457L67.9856 29.324L69.3415 32.6524L68.7974 32.8741Z" fill="#1E3575" />
      <path
        d="M70.4534 28.8071C70.1407 28.9345 69.94 29.1455 69.8512 29.44C69.7623 29.7346 69.7974 30.0769 69.9563 30.4669C70.1226 30.8752 70.3342 31.1491 70.591 31.2887C70.8493 31.4277 71.1417 31.4307 71.468 31.2977C71.6091 31.2402 71.7402 31.1709 71.8611 31.0898C71.9814 31.0072 72.1039 30.9148 72.2286 30.8127L72.4188 31.2794C72.1979 31.4844 71.922 31.6543 71.5912 31.7891C71.104 31.9875 70.6699 31.9927 70.2889 31.8046C69.9073 31.615 69.6045 31.2455 69.3807 30.6961C69.2397 30.35 69.1794 30.0216 69.1996 29.7107C69.2214 29.3993 69.324 29.1256 69.5076 28.8898C69.6911 28.654 69.9445 28.4703 70.2678 28.3386C70.6078 28.2001 70.951 28.1434 71.2975 28.1686L71.2863 28.7014C71.1523 28.6941 71.0143 28.6963 70.8723 28.7082C70.7311 28.7179 70.5915 28.7509 70.4534 28.8071Z"
        fill="#1E3575"
      />
      <path
        d="M75.0604 30.3227L73.1776 31.0897L71.8217 27.7613L73.7045 26.9943L73.8918 27.4542L72.5532 27.9995L72.9223 28.9056L74.1767 28.3946L74.3622 28.8499L73.1078 29.3609L73.5335 30.4059L74.8721 29.8606L75.0604 30.3227Z"
        fill="#1E3575"
      />
      <path
        d="M78.555 28.8991L77.8879 29.1708L75.1706 27.1854L75.1524 27.1928L75.224 27.3362C75.3603 27.6098 75.476 27.8635 75.5712 28.0972L76.2761 29.8275L75.7821 30.0287L74.4261 26.7003L75.0864 26.4314L77.7936 28.405L77.8072 28.3994C77.79 28.3657 77.7323 28.2415 77.6341 28.0266C77.5354 27.8103 77.461 27.6406 77.4109 27.5177L76.7005 25.7738L77.199 25.5707L78.555 28.8991Z"
        fill="#1E3575"
      />
      <path
        d="M80.9847 26.8555C81.1053 27.1514 81.0929 27.4272 80.9475 27.6829C80.8021 27.9385 80.5412 28.143 80.1649 28.2963C79.7885 28.4497 79.4565 28.5168 79.1691 28.4976L78.9595 27.9831C79.1404 27.9926 79.3241 27.9832 79.5108 27.9549C79.699 27.926 79.8667 27.8816 80.0139 27.8216C80.2295 27.7338 80.3714 27.6282 80.4397 27.5049C80.5095 27.3809 80.5163 27.2498 80.46 27.1117C80.4093 26.9872 80.3193 26.9009 80.1899 26.8527C80.0606 26.8046 79.8246 26.7813 79.4819 26.7828C79.1283 26.7835 78.8571 26.7303 78.6683 26.6232C78.4796 26.516 78.3401 26.3517 78.2498 26.1301C78.1366 25.8523 78.1463 25.5936 78.2787 25.3539C78.411 25.1141 78.6434 24.9265 78.9758 24.7911C79.2945 24.6613 79.6402 24.6019 80.0127 24.6129L80.0206 25.1273C79.6739 25.1234 79.3845 25.1688 79.1523 25.2634C78.9762 25.3351 78.8584 25.4282 78.7989 25.5427C78.7387 25.6557 78.7343 25.7752 78.7856 25.9012C78.8209 25.9877 78.8694 26.0546 78.9312 26.102C78.9923 26.1479 79.0758 26.1811 79.1815 26.2018C79.2872 26.2224 79.4669 26.2333 79.7204 26.2344C80.0062 26.2366 80.2239 26.2585 80.3736 26.3001C80.5233 26.3418 80.6473 26.4072 80.7455 26.4963C80.8438 26.5855 80.9235 26.7052 80.9847 26.8555Z"
        fill="#1E3575"
      />
      <path
        d="M83.8799 26.7298L81.9972 27.4968L80.6413 24.1684L82.524 23.4014L82.7114 23.8613L81.3727 24.4066L81.7418 25.3127L82.9962 24.8017L83.1817 25.257L81.9273 25.768L82.353 26.813L83.6917 26.2677L83.8799 26.7298Z"
        fill="#1E3575"
      />
    </svg>
  </IconContainer>
);
