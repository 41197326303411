import { useNavigate } from 'react-router-dom';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { LockRequest } from 'api/payment-extension-lock-api/LockRequest';
import { LockResponse } from 'api/payment-extension-lock-api/LockResponse';
import { paymentExtensionLockApi } from 'api/payment-extension-lock-api/payment-extension-lock-api';
import routes from 'constants/routes';
import { logger } from 'services';

interface IUsePELockResponse {
  isMutatePELockSuccess: boolean;
  mutatePELock: UseMutateFunction<AxiosResponse<LockResponse>, unknown, LockRequest, unknown>;
}

const usePELock = (): IUsePELockResponse => {
  const navigate = useNavigate();
  const { isSuccess: isMutatePELockSuccess, mutate: mutatePELock } = useMutation({
    mutationKey: ['payment-extension-lock'],
    mutationFn: (lockRequest: LockRequest) => paymentExtensionLockApi(lockRequest),
    onSuccess: (apiResponse: AxiosResponse<LockResponse>) => {
      if (apiResponse.data.messageReply.replyCode.toLowerCase() !== 'success') {
        logger.info('[API NOT SUccessful] Lock API was not successful! \nNavigating to PE Error Page', apiResponse);
        navigate(routes.error.paymentExtension);
      }
    },
    onError: (error: unknown) => {
      logger.info('[API Error] Lock API Error! \nNavigating to PE Error Page', error);
      navigate(routes.error.paymentExtension);
    },
  });

  return {
    isMutatePELockSuccess,
    mutatePELock,
  };
};

export default usePELock;
