import React from 'react';
import { createTheme } from '@mui/material';

import { breakpointsConfig } from './breakpoints/breakpointsConfig';
import { paletteConfig } from './paletteConfig';
import { typographyConfig } from './typographyConfig';

export const theme = createTheme({
  palette: paletteConfig,
  typography: typographyConfig,
  breakpoints: breakpointsConfig,
});

declare module '@mui/material/styles' {
  interface Palette {
    dteGrey: Palette['primary'];
    dteBlue: Palette['primary'];
    dteRed: Palette['primary'];
    dteGreen: Palette['primary'];
    dteBlack: Palette['primary'];
  }
  interface PaletteOptions {
    dteGrey: PaletteOptions['primary'];
    dteBlue: PaletteOptions['primary'];
    dteRed: PaletteOptions['primary'];
    dteGreen: PaletteOptions['primary'];
    dteBlack: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    title: React.CSSProperties;
    input: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    input?: React.CSSProperties;
  }
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    input: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    text: false;
    outlined: false;
    // contained: false; // TODO - we currently use this. should move away from it so this variant can be turned off
    primary: true;
    secondary: true;
  }
}

theme.components!.MuiButton = {
  variants: [
    {
      props: { variant: 'primary' },
      style: {
        background: theme.palette.dteBlue.main,
        textTransform: 'none',
        color: theme.palette.common.white,
        height: '40px',
        borderRadius: 'unset',
        width: '100%',
        boxShadow: 'unset',
        '&:hover': {
          background: theme.palette.dteBlue.dark,
          boxShadow: 'unset',
        },
        '&:focus': {
          background: theme.palette.dteBlue.dark,
          color: theme.palette.common.white,
          boxShadow: 'unset',
        },
        '&.Mui-disabled': {
          background: '#73767B', // TODO - this color is not defined in the theme color palette
          color: theme.palette.common.white,
        },
        [theme.breakpoints.down('tablet')]: {
          maxWidth: '327px',
        },
        [theme.breakpoints.up('tablet')]: {
          maxWidth: '300px',
        },
      },
    },
    {
      props: { variant: 'secondary' },
      style: {
        background: theme.palette.common.white,
        textTransform: 'none',
        color: theme.palette.dteBlue.main,
        height: '40px',
        borderRadius: 'unset',
        border: `1px solid ${theme.palette.dteBlue.main}`,
        width: '100%',
        boxShadow: 'unset',
        '&:hover': {
          background: theme.palette.dteBlue.dark,
          color: theme.palette.common.white,
          boxShadow: 'unset',
          border: 'unset',
        },
        '&:focus': {
          background: theme.palette.dteBlue.dark,
          color: theme.palette.common.white,
          boxShadow: 'unset',
          border: 'unset',
        },
        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.dteGrey.dark}`,
          background: '#F5F5F5', // TODO - this color is not defined in the theme color palette
          color: '#73767B', // TODO - this color is not defined in the theme color palette
        },
        [theme.breakpoints.down('tablet')]: {
          maxWidth: '327px',
        },
        [theme.breakpoints.up('tablet')]: {
          maxWidth: '300px',
        },
      },
    },
  ],
};

theme.components!.MuiTabs = {
  styleOverrides: {
    root: {
      height: '72px',
      backgroundColor: theme.palette.dteGrey.light,
      alignItems: 'flex-end',
      '& .MuiTabs-flexContainer': {
        gap: '4px',
      },
      [theme.breakpoints.down('mobile')]: {
        padding: '0px 8px',
      },
      [theme.breakpoints.between('mobile', 'tablet')]: {
        padding: '0px 24px',
      },
      [theme.breakpoints.up('tablet')]: {
        padding: '0px 40px',
      },
    },
    indicator: {
      top: '0px',
      height: '4px',
      backgroundColor: theme.palette.dteBlue.dark,
    },
  },
};

theme.components!.MuiTab = {
  styleOverrides: {
    root: {
      maxWidth: '470px',
      height: '60px',
      textTransform: 'none',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.dteBlue.main,
      fontWeight: 600,
      '&.Mui-selected': {
        color: theme.palette.dteBlue.dark,
        fontWeight: 700,
        '&:hover': {
          textDecoration: 'unset',
        },
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus-visible': {
        textDecoration: 'underline',
      },
      [theme.breakpoints.up('desktop')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('desktop')]: {
        fontSize: '16px',
      },
    },
  },
};
