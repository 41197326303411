import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material';

import { dayjsDate, DteHeader, DteStyledLogo, NewPageTemplate } from 'component-library';
import { PageTitles } from 'constants/';
import { useDunningLockDetails } from 'hooks';

import { onPageViewHandler } from './handlers';
import { WLockContent } from './WLockContent';

export default (): React.ReactElement => {
  const theme = useTheme();
  const { isDunningLockDetailsSuccess, dunningLockDetails } = useDunningLockDetails({ dunningLockReason: 'W' });
  const lockEndDate: string = dunningLockDetails
    ? dayjsDate(dunningLockDetails[0].endDate).format('dddd, MMMM D, YYYY')
    : '';

  useEffect(() => {
    if (isDunningLockDetailsSuccess) {
      onPageViewHandler();
    }
  }, [isDunningLockDetailsSuccess, onPageViewHandler]);

  const header = <DteHeader logo={<DteStyledLogo />} title="Service Protection" />;

  const content = <WLockContent lockEndDate={lockEndDate} />;

  const mainContainerSx = {
    padding: '0px 0px',
    [theme.breakpoints.down('mobile')]: {
      padding: '0px 0px',
    },
  };

  return (
    <NewPageTemplate
      content={content}
      mainContainerSx={mainContainerSx}
      pageHeader={header}
      showContent={isDunningLockDetailsSuccess}
      titleTag={PageTitles.SERVICE_PROTECTION_W_LOCK}
    />
  );
};
