import trackAccordionClick from './trackAccordionClick';
import trackApi from './trackApi';
import trackButtonClick from './trackButtonClick';
import trackEvent from './trackEvent';
import trackViewEvent from './trackViewEvent';

const googleTagManager = {
  trackAccordionClick,
  trackApi,
  trackButtonClick,
  trackEvent,
  trackViewEvent,
};

export default googleTagManager;
