import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { offeredPeScheduledDate } from '../dates/dayjsApStyleAbbreviateMonths';

const apFormattedPeDate = offeredPeScheduledDate().format('dddd, MMM D, YYYY');

const usePaymentExtensionSubtitleStyling = (): { sx: Record<string, SxProps> } => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    peSubtitleWrapper: {
      marginTop: '24px',
      textAlign: 'left',
      color: theme.palette.secondary.main,
    },
  };

  return { sx };
};

export const PaymentExtensionSubtitle = (): React.ReactElement => {
  const { sx } = usePaymentExtensionSubtitleStyling();

  return (
    <Box sx={sx.peSubtitleWrapper} data-testid="pe-sub-title">
      <Typography component="span" variant="body1" data-testid="sub-title-a">
        {`You are currently eligible for a Payment Extension, which allows you to keep your service active by `}
      </Typography>
      <Typography component="span" variant="body1" data-testid="sub-title-b" fontWeight={600}>
        {`scheduling a payment for ${apFormattedPeDate}.`}
      </Typography>
    </Box>
  );
};

export const PaymentExtensionConfirmDetailsSubtitle = ({
  shutoffDate,
}: PaymentExtensionConfirmTitleProps): React.ReactElement => {
  const { sx } = usePaymentExtensionSubtitleStyling();

  return (
    <Box sx={sx.peSubtitleWrapper} data-testid="pe-sub-title">
      <Typography component="span" variant="body1" data-testid="sub-title-a">
        {`We are ready to process your scheduled payment(s), however `}
      </Typography>
      <Typography component="span" variant="body1" data-testid="sub-title-b" fontWeight={600}>
        {`your shutoff date is ${shutoffDate}. `}
      </Typography>
      <Typography component="span" variant="body1" data-testid="sub-title-c">
        By scheduling a Payment Extension, you prevent interruption of service.
      </Typography>
    </Box>
  );
};

export const ReconnectSubtitle = (): React.ReactElement => (
  <Typography variant="body1" data-testid="service-sub-title">
    We want to help you quickly get your service reconnected.
  </Typography>
);
