import messages from './messages';

const getMonthsText = (numberOfMonths: number | string): string => {
  switch (numberOfMonths) {
    case '3':
      return messages.threeMonths;

    case '4':
      return messages.fourMonths;

    case '5':
      return messages.fiveMonths;

    case '6':
      return messages.sixMonths;

    default:
      return '';
  }
};

export default getMonthsText;
