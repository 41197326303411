import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { Dayjs } from 'dayjs';

import {
  DeadlineNoticeIcon,
  InterruptionNoticeIcon,
  PaymentDifferenceNoticeIcon,
} from 'component-library/icons/PaymentExtensionNoticeIcons';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

interface PaymentExtensionScheduledNoticeProps {
  date: Dayjs;
}

export const PaymentExtensionScheduledNotice = ({ date }: PaymentExtensionScheduledNoticeProps): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    noticesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '24px',
      backgroundColor: theme.palette.dteGrey.light,
    },
    smallMobileNoticesWrapper: {
      padding: '24px 8px',
    },
    noticeWrapper: {
      display: 'flex',
      gap: '20px',
      color: theme.palette.secondary.main,
    },
  };

  const smallMobileNoticesStyling = { ...sx.noticesWrapper, ...sx.smallMobileNoticesWrapper };
  const responsiveNoticesStyling = useResponsiveSx(
    sx.noticesWrapper,
    sx.noticesWrapper,
    sx.noticesWrapper,
    smallMobileNoticesStyling,
  );

  const peScheduledDate = date.format('dddd, MMM D, YYYY');

  return (
    <Box data-testid="pe-extension-scheduled-notices" sx={responsiveNoticesStyling}>
      <Typography variant="body2" color="primary.main" fontWeight={600}>
        Payment Extension Notices
      </Typography>
      <Box sx={sx.noticeWrapper}>
        <DeadlineNoticeIcon />
        <Typography variant="body1">
          {`If you choose to make changes to this future-dated payment, please pay your entire past-due balance before 2
          p.m. on ${peScheduledDate} to avoid service interruption.`}
        </Typography>
      </Box>
      <Box sx={sx.noticeWrapper}>
        <InterruptionNoticeIcon />
        <Typography variant="body1">
          If your payment cannot be processed or we do not receive it, you are at risk of service interruption on or
          after your shutoff date and ineligible for a Payment Extension for one year.
        </Typography>
      </Box>
      <Box sx={sx.noticeWrapper}>
        <PaymentDifferenceNoticeIcon />
        <Typography variant="body1">
          Any payment(s) you make prior to your scheduled payment date will be deducted from your scheduled payment
          amount.
        </Typography>
      </Box>
    </Box>
  );
};
