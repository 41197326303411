/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { CircularProgress, Collapse, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { Cell, Row } from '@tanstack/react-table';
import { ITestUser } from 'admin-page/types/ITestUser';

import { theme } from 'theme/theme';

import { CopyUsername } from '../../copy/CopyUsername';

import { EligibleTypeText } from './AdminPageTypography';
import EligibilityType from './EligibilityType';

interface TestUserRowProps {
  row: Row<ITestUser>;
}

export const TestUserRow = (props: TestUserRowProps): React.ReactElement => {
  const { row } = props;
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const infoDetails = (label?: string): React.ReactElement => (
    <>
      {label && (
        <Typography variant="body1" color={palette.dteRed.dark}>
          {label}
        </Typography>
      )}
    </>
  );

  const expandCollapseRow = (): void => {
    setOpen(!open);
  };

  const tableRowSx = row
    ? {
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.dteBlue.light,
      },
      ...(open && {
        background: theme.palette.dteGrey.light,
      }),
    }
    : {};

  const preSx = { backgroundColor: palette.dteGrey.light, fontSize: '10px', margin: '0', padding: '15px' };

  return (
    <>
      <TableRow key={row.id} onClick={expandCollapseRow} sx={tableRowSx}>
        {row.getVisibleCells().map((cell: Cell<ITestUser, unknown>): React.ReactElement => {
          if (cell.column.id === 'BP') {
            return (
              <TableCell key={cell.id} width="10%">
                <CopyUsername bpId={cell.getValue()} />
              </TableCell>
            );
          }

          if (cell.column.id === 'EligibilityType') {
            if (row.original.status?.isGetDataError) {
              return (
                <TableCell key={cell.id} colSpan={5}>{infoDetails(row.original.status.errorText || 'An Error Occurred While Retreiving Eligibility')}</TableCell>
              );
            }

            if (!row.original.status?.isGetDataError && !row.original.status?.isGetDataSuccess) {
              return (
                <TableCell key={cell.id} colSpan={5}><CircularProgress /></TableCell>
              );
            }
            return (
              <TableCell key={cell.id}>
                {row.original.EligibilityType && <EligibleTypeText>{row.original.EligibilityType}</EligibleTypeText>}
                {row.original.status?.isGetDataSuccess && row.original.EligibilityType?.length === 0 && infoDetails('No Eligibility Data Found')}
              </TableCell>
            );
          }

          if (cell.column.id === 'PA') {
            return <TableCell key={cell.id}><EligibilityType type={row.original.PA} /></TableCell>;
          }

          if (cell.column.id === 'PE') {
            return <TableCell key={cell.id}><EligibilityType type={row.original.PE} /></TableCell>;
          }

          if (cell.column.id === 'RE') {
            return <TableCell key={cell.id}><EligibilityType type={row.original.RE} /></TableCell>;
          }

          if (cell.column.id === 'DL') {
            return (
              <TableCell key={cell.id}>
                <EligibilityType type={row.original.DL} />
                {row.original.DunningLockType && <Typography variant="body1">{row.original.DunningLockType}</Typography>}
              </TableCell>
            );
          }
          return <></>
        })}
      </TableRow>
      <TableRow key={`${row.id}-details`}>
        <TableCell colSpan={2} sx={{ backgroundColor: palette.dteGrey.light, padding: '0' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <pre style={preSx}>{JSON.stringify(row.original.jsonResponse, null, 2)}</pre>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
