import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { axiosGet, axiosPost } from './axios-adapter';

/**
 * this is solving an issue where some links from newlook include the www when the user logs in. the webSecurityToken
 * cookie needs to be passed when calling these api's. in order for the 'withCredentials' in the config to attach the
 * cookie the baseURL needs to also include the www
 */
const isWWWReferrer = (): boolean => document.referrer.includes('://www.');

// withCredentials: true -> sets cookie
const awsConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: isWWWReferrer() ? process.env.REACT_APP_API_WWW_HOST : process.env.REACT_APP_API_HOST,
};

export const awsGet = async <T>(url: string): Promise<AxiosResponse<T>> => axiosGet(url, awsConfig);

export const awsPost = async <T>(url: string, body: unknown): Promise<AxiosResponse<T>> =>
  axiosPost(url, body, awsConfig);
