const messages = {
  fiveMonths: 'for 5 months',
  fourMonths: 'for 4 months',
  perMonth: '/Month',
  planheading: 'Select your monthly installment plan.',
  planNotice:
    'In addition to your Payment Agreement monthly installment, you are required to pay your current energy charges and any other program fees. Please ensure total payments are made on time.',
  sixMonths: 'for 6 months',
  threeMonths: 'for 3 months',
};

export default messages;
