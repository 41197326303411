const eligibilityTypes = {
  DunningLock: 'DunningLock' as EligibilityTypes,
  PaymentAgreement: 'PaymentAgreement' as EligibilityTypes,
  PAEnrolled: 'PaymentAgreementEnrolled' as EligibilityTypes,
  PaymentExtension: 'PaymentExtension' as EligibilityTypes,
  PaymentOffers: 'PaymentOffers' as EligibilityTypes,
  Reconnect: 'Reconnect' as EligibilityTypes,
};

export default eligibilityTypes;
