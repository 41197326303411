import { useState } from 'react';
import { styled, TextField, Theme } from '@mui/material';

import { DtePrimaryButton } from '../../component-library/buttons/DtePrimaryButton';

import onSubmitPaymentCenterTestLambda from './onSubmitPaymentCenterTestLambda';

const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '327px',
  },
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
  },
}));

const PaymentCenterTestContent: React.FC = (): React.ReactElement => {
  const [accounts, setAccounts] = useState<string>('');
  const [amount, setAmount] = useState<string>('0.00');

  const onChangeAccounts = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAccounts(event.target.value);
  };

  const onChangeAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAmount(event.target.value);
  };

  return (
    <Container>
      <TextField id="amount" label="Amount" onChange={onChangeAmount} placeholder="0.00" required value={amount} />
      <TextField id="accounts" label="Accounts" onChange={onChangeAccounts} required value={accounts} />
      <DtePrimaryButton
        data-testid="payment-submit-button"
        name="Make a Payment"
        onClick={(): Promise<void> => onSubmitPaymentCenterTestLambda({ accounts, amount })}
      />
    </Container>
  );
};

export default PaymentCenterTestContent;
