import * as React from 'react';
import { KeyboardEvent, useContext } from 'react';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { styled } from '@mui/material';

import {
  AdditionalAssistanceBanner,
  DefaultAssistanceMessage,
} from 'component-library/banners/AdditionalAssistanceBanner';
import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import { Breadcrumb } from 'component-library/links/Breadcrumb';
import { StyledCtaLink } from 'component-library/links/StyledLink';
import { TabPanelContainer } from 'component-library/tabs/TabPanelContainer';
import { TabContext } from 'component-library/tabs/TabsContext';
import { Bold } from 'component-library/typography/Bold';

import { currentBillUrl } from '../../../urls-and-paths';
import { LockStatusContent } from '../../LockStatusContent';

import { SubmitAMedicalCertificateForm } from './SubmitAMedicalCertificateForm';

type PanelProps = {
  lockEndDate: string;
  viewCurrentBillOnClickHandler: () => void;
  energyAssistanceProgramsLinkOnClickHandler: () => void;
  unitedWayLinkOnClickHandler: () => void;
  completeFormOnClickHandler: () => void;
  uploadFormOnClickHandler: () => void;
  viewMilitaryLinkOnClickHandler: () => void;
};

export const MedicalPanel = ({
  lockEndDate,
  viewCurrentBillOnClickHandler,
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
  completeFormOnClickHandler,
  uploadFormOnClickHandler,
  viewMilitaryLinkOnClickHandler,
}: PanelProps): React.ReactElement => {
  const { panelTabChangeHandler } = useContext(TabContext);
  const TopContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  });

  const SubmitAMedicalFormAndAssistanceBanner = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  });

  const BottomContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  });

  const handleTabChange = (): void => {
    panelTabChangeHandler(1);
    viewMilitaryLinkOnClickHandler();
  };

  const viewMilitaryLinkClickHandler = (): void => {
    handleTabChange();
  };

  const viewMilitaryLinkKeyboardHandler = (event: KeyboardEvent): void => {
    if (event.code === 'Enter') {
      handleTabChange();
    }
  };

  return (
    <TabPanelContainer data-testid="medical-panel">
      <LockStatusContent>
        <TopContent>
          <Breadcrumb text="View Current Bill" href={currentBillUrl} onClickHandler={viewCurrentBillOnClickHandler} />
          <SuccessBanner
            message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
          />
        </TopContent>
        <SubmitAMedicalFormAndAssistanceBanner>
          <SubmitAMedicalCertificateForm
            lockEndDate={lockEndDate}
            completeFormOnClickHandler={completeFormOnClickHandler}
            uploadFormOnClickHandler={uploadFormOnClickHandler}
          />
          <BottomContent>
            <AdditionalAssistanceBanner
              message={
                <DefaultAssistanceMessage
                  energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
                  unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
                />
              }
            />
            <StyledCtaLink
              sx={{ cursor: 'pointer', marginBottom: '-1px' }}
              onClick={viewMilitaryLinkClickHandler}
              tabIndex={0}
              onKeyDown={viewMilitaryLinkKeyboardHandler}
              data-testid="view-military-link"
            >
              <Bold>View Military</Bold>
              <ArrowForwardIosRounded sx={{ fontSize: '18px' }} />
            </StyledCtaLink>
          </BottomContent>
        </SubmitAMedicalFormAndAssistanceBanner>
      </LockStatusContent>
    </TabPanelContainer>
  );
};
