import { styled } from '@mui/material';

import { currentBillUrl } from '../../../urls-and-paths';
import { DteSecondaryButton } from '../../buttons/DteSecondaryButton';

import messages from './messages';

const redirectToCurrentBillHandler = (): void => {
  window.location.href = currentBillUrl;
};

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '40px',
  width: '300px',
}));

const ViewCurrentBillButton: React.FC<ErrorPageViewCurrentBillClickHandler> = (
  props: ErrorPageViewCurrentBillClickHandler,
): React.ReactElement => {
  const { onClick } = props;

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
    redirectToCurrentBillHandler();
  };

  return (
    <Container>
      <DteSecondaryButton name={messages.viewCurrentBillButtonLabel} onClick={handleClick} />
    </Container>
  );
};

export default ViewCurrentBillButton;
