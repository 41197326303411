export const mockBasePaymentExtensionSuccess: IPaymentExtensionDetailsResponse = {
  paymentExtensionDetails: {
    pastDueAmount: '100.00',
    shutOffDate: '2023-04-02',
    shutOffAmount: '50.00',
    sumOfAllPayments: '0.00',
  },
  messageReply: {
    replyCode: 'Success',
  },
};
