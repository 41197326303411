import { StyledComponent } from '@emotion/styled';
import { Link, LinkProps, styled, Theme } from '@mui/material';

type StyledComponentProps = Pick<
  LinkProps,
  'href' | 'children' | 'variant' | 'sx' | 'onClick' | 'tabIndex' | 'onKeyDown' | 'role'
>;

const StyledLink: StyledComponent<StyledComponentProps> = styled(Link)(({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '10px',
  color: theme.palette.dteBlue.main,
  '&:hover': {
    color: theme.palette.dteBlue.dark,
  },
  '&:focus': {
    color: theme.palette.dteBlue.dark,
  },
}));

export const StyledCtaLink = styled(StyledLink)({
  textDecoration: 'none',
  '&:focus': {
    outlineColor: 'transparent',
    textDecoration: 'underline',
  },
});

export const StyledTextLink = styled(StyledLink)({
  cursor: 'pointer',
  textDecoration: 'underline',
});
