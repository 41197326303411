import { styled, Theme } from '@mui/material';

import ContactUsButton from './ContactUsButton';
import ViewCurrentBillButton from './ViewCurrentBillButton';

const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '327px',
  },
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
  },
}));

const GenericErrorPageAction: React.FC<GenericErrorPageActionProps> = (
  props: GenericErrorPageActionProps,
): React.ReactElement => {
  const { onContactUsButtonClick, onViewCurrentBillButtonClick } = props;
  return (
    <Container data-testid="generic-error-page-action">
      <ContactUsButton onClick={onContactUsButtonClick} />
      <ViewCurrentBillButton onClick={onViewCurrentBillButtonClick} />
    </Container>
  );
};

export default GenericErrorPageAction;
