import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputAdornment, SxProps, Typography, useTheme } from '@mui/material';

const DollarStartAdornment: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    adornment: {
      minWidth: '35px',
      maxHeight: '36px',
      height: '36px',
      background: theme.palette.dteGrey.light,
      margin: '0px',
      borderRight: `1px solid ${theme.palette.dteGrey.dark}`,
      borderTop: `1px solid ${theme.palette.dteGrey.dark}`,
      borderBottom: `1px solid ${theme.palette.dteGrey.dark}`,
      display: 'flex',
      justifyContent: 'center',
    },
    failAdornment: {
      minWidth: '35px',
      maxHeight: '36px',
      height: '36px',
      background: theme.palette.dteRed.light,
      margin: '0px',
      borderRight: `1px solid ${theme.palette.dteRed.dark}`,
      borderTop: `1px solid ${theme.palette.dteRed.dark}`,
      borderBottom: `1px solid ${theme.palette.dteRed.dark}`,
      display: 'flex',
      justifyContent: 'center',
    },
    adornmentText: {
      color: theme.palette.primary.main,
    },
    failAdornmentText: {
      color: theme.palette.dteRed.dark,
    },
  };

  const { formState } = useFormContext();
  const adornmentValidationsSx =
    formState.errors.PaymentOptions?.type !== 'inputEmptyOrZero' ? sx.adornment : sx.failAdornment;
  const adornmentTextValidationsSx =
    formState.errors.PaymentOptions?.type !== 'inputEmptyOrZero' ? sx.adornmentText : sx.failAdornmentText;

  return (
    <InputAdornment data-testid="pay-another-amount-adornment" position="start" sx={adornmentValidationsSx}>
      <Typography
        data-testid="pay-another-amount-adornment-text"
        fontWeight={600}
        sx={adornmentTextValidationsSx}
        variant="body1"
      >
        $
      </Typography>
    </InputAdornment>
  );
};

export default DollarStartAdornment;
