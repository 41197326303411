const messages = {
  messageChunk1: 'Note:',
  messageChunk2: 'We are unable to accept your payment online. Please make your cash payment at a',
  messageChunk3: 'or at one of the',
  messageChunk4: 'near you.',
  dteKiosk: 'DTE kiosk',
  authorizedPaymentLocations: 'authorized payment locations',
};

export default messages;
