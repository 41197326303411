import React from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { ITestUser } from 'admin-page/types/ITestUser';

interface TestUsersPaginationProps {
  table?: Table<ITestUser>;
}

export const TablePagination: React.FC<TestUsersPaginationProps> = (
  props: TestUsersPaginationProps,
): React.ReactElement => {
  const { table } = props;
  const backOne = `<`;
  const fastBack = `<<`;
  const fastForward = `>>`;
  const forwardOne = `>`;

  const firstPage = (): void => table?.setPageIndex(0);
  const lastPage = (): void => table?.setPageIndex(table.getPageCount() - 1);
  const nextPage = (): void => table?.nextPage();
  const previousPage = (): void => table?.previousPage();

  return (
    <ButtonGroup aria-label="outlined secondary button group" color="secondary" variant="contained">
      <Button onClick={firstPage} disabled={!table?.getCanPreviousPage()}>
        <Typography>{fastBack}</Typography>
      </Button>
      <Button onClick={previousPage} disabled={!table?.getCanPreviousPage()}>
        <Typography>{backOne}</Typography>
      </Button>
      <Button onClick={nextPage} disabled={!table?.getCanNextPage()}>
        <Typography>{forwardOne}</Typography>
      </Button>
      <Button onClick={lastPage} disabled={!table?.getCanNextPage()}>
        <Typography>{fastForward}</Typography>
      </Button>
    </ButtonGroup>
  );
};
