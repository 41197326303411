import { googleTagManager } from 'services';

import { useEligibility } from '../../api/eligibility-api/eligibility-api';
import { useUserState } from '../../auth/authenticate';
import { unitedWayPhoneNumber } from '../../collections-copy';
import { accountHistoryUrl, currentBillUrl } from '../../urls-and-paths';

import {
  cLockAgencyPhoneNumberLinkOnClickEvent,
  cLockEnergyAssistanceProgramsLinkOnClickEvent,
  cLockUnitedWayLinkOnClickEvent,
  cLockViewCurrentBillButtonOnClickEvent,
  cLockViewEvent,
  cLockViewMyAccountHistoryButtonOnClickEvent,
} from './gtmEventsCLock';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCLockViewModel = () => {
  const { isAuthenticated } = useUserState();

  const { isEligibilitySuccess, isEligibilityError, eligibilityResponse, eligibilityError } = useEligibility({
    queryKey: ['lock-status-eligibility'],
    enabled: isAuthenticated,
  });

  const onPageViewHandler = (showAgencyPaymentContent: boolean): void => {
    googleTagManager.trackViewEvent({
      ...cLockViewEvent,
      authenticationStatus: isAuthenticated,
      paymentInfo: showAgencyPaymentContent ? 'true' : 'false',
    });
  };

  const viewMyAccountHistoryClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...cLockViewMyAccountHistoryButtonOnClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = accountHistoryUrl;
  };

  const viewCurrentBillClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...cLockViewCurrentBillButtonOnClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  const agencyPhoneNumberOnClickHandler = (): void => {
    const agencyNumber = eligibilityResponse?.data.dunningLockDetails
      ? eligibilityResponse!.data.dunningLockDetails[0].agencyNumber
      : '';
    googleTagManager.trackButtonClick({
      ...cLockAgencyPhoneNumberLinkOnClickEvent,
      actionObject: agencyNumber,
      authenticationStatus: isAuthenticated,
    });
  };

  const energyAssistanceProgramsLinkOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...cLockEnergyAssistanceProgramsLinkOnClickEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const unitedWayLinkOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...cLockUnitedWayLinkOnClickEvent,
      actionObject: unitedWayPhoneNumber,
      authenticationStatus: isAuthenticated,
    });
  };

  return {
    isEligibilitySuccess,
    isEligibilityError,
    eligibilityResponse,
    eligibilityError,
    onPageViewHandler,
    viewMyAccountHistoryClickHandler,
    viewCurrentBillClickHandler,
    agencyPhoneNumberOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
  };
};
