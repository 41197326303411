import { DteTabs, TabsProvider } from 'component-library';

import { MedicalPanel } from './medical-panel/MedicalPanel';
import { MilitaryPanel } from './military-panel/MilitaryPanel';
import {
  completeFormOnClickHandler,
  energyAssistanceProgramsLinkOnClickHandler,
  medicalTabOnClickHandler,
  militaryTabOnClickHandler,
  unitedWayLinkOnClickHandler,
  uploadFormOnClickHandler,
  viewCurrentBillOnClickHandler,
  viewMedicalLinkOnClickHandler,
  viewMilitaryLinkOnClickHandler,
} from './handlers';

const dteTabChangeHandler = (muiTabIndex: number): void => {
  const medicalTab = 0;
  const militaryTab = 1;
  if (muiTabIndex === medicalTab) {
    medicalTabOnClickHandler();
  }
  if (muiTabIndex === militaryTab) {
    militaryTabOnClickHandler();
  }
};

interface IMedicalAndMilitaryPanelProps {
  tabAriaId: string;
  tabLabel: string;
  tabPanelAriaId: string;
  tabPanelPresentation: React.ReactElement;
}

const medicalAndMilitaryPanelProps = (lockEndDate: string): IMedicalAndMilitaryPanelProps[] => [
  {
    tabAriaId: 'medical-tab',
    tabLabel: 'Medical',
    tabPanelAriaId: 'medical-panel',
    tabPanelPresentation: (
      <MedicalPanel
        completeFormOnClickHandler={completeFormOnClickHandler}
        energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
        lockEndDate={lockEndDate}
        unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
        uploadFormOnClickHandler={uploadFormOnClickHandler}
        viewCurrentBillOnClickHandler={viewCurrentBillOnClickHandler}
        viewMilitaryLinkOnClickHandler={viewMilitaryLinkOnClickHandler}
      />
    ),
  },
  {
    tabAriaId: 'military-tab',
    tabLabel: 'Military',
    tabPanelAriaId: 'military-panel',
    tabPanelPresentation: (
      <MilitaryPanel
        energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
        lockEndDate={lockEndDate}
        unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
        viewCurrentBillOnClickHandler={viewCurrentBillOnClickHandler}
        viewMedicalLinkOnClickHandler={viewMedicalLinkOnClickHandler}
      />
    ),
  },
];

export const WLockContent = ({ lockEndDate }: { lockEndDate: string }): React.ReactElement => (
  <TabsProvider
    // eslint-disable-next-line react/no-children-prop
    children={
      <DteTabs dteTabChangeHandler={dteTabChangeHandler} tabAndPanelProps={medicalAndMilitaryPanelProps(lockEndDate)} />
    }
    initialTab={0}
  />
);
