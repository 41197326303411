import React from 'react';
import { Alert, Snackbar, SxProps } from '@mui/material';

interface FileUploadSnackBarProps {
  isSnackBarOpen: boolean;
  handleCloseSnackbar: () => void;
}

export const FileUploadSnackBar: React.FC<FileUploadSnackBarProps> = (
  props: FileUploadSnackBarProps,
): React.ReactElement => {
  const { isSnackBarOpen, handleCloseSnackbar } = props;
  const horizontal = 'center';
  const vertical = 'top';

  const sx: Record<string, SxProps> = {
    clipboardSnackbar: {
      horizontal: 'center',
      width: '100%',
      vertical: 'top',
    },
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isSnackBarOpen}
      onClose={handleCloseSnackbar}
      key={vertical + horizontal}
    >
      <Alert onClose={handleCloseSnackbar} severity="success" sx={sx.clipboardSnackbar}>
        Eligibility Check Complete! Please provide BPID(s) if you wish to check eligibility for more users.
      </Alert>
    </Snackbar>
  );
};
