import { Box } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../../component-library/currency/ReadOnlyCurrencyMask';
import { H4HeadingBold, H5Heading, ParagraphSecondary } from '../../../../component-library/typography';

import messages from './messages';

interface IInstallmentSummaryProps {
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
}

const InstallmentSummary: React.FC<IInstallmentSummaryProps> = (props: IInstallmentSummaryProps) => {
  const { installmentAmount, numberOfInstallmentsOpted } = props;

  return (
    <Box data-testid="installment-summary" display="flex" flexDirection="column" gap="4px" textAlign="center">
      <H5Heading>
        {numberOfInstallmentsOpted.toString()}
        {` `}
        {messages.installmentSummary}
      </H5Heading>
      <span>
        <H4HeadingBold display="inline">{ReadOnlyCurrencyMask(parseFloat(installmentAmount))}</H4HeadingBold>
        {` `}
        <ParagraphSecondary display="inline">{messages.perMonth}</ParagraphSecondary>
      </span>
    </Box>
  );
};

export default InstallmentSummary;
