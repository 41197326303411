import { unitedWayPhoneNumber } from '../../collections-copy';

/** reference #21 * */
export const yLockViewEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'view',
  actionObject: 'income eligible documents lock details page',
  actionDetail: 'y',
  authenticationStatus: 'authenticated',
};

/** no reference number in Figma * */
export const yLockViewCurrentBillLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'y',
  authenticationStatus: 'authenticated',
};

/** reference #22 * */
export const yLockSubmitDocumentsButtonClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'income eligible documents lock details page',
  actionDetail: 'submit documents',
  authenticationStatus: 'authenticated',
};

/** reference #31 * */
export const yLockEnergyAssistanceProgramsLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'energy assistance programs',
  actionDetail: 'y',
  authenticationStatus: 'authenticated',
};

/** reference #32 * */
export const yLockUnitedWayLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: unitedWayPhoneNumber,
  actionDetail: 'y',
  authenticationStatus: 'authenticated',
};
