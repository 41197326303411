import logger from '../logger';

const pushDataLayer = (args: {}): void => {
  const dl = window.dataLayer;

  try {
    if (dl && dl.push) {
      dl.push(args);
    }
  } catch {
    logger.error(`Unable to push args to data layer, ${args}`);
  }
};

export default pushDataLayer;
