import { useEffect } from 'react';
import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import PageNotFoundIcon from 'component-library/icons/PageNotFoundErrorIcon';
import getFlagsConfig from 'config/getFlagsConfig';
import { PageHeaders } from 'constants/';
import { urls } from 'constants/urls';
import {
  base404ForPaAndPo,
  notFound404BackToCurrentBillClickEvent,
  Page404ForPaAndPoEventLabels,
} from 'gtm/gtmEvents404';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import { googleTagManager } from 'services';

import getGtmViewEvent from './getGtmViewEvent';
import messages from './messages';
import NewPageNotFoundContent from './NewPageNotFoundContent';

interface PageNotFoundProps {
  headerTitle?: string;
}

const PageNotFoundContent: React.FC<PageNotFoundProps> = (props: PageNotFoundProps): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const { enablePaymentAgreement } = getFlagsConfig();
  const { headerTitle } = props;

  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    contentWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '40px',
      width: '100%',
    },
    pageNotFoundText: {
      color: theme.palette.primary.main,
      fontSize: '32px',
      fontWeight: 300,
      lineHeight: '43.58px',
      marginBottom: '16px',
      marginTop: '32px',
    },
    topInformationText: {
      color: theme.palette.secondary.main,
      fontSize: '23px',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: '31.32px',
      textAlign: 'center',
    },
    bottomInformationText: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: '24.51px',
      textAlign: 'center',
    },
    backToCurrentBillButton: {
      background: theme.palette.dteBlue.main,
      borderRadius: 'unset',
      boxShadow: 'unset',
      color: theme.palette.common.white,
      fontWeight: '600px',
      height: '40px',
      marginTop: '32px',
      maxWidth: '300px',
      marginBottom: '32px',
      '&:hover': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
      '&:focus': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
      textTransform: 'none',
      width: '100%',
    },
  };

  useEffect(() => {
    if (isAuthenticated && isGoogleTagManagerLoaded) {
      const gtmViewEventObject = getGtmViewEvent(headerTitle || '');

      googleTagManager.trackViewEvent({
        ...gtmViewEventObject,
        authenticationStatus: isAuthenticated,
      })
    }
  }, [headerTitle, isAuthenticated, isGoogleTagManagerLoaded]);

  const handleClick = (): void => {
    if (
      enablePaymentAgreement &&
      (headerTitle === PageHeaders.PAYMENT_AGREEMENT || headerTitle === PageHeaders.PAYMENT_OFFERS)
    ) {
      googleTagManager.trackButtonClick({
        ...base404ForPaAndPo,
        actionObject: Page404ForPaAndPoEventLabels.backToCurrentBill,
        authenticationStatus: isAuthenticated,
        transactionStepName:
          headerTitle === PageHeaders.PAYMENT_AGREEMENT
            ? Page404ForPaAndPoEventLabels.transactionStepNamePa
            : Page404ForPaAndPoEventLabels.transactionStepNamePo,
      });
    } else {
      googleTagManager.trackButtonClick({
        ...notFound404BackToCurrentBillClickEvent,
        authenticationStatus: isAuthenticated,
      });
    }

    window.location.href = urls.currentBillUrl;
  };

  if (enablePaymentAgreement) {
    return <NewPageNotFoundContent onButtonClick={handleClick} />;
  }

  return (
    <Box sx={sx.contentWrapper}>
      <PageNotFoundIcon />
      <Typography sx={sx.pageNotFoundText} data-testid="page-not-found-text">
        {messages.pageNotFoundText}
      </Typography>
      <Typography sx={sx.topInformationText} data-testid="top-information-text">
        {messages.defaultText}
      </Typography>
      <Button
        variant="contained"
        sx={sx.backToCurrentBillButton}
        data-testid="back-to-current-bill-button"
        onClick={handleClick}
      >
        <Typography fontWeight={600} variant="body2" data-testid="back-to-current-bill-text">
          {messages.buttonLabel}
        </Typography>
      </Button>
      <Typography sx={sx.bottomInformationText} data-testid="bottom-information-text">
        {messages.apologyText}
      </Typography>
    </Box>
  );
};

export default PageNotFoundContent;
