import { Box, styled, Theme, Typography } from '@mui/material';

import { DialogProvider } from '../../../component-library/dialog/DialogContext';
import { ReviewPaymentModal } from '../review-payment-modal';

const CardWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  [theme.breakpoints.up('desktop')]: {
    padding: '40px 24px',
    width: '364px',
  },
  [theme.breakpoints.down('desktop')]: {
    padding: '40px 20px',
  },
  [theme.breakpoints.between('tablet', 'desktop')]: {
    flexGrow: '1',
    height: '276px',
    maxWidth: '364px',
  },
  [theme.breakpoints.down('tablet')]: {
    alignItems: 'center',
    height: '276px',
    width: '100%',
  },
  [theme.breakpoints.down('mobile')]: {
    padding: '40px 20px',
  },
}));

const CardContent = styled(Box)(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  button: {
    [theme.breakpoints.up('desktop')]: {
      maxWidth: '316px',
    },
    [theme.breakpoints.between('tablet', 'desktop')]: {
      width: '297px',
    },
    [theme.breakpoints.between('mobile', 'tablet')]: {
      width: '287px',
    },
  },
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '264px',
  },
}));

const CardDescription = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '14px',
  fontWeight: '400',
  [theme.breakpoints.down('tablet')]: {
    textAlign: 'center',
  },
}));

interface IPAEnrollmentCardProps {
  buttonLabel: string;
  buttonValue: string;
  description: string;
  icon: React.ReactNode;
  id: string;
}

const PAEnrollmentCard: React.FC<IPAEnrollmentCardProps> = (props: IPAEnrollmentCardProps): React.ReactElement => {
  const { buttonLabel, icon, id, description, buttonValue } = props;

  return (
    <CardWrapper data-testid="pa-enrollment-card" id={id}>
      <CardContent>
        {icon}
        <CardDescription>{description}</CardDescription>
        <DialogProvider>
          <ReviewPaymentModal buttonLabel={buttonLabel} value={buttonValue} />
        </DialogProvider>
      </CardContent>
    </CardWrapper>
  );
};

export default PAEnrollmentCard;
