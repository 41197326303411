import { useContext } from 'react';
import { styled } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { usePaymentAgreementDetails } from 'hooks';
import { googleTagManager } from 'services';

import { DteSecondaryButton } from '../../../../component-library/buttons/DteSecondaryButton';
import { DialogContext } from '../../../../component-library/dialog/DialogContext';
import messages from '../messages';

import CashOnlyConfirmButton from './CashOnlyConfirmButton';
import PayLaterConfirmButton from './PayLaterConfirmButton';
import ScheduleConfirmButton from './ScheduleConfirmButton';

interface ActionButtonsProps extends IDownPaymentMethod {
  isLoading?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ButtonsContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const LoadingContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100px',
}));

const ActionButtons: React.FC<ActionButtonsProps> = ({
  downPaymentMethod,
  isLoading,
  setIsLoading,
}: ActionButtonsProps): React.ReactElement => {
  const { setOpen } = useContext(DialogContext);
  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: false });
  const { cashOnly } = paymentAgreementDetails;
  const { isAuthenticated } = useUserState();

  const handleGoBack = (): void => {
    const gtmOnGoBackClick = {
      actionDetail: 'return to pa details',
      actionObject: 'go back',
      transactionStepName: 'enrollment confirmation',
      transactionType: 'payment agreement',
      userType: cashOnly === 'Y' ? 'cash only' : 'web',
    };

    googleTagManager.trackButtonClick({ ...gtmOnGoBackClick, authenticationStatus: isAuthenticated });
    setOpen(false);
  };

  return isLoading ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <ButtonsContainer data-testid="review-payment-modal-action-buttons">
      <>
        {downPaymentMethod === 'CashOnly' && <CashOnlyConfirmButton setIsLoading={setIsLoading} />}
        {downPaymentMethod === 'PayLater' && <PayLaterConfirmButton setIsLoading={setIsLoading} />}
        {downPaymentMethod === 'SchedulePayment' && <ScheduleConfirmButton setIsLoading={setIsLoading} />}
        <DteSecondaryButton
          data-testid="review-payment-modal-go-back-button"
          name={messages.actionButtonGoBackLabel}
          onClick={handleGoBack}
        />
      </>
    </ButtonsContainer>
  );
};

export default ActionButtons;
