import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { eligibilityApiPath } from '../../urls-and-paths';
import { axiosGet } from '../adapter/axios-adapter';
import { azureGet } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

import { CombinedEligibilityCheckResponse } from './EligibilityCheckResponse';

const combinedEligibilityCheck = async (): Promise<AxiosResponse<CombinedEligibilityCheckResponse>> =>
  azureGet<CombinedEligibilityCheckResponse>(eligibilityApiPath);

const localMockCombinedEligibilityCheck = async (): Promise<AxiosResponse<CombinedEligibilityCheckResponse>> =>
  localMockApiResponse(Mocks.combinedEligibilityResponseBody);

export const combinedEligibilityCheckApi = apiOrLocalMock(combinedEligibilityCheck, localMockCombinedEligibilityCheck);

export const adminEligibilityCheck = async (jwt: string): Promise<AxiosResponse<CombinedEligibilityCheckResponse>> =>
  axiosGet<CombinedEligibilityCheckResponse>(eligibilityApiPath, {
    baseURL: process.env.REACT_APP_BACKEND_HOST,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

export const useEligibility = ({
  queryKey,
  enabled,
  onSuccess,
  onError,
}: IUseCombinedEligibilityQueryProps): IUseCombinedEligibilityCheckResponse => {
  const {
    isSuccess: isEligibilitySuccess,
    isError: isEligibilityError,
    data: eligibilityResponse,
    error: eligibilityError,
    refetch: refetchEligibility,
  } = useQuery({
    queryKey,
    queryFn: combinedEligibilityCheckApi,
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  });

  return {
    isEligibilitySuccess,
    isEligibilityError,
    eligibilityResponse,
    eligibilityError,
    refetchEligibility,
  };
};
