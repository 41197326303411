import { downPaymentStatusValues } from 'constants/';

export const paymentAgreementBaseProperties = {
  actionObject: 'pa summary',
  event: 'transaction_event',
  transactionStepName: 'pa enrolled',
  transactionType: 'payment agreement',
  userType: 'web',
};

export const paymentAgreementClickEvents = {
  ...paymentAgreementBaseProperties,
  actionType: 'click',
};

export enum PaymentAgreementEventLabels {
  goToScheduledPayment = 'go to scheduled payment',
  paidDownPayment = 'paid down payment',
  requiredDownPayment = 'required down payment',
  scheduledDownPayment = 'scheduled down payment',
  viewCurrentBill = 'view current bill',
  viewScheduledPayment = 'view scheduled payment',
  myAccountHistory = 'my account history',
  cashOnlyUser = 'cash only',
  defaultUser = 'web',
}

export const getPaymentAgreemmentActionDetails = (downPaymentStatus: DownPaymentStatus): string => {
  switch (downPaymentStatus) {
    case downPaymentStatusValues.Paid:
      return PaymentAgreementEventLabels.paidDownPayment;
    case downPaymentStatusValues.Required:
      return PaymentAgreementEventLabels.requiredDownPayment;
    case downPaymentStatusValues.Scheduled:
      return PaymentAgreementEventLabels.scheduledDownPayment;
    default:
      return '';
  }
};
