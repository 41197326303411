import { styled } from '@mui/material';

import { urls } from '../../../constants';
import { DtePrimaryButton } from '../../buttons/DtePrimaryButton';

import messages from './messages';

const contactUsHandler = (): void => {
  window.location.href = urls.contactUsLink;
};

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '40px',
  width: '300px',
}));

const ContactUsButton: React.FC<ErrorPageContactUsButtonClickHandler> = (
  props: ErrorPageContactUsButtonClickHandler,
): React.ReactElement => {
  const { onClick } = props;

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
    contactUsHandler();
  };

  return (
    <Container>
      <DtePrimaryButton dataTestId="generic-error-contact-us" name={messages.contactUs} onClick={handleClick} />
    </Container>
  );
};

export default ContactUsButton;
