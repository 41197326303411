import { Box, styled, Theme } from '@mui/material';

const CardWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '327px',
  },
  [theme.breakpoints.up('mobile')]: {
    width: '100%',
  },
  [theme.breakpoints.up(755)]: {
    width: '50%',
    height: '443px',
  },
  [theme.breakpoints.up('desktop')]: {
    height: '421px',
  },
}));

const CardContent = styled(Box)(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  height: '100%',
  justifyContent: 'space-between',
  padding: '30px',
  outline: `1px solid ${theme.palette.dteGrey.main}`,
  width: '100%',
}));

interface OfferCardProps {
  children: React.ReactNode;
  id: string;
}

export const OfferCard: React.FC<OfferCardProps> = (props: OfferCardProps): React.ReactElement => {
  const { children, id } = props;

  return (
    <CardWrapper className="card-wrapper" data-testid={id} id={id}>
      <CardContent className="card-content" data-testid="offer-card-content">
        {children}
      </CardContent>
    </CardWrapper>
  );
};
