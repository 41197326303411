/* eslint-disable @typescript-eslint/typedef */
import { ReactNode, useContext } from 'react';
import { Dialog, DialogContent, IconButton, PaperProps, styled, Theme, Typography } from '@mui/material';

import { DialogContext } from '../dialog/DialogContext';
import { DteCloseIcon } from '../icons/DteCloseIcon';

interface IDteModalProps {
  content: ReactNode;
  id?: string;
  isLoading?: boolean;
  onCloseHandler?: () => void;
  title?: string;
}

export const ModalContainer = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      [theme.breakpoints.down(755)]: {
        width: '100%',
        gap: '15px',
      },
      [theme.breakpoints.up(755)]: {
        gap: '28px',
      },
    },
    '& .MuiDialogContent-root': {
      backgroundColor: theme.palette.common.white,
      padding: '0',
      width: '100%',
    },
  },
}));

const ModalTitleContainer = styled('div')({
  alignItems: 'flex-start',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const ModalTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '29px',
  fontWeight: '400',
  lineHeight: '39px',
}));

export const DteModal: React.FC<IDteModalProps> = (props: IDteModalProps): React.ReactElement => {
  const { content, id, isLoading, onCloseHandler, title } = props;
  const { open, setOpen } = useContext(DialogContext);

  const dialogContainerComponentProps = { backdrop: { style: { backgroundColor: '#F5F6F6F6' } } };

  const StyledIconButton = styled(IconButton)(({ theme }: { theme: Theme }) => ({
    borderRadius: '0',
    padding: '0',
    '&:focus-visible': { border: '1px solid #0072CE', padding: '3px' },
    [theme.breakpoints.down(755)]: {
      marginTop: '11px',
    },
    [theme.breakpoints.up(755)]: {
      marginTop: '13px',
    },
  }));

  const dialogContainerPaperProps: PaperProps = {
    sx: (theme) => ({
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderRadius: '0',
      margin: '0 auto',
      [theme.breakpoints.down(375)]: {
        padding: '0 8px',
      },
      [theme.breakpoints.up(375)]: {
        padding: '0 24px',
      },
      [theme.breakpoints.up(755)]: {
        margin: '0 auto',
        padding: 'unset',
        width: '720px',
      },
      verticalAlign: 'top',
    }),
  };

  const dialogOnCloseHandler = (): void => {
    if (!isLoading) {
      if (onCloseHandler) {
        onCloseHandler();
      }

      setOpen(false);
    }
  };

  return (
    <ModalContainer
      aria-labelledby="modal-title"
      componentsProps={dialogContainerComponentProps}
      data-testid={id}
      id={id}
      open={open}
      onClose={dialogOnCloseHandler}
      role="dialog"
      scroll="body"
      PaperProps={dialogContainerPaperProps}
    >
      <ModalTitleContainer>
        <ModalTitle id="modal-title">{title}</ModalTitle>
        {isLoading ? null : (
          <StyledIconButton aria-label="close dialog" disableRipple onClick={dialogOnCloseHandler}>
            <DteCloseIcon containerSx={{ width: '18px', height: '18px' }} iconSx={{ width: '100%', height: '100%' }} />
          </StyledIconButton>
        )}
      </ModalTitleContainer>
      <DialogContent data-testid="dialog-content">{content}</DialogContent>
    </ModalContainer>
  );
};
