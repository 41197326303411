import { KeyboardEvent, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, SxProps, Typography } from '@mui/material';

import { theme } from '../../theme/theme';

type AccordionProps = {
  collapsedSummary?: string | JSX.Element;
  expandedSummary?: string | JSX.Element;
  expandedPanel?: string | JSX.Element;
  summaryOnClick?: () => void;
  containerSx?: SxProps;
  summarySx?: SxProps;
  expandedPanelSx?: SxProps;
  initialOpen?: boolean;
  dataTestId?: string;
};

export const Accordion = ({
  collapsedSummary = 'Expand',
  expandedSummary = 'Collapse',
  expandedPanel,
  summaryOnClick,
  containerSx,
  summarySx,
  expandedPanelSx,
  initialOpen = false,
  dataTestId,
}: AccordionProps): React.ReactElement => {
  const [open, setOpen] = useState(initialOpen);

  const expandCollapseRow = (): void => {
    setOpen(!open);
    if (summaryOnClick) {
      summaryOnClick();
    }
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Enter' || event.code === 'Space') {
      expandCollapseRow();
    }
  };

  const expandCollapseSx = { fontSize: '32px', marginRight: '-4px' };

  const expandCollapseText = open ? expandedSummary : collapsedSummary;
  const expandCollapseIcon = open ? <ExpandLess sx={expandCollapseSx} /> : <ExpandMore sx={expandCollapseSx} />;

  const accordionContainerSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    ...containerSx,
  };

  const summaryContainerSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '4px',
    cursor: 'pointer',
    color: theme.palette.dteBlue.main,
    '&:focus-visible': {
      textDecoration: 'underline',
      outline: 'none',
    },
    ...summarySx,
  };

  const summaryTextSx: SxProps = {
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
  };

  const detailsContainerSx: SxProps = {
    width: '100%',
    ...expandedPanelSx,
  };

  return (
    <Box sx={accordionContainerSx} data-testid={dataTestId}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={detailsContainerSx}>{expandedPanel}</Box>
      </Collapse>
      <Box sx={summaryContainerSx} tabIndex={0} onKeyDown={handleKeyDown} onClick={expandCollapseRow}>
        <Typography sx={summaryTextSx}>{expandCollapseText}</Typography>
        {expandCollapseIcon}
      </Box>
    </Box>
  );
};
