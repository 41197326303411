import messages from './messages';
import PlanSelectionBodyText from './PlanSelectionBodyText';

const PlanSelectionNotice: React.FC = (): React.ReactElement => (
  <PlanSelectionBodyText data-testid="plan-selection-heading" id="plan-selection-heading" fontWeight={600}>
    {messages.planheading}
  </PlanSelectionBodyText>
);

export default PlanSelectionNotice;
