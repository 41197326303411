import { useState } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Header,
  HeaderGroup,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { ITestUser } from 'admin-page/types/ITestUser';

import { TestUserRow } from './test-user-row/TestUserRow';
import { GlobalFilter } from './global-filter';
import TableFilter from './TableFilter';
import { TablePagination } from './TablePagination';
import { TablePaging } from './TablePaging';

interface TestUserProps {
  columns: ColumnDef<ITestUser, string>[];
  isLoading: boolean;
  numberOfUsers: number;
  tableData: ITestUser[];
}

export const TestUserTable: React.FC<TestUserProps> = (props: TestUserProps): React.ReactElement => {
  const { columns, isLoading, numberOfUsers, tableData } = props;
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    columns,
    data: tableData,
    // debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // pipeline: core -> filtered -> pagination -> [final set of data]
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      columnFilters,
      globalFilter,
    },
  });

  const filterAndPagingSx = {
    display: 'flex',
    margin: '10px 10px 0 0',
    gap: '5px',
  };

  const loadingRowSx = {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '20px',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box sx={filterAndPagingSx}>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <TablePaging table={table} />
      </Box>
      <TableContainer component={Paper} style={{ maxHeight: 500 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<ITestUser>) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header: Header<ITestUser, unknown>) => (
                  <TableCell key={header.id} sx={{ fontWeight: '600' }}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanFilter() ? <TableFilter column={header.column} /> : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length} padding="normal">
                  <Typography align="center" variant="h6">
                    {`Loading eligibility data for ${numberOfUsers} test users`}
                  </Typography>
                  <Box sx={loadingRowSx}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {table.getPaginationRowModel().rows.map((row: Row<ITestUser>) => (
              <TestUserRow row={row} key={row.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <TablePagination table={table} />
      </Box>
    </Box>
  );
};
