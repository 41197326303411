import { Button, ButtonProps, Typography, useTheme } from '@mui/material';

import { useResponsiveSx } from '../../theme/breakpoints/responsiveHooks';

type MuiButtonProps = Pick<ButtonProps, 'onClick'>;

interface DteButtonProps extends MuiButtonProps {
  name: string;
}

export const DteSecondaryButton = ({ name, onClick }: DteButtonProps): React.ReactElement => {
  const theme = useTheme();
  const secondaryButtonSx = {
    background: theme.palette.common.white,
    textTransform: 'none',
    color: theme.palette.dteBlue.main,
    height: '40px',
    borderRadius: 'unset',
    border: `1px solid ${theme.palette.dteBlue.main}`,
    width: '100%',
    boxShadow: 'unset',
    '&:hover': {
      background: theme.palette.dteBlue.dark,
      color: theme.palette.common.white,
      boxShadow: 'unset',
      border: 'unset',
    },
    '&:focus': {
      background: theme.palette.dteBlue.dark,
      color: theme.palette.common.white,
      boxShadow: 'unset',
      border: 'unset',
    },
  };
  const mobileStyleSx = {
    maxWidth: '327px',
  };
  const tabletAndDesktopStyleSx = {
    maxWidth: '300px',
  };

  const mobileStyling = { ...secondaryButtonSx, ...mobileStyleSx };
  const tabletAndDesktopStyling = { ...secondaryButtonSx, ...tabletAndDesktopStyleSx };
  const responsiveStyling = useResponsiveSx(mobileStyling, tabletAndDesktopStyling, tabletAndDesktopStyling);

  return (
    <Button id="dteSecondaryButton" type="button" sx={responsiveStyling} onClick={onClick} disableFocusRipple>
      <Typography fontWeight={600} variant="body2">
        {name}
      </Typography>
    </Button>
  );
};
