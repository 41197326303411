const lockStatusErrorPageBaseEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status error page',
  actionDetail: 'lock status error page',
  authenticationStatus: 'authenticated',
};

/** reference #31 * */
export const lockStatusErrorPageViewEvent = {
  ...lockStatusErrorPageBaseEvent,
  actionType: 'view',
  actionObject: 'lock status error page',
};

/** reference #32 * */
export const lockStatusErrorPageViewCurrentBillClickEvent = {
  ...lockStatusErrorPageBaseEvent,
  actionType: 'click',
  actionObject: 'view current bill',
};
