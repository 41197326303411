export const paymentExtensionBaseProperties = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'payment extension',
  authenticationStatus: 'authenticated',
};

// PE eligible view
const peEligibleBase = {
  ...paymentExtensionBaseProperties,
  transactionStepName: 'pe offer details',
};
export const peEligibleHeaderCancelClickEvent = {
  ...peEligibleBase,
  actionType: 'click',
  actionObject: 'cancel',
  actionDetail: 'eligible',
};

interface IPeEligibleReturnType {
  actionDetail: string;
  actionObject: string;
  actionType: string;
  authenticationStatus: string;
  eventName: string;
  transactionName: string;
  transactionStepName: string;
  transactionType: string;
}

export const peEligiblePastDueBalanceViewEvent = (actionDetail: string): IPeEligibleReturnType => ({
  ...peEligibleBase,
  actionType: 'view',
  actionObject: 'past due balance',
  actionDetail,
});

export const peEligibleDateViewEvent = (actionDetail: string): IPeEligibleReturnType => ({
  ...peEligibleBase,
  actionType: 'view',
  actionObject: 'pe date',
  actionDetail,
});

export const peEligibleViewCurrentBillClickEvent = {
  ...peEligibleBase,
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'pe eligible details',
};

export const peEligibleSchedulePaymentClickEvent = {
  ...peEligibleBase,
  actionType: 'click',
  actionObject: 'schedule payment button',
  actionDetail: 'eligible',
};

// PE scheduled view
const peScheduledBase = {
  ...paymentExtensionBaseProperties,
  transactionStepName: 'pe scheduled details',
};
export const peScheduledDetailsViewEvent = {
  ...peScheduledBase,
  transactionComplete: '1',
  actionType: 'view',
  actionObject: 'payment extension details',
  actionDetail: 'scheduled',
};

export const peScheduledViewScheduledPaymentClickEvent = {
  ...peScheduledBase,
  actionType: 'click',
  actionObject: 'view scheduled payment',
  actionDetail: 'scheduled',
};

export const peScheduledViewCurrentBillClickEvent = {
  ...peScheduledBase,
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'scheduled',
};

// PE confirm details view
const peConfirmDetailsBase = {
  ...paymentExtensionBaseProperties,
  transactionStepName: 'confirm pe details',
};
export const peConfirmDetailsHeaderCancelClickEvent = {
  ...peConfirmDetailsBase,
  actionType: 'click',
  actionObject: 'cancel',
  actionDetail: 'confirm payment extension details',
};

export const peConfirmDetailsPageViewEvent = {
  ...peConfirmDetailsBase,
  actionType: 'view',
  actionObject: 'confirm payment extension details',
  actionDetail: 'confirm',
};

export const peConfirmDetailsSchedulePeClickEvent = {
  ...peConfirmDetailsBase,
  actionType: 'click',
  actionObject: 'schedule payment extension',
  actionDetail: 'confirm payment extension details',
};

export const peConfirmViewCurrentBillClickEvent = {
  ...peConfirmDetailsBase,
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'confirm payment extension details',
};

// PE Error Page

export const peErrorBase = {
  ...paymentExtensionBaseProperties,
  transactionStepName: 'pe error',
};

export const peErrorViewEvent = {
  ...peErrorBase,
  actionType: 'view',
  actionObject: 'pe error page',
  actionDetail: 'pe error page',
};

export const peErrorPhoneClickEvent = {
  ...peErrorBase,
  actionType: 'click',
  actionObject: 'phone number',
  actionDetail: 'phone number',
};

export const peErrorContactUsClickEvent = {
  ...peErrorBase,
  actionType: 'click',
  actionObject: 'contact us',
  actionDetail: 'contact us',
};

export const peErrorViewCurrentBillClickEvent = {
  ...peErrorBase,
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'view current bill',
};
