import gtmBaseEvent from 'constants/gtmBaseEvent';

import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import getOptionalGtmEvent from './getOptionalGtmEvent';
import pushDataLayer from './pushDataLayer';

type GtmTrackAccordionClickProps = GtmEventProps;

const trackAccordionClick = (params: GtmTrackAccordionClickProps, isExpanded: boolean): void => {
  const { actionObject, authenticationStatus } = params;
  if (actionObject) {
    const authStatus = getAuthenticationStatus(authenticationStatus);

    const event: IGtmEventProps = {
      ...gtmBaseEvent,
      action_detail: isExpanded ? 'hide' : 'unhide',
      action_object: actionObject,
      action_type: 'click',
      authentication_status: authStatus,
    };

    const optionalEvent = getOptionalGtmEvent(params);

    const gtmEvent: IGtmEventProps = { ...event, ...optionalEvent };

    logger.info(`[Google Tag Manager] Sending tags for accordion clicks`, gtmEvent);

    pushDataLayer(gtmEvent);
  }
};

export default trackAccordionClick;
