import HeaderDesktop from '../../component-library/header/HeaderDesktop';
import HeaderMobile from '../../component-library/header/HeaderMobile';
import getFlagsConfig from '../../config/getFlagsConfig';
import { PageHeaders } from '../../constants';
import { useResponsiveRendering } from '../../theme/breakpoints/responsiveHooks';

const PaymentCenterTestHeader = (): JSX.Element => {
  const { enableCenteredHeader } = getFlagsConfig();

  const mobileHeader = <HeaderMobile isHeaderButtonRendered={false} titleText={PageHeaders.PAYMENT_CENTER_TEST} />;
  const desktopHeader = (
    <HeaderDesktop
      isHeaderButtonRendered={false}
      titleText={PageHeaders.PAYMENT_CENTER_TEST}
      enableCenteredHeader={enableCenteredHeader}
    />
  );
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return pageHeader;
};

export default PaymentCenterTestHeader;
