import { MutationKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { paymentExtensionLockApiPath } from '../../urls-and-paths';
import { azurePost } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

import { LockRequest } from './LockRequest';
import { LockResponse } from './LockResponse';

const paymentExtensionLock = async (body: LockRequest): Promise<AxiosResponse<LockResponse>> =>
  azurePost<LockResponse>(paymentExtensionLockApiPath, body);

const localMockPaymentExtensionLock = async (): Promise<AxiosResponse<LockResponse>> =>
  localMockApiResponse(Mocks.successfulLockResponse);

export const paymentExtensionLockApi = apiOrLocalMock(paymentExtensionLock, localMockPaymentExtensionLock);

interface LockMutationProps {
  mutationKey?: MutationKey | undefined;
  onSuccess?: (httpResponse: AxiosResponse<LockResponse>) => void;
  onError: (error: unknown) => void;
}

interface IUseLockResponse {
  mutateLock: UseMutateFunction<AxiosResponse<LockResponse, unknown>, unknown, LockRequest, unknown>;
}

export const useLock = ({ mutationKey, onSuccess, onError }: LockMutationProps): IUseLockResponse => {
  const { mutate: mutateLock } = useMutation({
    mutationKey,
    mutationFn: (lockRequest: LockRequest) => paymentExtensionLockApi(lockRequest),
    onSuccess,
    onError,
  });

  return {
    mutateLock,
  };
};
