import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormLabel, SxProps, Typography, useTheme } from '@mui/material';

import DteErrorIcon from 'component-library/icons/DteErrorIcon';

const PaymentOptionsLabel: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    optionDescription: {
      color: theme.palette.primary.main,
      paddingBottom: '12px',
    },
    failOptionDescription: {
      color: theme.palette.dteRed.dark,
      paddingBottom: '12px',
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
  };
  const { formState } = useFormContext();

  const validationsSx =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected' ? sx.optionDescription : sx.failOptionDescription;

  return (
    <FormLabel data-testid="option-label-component">
      <Typography data-testid="option-label-top" sx={sx.optionDescription} variant="body1">
        {/* // TODO: 49944 Hiding Total Amount Due or now... */}
        {/* { */}
        {/*  'You have three payment options. If you select one of the first two options, we will reconnect your service within ' */}
        {/* } */}
        {'You have two payment options. If you select the first option, we will reconnect your service within '}
        <Box component="span" data-testid="option-label-top-bold" fontWeight={600}>
          15 minutes to an hour.
        </Box>
      </Typography>
      <Box sx={validationsSx}>
        {formState.errors.PaymentOptions?.type === 'noOptionSelected' ? <DteErrorIcon /> : ''}
        <Typography data-testid="option-label-bottom" fontWeight={600} variant="body1">
          Please select your payment option.
        </Typography>
      </Box>
    </FormLabel>
  );
};

export default PaymentOptionsLabel;
