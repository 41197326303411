import { CSSProperties } from 'react';
import { styled } from '@mui/material';

import { IconContainer } from './IconContainer';

type IconProps = {
  containerSx?: CSSProperties;
  iconSx?: CSSProperties;
};

export const PaymentIcon = ({ containerSx, iconSx }: IconProps): React.ReactElement => {
  const sizeStyle = {
    width: '74px',
    height: '69px',
  };

  const LogoContainer = styled(IconContainer)({
    ...sizeStyle,
  });

  const StyledSvg = styled('svg')({
    ...sizeStyle,
  });

  return (
    <LogoContainer sx={containerSx}>
      <StyledSvg
        sx={iconSx}
        viewBox="0 0 75 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="payment-icon"
      >
        <path
          d="M62 70.5C69.1797 70.5 75 64.6797 75 57.5C75 50.3203 69.1797 44.5 62 44.5C54.8203 44.5 49 50.3203 49 57.5C49 64.6797 54.8203 70.5 62 70.5Z"
          fill="#1D661A"
        />
        <path
          d="M67.0876 53.543L59.8412 61.456L55.7832 58.1436"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.36 41C66.7798 38.8985 67 36.725 67 34.5C67 16.2746 52.2254 1.5 34 1.5C15.7746 1.5 1 16.2746 1 34.5C1 52.7254 15.7746 67.5 34 67.5C38.2335 67.5 42.2808 66.7028 46 65.2504"
          stroke="#1D661A"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M33.6543 19.6005V13.7773"
          stroke="#1D661A"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.6543 54.831V49.0078"
          stroke="#1D661A"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0703 41.5816C25.0703 41.5816 25.6526 48.715 33.3684 49.0062C41.0841 49.4429 41.9576 43.4742 41.9576 42.3095C41.9576 41.1449 42.1032 36.4863 33.5139 34.157C24.9247 31.8278 25.6526 26.7325 25.7982 26.1501C25.9438 25.4222 26.5261 20.1814 33.2228 19.5991C39.9195 19.0167 42.5399 25.5678 42.5399 26.7325"
          stroke="#1D661A"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledSvg>
    </LogoContainer>
  );
};
