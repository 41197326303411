import { Box, SxProps, TooltipProps, Typography } from '@mui/material';

import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

import TooltipMinAmount from './TooltipMinAmount';

type Props = {
  tooltipPlacement: TooltipProps['placement'];
};

const RadioLabelMinAmount: React.FC<Props> = (props: Props): JSX.Element => {
  const { tooltipPlacement } = props;
  const sx: Record<string, SxProps> = {
    rootWrapperMobile: {
      marginRight: '0px',
    },
    rootWrapperDesktop: {
      marginRight: '36px',
    },
    labelEndDescription: {
      display: 'inline-flex',
      alignItems: 'center',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      height: '24.5px',
      width: '202px', // enforces correct wrapping of "to Reconnect Service" and tooltip icon
    },
  };

  const responsiveRootWrapper = useResponsiveSx(sx.rootWrapperMobile, sx.rootWrapperDesktop, sx.rootWrapperDesktop);

  return (
    <Box data-testid="radio-label-min-amount" sx={responsiveRootWrapper}>
      <Typography data-testid="radio-label-min-amount-text" component="span" fontWeight={600} variant="body2">
        {`Minimum Amount Due `}
      </Typography>
      <Box component="div" sx={sx.labelEndDescription}>
        <Typography component="span" fontWeight={600} variant="body2">
          to Reconnect Service
        </Typography>
        <Box component="span">
          <TooltipMinAmount placement={tooltipPlacement} />
        </Box>
      </Box>
    </Box>
  );
};

export default RadioLabelMinAmount;
