const messages = {
  firstStepMessage: 'Schedule or make all payments before 2 p.m. on your payment due date.',
  fourthStepMessage:
    'A payment unable to be processed may result in DTE accepting cash only payments for 12 months at a DTE Energy Bill Payment Kiosk or authorized payment agent.',
  heading: 'Payment Agreement Summary',
  installmentDescription:
    'In addition to your Payment Agreement monthly installment, you are required to pay your current energy charges and any other program fees. Please ensure total payments are made on time.',
  myAccountHistory: 'My Account History',
  paymentAgreementNotice: 'Payment Agreement Notices',
  secondStepMessage:
    'Any additional payments you make will be credited toward your next bill. Your Payment Agreement remains in effect until the end of your program.',
  thirdStepMessage:
    'If your payment cannot be processed or we do not receive it, you may be at risk of service interruption on or after your shutoff date. You may also be ineligible for a Payment Agreement for one year.',
  viewCurrentBill: 'View Current Bill',
  viewScheduledPayment: 'View Scheduled Payment',
};

export default messages;
