import { useContext } from 'react';
import { Box, styled } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../../component-library/currency/ReadOnlyCurrencyMask';
import { H5HeadingRegular, ParagraphPrimaryBold } from '../../../../component-library/typography';
import { theme } from '../../../../theme/theme';
import { EnrollmentContext } from '../../EnrollmentContext';
import messages from '../messages';

const PaymentDetailsContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  gap: '8px',
  padding: '0 11px',
  textAlign: 'center',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    width: '450px',
    height: '100%',
  },
}));

export const RequiredDownPayment: React.FC = () => {
  const { enrollmentInformation } = useContext(EnrollmentContext);
  const { downPaymentAmount } = enrollmentInformation;
  const formattedDownPaymentAmount = ReadOnlyCurrencyMask(parseFloat(downPaymentAmount));

  return (
    <PaymentDetailsContainer data-testid="required-down-payment">
      <H5HeadingRegular>{messages.requiredPaymentTitle}</H5HeadingRegular>
      <ParagraphPrimaryBold>{formattedDownPaymentAmount}</ParagraphPrimaryBold>
    </PaymentDetailsContainer>
  );
};
