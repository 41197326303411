import { useQuery } from '@tanstack/react-query';

import { logger } from 'services';

const appendGTMScript = (): Promise<boolean> =>
  new Promise<boolean>((resolve: Function) => {
    // Google Tag Manager Script
    const gtmScript = document.getElementById('gtm-script') as HTMLScriptElement;
    if (gtmScript) {
      resolve(true);
    } else {
      const gtmScriptElement = document.createElement('script');
      gtmScriptElement.title = 'gtm-script';
      gtmScriptElement.id = 'gtm-script';
      gtmScriptElement.src = '/google-tag-manager.js'; // located in public folder
      gtmScriptElement.onload = (): void => {
        resolve(true);
      };
      document.head.appendChild(gtmScriptElement);
    }
  });

export const useGoogleTagManager = (): boolean => {
  const gtmScript = document.getElementById('gtm-script') as HTMLScriptElement;

  const { data: isGoogleTagManagerLoaded } = useQuery({
    queryKey: ['append-gtm-script'],
    queryFn: appendGTMScript,
    enabled: process.env.REACT_APP_DEVELOPMENT_MODE !== 'true' && !gtmScript,
    retry: 0,
    refetchOnWindowFocus: false,
    onError: (error: unknown) => {
      logger.info('Google Tag Manager script failed to load!');
      logger.error(String(error));
    },
  });

  return !!isGoogleTagManagerLoaded;
};
