import {
  admin,
  HeaderTitle,
  paymentAgreement,
  paymentExtension,
  paymentOffers,
  reconnectService,
  serviceProtection,
} from '../component-library/header/header';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const titleAndUrlLookupTable: any = {
  admin,
  agency: serviceProtection,
  income: serviceProtection,
  medical: serviceProtection,
  'payment-agreement': paymentAgreement,
  'payment-extension': paymentExtension,
  'payment-offer': paymentOffers,
};

const titleIndexes = [
  'admin',
  'agency',
  'income',
  'medical',
  'payment-agreement',
  'payment-extension',
  'payment-offer',
];

export default (urlPaths: string[]): HeaderTitle => {
  try {
    let headerTitle: HeaderTitle = reconnectService;

    urlPaths.forEach((path: string) => {
      titleIndexes.every((title: string) => {
        if (path.includes(title)) {
          headerTitle = titleAndUrlLookupTable[title];
          return false;
        }
        return true;
      });
    });

    return headerTitle;
  } catch (err) {
    return reconnectService;
  }
};
