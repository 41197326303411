import routes from 'constants/routes';
import { googleTagManager } from 'services';

import gtmConfirmPeDetailsBaseEvent from './gtmConfirmPeDetailsBaseEvent';

const onSchedulePaymentExtensionButtonClick = (navigate: Function): void => {
  googleTagManager.trackButtonClick({
    ...gtmConfirmPeDetailsBaseEvent,
    actionDetail: 'confirm payment extension details',
    actionObject: 'schedule payment extension',
    authenticationStatus: true,
  });
  navigate(routes.paymentExtension.scheduled);
};

export default onSchedulePaymentExtensionButtonClick;
