import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useUserState } from 'auth/authenticate';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import { googleTagManager } from 'services';

import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import { GenericErrorPageContent } from '../component-library/templates/generic-error';
import { PageTitles } from '../constants';

import PaymentAgreementHeader from './components/PaymentAgreementHeader';

const PaymentAgreementErrorPage: React.FC = (): React.ReactElement => {
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const { isAuthenticated } = useUserState();
  const gtmPAErrorPageEvent = {
    transactionStepName: 'pa error',
    transactionType: 'payment agreement',
    userType: '',
  };

  useEffect((): void => {
    if (isGoogleTagManagerLoaded && isAuthenticated) {
      googleTagManager.trackViewEvent({
        ...gtmPAErrorPageEvent,
        actionDetail: 'contact us page',
        actionObject: 'contact us',
        authenticationStatus: isAuthenticated,
      });
    }
  }, [isGoogleTagManagerLoaded, isAuthenticated]);

  const handleContactUsButtonClickGtm = (): void => {
    googleTagManager.trackButtonClick({
      ...gtmPAErrorPageEvent,
      actionDetail: 'contact us page',
      actionObject: 'contact us',
      authenticationStatus: isAuthenticated,
    });
  };

  const handleViewCurrentBillButtonClickGtm = (): void => {
    googleTagManager.trackButtonClick({
      ...gtmPAErrorPageEvent,
      actionDetail: 'contact us page',
      actionObject: 'view current bill',
      authenticationStatus: isAuthenticated,
    });
  };

  return (
    <>
      <Helmet>
        <title>{PageTitles.ERROR}</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="40px"
        dataTestId="payment-agreement-error-page"
        header={<PaymentAgreementHeader displayCancelButton={false} transactionStepName="pa error" userType="" />}
        tabletDesktopContentWidth="470px"
      >
        <GenericErrorPageContent
          onContactUsButtonClick={handleContactUsButtonClickGtm}
          onViewCurrentBillButtonClick={handleViewCurrentBillButtonClickGtm}
        />
      </PageTemplate>
    </>
  );
};

export default PaymentAgreementErrorPage;
