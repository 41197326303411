import { useContext, useState } from 'react';
import { Button, styled, Theme, Typography } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DialogContext } from 'component-library/dialog/DialogContext';
import { DteModal } from 'component-library/modal/DteModal';
import { googleTagManager } from 'services';

import { EnrollmentContext } from '../EnrollmentContext';
import ReviewPaymentModalContent from '../review-payment-modal/ReviewPaymentModalContent';

import messages from './messages';

const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  background: theme.palette.dteBlue.main,
  borderRadius: 'unset',
  boxShadow: 'unset',
  color: theme.palette.common.white,
  disableFocus: true,
  textTransform: 'none',
  width: '261px',
  '&:hover': {
    background: theme.palette.dteBlue.dark,
    boxShadow: 'unset',
  },
  '&:focus': {
    background: theme.palette.dteBlue.dark,
    color: theme.palette.common.white,
    boxShadow: 'unset',
  },
  [theme.breakpoints.down(755)]: {
    height: '35px',
  },
  [theme.breakpoints.up(755)]: {
    height: '35px',
  },
}));

const ContinueEnrollmentButton: React.FC = (): React.ReactElement => {
  const { setOpen } = useContext(DialogContext);
  const { enrollmentInformation } = useContext(EnrollmentContext);
  const { isAuthenticated } = useUserState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClickHandler = (): void => {
    const gtmContinueEnrollmentClick = {
      actionDetail: `${enrollmentInformation.installmentDuration} months`,
      actionObject: 'continue enrollment',
      transactionStepName: 'pa detail page',
      transactionType: 'payment agreement',
      userType: 'cash only',
    };

    googleTagManager.trackButtonClick({ ...gtmContinueEnrollmentClick, authenticationStatus: isAuthenticated });

    setOpen(true);

    // **** NOTE: Had to move gtm modal load view event to the onClick due to the modal provider causing issues with useffects running multiple times, regardless of dep. array
    const gtmPAEnrollmentConfirmationModal = {
      actionDetail: 'modal',
      actionObject: 'pa enrollment',
      transactionStepName: 'enrollment confirmation',
      transactionType: 'payment agreement',
      userType: 'cash only',
    };

    googleTagManager.trackViewEvent({ ...gtmPAEnrollmentConfirmationModal, authenticationStatus: isAuthenticated });
  };

  const onCloseHandler = (): void => {
    const gtmOnGoBackClick = {
      actionDetail: 'return to pa details',
      actionObject: 'go back',
      transactionStepName: 'enrollment confirmation',
      transactionType: 'payment agreement',
      userType: 'cash only',
    };

    googleTagManager.trackButtonClick({ ...gtmOnGoBackClick, authenticationStatus: isAuthenticated });

    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <StyledButton data-testid="continue-enrollment-button" onClick={onClickHandler}>
        <Typography fontWeight={600} variant="body2">
          {messages.continueEnrollmentButton}
        </Typography>
      </StyledButton>
      <DteModal
        content={
          <ReviewPaymentModalContent downPaymentMethod="CashOnly" isLoading={isLoading} setIsLoading={setIsLoading} />
        }
        data-testid="review-and-confirm-payment-modal"
        isLoading={isLoading}
        onCloseHandler={onCloseHandler}
        title={messages.reviewPaymentModalTitle}
      />
    </>
  );
};
export default ContinueEnrollmentButton;
