export const admin = 'Admin';
export const paymentAgreement = 'Payment Agreement';
export const paymentCenterTest = 'Payment Center Test';
export const paymentExtension = 'Payment Extension';
export const paymentOffers = 'Payment Offers';
export const serviceProtection = 'Service Protection';
export const reconnectService = 'Reconnect Service';

export type HeaderTitle =
  | typeof admin
  | typeof paymentAgreement
  | typeof paymentCenterTest
  | typeof paymentExtension
  | typeof paymentOffers
  | typeof reconnectService
  | typeof serviceProtection;

export interface HeaderCancelButtonProps {
  onClick?: () => void;
}

export interface HeaderProps extends HeaderCancelButtonProps {
  enableCenteredHeader?: boolean;
  isHeaderButtonRendered: boolean;
  isInteractiveDteLogo?: boolean;
  titleText: HeaderTitle;
}
