import { Box, SxProps, Typography, useTheme } from '@mui/material';

import messages from '../messages';

export const PaymentAgreementNoticeTitle: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    noticeWrapperTitle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    titleWrapper: {
      color: theme.palette.primary.main,
      fontSize: '18px',
      fontWeight: '600',
    },
  };

  return (
    <Box sx={sx.noticeWrapperTitle}>
      <Typography data-testid="payment-agreement-notice-title" sx={sx.titleWrapper} variant="body2">
        {messages.paymentAgreementNotice}
      </Typography>
    </Box>
  );
};
