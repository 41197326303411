import modalMessages from '../review-payment-modal/messages';

const messages = {
  continueEnrollmentButton: 'Continue Enrollment',
  firstParagraphNote: 'We are unable to accept your payment online. Please make',
  fourthParagraphNote: 'Continue to enroll in the Payment Agreement program.',
  notesTitle: 'Note:',
  reviewPaymentModalTitle: modalMessages.modalTitle,
  secondParagraphNote: 'your cash payment at a',
  thirdParagraphNote: ' DTE kiosk or at one of the authorized payment locations near you.',
};

export default messages;
