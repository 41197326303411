const currentBillBaseUrl = `${process.env.REACT_APP_CURRENT_BILL_BASE_URL}`;
const currentBillBasePath = `${process.env.REACT_APP_CURRENT_BILL_BASE_PATH}`;

// API Paths
export const accountDetailsApiPath = '/api/accounts';
export const eligibilityApiPath = '/eligibility-check';
export const experianApiPath = '/experian';
export const paymentAgreementDetailsApiPath = '/payment-agreement-details';
export const paymentExtensionLockApiPath = '/schedule-pe-lock';
export const reconnectServiceApiPath = '/reconnect';
export const signInApiPath = '/api/signIn';

// Reconnect UI
export const reconnectPaymentOptionsPath = '/reconnect-payment-options';
export const reconnectConfirmationPath = '/reconnect-confirmation';
export const reconnectProbatePath = '/verification';

// Payment Extension UI
export const paymentExtensionEligiblePath = '/payment-extension-eligible';
export const paymentExtensionScheduledPath = '/payment-extension';
export const paymentExtensionConfirmDetailsPath = '/payment-extension-scheduled-payment';

// Lock Status UI
export const lockStatusJPath = '/agency-assistance-review';
export const lockStatusCPath = '/agency-payment-assistance';
export const lockStatusYPath = '/income-eligible-protection-review';
export const lockStatusWPath = '/medical-protection-review';

// Generic Error UI
export const paGenericErrorPath = '/pa-error';
export const peGenericErrorPath = '/pe-error';
export const reconnectGenericErrorPath = '/reconnect-error';
export const lockStatusErrorPath = '/service-protection-error';

// Page Not Found
export const pageNotFoundPath = '/page-not-found';

// Admin Tool UI
export const adminToolPath = '/admin';

// Auth
const gigyaRoute = '/JS/gigya.js';
export const gigyaUrl = `${process.env.REACT_APP_GIGYA_BASE_URL}${gigyaRoute}?apiKey=${process.env.REACT_APP_GIGYA_SITE_KEY}`;

// Newlook UI (Current Bill & Login)
const loginPath = '/login';
export const currentBillUrl = `${currentBillBaseUrl}${currentBillBasePath}${process.env.REACT_APP_CURRENT_BILL_PATH}`;
export const accountHistoryUrl = `${currentBillBaseUrl}${currentBillBasePath}${process.env.REACT_APP_ACCOUNT_HISTORY_PATH}`;
// AccountHistory needs to know the user is coming from the PE Scheduled View in order to trigger refetch of payment data, thus ?scheduled=true query param
export const accountHistoryUrlFromSchedueledPe = `${currentBillBaseUrl}${currentBillBasePath}${process.env.REACT_APP_ACCOUNT_HISTORY_PATH}?pescheduled=true`;
export const accountHistoryUrlFromSchedueledPa = `${currentBillBaseUrl}${currentBillBasePath}${process.env.REACT_APP_ACCOUNT_HISTORY_PATH}?pascheduled=true`;
export const loginUrl = `${currentBillBaseUrl}${currentBillBasePath}${loginPath}`;
export const dteHomepageUrl = `${currentBillBaseUrl}`;

// Medical Emergency Hold Request
export const medicalEmergencyHoldRequestFormUrl =
  'https://www.dteenergy.com/content/dam/dteenergy/deg/website/common/billing-and-payment/energy-assistance/general-assistance/Medical-emergency-hold-request.pdf';

// Document Submission Portal
const documentSubmissionPath = '/quicklinks/web-form';
export const documentSubmissionUrl = `${process.env.REACT_APP_DOCUMENT_SUBMISSION_BASE_URL}${documentSubmissionPath}`;

// Payment Center Lambda
const lambdaPaymentAgreementPath = '/api/sessions/payment-agreement';
const lambdaPaymentExtensionPath = '/api/sessions/payment-extension';
const lambdaReconnectPath = '/api/sessions/reconnect-service-accounts';
export const lambdaPaymentAgreementUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}${lambdaPaymentAgreementPath}`;
export const lambdaPaymentExtensionUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}${lambdaPaymentExtensionPath}`;
export const lambdaReconnectUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}${lambdaReconnectPath}`;

// Payment Center UI
const paymentCenterAgreementPath = '/pay-bill/payment-agreement';
const paymentCenterPaymentExtensionPath = '/pay-bill/payment-extension';
const paymentCenterReconnectPath = '/pay-bill/reconnect';
export const paymentCenterPaymentAgreementUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}${paymentCenterAgreementPath}`;
export const paymentCenterPaymentExtensionUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}${paymentCenterPaymentExtensionPath}`;
export const paymentCenterReconnectUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}${paymentCenterReconnectPath}`;
