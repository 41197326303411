import { ArrowForwardIosRounded } from '@mui/icons-material';
import { Link, SxProps, useTheme } from '@mui/material';

type Props = {
  name: string;
  href: string;
  onClick?: () => void;
};

export const DteCtaLink = ({ name, href, onClick }: Props): JSX.Element => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    ctaLink: {
      display: 'flex',
      alignItems: 'center',
      gap: '7px',
      color: theme.palette.dteBlue.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.dteBlue.dark,
      },
      '&:focus': {
        color: theme.palette.dteBlue.dark,
        outlineColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    rightArrow: {
      fontSize: '18px',
    },
  };

  return (
    <Link id="dteCtaLink" href={href} variant="body1" fontWeight={600} sx={sx.ctaLink} onClick={onClick}>
      {name}
      <ArrowForwardIosRounded sx={sx.rightArrow} />
    </Link>
  );
};
