import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { googleTagManager } from 'services';

import { CheckMarkList, OfferCard } from '../../../component-library';
import { DteButton } from '../../../component-library/buttons/DteButton';
import { routes } from '../../../constants';

import messages from './messages';
import { CardDescription, CardTitle } from './OfferCardComponent';

const PaymentAgreementOfferCard: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();

  const handleClick = (): void => {
    googleTagManager.trackButtonClick({
      actionDetail: 'payment agreement',
      actionObject: 'view details',
      authenticationStatus: true,
      transactionStepName: 'pa offers',
      transactionType: 'payment agreement',
      userType: 'web',
    });
    navigate(routes.paymentAgreement.howPAWorks);
  };

  return (
    <OfferCard id="payment-agreement-offer-card">
      <Box display="flex" flexDirection="column" gap="24px" justifyContent="space-between">
        <CardTitle data-testid="payment-agreement-offer-title">{messages.paymentAgreementOfferTitle}</CardTitle>
        <CardDescription data-testid="payment-agreement-offer-description">
          {messages.paymentAgreementOfferDescription}
        </CardDescription>
        <CheckMarkList
          id="payment-agreement-list"
          items={[
            messages.paymentAgreementListItemOne,
            messages.paymentAgreementListItemTwo,
            messages.paymentAgreementListItemThree,
          ]}
        />
      </Box>
      <DteButton onClick={handleClick} text={messages.viewDetailsButtonLabel} variant="primary" />
    </OfferCard>
  );
};

export default PaymentAgreementOfferCard;
