import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

type ContextProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

type ProviderProps = {
  initialOpen?: boolean;
  children: ReactNode;
};

export const DialogContext = createContext<ContextProps>({} as ContextProps);

export const DialogProvider = ({ initialOpen = false, children }: ProviderProps): React.ReactElement => {
  const [open, setOpen] = useState(initialOpen);

  return <DialogContext.Provider value={{ open, setOpen }}>{children}</DialogContext.Provider>;
};
