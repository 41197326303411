import { Helmet } from 'react-helmet-async';

import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import routes from 'constants/routes';
import { useAccountDetails } from 'hooks';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import PEConfirmDetailsContent from './PEConfirmDetailsContent';
import { desktopHeader, mobileHeader } from './PEConfirmDetailsHeader';
import usePEConfirmDetails from './usePEConfirmDetails';

const PaymentExtensionConfirmDetailsPage = (): React.ReactElement => {
  const { isAccountDetailsSuccess, accountDetails } = useAccountDetails({
    errorPagePath: routes.error.paymentExtension,
  });
  const { isPaymentExtensionDetailsLoading, isPaymentExtensionDetailsSuccess, paymentExtensionDetails } =
    usePEConfirmDetails({
      enabled: isAccountDetailsSuccess,
    });
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);
  const isSumOfAllPaymentMoreThanPastDueAmount =
    parseFloat(paymentExtensionDetails?.sumOfAllPayments || '0.00') >=
    parseFloat(paymentExtensionDetails?.pastDueAmount || '0.00');
  const shouldRenderPEConfirmDetails = isPaymentExtensionDetailsSuccess && isSumOfAllPaymentMoreThanPastDueAmount;

  return (
    <>
      <Helmet>
        <title>Payment Center | DTE Energy</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="16px"
        dataTestId="payment-extension-confirm-details-page"
        header={pageHeader}
        tabletDesktopContentWidth="740px"
      >
        <>
          {shouldRenderPEConfirmDetails && (
            <PEConfirmDetailsContent
              accountDetails={accountDetails}
              paymentExtensionDetails={paymentExtensionDetails}
            />
          )}
          {isPaymentExtensionDetailsLoading && <LoadingSpinner />}
        </>
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionConfirmDetailsPage;
