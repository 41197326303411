import { Theme, useMediaQuery } from '@mui/material';

export interface Breakpoints {
  isSmallMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const useBreakpoints = (): Breakpoints => {
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.between('mobile', 'tablet'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('tablet', 'desktop'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('desktop'));

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
  };
};
