import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logger from 'services/logger';

import { currentBillUrl, lockStatusErrorPath } from '../../urls-and-paths';

import { useYLockViewModel } from './useYLockViewModel';
import { YLockView } from './YLockView';

export const YLock = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isEligibilitySuccess, isEligibilityError, eligibilityResponse, eligibilityError } = useYLockViewModel();

  const notEligible = isEligibilitySuccess && eligibilityResponse?.data.eligible.dunningLock !== 'Y';

  useEffect(() => {
    if (notEligible) {
      logger.info('user is not eligible for dunning lock. re-routing to current bill.', eligibilityResponse);
      window.location.href = currentBillUrl;
    }
    // React expecting eligibilityResponse to be passed in
    // eslint-disable-next-line
  }, [notEligible]);

  useEffect(() => {
    if (isEligibilityError) {
      logger.info('lock-status-eligibility Error', eligibilityError);
      navigate(lockStatusErrorPath);
    }
    // React expecting eligibilityError to be passed in
    // eslint-disable-next-line
  }, [isEligibilityError]);

  return <YLockView />;
};
