import { PaymentExtensionDetails } from 'api/eligibility-api/EligibilityCheckResponse';

interface IisUserReadyForPELockProps {
  eligibilityFlag: String | undefined;
  paymentExtensionDetails?: PaymentExtensionDetails;
}

const isUserReadyForPELock = ({ eligibilityFlag, paymentExtensionDetails }: IisUserReadyForPELockProps): boolean =>
  !!(
    eligibilityFlag === 'Y' &&
    paymentExtensionDetails &&
    // eslint-disable-next-line no-prototype-builtins
    paymentExtensionDetails?.hasOwnProperty('scheduledPaymentDate') &&
    paymentExtensionDetails?.scheduledPaymentDate !== '' &&
    Number(paymentExtensionDetails?.sumOfAllPayments) !== 0
  );

export default isUserReadyForPELock;
