import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { ReadOnlyCurrencyMask } from 'component-library/currency/ReadOnlyCurrencyMask';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

type Props = {
  minAmountDue: number;
};

const PayAnotherAmtDescription: React.FC<Props> = (props: Props): JSX.Element => {
  const { minAmountDue } = props;
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    descriptionDesktop: {
      display: 'flex',
      alignItems: 'center',
      height: '109px',
      padding: '0px 24px 0px 12px',
      color: theme.palette.secondary.main,
    },
    descriptionMobile: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 24px 20px 76px',
      color: theme.palette.secondary.main,
    },
  };

  const responsiveDescription = useResponsiveSx(sx.descriptionMobile, sx.descriptionDesktop, sx.descriptionDesktop);

  return (
    <Box data-testid="pay-another-amt-description-component" sx={responsiveDescription}>
      <Typography data-testid="pay-another-amt-description-text" variant="body1">
        {`If you pay an amount less than `}
        <Box component="span" data-testid="pay-another-amt-description-bold" fontWeight={600}>
          {ReadOnlyCurrencyMask(minAmountDue)}
        </Box>
        , your overall balance is reduced, however your service will remain disconnected.
      </Typography>
    </Box>
  );
};

export default PayAnotherAmtDescription;
