import flags from './flags.json';

const getFlagsConfig = (): IFlagsConfig => {
  const env = process.env.REACT_APP_DEPLOYED_ENVIRONMENT;

  if (!env) {
    return flags.production;
  }

  if (!flags[env as keyof typeof flags]) {
    return flags.production;
  }

  return flags[env as ReactAppDeployedEnvironment];
};

export default getFlagsConfig;
