import { styled } from '@mui/material';

import { AccountNumber } from '../../../../component-library/templates/account-number/AccountNumber';
import { UserInformation } from '../../../../component-library/templates/user-information/UserInformation';

type AccountInformationProps = IAccountNumber & IUserInformation;

const Container = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  margin: '24px auto',
  textAlign: 'center',
}));

const AccountInformation: React.FC<AccountInformationProps> = (props: AccountInformationProps): React.ReactElement => {
  const { accountNumber, address, name } = props;

  return (
    <Container data-testid="account-information">
      <UserInformation address={address} name={name} />
      <AccountNumber accountNumber={accountNumber} />
    </Container>
  );
};

export default AccountInformation;
