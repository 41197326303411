import { IconProps } from '../icon-props';
import { IconContainer } from '../IconContainer';

const ScheduleIcon = (iconProps: IconProps): React.ReactElement => {
  const { containerSx, iconHeight = '54', iconWidth = '55' } = iconProps;

  return (
    <IconContainer data-testid="schedule-icon" sx={containerSx}>
      <svg fill="none" height={iconHeight} viewBox="0 0 55 54" width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M37.1001 1.25928C37.1001 0.845064 36.7643 0.509277 36.3501 0.509277C35.9358 0.509277 35.6001 0.845064 35.6001 1.25928V4.39583H15.3176V1.25993C15.3176 0.845713 14.9818 0.509927 14.5676 0.509927C14.1534 0.509927 13.8176 0.845713 13.8176 1.25993V4.39583H0.509766V49.1988H36.3007C36.715 49.1988 37.0507 48.863 37.0507 48.4488C37.0507 48.0346 36.715 47.6988 36.3007 47.6988H2.00977V16.0843H48.9079V36.2816C48.9079 36.6958 49.2437 37.0316 49.6579 37.0316C50.0721 37.0316 50.4079 36.6958 50.4079 36.2816V4.39583H37.1001V1.25928ZM15.3176 5.89583H35.6001V9.03174C35.6001 9.44595 35.9358 9.78174 36.3501 9.78174C36.7643 9.78174 37.1001 9.44595 37.1001 9.03174V5.89583H48.9079V14.5843H2.00977V5.89583H13.8176V9.03239C13.8176 9.4466 14.1534 9.78239 14.5676 9.78239C14.9818 9.78239 15.3176 9.4466 15.3176 9.03239V5.89583Z"
          fill="#1E3575"
          fillRule="evenodd"
        />
        <path
          d="M18.0148 31.1031C17.6448 30.6561 17.7053 29.9911 18.15 29.6227C18.5946 29.2509 19.2562 29.3117 19.6227 29.7586L23.5499 34.4999L36.9321 21.2987C37.3447 20.8911 38.01 20.8946 38.4155 21.313C38.821 21.7277 38.8174 22.3964 38.4012 22.804L24.2077 36.8061C24.1863 36.8276 24.165 36.8454 24.1401 36.8633C23.6954 37.2352 23.0338 37.1744 22.6674 36.7274L18.0144 31.1066L18.0148 31.1031Z"
          fill="#1E3575"
        />
        <path
          d="M45.1176 37.5005V38.9259C46.3529 39.097 47.3529 39.7812 47.7647 40.7505L47.1176 41.0356C46.7647 40.2943 46.0588 39.7812 45.1176 39.6672L44.9412 43.5443C46.4706 44.0575 48 44.6277 48 46.4522C48 48.1058 46.7059 49.1321 44.8235 49.1891L44.7059 50.5005H43.8824V49.1891C42.5294 49.018 41.4706 48.2198 41 47.0224L41.7059 46.6233C42.0588 47.5356 42.8824 48.2198 43.8824 48.3338L44.1176 44.1715C42.6471 43.7154 41.1765 43.1452 41.1765 41.3777C41.1765 39.8952 42.4118 38.8689 44.2941 38.8119L44.4706 37.5005H45.1176ZM44.1765 43.3163L44.2353 39.6101C42.7059 39.6672 42.0588 40.4654 42.0588 41.3777C42.1176 42.461 43.0588 42.9172 44.1765 43.3163ZM44.8235 44.4566L44.7059 48.3908C46.1176 48.3338 47 47.7066 47 46.4522C47 45.3119 46 44.8558 44.8235 44.4566Z"
          fill="#1E3575"
        />
        <path
          d="M44.5 35.0705C39.56 35.0705 35.57 39.108 35.57 44.0005C35.57 48.9405 39.56 52.9305 44.5 52.9305C49.3925 52.9305 53.43 48.9405 53.43 44.0005C53.43 39.108 49.3925 35.0705 44.5 35.0705ZM44.5 53.5005C39.275 53.5005 35 49.2255 35 44.0005C35 38.7755 39.275 34.5005 44.5 34.5005C49.725 34.5005 54 38.7755 54 44.0005C54 49.2255 49.725 53.5005 44.5 53.5005Z"
          fill="#1E3575"
        />
        <path
          d="M45.1176 37.5005V38.9259C46.3529 39.097 47.3529 39.7812 47.7647 40.7505L47.1176 41.0356C46.7647 40.2943 46.0588 39.7812 45.1176 39.6672L44.9412 43.5443C46.4706 44.0575 48 44.6277 48 46.4522C48 48.1058 46.7059 49.1321 44.8235 49.1891L44.7059 50.5005H43.8824V49.1891C42.5294 49.018 41.4706 48.2198 41 47.0224L41.7059 46.6233C42.0588 47.5356 42.8824 48.2198 43.8824 48.3338L44.1176 44.1715C42.6471 43.7154 41.1765 43.1452 41.1765 41.3777C41.1765 39.8952 42.4118 38.8689 44.2941 38.8119L44.4706 37.5005H45.1176ZM44.1765 43.3163L44.2353 39.6101C42.7059 39.6672 42.0588 40.4654 42.0588 41.3777C42.1176 42.461 43.0588 42.9172 44.1765 43.3163ZM44.8235 44.4566L44.7059 48.3908C46.1176 48.3338 47 47.7066 47 46.4522C47 45.3119 46 44.8558 44.8235 44.4566Z"
          stroke="#1E3575"
          strokeWidth="0.75"
        />
        <path
          d="M44.5 35.0705C39.56 35.0705 35.57 39.108 35.57 44.0005C35.57 48.9405 39.56 52.9305 44.5 52.9305C49.3925 52.9305 53.43 48.9405 53.43 44.0005C53.43 39.108 49.3925 35.0705 44.5 35.0705ZM44.5 53.5005C39.275 53.5005 35 49.2255 35 44.0005C35 38.7755 39.275 34.5005 44.5 34.5005C49.725 34.5005 54 38.7755 54 44.0005C54 49.2255 49.725 53.5005 44.5 53.5005Z"
          stroke="#1E3575"
          strokeWidth="0.75"
        />
      </svg>
    </IconContainer>
  );
};

export default ScheduleIcon;
