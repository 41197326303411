const messages = {
  agencyText:
    'An agency is reviewing and will provide updates on your application. If the agency advised that a co-payment must be made to DTE, please make the payment as soon as possible.',
  assistanceTitle: 'Assistance Information',
  heading: 'Please Submit Required Documents',
  instruction: 'You are required to submit copies of identity and income-related documents ',
  instructionAfterDate: 'to determine if you qualify for income-eligible energy assistance. Please follow these steps.',
  representativeText:
    'DTE Representatives do not have additional information about your application or when payments will be applied to your DTE account.',
  stepOne: 'Step 1',
  stepOneSubTitle: 'Gather Documents',
  stepOneInstruction:
    'Collect clear and complete images of required documents for all household members. Please follow the Guidelines below.',
  stepTwo: 'Step 2',
  stepTwoSubTitle: 'Upload Documents',
  stepTwoInstruction:
    'Upload the document copies in our secure Document Submission Portal and then submit them. Please ensure the required documents are valid.',
  stepThree: 'Step 3',
  stepThreeSubTitle: 'We Will Contact You',
  stepThreeInstruction:
    'Once your information is submitted, an Energy Advocacy Group team member will contact you within two business days at the phone number and email address you provided.',
  successBannerText: 'Your DTE account is temporarily protected from a service interruption through',
};

export default messages;
