import { Box, SxProps, useTheme } from '@mui/material';

import { urlParams } from 'constants/';
import { useGetUrlParam } from 'hooks';

import { ReadOnlyCurrencyMask } from '../../../../../component-library/currency/ReadOnlyCurrencyMask';
import { H5Heading, ParagraphSecondary } from '../../../../../component-library/typography';

import messages from './messages';

interface IDownPaymentSummaryProps {
  downPaymentAmount: string;
}

export const PaidDownPayment: React.FC<IDownPaymentSummaryProps> = (props: IDownPaymentSummaryProps) => {
  const paymentSuccessful = useGetUrlParam(urlParams.paymentCenterPaymentSuccessfulParam);
  const theme = useTheme();

  const { downPaymentAmount } = props;

  const sx: Record<string, SxProps> = {
    green: {
      color: theme.palette.dteGreen.main,
    },
    default: {
      color: theme.palette.dteBlack.dark,
    },
  };

  return (
    <Box data-testid="down-payment-paid" alignItems="center" display="flex" flexDirection="column" gap="4px">
      <H5Heading sx={paymentSuccessful === 'true' ? sx.green : sx.default}>{messages.paid}</H5Heading>
      <ParagraphSecondary sx={paymentSuccessful === 'true' ? sx.green : sx.default}>
        {ReadOnlyCurrencyMask(parseFloat(downPaymentAmount))}
      </ParagraphSecondary>
    </Box>
  );
};
