import HeaderDesktop from '../../component-library/header/HeaderDesktop';
import HeaderMobile from '../../component-library/header/HeaderMobile';
import getFlagsConfig from '../../config/getFlagsConfig';
import { PageHeaders } from '../../constants';
import { useResponsiveRendering } from '../../theme/breakpoints/responsiveHooks';
import { currentBillUrl } from '../../urls-and-paths';

interface PaymentOffersHeaderTypes {
  displayCancelButton: boolean;
  onCancelButtonClick?: () => void;
}

const PaymentOffersHeader = (props: PaymentOffersHeaderTypes): JSX.Element => {
  const { displayCancelButton, onCancelButtonClick } = props;
  const { enableCenteredHeader } = getFlagsConfig();

  const headerCancelButtonHandler = (): void => {
    if (onCancelButtonClick) {
      onCancelButtonClick();
    }
    window.location.href = currentBillUrl;
  };

  const mobileHeader = (
    <HeaderMobile
      isHeaderButtonRendered={displayCancelButton}
      onClick={headerCancelButtonHandler}
      titleText={PageHeaders.PAYMENT_OFFERS}
    />
  );
  const desktopHeader = (
    <HeaderDesktop
      isHeaderButtonRendered={displayCancelButton}
      onClick={headerCancelButtonHandler}
      titleText={PageHeaders.PAYMENT_OFFERS}
      enableCenteredHeader={enableCenteredHeader}
    />
  );
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return pageHeader;
};

export default PaymentOffersHeader;
