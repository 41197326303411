import { styled, Theme, Typography } from '@mui/material';

export const CardTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  lineHeight: '27.24px',
  variant: 'h4',
}));

export const CardDescription = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Open Sans',
  lineHeight: '21.79px',
}));
