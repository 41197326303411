import { Box, SxProps, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { ReadOnlyCurrencyMask } from 'component-library/currency/ReadOnlyCurrencyMask';
import { H5Heading, ParagraphSecondary } from 'component-library/typography';
import { usePaymentAgreementDetails } from 'hooks';

import messages from './messages';

export const dataTestidBase = 'down-payment-scheduled';

const ScheduledDownPayment: React.FC = () => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    green: {
      color: theme.palette.dteGreen.main,
    },
    wrapper: {
      gap: '4px',
      [theme.breakpoints.up('mobile')]: {
        height: '77px',
      },
      [theme.breakpoints.down('mobile')]: {
        height: '99px',
      },
    },
  };

  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: false });
  const { downPaymentAmount, downPaymentSubmittedDate } = paymentAgreementDetails;

  const formattedDate = dayjs(downPaymentSubmittedDate).format('dddd, MMMM D');

  return (
    <Box alignItems="center" data-testid={dataTestidBase} display="flex" flexDirection="column" sx={sx.wrapper}>
      <H5Heading sx={sx.green}>{messages.scheduledDownPayment}</H5Heading>
      <ParagraphSecondary data-testid={`${dataTestidBase}-time`} sx={sx.green}>
        {formattedDate}
      </ParagraphSecondary>
      <ParagraphSecondary data-testid={`${dataTestidBase}-amount`} sx={sx.green}>
        {ReadOnlyCurrencyMask(parseFloat(downPaymentAmount))}
      </ParagraphSecondary>
    </Box>
  );
};

export default ScheduledDownPayment;
