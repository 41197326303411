import gtmBaseEvent from 'constants/gtmBaseEvent';

import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import getOptionalGtmEvent from './getOptionalGtmEvent';
import pushDataLayer from './pushDataLayer';

type GtmTrackButtonClickProps = GtmEventProps;

const trackButtonClick = (params: GtmTrackButtonClickProps): void => {
  const { actionDetail, actionObject, authenticationStatus } = params;
  if (actionDetail && actionObject) {
    const authStatus = getAuthenticationStatus(authenticationStatus);

    const event: IGtmEventProps = {
      ...gtmBaseEvent,
      action_detail: actionDetail,
      action_object: actionObject,
      action_type: 'click',
      authentication_status: authStatus,
    };

    const optionalEvent = getOptionalGtmEvent(params);

    const gtmEvent: IGtmEventProps = {
      ...event,
      ...optionalEvent,
    };

    logger.info(`[Google Tag Manager] Sending tags for button clicks`, gtmEvent);

    pushDataLayer(gtmEvent);
  }
};

export default trackButtonClick;
