import { EligibleText, NotEligibleText, ScheduledOrEnrolledText } from './AdminPageTypography';

interface IEligibilityTypeProps {
  type: Eligible | Enrolled | NotEligible | Scheduled | Invalid | string | undefined;
}

const EligibilityType: React.FC<IEligibilityTypeProps> = (props: IEligibilityTypeProps): React.ReactElement => {
  const { type } = props;

  switch (type) {
    case 'Y':
      return <EligibleText>{type}</EligibleText>;
    case 'N':
    case 'X':
      return <NotEligibleText>{type}</NotEligibleText>;
    case 'S':
    case 'E':
      return <ScheduledOrEnrolledText>{type}</ScheduledOrEnrolledText>;
    default:
      return <></>;
  }
};

export default EligibilityType;
