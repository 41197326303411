import { createContext, ReactNode, SyntheticEvent, useState } from 'react';

type TabsProps = {
  selectedTab: number;
  tabChangeHandler: (_: SyntheticEvent, muiTabIndex: number) => void;
  panelTabChangeHandler: (muiTabIndex: number) => void;
};

type Props = {
  initialTab: number;
  children: ReactNode;
};

export const TabContext = createContext<TabsProps>({} as TabsProps);

export const TabsProvider = (props: Props): JSX.Element => {
  const { initialTab, children } = props;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const handleTabChange = (muiTabIndex: number): void => {
    setSelectedTab(muiTabIndex);
  };
  // handler for the mui tabs at the top of the page
  const tabChangeHandler = (_: SyntheticEvent, muiTabIndex: number): void => handleTabChange(muiTabIndex);
  // handler for the tab links at the bottom of the page
  const panelTabChangeHandler = (muiTabIndex: number): void => handleTabChange(muiTabIndex);

  return (
    <TabContext.Provider value={{ selectedTab, tabChangeHandler, panelTabChangeHandler }}>
      {children}
    </TabContext.Provider>
  );
};
