/** reference #13 * */
export const jLockViewEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'view',
  actionObject: 'agency 30 day lock details page',
  actionDetail: 'j',
  authenticationStatus: 'authenticated',
};

/** reference #14 * */
export const jLockViewCurrentBillClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'j',
  authenticationStatus: 'authenticated',
};
