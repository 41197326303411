import { Box, SxProps } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DteCtaLink } from 'component-library/links/DteCtaLink';
import { paymentAgreementClickEvents, PaymentAgreementEventLabels } from 'gtm/gtmEventsPaymentAgreement';
import { googleTagManager } from 'services';
import { accountHistoryUrlFromSchedueledPa } from 'urls-and-paths';

import messages from '../messages';

const sx: Record<string, SxProps> = {
  buttonWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
  linkWrapper: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
  },
};

interface IPropsMyAccountHistoryLink {
  cashOnly?: String;
}

const MyAccountHistoryLink: React.FC<IPropsMyAccountHistoryLink> = (
  props: IPropsMyAccountHistoryLink,
): React.ReactElement => {
  const { cashOnly } = props;
  const { isAuthenticated } = useUserState();

  const handleClick = (): void => {
    googleTagManager.trackEvent({
      ...paymentAgreementClickEvents,
      actionDetail: PaymentAgreementEventLabels.paidDownPayment,
      actionObject: PaymentAgreementEventLabels.myAccountHistory,
      userType: cashOnly === 'Y' ? PaymentAgreementEventLabels.cashOnlyUser : PaymentAgreementEventLabels.defaultUser,
      authenticationStatus: isAuthenticated,
    });
  };
  return (
    <Box onClick={handleClick} sx={sx.linkWrapper}>
      <DteCtaLink href={accountHistoryUrlFromSchedueledPa} name={messages.myAccountHistory} />
    </Box>
  );
};

export default MyAccountHistoryLink;
