/* eslint-disable @typescript-eslint/typedef */
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import DteDisclaimerIconPaymentExtension from '../icons/DteDisclaimerIconPaymentExtension';

export const PaymentServiceInterruptionBanner = (): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    root: {
      display: 'flex',
      marginTop: '32px',
      padding: '24px 0',
      width: '100%',
    },
    copy: {
      color: theme.palette.secondary.main,
      marginLeft: '20px',
      lineHeight: '22px',
    },
  };

  return (
    <Box sx={sx.root} data-testid="payment-service-interruption-banner">
      <DteDisclaimerIconPaymentExtension />
      <Box sx={sx.copy}>
        <Typography role="note">
          If your payment cannot be processed or we do not receive it, you are at risk of service interruption on or
          after your shutoff date and ineligible for another Payment Extension for one year.
        </Typography>
      </Box>
    </Box>
  );
};
