// styling comes from new page template but needs to be nested at this level of the DOM due to tabs
import { styled, Theme } from '@mui/material';

export const TabPanelContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px 24px',
  [theme.breakpoints.down('mobile')]: {
    padding: '40px 8px',
  },
}));
