const mockPaymentOffers = 'PaymentOffers' as EligibilityTypes;

export const mockRefactoredEligibilityResponse: IRefactoredEligibilityResponse = {
  eligible: {
    dunningLock: 'N',
    eligibilityType: mockPaymentOffers,
    paymentAgreement: 'N',
    paymentExtension: 'N',
    restore: 'N',
  },
  messageReply: {
    replyCode: 'Success',
    error: [],
  },
};
