import { Box, Typography, useTheme } from '@mui/material';

import { DteTextLink } from '../../../component-library/links/DteTextLink';

interface AgencyContactInformationProps {
  agencyName?: string;
  agencyPhoneNumber?: string;
  agencyPhoneNumberOnClickHandler?: () => void;
}
export const AgencyContactInformation = ({
  agencyName,
  agencyPhoneNumber,
  agencyPhoneNumberOnClickHandler,
}: AgencyContactInformationProps): React.ReactElement => {
  const theme = useTheme();

  const agencyContactInformationContainerSx = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.dteGrey.light,
    color: theme.palette.primary.main,
    gap: '12px',
    padding: '24px 0',
    width: '100%',
  };

  const contactInformationSx = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px',
    gap: '4px',
  };

  const agencyContactSx = {
    padding: '0 24px',
  };

  const instructionsBase = 'If you have questions, please contact';
  const instructions = agencyName ? `${instructionsBase}:` : `${instructionsBase} the agency at:`;

  return (
    <Box sx={agencyContactInformationContainerSx} data-testid="agencyContactInformationContainer">
      <Typography fontWeight={400} variant="body1" sx={agencyContactSx}>
        {instructions}
      </Typography>
      <Box sx={contactInformationSx}>
        {agencyName && (
          <Typography fontWeight={600} variant="body2">
            {agencyName}
          </Typography>
        )}
        {agencyPhoneNumber && (
          <DteTextLink
            href={`tel:${agencyPhoneNumber}`}
            name={agencyPhoneNumber}
            variant="body2"
            onClick={agencyPhoneNumberOnClickHandler}
          />
        )}
      </Box>
    </Box>
  );
};
