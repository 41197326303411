import { Box, styled, SxProps, useTheme } from '@mui/material';
import { MutationFunction } from '@tanstack/react-query';

import { PaymentServiceInterruptionBanner } from 'component-library/banners/PaymentServiceInterruptionBanner';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { PaymentExtensionTitle } from 'component-library/header/ServiceTitle';
import ViewCurrentBillLink from 'component-library/links/ViewCurrentBillLink';
import ViewPaymentOffersLink from 'component-library/links/ViewPaymentOffersLink';
import getFlagsConfig from 'config/getFlagsConfig';
import usePaymentExtensionMutateLambda from 'payment-extension/usePaymentExtensionMutateLambda';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

import PaymentExtensionAccountInformation from '../components/PaymentExtensionAccountInformation';
import { PaymentExtensionPastDueBalance } from '../components/PaymentExtensionPastDueBalance';

import messages from './messages';
import onCurrentBillButtonClick from './onCurrentBillButtonClick';
import onScheduleAPaymentButtonClick from './onScheduleAPaymentButtonClick';

interface IPaymentExtensionEligibleContentProps {
  accountDetails: IAccountDetailsResponse;
  bpId: string;
  isPaymentOffers: boolean;
  paymentExtensionDetails: IPaymentExtensionDetails;
}

const HeaderAndLinkContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const PaymentExtensionEligibleContent = (props: IPaymentExtensionEligibleContentProps): React.ReactElement => {
  const { accountDetails, bpId, isPaymentOffers, paymentExtensionDetails } = props;
  const { enablePaymentAgreement } = getFlagsConfig();
  const { mutateLambda } = usePaymentExtensionMutateLambda();

  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    peAccountInfoAndPastDueBalanceAdapter: {
      display: 'flex',
      border: `1px solid ${theme.palette.dteGrey.main}`,
      margin: '40px 0px 32px 0px',
    },
    peAccountInfoAndPastDueBalanceAdapterMobile: {
      flexDirection: 'column',
    },
    peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop: {
      flexDirection: 'row',
    },
  };

  const peAccountInfoAndPastDueBalanceMobileSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterMobile,
  };
  const peAccountInfoAndPastDueBalanceTabletAndDesktopSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop,
  };

  const peAccountInfoAndPastDueBalanceAdapterResponsiveSx = useResponsiveSx(
    peAccountInfoAndPastDueBalanceMobileSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
  );

  const accountNumber = accountDetails?.accounts[0].accountNumber;

  return (
    <Box>
      <HeaderAndLinkContainer>
        {enablePaymentAgreement && isPaymentOffers ? (
          <ViewPaymentOffersLink />
        ) : (
          <ViewCurrentBillLink onClickHandler={onCurrentBillButtonClick} />
        )}
        <PaymentExtensionTitle />
      </HeaderAndLinkContainer>
      <Box data-testid="account-info-past-due-balance-adapter" sx={peAccountInfoAndPastDueBalanceAdapterResponsiveSx}>
        <PaymentExtensionAccountInformation accountInfo={accountDetails} accountNumber={accountNumber} />
        <PaymentExtensionPastDueBalance paymentExtensionDetails={paymentExtensionDetails} />
      </Box>
      <Box sx={sx.rootWrapper}>
        <DtePrimaryButton
          dataTestId="schedule-a-payment"
          name={messages.scheduleAPaymentButton}
          onClick={(): void =>
            onScheduleAPaymentButtonClick(
              mutateLambda as MutationFunction<void, unknown>,
              accountNumber,
              bpId,
              paymentExtensionDetails,
            )
          }
        />
      </Box>
      <PaymentServiceInterruptionBanner />
    </Box>
  );
};

export default PaymentExtensionEligibleContent;
