import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const NoIcon = ({ containerSx, iconWidth = '43', iconHeight = '43' }: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="no-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 43 43" fill="none">
      <path
        d="M7.09953 35.7321C4.1347 32.7633 2.15992 28.9506 1.44558 24.8162C0.731237 20.6817 1.31224 16.4275 3.10904 12.6359C4.90584 8.84442 7.83061 5.70092 11.4829 3.63581C15.1352 1.57069 19.3366 0.684906 23.5118 1.09973C27.6869 1.51454 31.6318 3.20969 34.8064 5.95309C37.981 8.69648 40.2301 12.354 41.2457 16.425C42.2613 20.4959 41.9939 24.7813 40.48 28.6944C38.9661 32.6075 36.2797 35.957 32.7887 38.2844C28.8603 40.8605 24.1677 42.0123 19.4931 41.5478C14.8184 41.0834 10.4442 39.0308 7.09953 35.7321Z"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M36.1514 7.42578L7.6626 35.9145" stroke="#1E3575" strokeWidth="2" />
    </svg>
  </IconContainer>
);
