import { Divider, styled, Theme } from '@mui/material';

import { MonthPaymentAgreement } from './MonthPaymentAgreement';
import { RequiredDownPayment } from './RequiredDownPayment';

const TotalBalanceDueContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  display: 'flex',
  padding: '20px 24px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    height: '131px',
    alignContent: 'center',
  },
}));

const Line = styled(Divider)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.main,
  border: 'none',
  boxShadow: 'none',
  [theme.breakpoints.down('tablet')]: {
    height: '2px',
    margin: '8px 0',
  },
  [theme.breakpoints.up('tablet')]: {
    margin: '0 8px',
    orientation: 'vertical',
    width: '2px',
  },
}));

export const TotalBalanceDue: React.FC = () => (
  <TotalBalanceDueContainer data-testid="total-balance-due">
    <MonthPaymentAgreement />
    <Line data-testid="divider-line" />
    <RequiredDownPayment />
  </TotalBalanceDueContainer>
);
