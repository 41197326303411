import { styled, Theme, Typography } from '@mui/material';

const AddressContainer = styled('address')(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  color: theme.palette.secondary.main,
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '21.79px',
  size: '16px',
}));

const AddressLine = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '21.79px',
}));

interface AddressProps {
  address: IAddress;
}

export const Address: React.FC<AddressProps> = (props: AddressProps): React.ReactElement => {
  const { address } = props;
  const { addressLine1, addressLine2, city, state, zipcode } = address;

  return (
    <AddressContainer data-testid="address">
      <AddressLine data-testid="address-line-1">{addressLine1}</AddressLine>
      {addressLine2 && <AddressLine data-testid="address-line-2">{addressLine2}</AddressLine>}
      <AddressLine data-testid="address-city-state-zip">{`${city}, ${state} ${zipcode}`}</AddressLine>
    </AddressContainer>
  );
};
