import { styled } from '@mui/material';

import { DteButton } from '../../../component-library/buttons/DteButton';

const BottomContent = styled('div')({
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  paddingBottom: '32px',
});

type AgencyPaymentBottomContentProps = {
  viewCurrentBillClickHandler: () => void;
};

export const AgencyPaymentBottomContent = ({
  viewCurrentBillClickHandler,
}: AgencyPaymentBottomContentProps): React.ReactElement => (
  <BottomContent data-testid="agency-payment-info-bottom-content">
    <DteButton variant="primary" text="View Current Bill" onClick={viewCurrentBillClickHandler} />
  </BottomContent>
);
