import { useContext } from 'react';
import { styled, Theme } from '@mui/material';

import { dayjsDate } from '../../../component-library/dates/dayjsApStyleAbbreviateMonths';
import { ParagraphSecondary, ParagraphSecondaryBold } from '../../../component-library/typography';
import { EnrollmentContext } from '../EnrollmentContext';

import messages from './messages';

const SummaryTextContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down(755)]: {
    textAlign: 'center',
  },
}));

const SummaryText: React.FC = (): React.ReactElement => {
  const { enrollmentInformation } = useContext(EnrollmentContext);
  const { downPaymentDueDate } = enrollmentInformation;
  const paymentDueDate = dayjsDate(downPaymentDueDate).format('dddd, MMMM D');

  return (
    <SummaryTextContainer data-testid="review-payment-modal-summary-text">
      <ParagraphSecondaryBold>{`${messages.summaryTextDownPaymentDeadline} ${paymentDueDate}.`}</ParagraphSecondaryBold>
      <ParagraphSecondary>{messages.summaryTextNotice}</ParagraphSecondary>
    </SummaryTextContainer>
  );
};

export default SummaryText;
