import { styled } from '@mui/material';

import ReviewPaymentSummaryDetails from './ReviewPaymentSummaryDetails';

interface ReviewPaymentModalContentProps extends IDownPaymentMethod {
  isLoading?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled('div')(() => ({
  paddingBottom: '32px',
  paddingTop: '32px',
}));

const ReviewPaymentModalContent: React.FC<ReviewPaymentModalContentProps> = ({
  downPaymentMethod,
  isLoading,
  setIsLoading,
}: ReviewPaymentModalContentProps): React.ReactElement => (
  <Container data-testid="review-payment-modal-content">
    <ReviewPaymentSummaryDetails
      downPaymentMethod={downPaymentMethod}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  </Container>
);

export default ReviewPaymentModalContent;
