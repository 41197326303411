import { IconContainer } from './IconContainer';

type Props = {
  width?: number;
  height?: number;
  color: string;
};

const DteSuccessIcon = ({ width = 23, height = 23, color }: Props): React.ReactElement => (
  <IconContainer data-testid="dte-success-icon">
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11.5C22 17.299 17.299 22 11.5 22C5.70101 22 1 17.299 1 11.5C1 5.70101 5.70101 1 11.5 1C17.299 1 22 5.70101 22 11.5Z"
        stroke={`${color}`}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16 8L9.58974 15L6 12.0698"
        stroke={`${color}`}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
);

export default DteSuccessIcon;
