export const mockBaseCLockDunningLockDetailsResponse: IDunningLockDetailsResponse = {
  dunningLockDetails: [
    {
      dunningLockReason: 'C',
      name: 'Agency 30 Day Commitment Lock',
      startDate: '2023-07-19',
      endDate: '2023-08-18',
      agencyName: 'Test Agency Name',
      agencyNumber: '(555) 555-1212',
      agencyCode: 'Test Agency Code',
    },
  ],
  messageReply: {
    replyCode: 'Success',
  },
};

export const mockBaseYLockDunningLockDetailsResponse: IDunningLockDetailsResponse = {
  dunningLockDetails: [
    {
      dunningLockReason: 'Y',
      eligibilityFlag: 'Y',
      name: 'Income Eligible Lock',
      startDate: '2023-07-19',
      endDate: '2023-08-18',
    },
  ],
  messageReply: {
    replyCode: 'Success',
  },
};
