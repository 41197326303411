import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { paymentCenterLambda } from 'api/payment-center-lambda-api/payment-center-lambda-api';
import { useUserState } from 'auth/authenticate';
import { googleTagManager, logger } from 'services';

import { DtePrimaryButton } from '../../../../component-library/buttons/DtePrimaryButton';
import { routes, urls } from '../../../../constants';
import { useCreatePaymentAgreementPlan } from '../../../../hooks';
import { EnrollmentContext } from '../../EnrollmentContext';
import messages from '../messages';

interface ScheduleConfirmButtonProps {
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleConfirmButton: React.FC<ScheduleConfirmButtonProps> = ({
  setIsLoading,
}: ScheduleConfirmButtonProps): React.ReactElement => {
  const { enrollmentInformation } = useContext(EnrollmentContext);
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const {
    accountNumber: contractAccount,
    downPaymentAmount: downpaymentAmount,
    installmentAmount,
    installmentDuration,
  } = enrollmentInformation;

  const handleGtmOnConfirmClick = (): void => {
    const gtmOnScheduleConfirmClick = {
      actionDetail: 'continue to payment',
      actionObject: 'confirm enrollment',
      transactionStepName: 'enrollment confirmation',
      transactionType: 'payment agreement',
      userType: 'web',
    };

    googleTagManager.trackButtonClick({ ...gtmOnScheduleConfirmClick, authenticationStatus: isAuthenticated });
  };

  const onSuccess = (httpResponse: AxiosResponse<ICreatePaymentAgreementPlanResponse>): void => {
    const { documentNumber, messageReply } = httpResponse.data;

    const successCodeReceived = messageReply.replyCode.toLowerCase() === 'success';
    const documentNumberMorethanZero = documentNumber > 0;

    if (successCodeReceived && documentNumberMorethanZero) {
      logger.info('[Logging...] Plan Create API Call Success!', httpResponse);
      const formData = new URLSearchParams();

      formData.append('accounts', contractAccount);
      formData.append('paymentAgreementDetailsUrl', window.location.href);
      formData.append('paymentAmount', downpaymentAmount);
      formData.append('redirectUrl', `https://${window.location.hostname}${routes.paymentAgreement.enrolled}`);

      paymentCenterLambda({
        url: urls.lambdaPaymentAgreementUrl,
        formData,
      })
        .then(() => {
          window.location.href = urls.paymentCenterPaymentAgreementUrl;
        })
        .catch(() => {
          navigate(routes.error.paymentAgreement);
        });
    } else {
      navigate(routes.error.paymentAgreement);
    }
  };

  const { createPaymentAgreementPlan } = useCreatePaymentAgreementPlan({ onSuccess });

  return (
    <DtePrimaryButton
      dataTestId="review-payment-modal-schedule-payment-confirm-enrollment"
      name={messages.actionButtonConfirmLabel}
      onClick={(): void => {
        if (setIsLoading) {
          setIsLoading(true);
        }

        handleGtmOnConfirmClick();

        createPaymentAgreementPlan({
          contractAccount,
          downpaymentAmount,
          installmentAmount,
          numberOfInstallments: parseInt(installmentDuration, 10),
        });
      }}
    />
  );
};

export default ScheduleConfirmButton;
