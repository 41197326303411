import { Box, styled, Theme } from '@mui/material';

import { ParagraphSecondaryBold } from '../../../component-library/typography';

import messages from './messages';
import PaymentAgreementOfferCard from './PaymentAgreementOfferCard';
import PaymentExtensionOfferCard from './PaymentExtensionOfferCard';

const OfferCardsWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.up('desktop')]: {
    gap: '24px',
  },
  [theme.breakpoints.down('desktop')]: {
    gap: '16px',
  },
  [theme.breakpoints.down(755)]: {
    flexDirection: 'column',
  },
}));

const OffersSection: React.FC = (): React.ReactElement => (
  <Box
    data-testid="offers-section"
    display="flex"
    flexDirection="column"
    gap="20px"
    justifyContent="space-between"
    width="100%"
  >
    <ParagraphSecondaryBold data-testid="payment-offers-instruction" id="payment-offers-instruction">
      {messages.paymentOffersInstruction}
    </ParagraphSecondaryBold>
    <OfferCardsWrapper data-testid="payment-offer-cards" id="payment-offer-cards" justifyContent="space-between">
      <PaymentAgreementOfferCard />
      <PaymentExtensionOfferCard />
    </OfferCardsWrapper>
  </Box>
);

export default OffersSection;
