import * as React from 'react';
import { styled, Theme, Typography } from '@mui/material';

import { DteButton } from 'component-library/buttons/DteButton';
import { CompleteFormIcon } from 'component-library/icons/CompleteFormIcon';
import { UploadDocumentIcon } from 'component-library/icons/UploadDocumentIcon';
import { WeWillContactYouIcon } from 'component-library/icons/WeWillContactYouIcon';
import { Step } from 'component-library/step/Step';
import { Bold } from 'component-library/typography/Bold';

import { documentSubmissionUrl, medicalEmergencyHoldRequestFormUrl } from '../../../urls-and-paths';

type SubmitAMedicalCertificateFormProps = {
  lockEndDate: string;
  completeFormOnClickHandler: () => void;
  uploadFormOnClickHandler: () => void;
};

export const SubmitAMedicalCertificateForm = ({
  lockEndDate,
  completeFormOnClickHandler,
  uploadFormOnClickHandler,
}: SubmitAMedicalCertificateFormProps): React.ReactElement => {
  const SubmitAMedicalCertificateFormContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    paddingBottom: '32px',
  }));

  const HeadingAndInstructions = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  });

  const Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 400,
    color: theme.palette.primary.main,
    textAlign: 'center',
  }));

  const Instructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('tablet')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('tablet')]: {
      textAlign: 'center',
    },
  }));

  const Steps = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '24px',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));

  const StepWithButton = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '32px',
    maxWidth: '300px',
    flexBasis: '100%',
    [theme.breakpoints.down('tablet')]: {
      maxWidth: '100%',
    },
  }));

  return (
    <SubmitAMedicalCertificateFormContainer data-testid="submit-a-medical-certificate-form">
      <HeadingAndInstructions>
        <Heading>Please Submit a Medical Certification Form</Heading>
        <Instructions variant="body2">
          You are required to submit documentation <Bold>{`before ${lockEndDate}`}</Bold> to determine if you qualify
          for medical-related service protection. The process involves three steps:
        </Instructions>
      </HeadingAndInstructions>
      <Steps>
        <StepWithButton>
          <Step
            title="Step 1"
            subTitle="Complete Form"
            icon={<CompleteFormIcon />}
            instructions="If you or a member of your household has a medical emergency or requires home medical equipment or a life-support system, you must provide a signed and completed Medical Certification Form."
            dataTestId="medical-certificate-step1"
          />
          <DteButton
            variant="primary"
            text="Complete Form"
            href={medicalEmergencyHoldRequestFormUrl}
            onClick={completeFormOnClickHandler}
          />
        </StepWithButton>
        <StepWithButton>
          <Step
            title="Step 2"
            subTitle="Upload Documents"
            icon={<UploadDocumentIcon />}
            instructions="Upload and then submit the completed Medical Certification Form in our secure Document Submission Portal."
          />
          <DteButton
            variant="primary"
            text="Upload Form"
            href={documentSubmissionUrl}
            onClick={uploadFormOnClickHandler}
          />
        </StepWithButton>
        <Step
          title="Step 3"
          subTitle="We will Contact You"
          icon={<WeWillContactYouIcon />}
          instructions="Once your information is submitted, an Energy Advocacy Group team member will contact you within two business days at the phone number and email address you provided."
        />
      </Steps>
    </SubmitAMedicalCertificateFormContainer>
  );
};
