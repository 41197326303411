/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { logger } from 'services';

import { paymentAgreementDetailsApi } from '../api/payment-agreement-details-api/payment-agreement-details-api';
import { useUserState } from '../auth/authenticate';
import { paGenericErrorPath } from '../urls-and-paths';

interface IPaymentAgreementDetailsParams {
  shouldFetch: boolean;
}

interface IUsePaymentAgreementDetailsData {
  isFetched?: boolean;
  isPaymentAgreementDetailsSuccess: boolean;
  paymentAgreementDetails: IPaymentAgreementDetails;
}

const usePaymentAgreementDetails = ({
  shouldFetch,
}: IPaymentAgreementDetailsParams): IUsePaymentAgreementDetailsData => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();
  const {
    isFetched,
    isSuccess: isPaymentAgreementDetailsSuccess,
    data: paymentAgreementDetailsResponse,
  } = useQuery({
    enabled: isAuthenticated,
    queryKey: ['payment-agreement-details'],
    queryFn: paymentAgreementDetailsApi,
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: shouldFetch ? 0 : Infinity,
    // eslint-disable-next-line @typescript-eslint/typedef
    onSuccess: (httpResponse: { data: any }) => {
      const { data } = httpResponse;
      logger.info('[Logging...] Payment Agreement Details API Call Success!', httpResponse);
      if (!data?.paymentAgreementDetails) {
        // console.log('paymentAgreementDetails data.paymentAgreementDetails is missing');
        navigate(paGenericErrorPath);
      }
    },
    // eslint-disable-next-line @typescript-eslint/typedef
    onError: (error) => {
      /* navigate to error page */
      logger.info('[Logging...] Payment Agreement Details API Call Error!', error);
      navigate(paGenericErrorPath);
    },
  });

  if (paymentAgreementDetailsResponse?.data?.paymentAgreementDetails) {
    return {
      isFetched,
      isPaymentAgreementDetailsSuccess,
      paymentAgreementDetails: paymentAgreementDetailsResponse.data.paymentAgreementDetails,
    };
  }
  return {
    isFetched,
    isPaymentAgreementDetailsSuccess: false,
    paymentAgreementDetails: {
      downPaymentAmount: '',
      downPaymentPercentage: 0,
      pastDueBalance: '',
      paymentAgreement: '',
      paymentOptions: [],
      totalAccountBalance: '',
      totalPABalance: '',
    },
  };
};

export default usePaymentAgreementDetails;
