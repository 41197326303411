import { Box, styled, Theme, Typography } from '@mui/material';

import { DtePrimaryButtonPA } from '../../component-library/buttons/DtePrimaryButtonPA';
import PageNotFoundIcon from '../../component-library/icons/PageNotFoundErrorIcon';

import messages from './messages';

const BottomText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '18px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '24.51px',
  textAlign: 'center',
}));

const Container = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  marginTop: '40px',
  paddingBottom: '75px',
  width: '100%',
}));

const InfoContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const InstructionText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '23px',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '31.32px',
  textAlign: 'center',
}));

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '32px',
  fontWeight: 300,
  lineHeight: '43.58px',
  textAlign: 'center',
}));

interface INewPageNotFoundContentProps {
  onButtonClick: () => void;
}

const NewPageNotFoundContent: React.FC<INewPageNotFoundContentProps> = (
  props: INewPageNotFoundContentProps,
): React.ReactElement => {
  const { onButtonClick } = props;

  return (
    <Container>
      <PageNotFoundIcon />
      <InfoContainer>
        <Title data-testid="page-not-found-text">{messages.pageNotFoundText}</Title>
        <InstructionText data-testid="top-information-text">{messages.returnToCurrentBillText}</InstructionText>
      </InfoContainer>
      <DtePrimaryButtonPA dataTestId="back-to-current-bill" name={messages.buttonLabel} onClick={onButtonClick} />
      <BottomText data-testid="bottom-information-text">{messages.apologyText}</BottomText>
    </Container>
  );
};

export default NewPageNotFoundContent;
