import * as React from 'react';
import { styled, Theme, Typography } from '@mui/material';

const Footer = (): JSX.Element => {
  const StyledFooter = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '97px',
    width: '100%',
    background: theme.palette.dteBlue.dark,
    flexShrink: '0',
  }));

  const Copy = styled(Typography)(({ theme }: { theme: Theme }) => ({
    ...theme.typography.subtitle2,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.white,
    fontWeight: '400',
  }));

  return (
    <StyledFooter role="contentinfo" data-testid="footer">
      <Copy>© {new Date().getFullYear()} DTE Energy. All rights reserved.</Copy>
    </StyledFooter>
  );
};

export default Footer;
