import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';

import { HeaderCancelButtonProps } from '../header/header';
import DteCancelIcon from '../icons/DteCancelIcon';

const HeaderCancelButtonMobile: React.FC<HeaderCancelButtonProps> = ({
  onClick,
}: HeaderCancelButtonProps): React.ReactElement => {
  const theme = useTheme();
  const buttonSx = {
    borderRadius: '0px',
    color: theme.palette.common.white,
    height: '22px',
    padding: '0px 0px 0px 0px',
    textTransform: 'none',
    width: '76px',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
  };

  return (
    <Typography
      component={Button}
      data-testid="header-cancel-button-mobile"
      id="headerCancelButtonMobile"
      onClick={onClick}
      startIcon={<DteCancelIcon />}
      sx={buttonSx}
      variant="body1"
      fontWeight={400}
      disableFocusRipple
    >
      Cancel
    </Typography>
  );
};

export default HeaderCancelButtonMobile;
