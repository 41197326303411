import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePaymentAgreementDetails } from 'hooks';

import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import { PageTitles } from '../constants';

import PaymentAgreementHeader from './components/PaymentAgreementHeader';
import PaymentAgreementEnrollmentContent from './pa-enrollment/PaymentAgreementEnrollmentContent';

const PaymentAgreementEnrollmentPage: React.FC = (): React.ReactElement => {
  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: true });
  const { cashOnly } = paymentAgreementDetails;

  return (
    <>
      <Helmet>
        <title>{PageTitles.PAYMENT_AGREEMENT_ENROLLMENT}</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="16px"
        dataTestId="payment-agreement-enrollment-page"
        header={
          <PaymentAgreementHeader
            displayCancelButton
            transactionStepName="pa detail page"
            userType={cashOnly === 'Y' ? 'cash only' : 'web'}
          />
        }
        tabletDesktopContentWidth="740px"
      >
        <PaymentAgreementEnrollmentContent />
      </PageTemplate>
    </>
  );
};

export default PaymentAgreementEnrollmentPage;
