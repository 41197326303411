import { Box, styled, Theme } from '@mui/material';

import getFlagsConfig from 'config/getFlagsConfig';

import DownPaymentSummary from './DownPaymentSummary';
import InstallmentSummary from './InstallmentSummaryV2';

const { enablePAMonthlyRedesign } = getFlagsConfig();

interface IPaymentDetailsProps {
  downPaymentAmount: string;
  downPaymentStatus: string;
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
}

interface IPaymentDetailsContainerProps {
  numberOfInstallmentsOpted?: number;
}

interface IPaymentDetailsContainerPropsWithTheme {
  numberOfInstallmentsOpted?: number;
  theme: Theme;
}

const getPaymentDetailsContainerPadding = (numberOfInstallmentsOpted: number): string => {
  if (enablePAMonthlyRedesign) {
    if (numberOfInstallmentsOpted >= 25) {
      return '58.5px 24px';
    }
    return '79.5px 24px';
  }

  return '49px 24px';
};

const PaymentDetailsContainer = styled(Box)<IPaymentDetailsContainerProps>(
  ({ numberOfInstallmentsOpted = 0, theme }: IPaymentDetailsContainerPropsWithTheme) => ({
    backgroundColor: theme.palette.dteGrey.light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('tablet')]: {
      padding: '24px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: getPaymentDetailsContainerPadding(numberOfInstallmentsOpted),
      width: numberOfInstallmentsOpted >= 25 ? '100%' : '50%',
    },
  }),
);

export const PaymentDetailsV2: React.FC<IPaymentDetailsProps> = (props: IPaymentDetailsProps) => {
  const { downPaymentStatus, downPaymentAmount, installmentAmount, numberOfInstallmentsOpted } = props;

  return (
    <PaymentDetailsContainer data-testid="payment-details" numberOfInstallmentsOpted={numberOfInstallmentsOpted}>
      <Box display="flex" flexDirection="column" gap="24px">
        <InstallmentSummary
          installmentAmount={installmentAmount}
          numberOfInstallmentsOpted={numberOfInstallmentsOpted}
        />
        {numberOfInstallmentsOpted < 25 ? (
          <DownPaymentSummary downPaymentAmount={downPaymentAmount} downPaymentStatus={downPaymentStatus} />
        ) : null}
      </Box>
    </PaymentDetailsContainer>
  );
};
