import loggingConfig from './loggingConfig.json';

const getLogLevel = (): LogLevel => {
  const env = process.env.REACT_APP_DEPLOYED_ENVIRONMENT;

  if (!env) {
    return loggingConfig.production;
  }

  if (!loggingConfig[env as keyof typeof loggingConfig]) {
    return loggingConfig.production;
  }

  return loggingConfig[env as ReactAppDeployedEnvironment];
};

export default getLogLevel;
