import { adminEligibilityCheck } from 'api/eligibility-api/eligibility-api';
import { jwtApi } from 'api/login-api/jwt-api';
import { signInApi } from 'api/login-api/sign-in-api';
import { logger } from 'services';

import { ITestUser } from './types/ITestUser';
import { adminRefactoredEligibilityCheck } from './adminEligibilityCheck';

const logAPIError = (errorText: string, error: unknown): void => {
  logger.info(`[API Error] - ${errorText}`, error);
};

const fetchEligibility = async (bpId: string): Promise<ITestUser> => {
  const userData: ITestUser = {
    BP: bpId.toString(),
    DL: '',
    DunningLockType: '',
    EligibilityType: '',
    PA: '',
    PE: '',
    RE: '',
    jsonResponse: {},
    status: {
      isGetDataError: false,
      isGetDataSuccess: true,
    },
  };

  try {
    const signInApiResponse = await signInApi({ username: `BP${bpId}@DTEACCEPT.COM`, password: 'TEST1234' });

    if (signInApiResponse.data.gigyaToken.value) {
      try {
        const jwtResponse = await jwtApi(signInApiResponse.data.gigyaToken.value);

        if (jwtResponse.data.id_token) {
          try {
            const refactoredEligiblity = await adminRefactoredEligibilityCheck(jwtResponse.data.id_token);
            const { eligibilityType, paymentAgreement, paymentExtension, restore, dunningLock } =
              refactoredEligiblity.data.eligible;

            userData.DL = dunningLock;
            userData.EligibilityType = eligibilityType;
            userData.PA = paymentAgreement;
            userData.PE = paymentExtension;
            userData.RE = restore;
            userData.jsonResponse = refactoredEligiblity.data;
            userData.status = {
              isGetDataError: false,
              isGetDataSuccess: true,
            };

            if (eligibilityType === 'DunningLock') {
              try {
                const combinedEligibilityResponse = await adminEligibilityCheck(jwtResponse.data.id_token);

                if (combinedEligibilityResponse.data && combinedEligibilityResponse.data.dunningLockDetails) {
                  const { dunningLockReason, name } = combinedEligibilityResponse.data.dunningLockDetails[0];

                  userData.EligibilityType = `${eligibilityType}: ${dunningLockReason} Lock`;
                  userData.DunningLockType = name;
                  userData.jsonResponse = combinedEligibilityResponse.data;
                  userData.status = {
                    isGetDataError: false,
                    isGetDataSuccess: true,
                  };
                }
              } catch (error) {
                const errorText = 'Combined Eligibility Error';

                logAPIError(errorText, error);

                return {
                  ...userData,
                  jsonResponse: error,
                  status: {
                    isGetDataSuccess: false,
                    isGetDataError: true,
                    errorText,
                  },
                };
              }
            }
            return {
              ...userData,
              jsonResponse: refactoredEligiblity.data,
              status: {
                isGetDataError: false,
                isGetDataSuccess: true,
              },
            };
          } catch (error) {
            const errorText = 'Refactored Eligibility Error';

            logAPIError(errorText, error);

            return {
              ...userData,
              jsonResponse: error,
              status: {
                isGetDataError: true,
                isGetDataSuccess: false,
                errorText,
              },
            };
          }
        }
      } catch (error) {
        const errorText = 'JWT Error';

        logAPIError(errorText, error);

        return {
          ...userData,
          jsonResponse: error,
          status: {
            isGetDataError: true,
            isGetDataSuccess: false,
            errorText,
          },
        };
      }
    }
  } catch (error) {
    const errorText = 'Sign In Error';

    logAPIError(errorText, error);
    logger.info('userData', userData);

    return {
      ...userData,
      jsonResponse: error,
      status: {
        isGetDataError: true,
        isGetDataSuccess: false,
        errorText,
      },
    };
  }
  return {
    ...userData,
    status: {
      isGetDataError: true,
      isGetDataSuccess: false,
      errorText: 'Unknown Error',
    },
  };
};

export default fetchEligibility;
