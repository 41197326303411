import { useNavigate } from 'react-router-dom';
import { Button, styled, Theme, Typography } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import googleTagManager from 'services/googleTagManager';

import { routes } from '../../constants';

import messages from './messages';

const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  background: theme.palette.dteBlue.main,
  borderRadius: 'unset',
  boxShadow: 'unset',
  color: theme.palette.common.white,
  disableFocus: true,
  textTransform: 'none',
  width: '300px',
  '&:hover': {
    background: theme.palette.dteBlue.dark,
    boxShadow: 'unset',
  },
  '&:focus': {
    background: theme.palette.dteBlue.dark,
    color: theme.palette.common.white,
    boxShadow: 'unset',
  },
  [theme.breakpoints.down(755)]: {
    height: '35px',
  },
  [theme.breakpoints.up(755)]: {
    height: '40px',
  },
}));

export const gtmContinueButtonClick = {
  actionDetail: 'continue to pa details',
  actionObject: 'continue',
  transactionStepName: 'how it works',
  transactionType: 'payment agreement',
  userType: 'web',
};

const ContinueButton: React.FC = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const navigate = useNavigate();

  const handleClick = (): void => {
    googleTagManager.trackButtonClick({
      ...gtmContinueButtonClick,
      authenticationStatus: isAuthenticated,
    });

    navigate(routes.paymentAgreement.enrollment);
  };

  return (
    <StyledButton data-testid="how-payment-agreement-works-continue-button" onClick={(): void => handleClick()}>
      <Typography fontWeight={600} variant="body2">
        {messages.continueButton}
      </Typography>
    </StyledButton>
  );
};

export default ContinueButton;
