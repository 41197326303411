import React from 'react';
import { Alert, Snackbar, SnackbarOrigin, SxProps } from '@mui/material';

export interface SnackbarState extends SnackbarOrigin {
  isSnackbarOpen: boolean;
  clipboardValue: string;
}

interface CopySnackBarProps {
  snackbarState: SnackbarState;
  handleCloseSnackbar: () => void;
}

export const CopySnackbar: React.FC<CopySnackBarProps> = (props: CopySnackBarProps): React.ReactElement => {
  const { snackbarState, handleCloseSnackbar } = props;
  const { vertical, horizontal, isSnackbarOpen, clipboardValue } = snackbarState;
  const sx: Record<string, SxProps> = {
    clipboardSnackbar: {
      width: '100%',
    },
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isSnackbarOpen}
      onClose={handleCloseSnackbar}
      key={vertical + horizontal}
    >
      <Alert onClose={handleCloseSnackbar} severity="success" sx={sx.clipboardSnackbar}>
        {`Copied ${clipboardValue} to clipboard!`}
      </Alert>
    </Snackbar>
  );
};
