const messages = {
  stepOneInstructions: ' you prefer (from 3 to 6 months).',
  stepOneInstructionsSubTittle: 'Select the number of monthly payments',
  stepOneTitle: '1. Select an Option',
  stepThreeInstructions: ' beginning with your next DTE statement.',
  stepThreeInstructionsSubTittle: 'Pay your monthly installments',
  stepThreeTitle: '3. Pay Off Over Time',
  stepTwoInstructions: ' now or within 5 days of enrollment.',
  stepTwoInstructionsSubTittle: 'Pay your down payment',
  stepTwoTitle: '2. Enroll & Schedule',
};

export default messages;
