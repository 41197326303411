import { styled, Theme } from '@mui/material';

import DownPaymentRequiredSection from './DownPaymentRequiredSection';
import InformationSection from './InformationSection';
import PaymentEligibleBalanceSection from './PaymentEligibleBalanceSection';

const ContentWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('mobile')]: {
    gridTemplateAreas: `"informationSection informationSection"
    "paymentEligibleBalanceSection paymentEligibleBalanceSection"
    "downPaymentRequiredSection downPaymentRequiredSection"`,
    width: '304px',
  }, // SmallMobile
  [theme.breakpoints.between('mobile', 'tablet')]: {
    gridTemplateAreas: `"informationSection informationSection"
    "paymentEligibleBalanceSection paymentEligibleBalanceSection"
    "downPaymentRequiredSection downPaymentRequiredSection"`,
  }, // Mobile
  [theme.breakpoints.between('tablet', 'desktop')]: {
    gridTemplateAreas: `"informationSection informationSection"
    "paymentEligibleBalanceSection downPaymentRequiredSection"`,
  }, // Tablet
  [theme.breakpoints.up('desktop')]: {
    gridTemplateAreas: `"informationSection paymentEligibleBalanceSection"
    "informationSection downPaymentRequiredSection"`,
  }, // Desktop
}));

interface Props {
  accountInfo: IAccountDetailsResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentDetails: any;
}

const AccountInfoSection = ({ accountInfo, paymentDetails }: Props): React.ReactElement => (
  <>
    <ContentWrapper>
      <InformationSection accountInfo={accountInfo} />
      <PaymentEligibleBalanceSection totalPABalance={paymentDetails?.totalPABalance} />
      <DownPaymentRequiredSection
        downPaymentAmount={paymentDetails?.downPaymentAmount}
        downPaymentDueDate={paymentDetails?.downPaymentDueDate}
        downPaymentDueDayOfWeek={paymentDetails?.downPaymentDueDayOfWeek}
      />
    </ContentWrapper>
  </>
);

export default AccountInfoSection;
