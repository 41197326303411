import { styled } from '@mui/material';

import {
  AdditionalAssistanceBanner,
  DefaultAssistanceMessage,
} from 'component-library/banners/AdditionalAssistanceBanner';
import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import ViewCurrentBillLink from 'component-library/links/ViewCurrentBillLink';

import { LockStatusContent } from '../LockStatusContent';

import { DocumentSubmissionGuidelines } from './document-submission-guidelines/DocumentSubmissionGuidelines';
import { RequiredDocumentsV2 } from './required-documents/RequiredDocumentsV2';
import {
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
  viewCurrentBillOnClickHandler,
} from './handlers';

interface YLockContentProps {
  endDate: string;
}
export const YLockContent = (props: YLockContentProps): JSX.Element => {
  const { endDate } = props;

  const lockEndDate: string = endDate ? dayjsDate(endDate).format('dddd, MMMM D, YYYY') : '';

  const TopContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  });
  const BannerAndInstructions = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  }));

  return (
    <LockStatusContent>
      <TopContent>
        <ViewCurrentBillLink onClickHandler={viewCurrentBillOnClickHandler} />
        <BannerAndInstructions data-testid="banner-instruction">
          <SuccessBanner
            message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
          />
          <RequiredDocumentsV2 lockEndDate={lockEndDate} />
        </BannerAndInstructions>
      </TopContent>
      <DocumentSubmissionGuidelines />
      <AdditionalAssistanceBanner
        message={
          <DefaultAssistanceMessage
            energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
            unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          />
        }
      />
    </LockStatusContent>
  );
};
