const routes = {
  admin: '/admin',
  error: {
    paymentAgreement: '/pa-error',
    paymentExtension: '/pe-error',
    paymentOffers: '/po-error',
    reconnect: '/reconnect-error',
    lockStatus: '/service-protection-error',
  },
  pageNotFound: '/page-not-found',
  paymentAgreement: {
    enrollment: '/payment-agreement-enrollment',
    howPAWorks: '/how-payment-agreement-works',
    enrolled: '/payment-agreement',
  },
  paymentCenterTest: '/payment-center-test',
  paymentExtension: {
    eligible: '/payment-extension-eligible',
    scheduled: '/payment-extension',
    confirmationDetails: '/payment-extension-scheduled-payment',
  },
  paymentOffers: {
    default: '/payment-offers',
  },
  reconnect: {
    paymentOptions: '/reconnect-payment-options',
    confirmation: '/reconnect-confirmation',
    probate: '/verification',
  },
  root: '/',
  lockStatus: {
    j: '/agency-assistance-review',
    c: '/agency-payment-assistance',
    y: '/income-eligible-protection-review',
    w: '/medical-protection-review',
  },
};

export default routes;
