import { IconProps } from '../icon-props';
import { IconContainer } from '../IconContainer';

const PayOffOverTime = (iconProps: IconProps): React.ReactElement => {
  const { containerSx } = iconProps;

  return (
    <IconContainer data-testid="payoff-over-time-icon" sx={containerSx}>
      <svg width="56px" height="56px" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          fillRule="evenodd"
          d="M37.3383 9.37967C37.3383 9.93196 37.786 10.3797 38.3383 10.3797C38.8906 10.3797 39.3383 9.93196 39.3383 9.37967V6.19217H51.1149V14.571H2.00024V6.19217H13.7784V9.38072C13.7784 9.93301 14.2261 10.3807 14.7784 10.3807C15.3306 10.3807 15.7784 9.93301 15.7784 9.38072V6.19217H37.3383V9.37967ZM39.3383 4.19217V0.999756C39.3383 0.447471 38.8906 -0.00024412 38.3383 -0.000244141C37.786 -0.000244162 37.3383 0.447471 37.3383 0.999756V4.19217H15.7784V1.0008C15.7784 0.448518 15.3306 0.000803092 14.7784 0.00080307C14.2261 0.000803049 13.7784 0.448518 13.7784 1.0008V4.19217H1.00024C0.447959 4.19217 0.000244141 4.63988 0.000244141 5.19217V15.571V15.5721V49.3034C0.000244141 49.8557 0.447951 50.3034 1.00023 50.3034L1.00024 49.3034C1.00023 50.3034 1.00031 50.3034 1.00048 50.3034H1.00124H1.00425H1.01626H1.06385L1.25083 50.3035L1.97161 50.3035L4.63761 50.3035L13.5645 50.3036L35.3758 50.304C35.7029 50.304 36.0094 50.144 36.1963 49.8755C36.3833 49.6071 36.4272 49.2642 36.3138 48.9573C34.7321 44.6772 35.9294 40.3565 38.7852 37.7618C41.6082 35.197 46.1993 34.1984 51.6725 36.8986C51.9825 37.0515 52.3494 37.0335 52.6429 36.851C52.9365 36.6685 53.1149 36.3474 53.1149 36.0018V15.5721V15.571V5.19217C53.1149 4.63988 52.6672 4.19217 52.1149 4.19217H39.3383ZM2.00024 16.5721H51.1149V34.4623C45.5877 32.2636 40.672 33.3454 37.4403 36.2816C34.2731 39.1591 32.845 43.719 34.0175 48.3039L13.5646 48.3036L4.63764 48.3035L2.00024 48.3035V16.5721Z"
          fill="#1E3575"
        />
        <path
          clipRule="evenodd"
          fillRule="evenodd"
          d="M44.0829 22.1291C44.4844 22.5083 44.5025 23.1412 44.1233 23.5427L37.6469 30.3999C37.2785 30.79 36.6679 30.8197 36.2632 30.4674L33.5734 28.125C33.1569 27.7623 33.1132 27.1306 33.4759 26.7141C33.8386 26.2976 34.4703 26.254 34.8868 26.6167L36.8524 28.3284L42.6693 22.1694C43.0485 21.7679 43.6814 21.7499 44.0829 22.1291ZM9.44685 22.4509C9.83027 22.0534 10.4633 22.042 10.8608 22.4254L13.4004 24.875L15.8585 22.3397C16.2429 21.9432 16.876 21.9334 17.2725 22.3178C17.669 22.7022 17.6788 23.3353 17.2944 23.7318L14.8399 26.2635L17.3774 28.7111C17.7749 29.0945 17.7864 29.7276 17.4029 30.1251C17.0195 30.5226 16.3865 30.534 15.989 30.1506L13.4478 27.6995L10.9926 30.2318C10.6082 30.6284 9.97511 30.6382 9.57858 30.2537C9.18206 29.8693 9.17225 29.2362 9.55669 28.8397L12.0083 26.311L9.47236 23.8649C9.07485 23.4815 9.06344 22.8484 9.44685 22.4509ZM10.5212 35.7855V41.8982H16.9382V35.7855H10.5212ZM9.42124 34.2855C9.20033 34.2855 9.02124 34.4646 9.02124 34.6855V42.9982C9.02124 43.2192 9.20033 43.3982 9.42124 43.3982H18.0382C18.2591 43.3982 18.4382 43.2192 18.4382 42.9982V34.6855C18.4382 34.4646 18.2591 34.2855 18.0382 34.2855H9.42124ZM23.4654 22.2457C23.0679 21.8623 22.4349 21.8737 22.0515 22.2712C21.668 22.6687 21.6795 23.3018 22.077 23.6852L24.6127 26.1311L22.1609 28.66C21.7765 29.0565 21.7863 29.6896 22.1828 30.0741C22.5793 30.4585 23.2124 30.4487 23.5968 30.0522L26.0522 27.5196L28.5936 29.9709C28.9911 30.3543 29.6241 30.3429 30.0075 29.9454C30.391 29.5479 30.3795 28.9148 29.982 28.5314L27.4443 26.0836L29.8986 23.5522C30.283 23.1556 30.2732 22.5225 29.8767 22.1381C29.4802 21.7537 28.8471 21.7635 28.4627 22.16L26.0048 24.6951L23.4654 22.2457ZM23.1255 41.8982V35.7855H29.5424V41.8982H23.1255ZM21.6255 34.6855C21.6255 34.4646 21.8046 34.2855 22.0255 34.2855H30.6424C30.8633 34.2855 31.0424 34.4646 31.0424 34.6855V42.9982C31.0424 43.2192 30.8633 43.3982 30.6424 43.3982H22.0255C21.8046 43.3982 21.6255 43.2192 21.6255 42.9982V34.6855Z"
          fill="#1E3575"
        />
        <path
          d="M45.1153 55.9999C51.1269 55.9999 56.0002 51.139 56.0002 45.1428C56.0002 39.1466 51.1269 34.2856 45.1153 34.2856C39.1038 34.2856 34.2305 39.1466 34.2305 45.1428C34.2305 51.139 39.1038 55.9999 45.1153 55.9999Z"
          fill="white"
          stroke="#1E3575"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.1158 40.5464V38.688M45.1158 51.697V49.8386M42.3701 47.4911C42.3701 47.4911 42.5662 49.7408 45.0178 49.8386C47.4693 49.9364 47.7635 48.0779 47.7635 47.6867C47.7635 47.2955 47.7635 45.8283 45.1158 45.1436C42.3701 44.4589 42.5662 42.7961 42.6643 42.6005C42.7624 42.4048 42.8604 40.742 45.0178 40.5464C47.1752 40.3508 47.9596 42.4048 47.9596 42.7961"
          stroke="#1E3575"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconContainer>
  );
};

export default PayOffOverTime;
