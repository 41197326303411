import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const PhotoIdentificationIcon = ({
  containerSx,
  iconWidth = '80',
  iconHeight = '52',
}: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="photo-id-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 82 54" fill="none">
      <circle cx="62" cy="40.5" r="1" fill="#1E3575" />
      <rect
        x="1"
        y="1"
        width="80"
        height="52"
        rx="3"
        fill="white"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 47.3333L7.96453 45.586C8.34484 44.897 9.33516 44.897 9.71548 45.586L9.9251 45.9658C10.2777 46.6045 11.1735 46.6628 11.6059 46.0752L11.761 45.8646C12.1466 45.3407 12.9218 45.3186 13.3366 45.8196L14.6133 47.3614C14.9766 47.8002 15.6326 47.8473 16.0548 47.4648L17.2652 46.3685C17.6874 45.986 18.3434 46.0331 18.7067 46.4719L20.1333 48.1948C20.4966 48.6336 21.1526 48.6806 21.5748 48.2982L23.2742 46.7588C23.4581 46.5923 23.6974 46.5 23.9456 46.5H30"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 32C17.9853 32 20 29.9853 20 27.5C20 25.0147 17.9853 23 15.5 23C13.0147 23 11 25.0147 11 27.5C11 29.9853 13.0147 32 15.5 32Z"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 39C9 35.6923 11.9167 33 15.5 33C19.0833 33 22 35.6923 22 39"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56 6C54.4615 8.67622 51.0615 14.6971 47 15.5"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65 9C64.5238 10.4815 63.2571 13.5556 62 14"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60 6C58.8914 8.97082 56.9267 13.6088 54 14.5"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60 6C60.6349 8.37037 62.3238 13.2889 64 14"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69 9C68.3651 10.5144 66.6762 13.5457 65 14"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69 9C69.3333 11 71.0916 15.0435 73 15.5"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="6" y="20" width="19" height="22" rx="1" stroke="#1E3575" strokeWidth="1.5" />
      <line
        x1="29.75"
        y1="24.25"
        x2="47.25"
        y2="24.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="29.75"
        y1="32.25"
        x2="41.25"
        y2="32.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="29.75"
        y1="36.25"
        x2="44.25"
        y2="36.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="29.75"
        y1="40.25"
        x2="47.25"
        y2="40.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="29.75"
        y1="28.25"
        x2="44.25"
        y2="28.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="62.75"
        y1="28.25"
        x2="76.25"
        y2="28.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="62.75"
        y1="24.25"
        x2="76.25"
        y2="24.25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77 16.703C65.2189 12.5886 58.9024 14.1255 51.3724 15.1547C46.5376 15.8156 24 18 6.5 16.5"
        stroke="#1E3575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M56 18C56 14 56 6 56 6H60V18" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M65 18C65 15 65 9 65 9H69V18" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M73.5 16L73.5 18.5" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.5 16L46.5 18" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <line x1="38.5" y1="16.5" x2="38.5" y2="18.5" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <line x1="30.5" y1="17.5" x2="30.5" y2="18.5" stroke="#1E3575" strokeLinecap="round" strokeLinejoin="round" />
      <svg x="6" y="6" width="31" height="6" viewBox="0 0 31 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.92016V0.0838323H0.954092V5.1018H3.42515V5.92016H0Z" fill="#1E3575" />
        <path d="M4.43114 5.92016V0.0838323H5.38523V5.92016H4.43114Z" fill="#1E3575" />
        <path
          d="M9.41317 0.818363C8.86494 0.818363 8.4338 1.01264 8.11976 1.4012C7.80572 1.78975 7.6487 2.32601 7.6487 3.00998C7.6487 3.72588 7.79907 4.26747 8.0998 4.63473C8.40319 5.002 8.84098 5.18563 9.41317 5.18563C9.66068 5.18563 9.9002 5.16168 10.1317 5.11377C10.3633 5.06321 10.6041 4.99933 10.8543 4.92216V5.74052C10.3965 5.91351 9.87758 6 9.29741 6C8.44311 6 7.78709 5.74185 7.32934 5.22555C6.87159 4.70659 6.64271 3.9654 6.64271 3.002C6.64271 2.39521 6.75316 1.86427 6.97405 1.40918C7.1976 0.954092 7.51963 0.605456 7.94012 0.363273C8.36061 0.121091 8.85429 0 9.42116 0C10.0173 0 10.5682 0.125083 11.0739 0.37525L10.7305 1.16966C10.5336 1.07651 10.3247 0.995343 10.1038 0.926148C9.88556 0.854291 9.65536 0.818363 9.41317 0.818363Z"
          fill="#1E3575"
        />
        <path
          d="M15.4132 5.92016H12.1118V0.0838323H15.4132V0.89022H13.0659V2.47904H15.2655V3.27745H13.0659V5.10978H15.4132V5.92016Z"
          fill="#1E3575"
        />
        <path
          d="M21.5409 5.92016H20.3713L17.501 1.26946H17.4691L17.489 1.52894C17.5263 2.02395 17.5449 2.47638 17.5449 2.88623V5.92016H16.6786V0.0838323H17.8363L20.6986 4.71058H20.7226C20.7172 4.64937 20.7066 4.42715 20.6906 4.04391C20.6747 3.65802 20.6667 3.35729 20.6667 3.14172V0.0838323H21.5409V5.92016Z"
          fill="#1E3575"
        />
        <path
          d="M26.4471 4.33533C26.4471 4.85429 26.2595 5.26148 25.8842 5.55689C25.509 5.8523 24.9914 6 24.3313 6C23.6713 6 23.1311 5.89754 22.7106 5.69261V4.79042C22.9767 4.9155 23.2588 5.01397 23.5569 5.08583C23.8576 5.15768 24.1371 5.19361 24.3952 5.19361C24.7731 5.19361 25.0512 5.12176 25.2295 4.97804C25.4105 4.83433 25.501 4.64138 25.501 4.3992C25.501 4.18097 25.4185 3.99601 25.2535 3.84431C25.0885 3.69261 24.7478 3.51297 24.2315 3.30539C23.6993 3.08982 23.324 2.84365 23.1058 2.56687C22.8876 2.29009 22.7784 1.95742 22.7784 1.56886C22.7784 1.08184 22.9514 0.698603 23.2974 0.419162C23.6434 0.139721 24.1078 0 24.6906 0C25.2495 0 25.8057 0.122422 26.3593 0.367265L26.0559 1.14571C25.5369 0.927478 25.0739 0.818363 24.6667 0.818363C24.358 0.818363 24.1238 0.886228 23.9641 1.02196C23.8044 1.15502 23.7246 1.332 23.7246 1.55289C23.7246 1.70459 23.7565 1.835 23.8204 1.94411C23.8842 2.05057 23.9894 2.1517 24.1357 2.2475C24.2821 2.34331 24.5456 2.46973 24.9261 2.62675C25.3546 2.80506 25.6687 2.97139 25.8683 3.12575C26.0679 3.28011 26.2142 3.45442 26.3074 3.6487C26.4005 3.84298 26.4471 4.07186 26.4471 4.33533Z"
          fill="#1E3575"
        />
        <path
          d="M30.8782 5.92016H27.5768V0.0838323H30.8782V0.89022H28.5309V2.47904H30.7305V3.27745H28.5309V5.10978H30.8782V5.92016Z"
          fill="#1E3575"
        />
      </svg>
    </svg>
  </IconContainer>
);
