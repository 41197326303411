import { Box } from '@mui/material';

const DteCancelIcon = (): React.ReactElement => (
  <Box data-testid="dte-cancel-icon">
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.5L10.5 1.5C10.5 1.5 15 1.5 15 5.5C15 9.5 10.5 9.5 10.5 9.5H1M1 9.5L5 5.5M1 9.5L5 13.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

export default DteCancelIcon;
