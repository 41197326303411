import { Box, SxProps, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { ReadOnlyCurrencyMask } from 'component-library/currency/ReadOnlyCurrencyMask';
import { H5Heading, ParagraphSecondary } from 'component-library/typography';
import { usePaymentAgreementDetails } from 'hooks';

import messages from './messages';

const RequiredDownPayment: React.FC = () => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    red: {
      color: theme.palette.dteRed.dark,
    },
    wrapper: {
      gap: '4px',
      textAlign: 'center',
      [theme.breakpoints.up('mobile')]: {
        height: '77px',
      },
      [theme.breakpoints.down('mobile')]: {
        height: '99px',
      },
    },
  };

  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: false });
  const { downPaymentAmount, downPaymentDueDayOfWeek, downPaymentDueDate } = paymentAgreementDetails;
  const formattedDate = dayjs(downPaymentDueDate).format('MMMM DD');

  return (
    <Box alignItems="center" data-testid="down-payment-required" display="flex" flexDirection="column" sx={sx.wrapper}>
      <H5Heading sx={sx.red}>{messages.required}</H5Heading>
      <ParagraphSecondary
        data-testid="down-payment-required-time"
        sx={sx.red}
      >{`${messages.by} ${''}${downPaymentDueDayOfWeek}, ${formattedDate}${''} ${messages.before2PM}`}</ParagraphSecondary>
      <ParagraphSecondary data-testid="down-payment-required-amount" sx={sx.red}>
        {ReadOnlyCurrencyMask(parseFloat(downPaymentAmount))}
      </ParagraphSecondary>
    </Box>
  );
};

export default RequiredDownPayment;
