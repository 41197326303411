// 404 Page
export const notFoundBase = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'collections error',
  authenticationStatus: 'authenticated',
  transactionStepName: '404 page',
};

export const notFound404PageViewEvent = {
  ...notFoundBase,
  actionType: 'view',
  actionObject: 'view 404 not found page',
  actionDetail: 'collections page not found',
};

export const notFound404BackToCurrentBillClickEvent = {
  ...notFoundBase,
  actionType: 'click',
  actionObject: 'back to current bill',
  actionDetail: '404 not found page',
};

export const base404ForPaAndPo = {
  actionDetail: '404 error page',
  actionObject: 'page not found',
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'payment agreement',
};

export enum Page404ForPaAndPoEventLabels {
  backToCurrentBill = 'back to current bill',
  transactionStepNamePa = 'pa error',
  transactionStepNamePo = 'payment offers error',
}
