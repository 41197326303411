import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { paymentExtensionDetailsApi } from 'api/payment-extension-details-api/payment-extension-details-api';
import routes from 'constants/routes';
import { logger } from 'services';

interface IUsePEDetailsScheduledParams {
  enabled: boolean;
}

interface IUsePEDetailsScheduledResults {
  isFetched?: boolean;
  isPEDataError?: boolean;
  isPEDataLoading?: boolean;
  isPEDataSuccess?: boolean;
  isReadyForPELock?: boolean;
  paymentExtensionDetails: IPaymentExtensionDetails;
  refetchPaymentExtensionDetails: Function;
}

const usePEDetailsScheduled = (params: IUsePEDetailsScheduledParams): IUsePEDetailsScheduledResults => {
  const { enabled } = params;
  const navigate = useNavigate();
  const {
    data: paymentExtensionDetailsResponse,
    isFetched,
    isError: isPEDataError,
    isLoading: isPEDataLoading,
    isSuccess: isPEDataSuccess,
    refetch: refetchPaymentExtensionDetails,
  } = useQuery({
    enabled,
    onError: (error: unknown) => {
      logger.info('[API Error] PE Details API Call Error! Navigating to PE Error Page', error);
      navigate(routes.error.paymentExtension);
    },
    onSuccess: (httpResponse: AxiosResponse<IPaymentExtensionDetailsResponse>): void => {
      const { paymentExtensionDetails } = httpResponse.data;

      logger.info('[API Success] PE Details API Call Success!', httpResponse);

      if (!paymentExtensionDetails) {
        logger.info('[API Success - No Data] PE Details API Response is Empty! Navigating to PE Error Page');
        navigate(routes.error.paymentExtension);
      }

      logger.info('payment extension details api response : ', paymentExtensionDetails);

      const { eligibilityFlag, sumOfAllPayments, pastDueAmount } = paymentExtensionDetails;

      const eligibleForPE = eligibilityFlag === 'Y';
      const scheduledForPe = eligibilityFlag === 'S';
      const isSumOfAllPaymentsEqualOrMoreThanPastDueAmount =
        parseFloat(sumOfAllPayments || '0.00') >= parseFloat(pastDueAmount);

      if (eligibleForPE && !isSumOfAllPaymentsEqualOrMoreThanPastDueAmount) {
        navigate(routes.paymentExtension.eligible);
      }

      if (!eligibleForPE && !scheduledForPe) {
        logger.info('[API Success] Ineligible for PE! Navigating to PE Error Page');
        navigate(routes.error.paymentExtension);
      }
    },
    queryKey: ['payment-extension-details'],
    queryFn: paymentExtensionDetailsApi,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  if (paymentExtensionDetailsResponse?.data.paymentExtensionDetails) {
    const { eligibilityFlag, sumOfAllPayments, pastDueAmount } =
      paymentExtensionDetailsResponse?.data.paymentExtensionDetails;

    const eligibleForPE = eligibilityFlag === 'Y';
    const isSumOfAllPaymentsEqualOrMoreThanPastDueAmount =
      parseFloat(sumOfAllPayments || '0.00') >= parseFloat(pastDueAmount);

    return {
      isFetched,
      isPEDataError: false,
      isPEDataLoading: false,
      isPEDataSuccess: true,
      isReadyForPELock: eligibleForPE && isSumOfAllPaymentsEqualOrMoreThanPastDueAmount,
      paymentExtensionDetails: paymentExtensionDetailsResponse?.data.paymentExtensionDetails,
      refetchPaymentExtensionDetails,
    };
  }

  return {
    isFetched,
    isPEDataError,
    isPEDataLoading,
    isPEDataSuccess,
    isReadyForPELock: false,
    paymentExtensionDetails: {
      pastDueAmount: '',
      shutOffDate: '',
    },
    refetchPaymentExtensionDetails,
  };
};

export default usePEDetailsScheduled;
