import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Box, SxProps, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { useAccountDetails } from 'api/account-details-api/account-details-api';
import { AccountDetailsResponse } from 'api/account-details-api/AccountDetailsResponse';
import { useEligibility } from 'api/eligibility-api/eligibility-api';
import { useUserState } from 'auth/authenticate';
import { PaymentServiceInterruptionBanner } from 'component-library/banners/PaymentServiceInterruptionBanner';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import ViewCurrentBillButton from 'component-library/buttons/ViewCurrentBillButton';
import { offeredPeScheduledDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { paymentExtension } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { PaymentExtensionConfirmDetailsTitle } from 'component-library/header/ServiceTitle';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { trackGA4Event } from 'gtm/gtag';
import {
  peConfirmDetailsHeaderCancelClickEvent,
  peConfirmDetailsPageViewEvent,
  peConfirmDetailsSchedulePeClickEvent,
  peConfirmViewCurrentBillClickEvent,
} from 'gtm/gtmEventsPaymentExtension';
import { useResponsiveRendering, useResponsiveSx } from 'theme/breakpoints/responsiveHooks';
import { currentBillUrl, paymentExtensionScheduledPath, peGenericErrorPath } from 'urls-and-paths';

import PaymentExtensionAccountInformation from './components/PaymentExtensionAccountInformation';
import { PaymentExtensionDate } from './components/PaymentExtensionDate';

const headerCancelButtonHandler = (): void => {
  trackGA4Event(peConfirmDetailsHeaderCancelClickEvent);
  window.location.href = currentBillUrl;
};

const mobileHeader = (
  <HeaderMobile titleText={paymentExtension} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);

const desktopHeader = (
  <HeaderDesktop titleText={paymentExtension} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);

const PaymentExtensionConfirmDetailsPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();
  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<AccountDetailsResponse>) => {
      const { data } = httpResponse;
      if (!data.accounts[0].accountNumber) {
        console.log('accountDetails response is missing AccountNumber');
        navigate(peGenericErrorPath);
      }
    },
    onError: (error: unknown) => {
      /* navigate to error page */
      console.log('accountDetails Error', error);
      navigate(peGenericErrorPath);
    },
  });

  const { isEligibilitySuccess, eligibilityResponse } = useEligibility({
    queryKey: ['payment-extension-confirm-eligibility'],
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<CombinedEligibilityCheckResponse>) => {
      trackGA4Event(peConfirmDetailsPageViewEvent);

      const combinedEligibilityResponse = httpResponse.data;

      if (combinedEligibilityResponse.eligible.paymentExtension !== 'Y') {
        console.log('Not Eligible for Payment Extension -> routing to error page');
        // navigate to Generic Error Page
        navigate(peGenericErrorPath);
      }
    },
    onError: (error: unknown) => {
      console.log('combinedEligibility', error);
      // navigate to Generic Error Page
      navigate(peGenericErrorPath);
    },
  });

  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  const accountNumber = Number(accountDetailsResponse?.data.accounts[0].accountNumber);
  const shutoffDate = dayjs(eligibilityResponse?.data.paymentExtensionDetails?.shutOffDate).format('dddd, MMM D, YYYY');

  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    peAccountInfoAndPastDueBalanceAdapter: {
      display: 'flex',
      border: `1px solid ${theme.palette.dteGrey.main}`,
      margin: '40px 0px 32px 0px',
    },
    peAccountInfoAndPastDueBalanceAdapterMobile: {
      flexDirection: 'column',
    },
    peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop: {
      flexDirection: 'row',
    },
    peDate: {
      display: 'flex',
      minWidth: '50%',
      padding: '30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.dteGrey.light,
    },
  };

  const peAccountInfoAndPastDueBalanceMobileSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterMobile,
  };
  const peAccountInfoAndPastDueBalanceTabletAndDesktopSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop,
  };

  const peAccountInfoAndPastDueBalanceAdapterResponsiveSx = useResponsiveSx(
    peAccountInfoAndPastDueBalanceMobileSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
  );

  const schedulePaymentExtensionHandler = (): void => {
    trackGA4Event(peConfirmDetailsSchedulePeClickEvent);
    navigate(paymentExtensionScheduledPath);
  };

  const redirectToCurrentBillHandler = (): void => {
    trackGA4Event(peConfirmViewCurrentBillClickEvent);
    window.location.href = currentBillUrl;
  };

  const schedulePaymentExtensionButton = (
    <DtePrimaryButton name="Schedule Payment Extension" onClick={schedulePaymentExtensionHandler} />
  );

  return (
    <>
      <Helmet>
        <title>Payment Center | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="16px"
        tabletDesktopContentWidth="740px"
        dataTestId="payment-extension-confirm-details-page"
      >
        {isEligibilitySuccess && isAccountDetailsSuccess ? (
          <>
            <ViewCurrentBillButton onClick={redirectToCurrentBillHandler} />
            <PaymentExtensionConfirmDetailsTitle shutoffDate={shutoffDate} />
            <Box
              data-testid="account-info-past-due-balance-adapter"
              sx={peAccountInfoAndPastDueBalanceAdapterResponsiveSx}
            >
              <PaymentExtensionAccountInformation
                accountInfo={accountDetailsResponse?.data}
                accountNumber={accountNumber}
              />
              <Box sx={sx.peDate}>
                <PaymentExtensionDate date={offeredPeScheduledDate()} fontVariant="body2" />
              </Box>
            </Box>
            <Box sx={sx.rootWrapper}>{schedulePaymentExtensionButton}</Box>
            <PaymentServiceInterruptionBanner />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionConfirmDetailsPage;
