import { AxiosResponse } from 'axios';

import { axiosGet } from 'api/adapter/axios-adapter';
import { apiPaths } from 'constants/apiPaths';

export const adminRefactoredEligibilityCheck = async (
  jwt: string,
): Promise<AxiosResponse<IRefactoredEligibilityResponse>> =>
  axiosGet<IRefactoredEligibilityResponse>(apiPaths.refactoredEligibilityApiPath, {
    baseURL: process.env.REACT_APP_BACKEND_HOST,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
