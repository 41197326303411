import { styled } from '@mui/material';

import { downPaymentStatusValues, urlParams } from 'constants/';
import { useGetUrlParam, usePaymentAgreementDetails } from 'hooks';

import MyAccountHistoryLink from './MyAccountHistoryLink';
import PAViewCurrentBillButton from './PAViewCurrentBillButton';
import ViewScheduledPaymentLink from './ViewScheduledPaymentLink';

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
}));

const ViewPaymentSection: React.FC = (): React.ReactElement => {
  const paymentSuccessful = useGetUrlParam(urlParams.paymentCenterPaymentSuccessfulParam);

  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: false });
  const { cashOnly, downPaymentStatus } = paymentAgreementDetails;

  const showMyAccountHistoryLink =
    downPaymentStatus === downPaymentStatusValues.Paid && cashOnly !== 'Y' && paymentSuccessful !== 'true';

  return (
    <TitleContainer data-testid="view-payment-section">
      <PAViewCurrentBillButton />
      {showMyAccountHistoryLink && <MyAccountHistoryLink cashOnly={cashOnly} />}
      {downPaymentStatus === downPaymentStatusValues.Scheduled && <ViewScheduledPaymentLink />}
    </TitleContainer>
  );
};

export default ViewPaymentSection;
