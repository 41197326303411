import React, { ChangeEvent, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Box, TextField, Typography } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';

import { UploadDocumentIcon } from '../component-library/icons/UploadDocumentIcon';
import { theme } from '../theme/theme';

import { CopyTestPassword } from './copy/CopyTestPassword';
import { ITestUser } from './types/ITestUser';
import { TestUserTable } from './user-table/TestUserTable';
import { CheckEligibilityButton } from './CheckEligibilityButton';
import { FileUploadSnackBar } from './FileUploadSnackBar';

interface AdminProps {
  bpId: string;
  bpIdList: string[];
  setBpIdHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  fileUploadChangeHandler: (file: File) => void;
}

export const AdminPagePresentation: React.FC<AdminProps> = (props: AdminProps): React.ReactElement => {
  const { bpId, bpIdList, fileUploadChangeHandler, setBpIdHandler } = props;

  const [tableData, setTableData] = useState<ITestUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSnackBarOpen, setFileUploadSnackBar] = useState<boolean>(false);

  const columnHelper = createColumnHelper<ITestUser>();

  const getCellValue = (info: CellContext<ITestUser, string>): string | unknown => info.getValue();

  const columns = [
    columnHelper.accessor('BP', {
      cell: getCellValue,
      header: () => 'BpId',
      enableColumnFilter: true,
    }),
    columnHelper.accessor('EligibilityType', {
      cell: getCellValue,
      enableColumnFilter: true,
      header: () => 'Eligibility Type',
    }),
    columnHelper.accessor('PA', {
      header: () => 'Payment Agreement',
      enableColumnFilter: true,
      cell: getCellValue,
    }),
    columnHelper.accessor('PE', {
      header: () => 'Payment Extension',
      enableColumnFilter: true,
      cell: getCellValue,
    }),
    columnHelper.accessor('RE', {
      header: () => 'Reconnect',
      enableColumnFilter: true,
      cell: getCellValue,
    }),
    columnHelper.accessor('DL', {
      header: () => 'Dunning Lock',
      enableColumnFilter: true,
      cell: getCellValue,
    }),
  ];

  const containerSx = { margin: '15px' };

  const fileUploaderSx = {
    alignItems: 'center',
    border: `2px dashed ${theme.palette.dteBlue.dark}`,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    height: '100px',
    width: '100%',
  };

  const singleBpIdCheckSx = {
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
    marginTop: '10px',
  };

  return (
    <Box sx={containerSx}>
      <FileUploader name="testUsers" label="Upload or Drag & Drop" handleChange={fileUploadChangeHandler}>
        <Box sx={fileUploaderSx}>
          <UploadDocumentIcon containerSx={{ scale: '.75', marginRight: '15px' }} />
          <input accept=".xlsx" type="file" name="testUsers" hidden />
          {bpIdList.length > 0 ? (
            <Box display="flex" flexDirection="column">
              <Typography variant="h1" sx={{ fontWeight: '600' }} color={theme.palette.dteGreen.main}>
                Your File has been dropped!
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: '400' }}>
                Click{' '}
                <i>
                  <b>Check Eligibility</b>
                </i>{' '}
                button to see result
              </Typography>
            </Box>
          ) : (
            <Typography variant="h1" sx={{ fontWeight: '600' }}>
              Drag & Drop here!
            </Typography>
          )}
        </Box>
      </FileUploader>
      <FileUploadSnackBar
        handleCloseSnackbar={(): void => setFileUploadSnackBar(false)}
        isSnackBarOpen={isSnackBarOpen}
      />
      <Box sx={singleBpIdCheckSx}>
        <TextField label="bpId" value={bpId} onChange={setBpIdHandler} />
        <CheckEligibilityButton
          bpIdFromTextBox={bpId}
          bpIdList={bpIdList}
          handleFileUpload={(): void => {
            fileUploadChangeHandler(new File([], 'empty'));
            setBpIdHandler({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
            setFileUploadSnackBar(true);
          }}
          setIsLoading={setIsLoading}
          setTableData={setTableData}
        />
        <CopyTestPassword />
      </Box>
      {tableData && (
        <TestUserTable
          columns={columns}
          isLoading={isLoading}
          numberOfUsers={bpIdList.length + (bpId.length ? 1 : 0)}
          tableData={tableData}
        />
      )}
    </Box>
  );
};
