import { theme } from 'theme/theme';

export const rootContainerSx = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
};

export const mainContentBaseSx = {
  alignItems: 'center',
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  padding: '40px 24px',
};

export const mainContentSx = {
  desktop: {
    ...mainContentBaseSx,
  },
  tablet: {
    ...mainContentBaseSx,
  },
  mobile: {
    ...mainContentBaseSx,
  },
  smallMobile: {
    ...mainContentBaseSx,
    padding: '40px 8px',
  },
};

export const assistanceInformationContainerBaseSx = {
  alignItems: 'center',
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  padding: '40px 0',
  width: '100%',
};

export const assistanceInformationContainerSx = {
  desktop: {
    ...assistanceInformationContainerBaseSx,
  },
  tablet: {
    ...assistanceInformationContainerBaseSx,
  },
  mobile: {
    ...assistanceInformationContainerBaseSx,
  },
  smallMobile: {
    ...assistanceInformationContainerBaseSx,
  },
};

export const assistanceInformationContentBaseSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
};

export const assistanceInformationContentSx = {
  desktop: {
    ...assistanceInformationContentBaseSx,
    maxWidth: '600px',
  },
  tablet: {
    ...assistanceInformationContentBaseSx,
    maxWidth: '600px',
  },
  mobile: {
    ...assistanceInformationContentBaseSx,
  },
  smallMobile: {
    ...assistanceInformationContentBaseSx,
  },
};

export const assistanceTitleSx = {
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
};

export const assistanceCopySx = {
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
};
