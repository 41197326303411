import React from 'react';
import { Box, styled, SxProps } from '@mui/material';

import ResponsiveTooltip from 'component-library/tooltip/ResponsiveTooltip';
import { H5Heading } from 'component-library/typography';
import { theme } from 'theme/theme';

import messages from './messages';
import ProgressCircle from './ProgressCircle';
import ProgressLabel from './ProgressLabel';

interface IMonthlyProgressProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const Container = styled(Box)(() => ({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '13px',
  justifyContent: 'center',
  padding: '24px',
  [theme.breakpoints.down('tablet')]: {
    borderTop: `1px solid ${theme.palette.dteGrey.main}`,
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    width: '50%',
  },
}));

const sx: Record<string, SxProps> = {
  labelEndDescription: {
    display: 'inline-flex',
    alignItems: 'center',
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap',
    height: '24.5px',
  }, // wraps icon to text
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('mobile')]: {
      paddingLeft: '13px',
    }, // SmallMobile
    [theme.breakpoints.between('mobile', 'tablet')]: {
      paddingLeft: '13px',
    }, // Mobile
  },
  toolTipWrapper: {
    // overwrite tooltip css
    ' > div > button ': {
      margin: '3px 0px 3px 5px',
      padding: '0px',
    },
  },
};

const HeadingWithTooltip = (): React.ReactElement => (
  <Box sx={sx.tooltipContainer}>
    <Box component="div" sx={sx.labelEndDescription}>
      <H5Heading data-testid="monthly-payment-title">{messages.title}</H5Heading>
      <Box component="span" sx={sx.toolTipWrapper}>
        <ResponsiveTooltip popoverText={messages.popoverText} />
      </Box>
    </Box>
  </Box>
);

export const MonthlyPayment: React.FC<IMonthlyProgressProps> = (props: IMonthlyProgressProps) => {
  const { numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = props;

  return (
    <Container data-testid="monthly-payment">
      <HeadingWithTooltip />
      <Box data-testid="monthly-progress" sx={{ height: '143px', position: 'relative', width: '143px' }}>
        <ProgressCircle
          numberOfInstallmentsOpted={numberOfInstallmentsOpted}
          numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
        />
        <ProgressLabel
          numberOfInstallmentsOpted={numberOfInstallmentsOpted}
          numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
        />
      </Box>
    </Container>
  );
};
