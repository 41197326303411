import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { onRequestError, onRequestHandler, onResponseError, onResponseHandler } from './axios-interceptors';

let instance: AxiosInstance;

// this function only gets called once -the first time
const createInstance = (): AxiosInstance => {
  instance = axios.create();
  // @ts-ignore
  instance.interceptors.request.use(onRequestHandler, onRequestError);
  instance.interceptors.response.use(onResponseHandler, onResponseError);

  return instance;
};

const getAxiosInstance = (): AxiosInstance => instance || createInstance();

export const axiosGet = async <T>(
  url: string,
  config: Partial<AxiosRequestConfig>,
  axiosInstance: AxiosInstance = getAxiosInstance(),
): Promise<AxiosResponse<T>> => axiosInstance.get(url, config);

export const axiosPost = async <T>(
  url: string,
  data: unknown,
  config: Partial<AxiosRequestConfig>,
  axiosInstance: AxiosInstance = getAxiosInstance(),
): Promise<AxiosResponse<T>> => axiosInstance.post(url, data, config);
