import { Box, styled, Theme, Typography } from '@mui/material';

import { DialogProvider } from 'component-library/dialog/DialogContext';

import CashOnlyIcon from '../../../component-library/icons/payment-agreement-icons/CashOnlyIcon';

import ContinueEnrollmentButton from './ContinueEnrollmentButton';
import messages from './messages';

const SectionWrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
}));

const ContentWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  padding: '30px 20px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    textAlign: 'center',
    width: '304px',
  },
  [theme.breakpoints.between('tablet', 'desktop')]: {
    width: '688px',
  },
}));

const Notes = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'open sans',
  fontSize: '16px',
  fontWeight: '700',
}));

const NotesWrapper = styled('div')(() => ({
  display: 'flex',
}));

const Paragraph = styled('span')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'open sans',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
}));

const CashOnlySelection: React.FC = (): React.ReactElement => (
  <SectionWrapper data-testid="cash-only-selection">
    <ContentWrapper>
      <CashOnlyIcon />
      <NotesWrapper>
        <Notes>
          {`${messages.notesTitle} `}
          <Paragraph>
            {messages.firstParagraphNote} {messages.secondParagraphNote} {messages.thirdParagraphNote} <br />
            {messages.fourthParagraphNote}
          </Paragraph>
        </Notes>
      </NotesWrapper>
    </ContentWrapper>
    <DialogProvider>
      <ContinueEnrollmentButton />
    </DialogProvider>
  </SectionWrapper>
);

export default CashOnlySelection;
