import { styled, Theme, Typography } from '@mui/material';

import messages from './messages';

const Paragraph = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Open Sans',
  lineHeight: '21.79px',
}));

const PaymentOffersDescription: React.FC = (): React.ReactElement => (
  <Paragraph data-testid="payment-offers-description">{messages.paymentOffersDecription}</Paragraph>
);

export default PaymentOffersDescription;
