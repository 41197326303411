import { styled, Theme, Typography } from '@mui/material';

import { Accordion } from 'component-library/accordion/Accordion';
import { Bold } from 'component-library/typography/Bold';

import { DocumentGuideline } from './DocumentGuideline';
import {
  DocumentsNotAcceptedGuidelineLabel,
  PhotoIdentificationGuidelineLabel,
  ProofOfHouseholdMembersGuidelineLabel,
  ProofOfIncomeGuidelineLabel,
} from './DocumentGuidelinesLabel';
import {
  DocumentsNotAcceptedPanelContent,
  ExpandedPanel,
  PhotoIdentificationPanelContent,
  ProofOfHouseholdMembersPanelContent,
  ProofOfIncomePanelContent,
} from './ExpandedPanel';

const DocumentSubmissionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: '24px',
}));

const DocumentSubmissionTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '22px',
  lineHeight: '30px',
  textAlign: 'center',
}));

const DocumentSubmissionInstructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '400',
}));

const RequiredDocumentsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '14px',
}));

const PhotoIdentificationPanel = <ExpandedPanel content={<PhotoIdentificationPanelContent />} />;
const ProofOfHouseholdMembersPanel = <ExpandedPanel content={<ProofOfHouseholdMembersPanelContent />} />;
const ProofOfIncomePanel = <ExpandedPanel content={<ProofOfIncomePanelContent />} />;
const DocumentsNotAcceptedPanel = <ExpandedPanel content={<DocumentsNotAcceptedPanelContent />} />;

export const PhotoIdentificationAccordion = (): React.ReactElement => (
  <Accordion
    collapsedSummary="View Required Documents"
    expandedSummary="Hide Required Documents"
    expandedPanel={PhotoIdentificationPanel}
    dataTestId="photo-identification-accordion"
  />
);

export const ProofOfHouseholdMembersAccordion = (): React.ReactElement => (
  <Accordion
    collapsedSummary="View Required Documents"
    expandedSummary="Hide Required Documents"
    expandedPanel={ProofOfHouseholdMembersPanel}
    dataTestId="proof-of-household-members-accordion"
  />
);

export const ProofOfIncomeAccordion = (): React.ReactElement => (
  <Accordion
    collapsedSummary="View Required Documents"
    expandedSummary="Hide Required Documents"
    expandedPanel={ProofOfIncomePanel}
    dataTestId="proof-of-income-accordion"
  />
);

export const DocumentsNotAcceptedAccordion = (): React.ReactElement => (
  <Accordion
    collapsedSummary="View Documents"
    expandedSummary="Hide Documents"
    expandedPanel={DocumentsNotAcceptedPanel}
    dataTestId="documents-not-accepted-accordion"
  />
);

export const DocumentSubmissionGuidelines = (): React.ReactElement => (
  <DocumentSubmissionContainer data-testid="document-submission-container">
    <DocumentSubmissionTitle>
      <Bold>Document Submission Guidelines</Bold>
    </DocumentSubmissionTitle>
    <DocumentSubmissionInstructions variant="body1">
      <Bold>{`Documentation is required for each of the three categories below. `}</Bold>
      {`Please ensure the required documents are valid
          and be sure to follow these guidelines to avoid a processing delay or denial because of invalid, altered, blurry
          or missing documents. Documents not required and not accepted are also listed below.`}
    </DocumentSubmissionInstructions>
    <RequiredDocumentsContainer>
      <DocumentGuideline
        labelComponent={<PhotoIdentificationGuidelineLabel />}
        accordionComponent={<PhotoIdentificationAccordion />}
        dataTestId="photo-identification-document-guideline"
      />
      <DocumentGuideline
        labelComponent={<ProofOfHouseholdMembersGuidelineLabel />}
        accordionComponent={<ProofOfHouseholdMembersAccordion />}
        dataTestId="proof-of-household-members-document-guideline"
      />
      <DocumentGuideline
        labelComponent={<ProofOfIncomeGuidelineLabel />}
        accordionComponent={<ProofOfIncomeAccordion />}
        dataTestId="proof-of-income-document-guideline"
      />
      <DocumentGuideline
        labelComponent={<DocumentsNotAcceptedGuidelineLabel />}
        accordionComponent={<DocumentsNotAcceptedAccordion />}
        dataTestId="documents-not-accepted-guideline"
      />
    </RequiredDocumentsContainer>
  </DocumentSubmissionContainer>
);
