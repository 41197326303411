const PageHeaders = Object.freeze({
  ADMIN: 'Admin',
  PAYMENT_AGREEMENT: 'Payment Agreement',
  PAYMENT_CENTER_TEST: 'Payment Center Test',
  PAYMENT_EXTENSION: 'Payment Extension',
  PAYMENT_OFFERS: 'Payment Offers',
  RECONNECT: 'Reconnect',
  SERVICE_PROTECTION: 'Service Protection',
});

export default PageHeaders;
