import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { ITestUser } from 'admin-page/types/ITestUser';

interface TablePagingProps {
  table?: Table<ITestUser>;
}

export const TablePaging = (props: TablePagingProps): React.ReactElement => {
  const { table } = props;

  const pageCount = table?.getPageCount();
  const pageIndex = table?.getState().pagination.pageIndex;

  const currentPageNumber = pageCount === undefined || pageCount === 0 ? 0 : pageIndex! + 1;
  const numberOfPages = pageCount === undefined || pageCount === 0 ? 0 : pageCount;
  const rowPerPage = table?.getState().pagination.pageSize;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '5px',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px', width: '100%' }}>
        <Typography>Page </Typography>
        <Typography sx={{ fontWeight: '600' }}>
          {currentPageNumber} of {numberOfPages}
        </Typography>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <InputLabel id="rows-per-page-label">rows per page</InputLabel>
        <Select
          labelId="rows-per-page-label"
          label="rows per page"
          value={rowPerPage}
          onChange={(event: SelectChangeEvent<number>): void => {
            table?.setPageSize(Number(event.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 100].map((pageSize: number) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
