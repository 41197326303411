import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const ProofOfIncomeIcon = ({
  containerSx,
  iconWidth = '92',
  iconHeight = '50',
}: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="proof-of-income-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 92 50" fill="none">
      <path
        d="M57.6653 13.2227C56.9333 13.2227 52.1752 13.2227 49.8877 13.2227V26.5557H57.6653"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.3308 26.5547C75.0628 26.5547 79.8209 26.5547 82.1084 26.5547L82.1084 13.2217L74.3308 13.2217"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="1"
        y1="38.8867"
        x2="90.9978"
        y2="38.8867"
        stroke="#1E3575"
        strokeWidth="2"
        strokeDasharray="4 4 4 4 4 4"
      />
      <path
        d="M36.5552 13.2188H12.1113"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7776 19.8867H12.1113"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3333 26.5508H12.1113"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.5547 12.1089V9.88672"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.5547 29.8862V27.6641"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.1104 23.7766C62.1104 23.7766 62.4458 27.4811 66.3031 27.6575C70.1605 27.8339 70.6636 24.6586 70.6636 24.1294C70.6636 23.6002 70.6636 20.9542 66.4708 19.8958C62.1104 18.661 62.6135 16.5537 62.6135 15.6622C62.6135 15.3094 62.9489 12.4869 66.3031 12.1341C69.6573 11.7813 70.999 15.3094 70.999 15.8386"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.68742 47.2988L5.57057 45.2452L9.45374 47.2988L12.1948 48.7773L14.9358 47.2988L18.819 45.2452L22.7022 47.2988L25.4432 48.7773L28.1843 47.2988L32.0674 45.2452L35.9506 47.2988L38.6916 48.7773L41.4327 47.2988L45.3158 45.2452L49.199 47.2988L51.94 48.7773L54.6811 47.2988L58.5642 45.2452L62.6758 47.2988L65.4169 48.7773L68.1579 47.2988L72.0411 45.2452L75.9242 47.2988L78.6653 48.7773L81.4063 47.2988L85.2895 45.2452L89.1726 47.2988L91 48.3666L90.9978 1.00073L1 1.00072L1.00215 47.7095L1.68742 47.2988Z"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
);
