import { useEffect } from 'react';
import { styled } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import getFlagsConfig from 'config/getFlagsConfig';
import {
  getPaymentAgreemmentActionDetails,
  paymentAgreementBaseProperties,
  PaymentAgreementEventLabels,
} from 'gtm/gtmEventsPaymentAgreement';
import { googleTagManager } from 'services';

import LoadingSpinner from '../../component-library/loading-spinner/LoadingSpinner';
import { downPaymentStatusValues, eligibilityTypes, routes, urlParams } from '../../constants';
import { useAccountDetails, useGetUrlParam, usePaymentAgreementDetails, useRefactoredEligibility } from '../../hooks';
import { paGenericErrorPath } from '../../urls-and-paths';

import { PaymentAgreementNotice } from './payment-agreement-summary-enrollment/scheduled-payment/PaymentAgreementNotice';
import { ActivePaymentBanner } from './active-payment-banner';
import CashPaymentNotice from './cash-payment-notice';
import {
  PaymentAgreementSummary,
  PaymentAgreementSummaryV2,
  ViewPaymentSection,
} from './payment-agreement-summary-enrollment';
import PaymentAgreementSuccessMessage from './success-messages';

const { enablePAMonthlyRedesign } = getFlagsConfig();

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
}));

const PaymentAgreementContent: React.FC = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const { accountDetails } = useAccountDetails({ errorPagePath: paGenericErrorPath });
  const paymentSuccessful = useGetUrlParam(urlParams.paymentCenterPaymentSuccessfulParam);
  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: true });
  const {
    cashOnly,
    downPaymentAmount,
    downPaymentStatus = '',
    downPaymentPostedDate,
    enrollDetails,
  } = paymentAgreementDetails;
  const { isFetching, isError } = useRefactoredEligibility({
    errorRedirectPath: routes.error.paymentAgreement,
    requiredEligibilityTypes: [eligibilityTypes.PAEnrolled],
  });

  useEffect(() => {
    if (downPaymentStatus) {
      // should fire gtm post successful api response
      googleTagManager.trackViewEvent({
        ...paymentAgreementBaseProperties,
        actionDetail: getPaymentAgreemmentActionDetails(downPaymentStatus),
        userType: cashOnly === 'Y' ? PaymentAgreementEventLabels.cashOnlyUser : PaymentAgreementEventLabels.defaultUser,
        authenticationStatus: isAuthenticated,
      });
    }
  }, [downPaymentStatus]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  const PaymentAgreementSummaryContainer = enablePAMonthlyRedesign
    ? PaymentAgreementSummaryV2
    : PaymentAgreementSummary;

  if (!isError && enrollDetails && accountDetails.accounts.length) {
    return (
      <Container>
        {paymentSuccessful === 'true' && downPaymentStatus === downPaymentStatusValues.Paid ? (
          <ActivePaymentBanner downPaymentAmount={downPaymentAmount} downPaymentPostedDate={downPaymentPostedDate} />
        ) : (
          <PaymentAgreementSuccessMessage />
        )}
        <PaymentAgreementSummaryContainer
          accountInformation={accountDetails}
          downPaymentAmount={downPaymentAmount}
          downPaymentStatus={downPaymentStatus}
          enrollDetails={enrollDetails}
        />
        {cashOnly === 'Y' && <CashPaymentNotice />}
        <ViewPaymentSection />
        <PaymentAgreementNotice />
      </Container>
    );
  }

  return <></>;
};

export default PaymentAgreementContent;
