const messages = {
  paymentAgreementOfferTitle: 'Payment Agreement',
  paymentAgreementOfferDescription: 'Pay off your balance in monthly installments and avoid late fees.',
  paymentAgreementListItemOne: 'Pay a portion of your balance in a down payment.',
  paymentAgreementListItemTwo:
    'Pay the remaining balance in 3 to 6 monthly installments in addition to monthly service charges. ',
  paymentAgreementListItemThree: 'Get back on track and avoid service interruption. ',
  paymentExtensionOfferTitle: 'Payment Extension',
  paymentExtensionOfferDescription: 'Need more time to pay a past due amount?',
  paymentExtensionListItemOne: 'Get up to 10 more calendar days to pay a past due balance.',
  paymentExtensionLisItemtTwo: 'Schedule your payment for the last day of your payment extension.',
  paymentExtensionListItemThree: 'Avoid service interruption.',
  paymentOffersInstruction: 'Begin by selecting a payment plan.',
  viewDetailsButtonLabel: 'View Details',
};

export default messages;
