import React from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import DteErrorIcon from 'component-library/icons/DteErrorIcon';

type Props = {
  errorText: string;
};

const ErrorMessage: React.FC<Props> = (props: Props): JSX.Element => {
  const { errorText } = props;
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    errorMessageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
      paddingBottom: '32px',
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
      border: `2px solid ${theme.palette.dteRed.dark}`,
      width: '100%',
      maxWidth: '469px',
      height: '64px',
      paddingLeft: '21px',
      gap: '12px',
      borderRadius: '5px',
      backgroundColor: theme.palette.dteRed.light,
      color: theme.palette.primary.main,
    },
  };

  return (
    <Box sx={sx.errorMessageWrapper}>
      <Box sx={sx.errorMessage} data-testid="error-message-wrapper">
        <DteErrorIcon />
        <Typography data-testid="error-message-text" variant="body1">
          {errorText}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorMessage;
