import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import LoadingSpinner from '../component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import { eligibilityTypes, PageTitles, routes } from '../constants';
import { useRefactoredEligibility } from '../hooks';
import { googleTagManager } from '../services';

import PaymentOffersContent from './components/PaymentOffersContent';
import PaymentOffersHeader from './components/PaymentOffersHeader';

const PaymentOffersPage: React.FC = (): React.ReactElement => {
  const { eligibilityType, isFetching, isError } = useRefactoredEligibility({
    errorRedirectPath: routes.error.paymentOffers,
    requiredEligibilityTypes: [eligibilityTypes.PaymentOffers],
  });

  useEffect((): void => {
    if (!isError && eligibilityType === eligibilityTypes.PaymentOffers) {
      googleTagManager.trackViewEvent({
        actionDetail: 'select payment plan',
        actionObject: 'select payment plan',
        authenticationStatus: true,
        transactionStepName: 'pa offers',
        transactionType: 'payment agreement',
        userType: 'web',
      });
    }
  }, [isError, eligibilityType]);

  if (!isError && eligibilityType === eligibilityTypes.PaymentOffers) {
    const handleCancelButtonClickGtm = (): void => {
      googleTagManager.trackButtonClick({
        actionDetail: 'select payment plan',
        actionObject: 'cancel',
        authenticationStatus: true,
        transactionStepName: 'pa offers',
        transactionType: 'payment agreement',
        userType: 'web',
      });
    };

    return (
      <>
        <Helmet>
          <title>{PageTitles.PAYMENT_OFFERS}</title>
        </Helmet>
        <PageTemplate
          header={<PaymentOffersHeader displayCancelButton onCancelButtonClick={handleCancelButtonClickGtm} />}
          allViewportPaddingTop="16px"
          tabletDesktopContentWidth="740px"
          dataTestId="payment-offers-page"
        >
          {isFetching ? <LoadingSpinner /> : <PaymentOffersContent />}
        </PageTemplate>
      </>
    );
  }

  return <></>;
};

export default PaymentOffersPage;
