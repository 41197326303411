import { AxiosResponse } from 'axios';

import { axiosGet } from '../adapter/axios-adapter';

export interface JwtResponse {
  callId: string;
  errorCode: number;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
  id_token: string;
}

export const jwtApi = async (gigyaLoginToken: string): Promise<AxiosResponse<JwtResponse>> => {
  const baseUrl = process.env.REACT_APP_GIGYA_GETJWT_BASE_URL ? process.env.REACT_APP_GIGYA_GETJWT_BASE_URL : '';
  const expiration = '2700';
  const apiKey = process.env.REACT_APP_GIGYA_SITE_KEY ? process.env.REACT_APP_GIGYA_SITE_KEY : '';
  const jwtUrl = `/accounts.getJWT?expiration=${expiration}&APIKey=${apiKey}&login_token=${gigyaLoginToken}&format=json`;
  return axiosGet<JwtResponse>(jwtUrl, { baseURL: baseUrl });
};
