import { Tab, TabProps } from '@mui/material';

type DteTabProps = TabProps & {
  label: string;
  id: string;
  ariaControls: string;
};
export const DteTab = ({ label, id, ariaControls, ...otherProps }: DteTabProps): React.ReactElement => (
  <Tab {...otherProps} label={label} id={id} aria-controls={ariaControls} disableRipple />
);
