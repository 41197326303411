import { styled, Theme, Typography } from '@mui/material';

const paragraphStyling = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21.79px',
};

export const ParagraphPrimary = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...paragraphStyling,
  color: theme.palette.primary.main,
}));

export const ParagraphPrimaryBold = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...paragraphStyling,
  color: theme.palette.primary.main,
  fontWeight: '600',
}));

export const ParagraphSecondary = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...paragraphStyling,
  color: theme.palette.secondary.main,
}));

export const ParagraphSecondaryBold = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...paragraphStyling,
  color: theme.palette.secondary.main,
  fontWeight: '600',
}));

export const ParagraphGreen = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...paragraphStyling,
  color: theme.palette.dteGreen.main,
}));
