import { styled, Theme, Typography } from '@mui/material';

import { BulletedList } from 'component-library/lists/BulletedList';
import { Bold } from 'component-library/typography/Bold';
import { Light } from 'component-library/typography/Light';

const ExpandedPanelRoot = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  lineHeight: '22px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('tablet')]: {
    padding: '0px 4px 24px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 24px 24px 20px',
  },
}));

const ProofOfHouseholdMembersPanelRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const ProofOfHouseholdMembersNote = styled('div')(({ theme }: { theme: Theme }) => ({
  padding: '20px',
  backgroundColor: theme.palette.dteGrey.light,
}));

type ExpandedPanelProps = {
  content: JSX.Element;
};

export const PhotoIdentificationPanelContent = (): React.ReactElement => (
  <div>
    <Typography>
      {`Household members' identification must match the DTE account address. `}
      <Bold>A valid, color copy must be submitted.</Bold>
    </Typography>
    <br />
    <BulletedList>
      <li>Michigan Driver License</li>
      <li>Michigan State ID</li>
      <li>City-Issued ID</li>
      <li>Passport (Applies to account holder only)</li>
    </BulletedList>
  </div>
);

export const ProofOfHouseholdMembersPanelContent = (): React.ReactElement => (
  <ProofOfHouseholdMembersPanelRoot>
    <div>
      <Typography>
        <Bold>Adults</Bold>
        {` (18 years or older):`}
      </Typography>
      <BulletedList>
        <li>Social Security card (one per adult)</li>
      </BulletedList>
    </div>
    <div>
      <Typography>
        <Bold>Dependents</Bold>
        {` (17 years or younger):`}
      </Typography>
      <BulletedList>
        <li>A birth certificate for each person (one per person).</li>
      </BulletedList>
    </div>
    <ProofOfHouseholdMembersNote data-testid="proof-of-household-members-note">
      <Bold>Please Note:</Bold>
      {` If an adult household member does not have a Social Security card or
        a dependent does not have a birth certificate, please provide the DTE
        Account Holder’s most recent tax form 1040 (Pages 1 and 2) with all
        household members listed.`}
    </ProofOfHouseholdMembersNote>
  </ProofOfHouseholdMembersPanelRoot>
);

export const ProofOfIncomePanelContent = (): React.ReactElement => (
  <BulletedList>
    <li>Two most recent pay stubs dated within the past 60 days.</li>
    <li>Social Security and/or Supplemental Security Income (SSI) statement for the current year.</li>
    <li>Unemployment compensation benefit statement.</li>
    <li>Pension statement for the current year.</li>
    <li>
      Michigan Department of Health & Human Services Benefit Letter that includes cash benefits.
      <Light>{` All pages required for Cash Assistance, Food Assistance and/or Medicaid Award Letters.`}</Light>
    </li>
    <li>
      The first two pages of your most recently-filed Federal Tax Form W-2 (1040) with dependents and income listed.
      <Light>{` Only valid for submission until June 1 of the year it was filed.`}</Light>
    </li>
    <li>
      State of Michigan Energy Draft or Letter for the current year from any energy provider.
      <Light>{` Applies to Account Holder only.`}</Light>
    </li>
    <li>
      <Bold>If self-employed:</Bold>
      {` accounting and business records showing income.`}
      <Light>{` 1099 Tax Statement is acceptable.`}</Light>
    </li>
    <li>
      <Bold>For household members with unreported wages or who just started a job:</Bold>
      {` please provide a letter from the employer on company letterhead dated within the last 30 days. The letter must include the hourly rate or monthly gross amount and the number of hours worked per week, including the employer's signature.`}
    </li>
    <li>Veteran Award Benefit Letter.</li>
  </BulletedList>
);

export const DocumentsNotAcceptedPanelContent = (): React.ReactElement => (
  <BulletedList>
    <li>Bank statement</li>
    <li>Bridge card</li>
    <li>Concealed Pistol License (CPL)</li>
    <li>Explanation of Benefit Letter</li>
    <li>Housing & Urban Development (HUD) documents</li>
    <li>Lease documents</li>
    <li>Medicaid and/or Medicare card</li>
    <li>Pandemic Electronic Benefit Transfer (P-EBT) for students</li>
    <li>Passport (acceptable for account holder only)</li>
    <li>Woman, Infant, Child (WIC) documents</li>
    <li>W-2 Form or 1040 Form for prior tax years</li>
    <li>Freedom to Work Benefit Letter</li>
    <li>Any documents that appear to be altered</li>
    <li>Black and white copies of photo identification documents (only color copies are accepted)</li>
  </BulletedList>
);

export const ExpandedPanel = ({ content }: ExpandedPanelProps): React.ReactElement => (
  <ExpandedPanelRoot data-testid="expanded-panel-root">{content}</ExpandedPanelRoot>
);
