import { FocusEventHandler } from 'react';
import { Box, Button, styled, Theme, Typography } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../component-library/currency/ReadOnlyCurrencyMask';
import CheckMarkTopRightIcon from '../../../component-library/icons/CheckMarkTopRightIcon';

import getMonthsText from './getMonthsText';
import messages from './messages';
import PlanSelectionBodyText from './PlanSelectionBodyText';

const AmountPerMonthContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

const ButtonCheckMarkContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
  border: `2px solid ${theme.palette.dteBlue.dark}`,
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'flex-end',
  position: 'absolute',
  tabIndex: '0',
  width: '100%',
}));

const ButtonContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  height: '100%',
  position: 'absolute',
  tabIndex: '0',
  width: '100%',
  zIndex: 999,
  '&:hover': {
    border: `2px solid ${theme.palette.dteBlue.main}`,
  },
}));

const ButtonContainerFocused = styled(Box)(({ theme }: { theme: Theme }) => ({
  border: `2px solid ${theme.palette.dteBlue.main}`,
  height: '100%',
  position: 'absolute',
  tabIndex: '0',
  width: '100%',
  zIndex: 999,
}));

const ButtonWrapper = styled(Button)(({ theme }: { theme: Theme }) => ({
  border: '0px',
  borderRadius: '0px',
  display: 'flex',
  flexDirection: 'row',
  padding: '0',
  position: 'relative',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  [theme.breakpoints.down('mobile')]: {
    height: '120px',
    width: '304px',
  },
  [theme.breakpoints.between('mobile', 'tablet')]: {
    height: '120px',
    width: '100%',
  },
  [theme.breakpoints.between('tablet', 'desktop')]: {
    height: '163px',
    width: '25%',
  },
  [theme.breakpoints.up('desktop')]: {
    height: '162px',
    width: '25%',
  },
}));

const CurrencyText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontWeight: '600',
  variant: 'body1',
  [theme.breakpoints.down('desktop')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('desktop')]: {
    fontSize: '24px',
  },
}));

const MainContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
}));

interface IPlanButtonProps {
  isFocus: boolean;
  isSelected?: boolean;
  onBlurHandler?: FocusEventHandler;
  onFocusVisible?: React.FocusEventHandler;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  paymentOption: IPaymentOption;
}

const PlanButton: React.FC<IPlanButtonProps> = (props: IPlanButtonProps): React.ReactElement => {
  const { isFocus, isSelected, onBlurHandler, onClick, onFocusVisible, paymentOption } = props;
  const { installmentAmount, installmentDuration } = paymentOption;
  const buttonTestId = `button-${installmentDuration}-months-option`;
  const currencyTestId = `monthly-amount-${installmentDuration}-months`;

  return (
    <ButtonWrapper
      data-testid={buttonTestId}
      disableFocusRipple
      disableRipple
      tabIndex={0}
      onFocusVisible={onFocusVisible}
      id={buttonTestId}
      onClick={onClick}
      value={installmentAmount}
      onBlur={onBlurHandler}
    >
      {!isFocus && <ButtonContainer />}
      {isSelected && (
        <ButtonCheckMarkContainer>
          <CheckMarkTopRightIcon />
        </ButtonCheckMarkContainer>
      )}
      {isFocus && !isSelected && <ButtonContainerFocused />}

      <MainContent>
        <AmountPerMonthContainer>
          <CurrencyText data-testid={currencyTestId}>
            {ReadOnlyCurrencyMask(parseFloat(installmentAmount))}
          </CurrencyText>
          <PlanSelectionBodyText fontSize={14}>{messages.perMonth}</PlanSelectionBodyText>
        </AmountPerMonthContainer>
        <PlanSelectionBodyText>{getMonthsText(installmentDuration)}</PlanSelectionBodyText>
      </MainContent>
    </ButtonWrapper>
  );
};

export default PlanButton;
