export default {
  agencyPaymentAssistanceInfoBoldText: ' at ',
  agencyPaymentAssistanceInfoCopy1: 'Great News!',
  agencyPaymentAssistanceInfoCopy2: 'has made an energy assistance payment on your account.',
  agencyPaymentAssistanceInfoContactText: 'If you have questions, please contact:',
  agencyPaymentAssistanceInfoDTEText:
    'If you have questions, please contact your agency. DTE Representatives do not have additional information about agency payments.',

  agencyPaymentInfoTitle: 'Agency Payment Received',
  agencyPaymentInfoAssistanceText: 'Assistance Amount: ',
  agencyPaymentInfoViewAccountHistoryText: 'View My Account History',

  clockTitle: 'Agency Payment Assistance | DTE Energy',

  defaultAssistancePaymentText:
    'has committed to making an energy assistance payment on your account. Once this payment has been applied, you will receive a notification.',
  defaultAssistanceRepresentativeText: 'DTE Representatives do not have additional information about agency payments.',
  defaultAssistanceTitle: 'Assistance Information',
};
