import { BillComparisonResponse } from 'api/bill-comparison-api/BillComparisonResponse';

export const mockBillComparisonResponse: BillComparisonResponse = {
  billComparison: {
    accountNumber: '',
    currentCycle: {
      averageDailyTemperatures: {
        average: 0,
        averageTemperatures: [
          {
            date: new Date(2023, 2, 24),
            temp: 0,
          },
        ],
      },
      billAmount: 0,
      dayCount: 0,
      endDate: new Date(2023, 2, 24),
      products: [
        {
          type: '',
          unit: '',
          usage: 0,
        },
      ],
      startDate: new Date(2023, 2, 24),
    },
    siteId: '',
  },
};
