import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { OverridableStringUnion } from '@mui/types';
import { Dayjs } from 'dayjs';

type Props = {
  date: Dayjs;
  fontVariant: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
};

export const PaymentExtensionDate = ({ date, fontVariant }: Props): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
  };
  const apFormattedPeDate = date.format('dddd, MMM D, YYYY');

  return (
    <Box data-testid="pe-date-component" sx={sx.rootWrapper}>
      <Typography data-testid="pe-date-sub-title" variant="body1">
        Payment Extension Date
      </Typography>
      <Typography data-testid="pe-date" fontWeight={600} variant={fontVariant}>
        {apFormattedPeDate}
      </Typography>
    </Box>
  );
};
