import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

interface PaymentOptionsInterface {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
}

type Props = {
  children: ReactNode;
};

export const PaymentOptionsContext = createContext<PaymentOptionsInterface>({} as PaymentOptionsInterface);

export const PaymentOptionsProvider: FC<Props> = (props: Props): JSX.Element => {
  const { children } = props;
  const [inputValue, setInputValue] = useState('');

  return (
    <PaymentOptionsContext.Provider value={{ inputValue, setInputValue }}>{children}</PaymentOptionsContext.Provider>
  );
};
