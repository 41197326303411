import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { useGoogleTagManager } from './gtm/useGoogleTagManager';
import { theme } from './theme/theme';
import { appRoutes } from './AppRoutes';

import './App.css';

// Dynatrace Script
if (process.env.REACT_APP_DYNATRACE_URL) {
  const dynatraceScriptElement = document.createElement('script');
  dynatraceScriptElement.type = 'text/javascript';
  dynatraceScriptElement.title = 'dynatrace-script';
  dynatraceScriptElement.src = process.env.REACT_APP_DYNATRACE_URL;
  dynatraceScriptElement.crossOrigin = 'anonymous';
  document.head.appendChild(dynatraceScriptElement);
}

function App(): JSX.Element {
  useGoogleTagManager();

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>{appRoutes}</Routes>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
