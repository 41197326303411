import { downPaymentStatusValues } from 'constants/';

import { PaidDownPayment, RequiedDownPayment, ScheduledDownPayment } from './down-payment';

interface IDownPaymentSummaryProps {
  downPaymentAmount: string;
  downPaymentStatus: string;
}

const DownPaymentSummary: React.FC<IDownPaymentSummaryProps> = (props: IDownPaymentSummaryProps) => {
  const { downPaymentAmount, downPaymentStatus } = props;

  switch (downPaymentStatus) {
    case downPaymentStatusValues.Paid:
      return <PaidDownPayment downPaymentAmount={downPaymentAmount} />;

    case downPaymentStatusValues.DownPaymentAtRisk:
    case downPaymentStatusValues.Required:
      return <RequiedDownPayment />;

    case downPaymentStatusValues.DownPaymentScheduleChanged:
    case downPaymentStatusValues.Scheduled:
      return <ScheduledDownPayment />;
    default:
      return <></>;
  }
};

export default DownPaymentSummary;
