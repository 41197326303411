import { styled, Theme, Typography } from '@mui/material';

import AccountInformation from './account-information/AccountInformation';
import EnrollmentDetails from './EnrollmentDetailsV2';
import InstallmentAmountDescription from './InstallmentAmountDescription';
import messages from './messages';

const AccountDetailsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  borderTop: `1px solid ${theme.palette.dteGrey.main}`,
  gridArea: 'AccountDetailsContainer',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

interface IContentWrapperProps {
  numberOfInstallmentsOpted?: number;
}

interface IContentWrapperPropsWithTheme {
  numberOfInstallmentsOpted?: number;
  theme: Theme;
}

const ContentWrapper = styled('div')<IContentWrapperProps>(
  ({ theme, numberOfInstallmentsOpted = 0 }: IContentWrapperPropsWithTheme) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.up('tablet')]: {
      gridTemplateAreas:
        numberOfInstallmentsOpted < 25
          ? `
      "AccountDetailsContainer AccountDetailsContainer"
      "EnrollDetailsContainer EnrollDetailsContainer"
    `
          : `
      "AccountDetailsContainer EnrollDetailsContainer"
    `,
    },
    [theme.breakpoints.down('tablet')]: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
      "AccountDetailsContainer"
      "EnrollDetailsContainer"
    `,
    },
  }),
);

const ContentWrapperNoMonthlyCircle = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.up('mobile')]: {
    gridTemplateAreas: `
      "AccountDetailsContainer EnrollDetailsContainer"
    `,
  },
  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      "AccountDetailsContainer"
      "EnrollDetailsContainer"
    `,
  },
}));

const EnrollDetailsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  borderTop: `1px solid ${theme.palette.dteGrey.main}`,
  display: 'flex',
  gridArea: 'EnrollDetailsContainer',
  height: '100%',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
  },
}));

const HeadingContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  alignContent: 'center',
  backgroundColor: theme.palette.dteBlue.dark,
  color: theme.palette.common.white,
  display: 'flex',
  height: '52px',
  justifyContent: 'center',
}));

const PASummaryHeading = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '27.24px',
  textAlign: 'center',
  variant: 'h4',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '18px',
    lineHeight: '24.51px',
  },
}));

const SummaryContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  width: '100%',
}));

interface IPaymentAgreementSummaryProps {
  accountInformation: IAccountDetailsResponse;
  downPaymentAmount: string;
  downPaymentStatus: string;
  enrollDetails: IEnrollDetails;
}

interface IMainContentWrapperProps {
  children: React.ReactNode;
  numberOfInstallmentsOpted: number;
}

const MainContentWrapper: React.FC<IMainContentWrapperProps> = (props: IMainContentWrapperProps): React.ReactElement =>
  props.numberOfInstallmentsOpted < 25 ? (
    <>
      <ContentWrapper numberOfInstallmentsOpted={props.numberOfInstallmentsOpted}>{props.children}</ContentWrapper>
    </>
  ) : (
    <>
      <ContentWrapperNoMonthlyCircle>{props.children}</ContentWrapperNoMonthlyCircle>
    </>
  );

export const PaymentAgreementSummaryV2: React.FC<IPaymentAgreementSummaryProps> = (
  props: IPaymentAgreementSummaryProps,
): React.ReactElement => {
  const { accountInformation, downPaymentAmount, downPaymentStatus, enrollDetails } = props;
  const { installmentAmount, numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = enrollDetails;
  const { accounts, customerName } = accountInformation;
  const { accountNumber, serviceAddresses } = accounts[0];

  return (
    <Container data-testid="payment-agreement-summary">
      <SummaryContainer>
        <HeadingContainer>
          <PASummaryHeading>{messages.heading}</PASummaryHeading>
        </HeadingContainer>
        <MainContentWrapper numberOfInstallmentsOpted={numberOfInstallmentsOpted}>
          <AccountDetailsContainer>
            <AccountInformation accountNumber={accountNumber} address={serviceAddresses[0]} name={customerName} />
          </AccountDetailsContainer>
          <EnrollDetailsContainer data-testid="enrollment-details">
            <EnrollmentDetails
              downPaymentAmount={downPaymentAmount}
              downPaymentStatus={downPaymentStatus}
              installmentAmount={installmentAmount}
              numberOfInstallmentsOpted={numberOfInstallmentsOpted}
              numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
            />
          </EnrollDetailsContainer>
        </MainContentWrapper>
      </SummaryContainer>
      <InstallmentAmountDescription />
    </Container>
  );
};
