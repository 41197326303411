import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../component-library/currency/ReadOnlyCurrencyMask';
import ResponsiveTooltip from '../../../component-library/tooltip/ResponsiveTooltip';

import messages from './messages';

interface Props {
  totalPABalance?: string;
}

const PaymentEligibleBalanceSectionContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  alignContent: 'center',
  borderLeft: `1px solid ${theme.palette.dteGrey.main}`,
  borderBottom: `1px solid ${theme.palette.dteGrey.main}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  gridArea: 'paymentEligibleBalanceSection',
  padding: '24px 16px',
  textAlign: 'center',
  [theme.breakpoints.between('tablet', 'desktop')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '304px',
  },
  [theme.breakpoints.down('tablet')]: {
    borderRight: `1px solid ${theme.palette.dteGrey.main}`,
  },
  [theme.breakpoints.up('desktop')]: {
    borderLeft: '0',
    borderTop: `1px solid ${theme.palette.dteGrey.main}`,
    borderRight: `1px solid ${theme.palette.dteGrey.main}`,
  },
}));

const PaymentEligibleBalanceSection = ({ totalPABalance = '0' }: Props): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    body: {
      color: theme.palette.secondary.main,
    },
    labelEndDescription: {
      display: 'inline-flex',
      alignItems: 'center',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      height: '24.5px',
      [theme.breakpoints.down('tablet')]: {
        whiteSpace: 'wrap',
      },
    }, // wraps icon to text
    paBalanceText: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('tablet')]: {
        width: '201px',
        height: '40px',
      },
    },
    tooltipContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '4px',
    },
    title: {
      color: theme.palette.primary.main,
    },
    toolTipWrapper: {
      // overwrite tooltip css
      ' > div > button ': {
        margin: '3px 0px 3px 5px',
        padding: '0px',
      },
    },
  };

  const EligibleBalanceTooltip = (): React.ReactElement => (
    <Box sx={sx.tooltipContainer}>
      <Box component="div" sx={sx.labelEndDescription}>
        <Typography
          data-testid="pae-account-info-eligible-popover"
          fontSize="16px"
          fontWeight="400"
          sx={sx.paBalanceText}
          variant="body1"
        >
          {messages.eligiblePaymentAgreementBalanceText}
        </Typography>
        <Box component="span" sx={sx.toolTipWrapper}>
          <ResponsiveTooltip popoverText={messages.eligiblePaymentAgreementBalancePopoverText} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <PaymentEligibleBalanceSectionContainer>
      <EligibleBalanceTooltip />
      <Typography
        data-testid="pae-account-info-total-pa-balance"
        fontSize="16px"
        fontWeight="600"
        paddingTop="4px"
        sx={sx.body}
        variant="body1"
      >
        {ReadOnlyCurrencyMask(parseFloat(totalPABalance))}
      </Typography>
    </PaymentEligibleBalanceSectionContainer>
  );
};

export default PaymentEligibleBalanceSection;
