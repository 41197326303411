import React from 'react';
import { Box } from '@mui/material';

import { CheckMarkListItem } from './CheckMarkListItem';

interface CheckMarkListProps {
  id?: string;
  items: string[];
}

export const CheckMarkList: React.FC<CheckMarkListProps> = (props: CheckMarkListProps) => {
  const { items, id } = props;
  const listName = id || 'checkmark-list';

  return (
    <Box
      data-testid={listName}
      gap="8px"
      id={listName}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
    >
      {items.map((item: string) => (
        <CheckMarkListItem key={item} text={item} />
      ))}
    </Box>
  );
};
