import { styled, Theme, Typography } from '@mui/material';

import { Address } from './Address';
export interface IUserInformationProps {
  address: IAddress;
  name: string;
}

const Container = styled('div')({
  marginBottom: '12px',
});

const Name = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: '600',
  height: '25px',
  lineHeight: '24.51px',
}));

export const UserInformation: React.FC<IUserInformationProps> = (props: IUserInformationProps): React.ReactElement => {
  const { address, name } = props;

  return (
    <Container data-testid="user-information">
      <Name data-testid="name">{name}</Name>
      <Address address={address} />
    </Container>
  );
};
