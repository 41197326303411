import React from 'react';

import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { logger } from 'services';

import { ITestUser } from './types/ITestUser';
import fetchEligibility from './fetchEligibility';

interface ICheckEligibilityButtonProps {
  bpIdFromTextBox: string;
  bpIdList: string[];
  handleFileUpload: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTableData: React.Dispatch<React.SetStateAction<ITestUser[]>>;
}

export const CheckEligibilityButton: React.FC<ICheckEligibilityButtonProps> = (
  props: ICheckEligibilityButtonProps,
): React.ReactElement => {
  const { bpIdFromTextBox, bpIdList, handleFileUpload, setIsLoading, setTableData } = props;

  const allBpIdsToCheck = [...bpIdList];
  if (bpIdFromTextBox && bpIdFromTextBox.length > 0) {
    allBpIdsToCheck.push(bpIdFromTextBox);
  }

  const checkUserEligibility = async (): Promise<void> => {
    logger.info('Checking eligibility for users');
    setTableData([]);

    logger.info('Loading eligibility data for all users', bpIdList);
    setIsLoading(true);

    const tableData = await Promise.all(
      allBpIdsToCheck.map(async (bpId: string) => {
        const testUserData = await fetchEligibility(bpId);

        return testUserData;
      }),
    );

    setTableData(tableData || []);
    setIsLoading(false);
    handleFileUpload();
  };

  return <DtePrimaryButton name="Check Eligibility" onClick={async (): Promise<void> => checkUserEligibility()} />;
};
