import { styled, Theme, Typography } from '@mui/material';

import { DteButton } from '../../../component-library/buttons/DteButton';

const BottomContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  paddingBottom: '32px',
  gap: '32px',
});

const Instructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontWeight: '600',
  width: '100%',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('tablet')]: {
    textAlign: 'center',
    maxWidth: '600px',
  },
}));

const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  width: '100%',
});

type DefaultBottomContentProps = {
  viewMyAccountHistoryClickHandler: () => void;
  viewCurrentBillClickHandler: () => void;
};

export const DefaultBottomContent = ({
  viewMyAccountHistoryClickHandler,
  viewCurrentBillClickHandler,
}: DefaultBottomContentProps): React.ReactElement => (
  <BottomContent data-testid="default-bottom-content">
    <Instructions>
      Please note: You can check on agency-related payments in your My Account History. They will show as new payments
      made on your account.
    </Instructions>
    <Buttons>
      <DteButton variant="primary" text="View My Account History" onClick={viewMyAccountHistoryClickHandler} />
      <DteButton variant="secondary" text="View Current Bill" onClick={viewCurrentBillClickHandler} />
    </Buttons>
  </BottomContent>
);
