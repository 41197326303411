import { Box, styled, Theme, Typography } from '@mui/material';

import messages from '../messages';

export interface IAccountNumberProps {
  accountNumber: string;
}

const Number = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'Open Sans',
  fontWeight: '400',
  height: '22px',
  lineHeight: '21.79px',
  size: '16px',
}));

const Tittle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Open Sans',
  fontWeight: '600',
  height: '22px',
  lineHeight: '21.79px',
  size: '16px',
}));

export const AccountNumber: React.FC<IAccountNumberProps> = (props: IAccountNumberProps): React.ReactElement => {
  const { accountNumber } = props;

  return (
    <Box data-testid="account-number">
      <Tittle>{messages.accountTitle}</Tittle>
      <Number>{accountNumber}</Number>
    </Box>
  );
};
