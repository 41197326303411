import { IconProps } from '../icon-props';
import { IconContainer } from '../IconContainer';

const PayLaterFiveDaysIcon = (iconProps: IconProps): React.ReactElement => {
  const { containerSx, iconHeight = '80', iconWidth = '81' } = iconProps;

  return (
    <IconContainer sx={containerSx} data-testid="pay-later-five-days-icon">
      <svg fill="none" height={iconHeight} width={iconWidth} viewBox="0 0 81 80" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#8DC8E8" fillOpacity="0.2" height="80" rx="40" x="0.5" y="-0.000488281" width="80" />
        <path
          clipRule="evenodd"
          fillRule="evenodd"
          fill="#1E3575"
          d="M47.8757 16.3345C48.428 16.3345 48.8757 16.7822 48.8757 17.3345V19.9352H59.246C59.7983 19.9352 60.246 20.3829 60.246 20.9352V29.9605L60.2462 29.979V44.8966C59.6089 44.6108 58.9399 44.3843 58.2462 44.2242V30.9795H18.8346V58.373H45.2066C45.4122 59.0706 45.6829 59.7397 46.0115 60.373H17.8346C17.2824 60.373 16.8346 59.9253 16.8346 59.373V29.998L16.8345 29.9795V20.9352C16.8345 20.3829 17.2822 19.9352 17.8345 19.9352H28.3782V17.6496C28.3782 17.0973 28.8259 16.6496 29.3782 16.6496C29.9305 16.6496 30.3782 17.0973 30.3782 17.6496V19.9352H46.8757V17.3345C46.8757 16.7822 47.3234 16.3345 47.8757 16.3345ZM46.8757 23.9049V21.9352H30.3782V24.22C30.3782 24.7723 29.9305 25.22 29.3782 25.22C28.8259 25.22 28.3782 24.7723 28.3782 24.22V21.9352H18.8345V28.979H58.246V21.9352H48.8757V23.9049C48.8757 24.4572 48.428 24.9049 47.8757 24.9049C47.3234 24.9049 46.8757 24.4572 46.8757 23.9049ZM40.8889 42.5943C40.2618 42.2842 39.5351 42.1292 38.7087 42.1292C38.275 42.1292 37.9116 42.1535 37.6186 42.2022C37.3256 42.2447 37.0736 42.2903 36.8626 42.3389L37.1439 39.0195H42.0054V37.1318H35.3241L34.8406 43.6521L35.7461 44.2084C36.0098 44.1354 36.338 44.0686 36.7307 44.0078C37.1292 43.9409 37.5043 43.9075 37.856 43.9075C38.8582 43.9075 39.5937 44.1172 40.0625 44.5367C40.5373 44.9501 40.7746 45.5398 40.7746 46.3058C40.7746 46.8834 40.6574 47.3606 40.423 47.7376C40.1944 48.1145 39.8662 48.3941 39.4384 48.5765C39.0105 48.7589 38.4977 48.8501 37.8999 48.8501C37.3373 48.8501 36.7483 48.7711 36.1329 48.613C35.5175 48.4489 34.9901 48.2391 34.5505 47.9838V49.9171C34.9725 50.1603 35.4736 50.3426 36.0538 50.4642C36.6399 50.5858 37.2699 50.6466 37.9439 50.6466C39.0047 50.6466 39.9043 50.4642 40.6428 50.0995C41.3812 49.7347 41.9409 49.2149 42.3219 48.5401C42.7028 47.8592 42.8933 47.0506 42.8933 46.1143C42.8933 45.2571 42.7175 44.5337 42.3658 43.9439C42.0142 43.3481 41.5219 42.8983 40.8889 42.5943Z"
        />
        <path
          d="M55.7937 63.9994C50.9917 63.9994 47.0874 60.0319 47.0874 55.1522C47.0874 53.1404 47.7841 51.1476 49.0307 49.5834C49.1953 49.3782 49.5075 49.3411 49.7087 49.5092C49.9106 49.6765 49.9471 49.9938 49.7817 50.1982C48.6641 51.6133 48.0412 53.3638 48.0412 55.1523C48.0412 59.4923 51.5237 63.0122 55.7759 63.0122C60.028 63.0122 63.5105 59.4733 63.5105 55.1523C63.5105 50.8313 60.0645 47.2924 55.7936 47.2924C55.5187 47.2924 55.3168 47.069 55.3168 46.8078C55.3168 46.5473 55.5366 46.3232 55.7936 46.3232C60.5957 46.3232 64.4999 50.2907 64.4999 55.1705C64.4999 60.051 60.5965 63.9994 55.7937 63.9994ZM51.23 50.9749C51.4498 50.9749 51.6331 50.7886 51.6331 50.5652L51.6331 47.8698C51.6331 47.6464 51.4498 47.4602 51.23 47.4602H48.3579C48.1381 47.4602 47.9548 47.6464 47.9548 47.8698C47.9548 48.0931 48.1381 48.2794 48.3579 48.2794H50.8269V50.5653C50.8269 50.8068 51.0102 50.9749 51.23 50.9749Z"
          fill="#1E3575"
          stroke="#1E3575"
          strokeWidth="0.7"
        />
      </svg>
    </IconContainer>
  );
};

export default PayLaterFiveDaysIcon;
