import { unitedWayPhoneNumber } from 'collections-copy';

import { googleTagManager } from 'services';

import {
  wLockCompleteFormOnClickEvent,
  wLockEnergyAssistanceProgramsLinkOnClickEvent,
  wLockMedicalTabOnClickEvent,
  wLockMilitaryTabOnClickEvent,
  wLockUnitedWayLinkOnClickEvent,
  wLockUploadFormOnClickEvent,
  wLockViewCurrentBillLinkOnClickEvent,
  wLockViewEvent,
  wLockViewMedicalLinkOnClickEvent,
  wLockViewMilitaryLinkOnClickEvent,
} from './gtmEventsWLock';

const scrollToTop = (): void => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'auto',
  });
};

export const completeFormOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockCompleteFormOnClickEvent,
    authenticationStatus: true,
  });
};

export const energyAssistanceProgramsLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockEnergyAssistanceProgramsLinkOnClickEvent,
    authenticationStatus: true,
  });
};

export const medicalTabOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockMedicalTabOnClickEvent,
    authenticationStatus: true,
  });
};

export const militaryTabOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockMilitaryTabOnClickEvent,
    authenticationStatus: true,
  });
};

export const onPageViewHandler = (): void => {
  googleTagManager.trackViewEvent({
    ...wLockViewEvent,
    authenticationStatus: true,
  });
};

export const uploadFormOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockUploadFormOnClickEvent,
    authenticationStatus: true,
  });
};

export const unitedWayLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockUnitedWayLinkOnClickEvent,
    actionObject: unitedWayPhoneNumber,
    authenticationStatus: true,
  });
};

export const viewCurrentBillOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockViewCurrentBillLinkOnClickEvent,
    authenticationStatus: true,
  });
};

export const viewMedicalLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockViewMedicalLinkOnClickEvent,
    authenticationStatus: true,
  });
  scrollToTop();
};

export const viewMilitaryLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...wLockViewMilitaryLinkOnClickEvent,
    authenticationStatus: true,
  });
  scrollToTop();
};
