import * as React from 'react';
import { styled, Theme, Typography } from '@mui/material';

import { DteButton } from 'component-library/buttons/DteButton';
import { GatherDocumentsIcon } from 'component-library/icons/GatherDocumentsIcon';
import { UploadDocumentIcon } from 'component-library/icons/UploadDocumentIcon';
import { WeWillContactYouIcon } from 'component-library/icons/WeWillContactYouIcon';
import { Step } from 'component-library/step/Step';
import { Bold } from 'component-library/typography/Bold';
import messages from '../messages';

import { documentSubmissionUrl } from '../../../urls-and-paths';

type RequiredDocumentsProps = {
  lockEndDate?: string;
  submitDocumentsOnClickHandler?: () => void;
};
export const RequiredDocuments = ({
  lockEndDate,
  submitDocumentsOnClickHandler,
}: RequiredDocumentsProps): React.ReactElement => {
  const RequiredDocumentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px',
  });

  const Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '38px',
  }));

  const Instructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '18px',
    [theme.breakpoints.between('tablet', 'desktop')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'center',
    },
  }));

  const Steps = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
    },
  }));

  return (
    <RequiredDocumentsContainer data-testid="required-documents-container">
      <Heading>{messages.heading}</Heading>
      <Instructions>
        You are required to submit copies of identity and income-related documents <Bold>before {lockEndDate}</Bold> to
        determine if you qualify for income-eligible energy assistance. Please follow these steps.
      </Instructions>
      <Steps>
        <Step
          title="Step 1"
          subTitle="Gather Documents"
          icon={<GatherDocumentsIcon />}
          instructions="Collect clear and complete images of required documents for all household members. Please follow the Guidelines below."
        />
        <Step
          title="Step 2"
          subTitle="Upload Documents"
          icon={<UploadDocumentIcon />}
          instructions="Upload the document copies in our secure Document Submission Portal and then submit them. Please ensure the required documents are valid."
        />
        <Step
          title="Step 3"
          subTitle="We Will Contact You"
          icon={<WeWillContactYouIcon />}
          instructions="Once your information is submitted, an Energy Advocacy Group team member will contact you within two business days at the phone number and email address you provided."
        />
      </Steps>
      <DteButton
        variant="primary"
        text="Submit Documents"
        href={documentSubmissionUrl}
        onClick={submitDocumentsOnClickHandler}
      />
    </RequiredDocumentsContainer>
  );
};
