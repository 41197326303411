import { styled } from '@mui/material';

import GenericErrorPageAction from './GenericErrorPageAction';
import GenericErrorPageDescription from './GenericErrorPageDescription';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
}));

const GenericErrorPageContent: React.FC<GenericErrorPageContentProps> = (
  props: GenericErrorPageContentProps,
): React.ReactElement => {
  const { onContactUsButtonClick, onViewCurrentBillButtonClick } = props;

  return (
    <Container>
      <GenericErrorPageDescription />
      <GenericErrorPageAction
        onContactUsButtonClick={onContactUsButtonClick}
        onViewCurrentBillButtonClick={onViewCurrentBillButtonClick}
      />
    </Container>
  );
};

export default GenericErrorPageContent;
