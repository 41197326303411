import { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import { read, utils } from 'xlsx';

import { HeaderDesktop, HeaderMobile } from 'component-library/header';
import { admin } from 'component-library/header/header';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import { ITestUser } from './types/ITestUser';
import { AdminPagePresentation } from './AdminPage.presentation';

export const AdminPage = (): React.ReactElement => {
  const [bpId, setBpId] = useState<string>('');
  const [file, setFile] = useState<File | undefined>();
  const [testUsers, setBpIdList] = useState<string[]>([]);

  const desktopHeader = <HeaderDesktop titleText={admin} isHeaderButtonRendered={false} />;
  const mobileHeader = <HeaderMobile titleText={admin} isHeaderButtonRendered={false} />;

  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  const fileUploadChangeHandler = (uploadedFile?: File): void => {
    setBpIdList([]); // reset the test users
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const setBpIdHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    setBpId(event.target.value);

  useEffect(() => {
    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        const data = event.target!.result;
        const workBook = read(data, { type: 'binary' });
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        const rows = utils.sheet_to_json<ITestUser>(workSheet);

        setBpIdList(rows.map((row: ITestUser) => row.BP));
      };
      fileReader.readAsBinaryString(file!);
    }
  }, [file, setBpIdList]);

  return (
    <Box>
      <Helmet>
        <title>Admin | DTE Energy</title>
      </Helmet>
      {pageHeader}
      <AdminPagePresentation
        bpId={bpId}
        bpIdList={testUsers}
        fileUploadChangeHandler={fileUploadChangeHandler}
        setBpIdHandler={setBpIdHandler}
      />
    </Box>
  );
};
