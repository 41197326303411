import { styled, Theme } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../../component-library/currency/ReadOnlyCurrencyMask';
import { H4HeadingBold, H5Heading, ParagraphSecondary } from '../../../../component-library/typography';

import messages from './messages';

interface IInstallmentSummaryProps {
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
}

const InstallmentSummaryContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '0 24px',
  textAlign: 'center',
  [theme.breakpoints.down('tablet')]: {
    padding: '0',
  },
}));

const InstallmentSummary: React.FC<IInstallmentSummaryProps> = (props: IInstallmentSummaryProps) => {
  const { installmentAmount, numberOfInstallmentsOpted } = props;

  return (
    <InstallmentSummaryContainer data-testid="installment-summary">
      {numberOfInstallmentsOpted < 25 ? (
        <>
          <H5Heading>
            {numberOfInstallmentsOpted.toString()}
            {` `}
            {messages.installmentSummary}
          </H5Heading>
        </>
      ) : (
        <>
          <H5Heading>{messages.installmentSummaryMoreThan25Payments}</H5Heading>
        </>
      )}
      <span>
        <H4HeadingBold display="inline">{ReadOnlyCurrencyMask(parseFloat(installmentAmount))}</H4HeadingBold>
        {` `}
        <ParagraphSecondary display="inline">{messages.perMonth}</ParagraphSecondary>
      </span>
    </InstallmentSummaryContainer>
  );
};

export default InstallmentSummary;
