import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  rootWrapper: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const DteTooltipClickOrHoverIcon: React.FC = (): React.ReactElement => (
  <Box data-testid="dte-tooltip-click-hover-button" sx={sx.rootWrapper}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#1E3575" />
      <circle r="0.914286" transform="matrix(-1 0 0 1 8 4.34287)" fill="white" />
      <rect width="1.82857" height="7.31429" rx="0.914286" transform="matrix(-1 0 0 1 8.91428 6.17145)" fill="white" />
    </svg>
  </Box>
);

export default DteTooltipClickOrHoverIcon;
