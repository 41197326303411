import { SyntheticEvent } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, SxProps, Typography } from '@mui/material';

type Props = {
  handleTooltipClose: (event: SyntheticEvent | Event) => void;
};

const sx: Record<string, SxProps> = {
  tooltipBubble: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '20px',
  },
  tooltipCancelButton: {
    padding: '10px',
    top: '-10px',
    right: '-10px',
    marginLeft: '-29px',
  },
};

const TooltipMinAmountBubble: React.FC<Props> = (props: Props): JSX.Element => {
  const { handleTooltipClose } = props;

  return (
    <Box data-testid="min-amount-due-tooltip-bubble" sx={sx.tooltipBubble}>
      <Typography data-testid="min-amount-due-tooltip-text" variant="body1">
        By paying only the minimum amount due, your current bill is still due on the due date.
      </Typography>
      <IconButton
        sx={sx.tooltipCancelButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleTooltipClose(event)}
      >
        <CloseRoundedIcon color="primary" data-testid="min-amount-due-tooltip-cancel-button" />
      </IconButton>
    </Box>
  );
};

export default TooltipMinAmountBubble;
