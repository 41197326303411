import React from 'react';
import { InputAttributes, NumberFormatValues, NumericFormat } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
}

const maxLimit = 2000000;

// eslint-disable-next-line @typescript-eslint/typedef
const NumberFormatCustom = React.forwardRef<typeof NumericFormat<InputAttributes>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues): void => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      displayType="input"
      isAllowed={(values: NumberFormatValues): boolean => {
        const { value } = values;
        if (!value) {
          return true;
        }
        return parseFloat(value) <= maxLimit;
      }}
    />
  );
});

export default NumberFormatCustom;
