import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const WeWillContactYouIcon = ({
  containerSx,
  iconWidth = '51',
  iconHeight = '61',
}: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="we-will-contact-you-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 51 61" fill="none">
      <path
        d="M47.9523 20.7143V20.7143C47.9523 9.82638 39.1259 1 28.238 1H23.0951C12.2072 1 3.38086 9.82638 3.38086 20.7143V20.7143"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M47.9523 42.9983V42.9983C47.7133 50.1674 41.8325 55.8555 34.6594 55.8555H33.3809"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="18.9521" y="50.8555" width="13.4286" height="9.14286" rx="4" stroke="#1E3575" strokeWidth="2" />
      <rect
        x="49.5234"
        y="21.6133"
        width="21.2446"
        height="9.14286"
        rx="4"
        transform="rotate(90 49.5234 21.6133)"
        stroke="#1E3575"
        strokeWidth="2"
      />
      <rect
        x="10.9521"
        y="21.6133"
        width="21.2446"
        height="9.14286"
        rx="4"
        transform="rotate(90 10.9521 21.6133)"
        stroke="#1E3575"
        strokeWidth="2"
      />
    </svg>
  </IconContainer>
);
