import { Button, ButtonProps, Typography } from '@mui/material';

export type MuiButtonProps = Pick<ButtonProps, 'href' | 'variant' | 'onClick' | 'sx' | 'tabIndex'> & {
  text?: string;
  disabled?: boolean;
};

export const DteButton = ({
  variant,
  text,
  href,
  onClick,
  disabled = false,
  sx,
  tabIndex,
}: MuiButtonProps): React.ReactElement => (
  <Button
    type="button"
    variant={variant}
    href={href}
    onClick={onClick}
    disabled={disabled}
    disableFocusRipple
    sx={sx}
    tabIndex={tabIndex}
  >
    <Typography fontWeight={600} variant="body2">
      {text}
    </Typography>
  </Button>
);
