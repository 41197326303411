import { CircularProgress, useTheme } from '@mui/material';

import getProgressValue from './getProgressValue';

interface IProgressCircleProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const ProgressCircle: React.FC<IProgressCircleProps> = (props: IProgressCircleProps): React.ReactElement => {
  const { palette } = useTheme();
  const { numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = props;
  const progressValue = getProgressValue(numberOfInstallmentsOpted, numberOfInstallmentsRemaining);

  return (
    <>
      <CircularProgress
        data-testid="monthly-payment-circle-progress"
        style={{
          color: palette.dteBlue.main,
          height: '100%',
          position: 'absolute',
          width: '100%',
          zIndex: '2',
        }}
        thickness={3}
        value={progressValue}
        variant="determinate"
      />
      <CircularProgress
        style={{
          color: palette.dteGrey.main,
          height: '100%',
          position: 'absolute',
          width: '100%',
        }}
        thickness={3}
        value={100}
        variant="determinate"
      />
    </>
  );
};

export default ProgressCircle;
