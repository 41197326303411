import { styled, Theme } from '@mui/material';

import { ScheduleIcon, SelectIcon } from '../../../component-library/icons/payment-agreement-icons';
import PayOffOverTime from '../../../component-library/icons/payment-agreement-icons/PayOffOverTime';

import messages from './messages';
import { PaymentAgreementStep } from './PaymentAgreementStep';

const Steps = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down(755)]: {
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    width: '304px',
  },
  [theme.breakpoints.up(755)]: {
    flexDirection: 'row',
    gap: '16px',
  },
}));

const PaymentAgreementWorksSteps: React.FC = (): React.ReactElement => (
  <Steps data-testid="payment-agreement-steps">
    <PaymentAgreementStep
      dataTestId="payment-agreement-step1"
      icon={<SelectIcon iconHeight="57px" iconWidth="38px" />}
      instructions={messages.stepOneInstructions}
      subTitle={messages.stepOneInstructionsSubTittle}
      title={messages.stepOneTitle}
    />
    <PaymentAgreementStep
      dataTestId="payment-agreement-step2"
      icon={<ScheduleIcon iconHeight="56px" iconWidth="56px" />}
      instructions={messages.stepTwoInstructions}
      subTitle={messages.stepTwoInstructionsSubTittle}
      title={messages.stepTwoTitle}
    />
    <PaymentAgreementStep
      dataTestId="payment-agreement-step3"
      icon={<PayOffOverTime iconHeight="56px" iconWidth="56px" />}
      instructions={messages.stepThreeInstructions}
      subTitle={messages.stepThreeInstructionsSubTittle}
      title={messages.stepThreeTitle}
    />
  </Steps>
);

export default PaymentAgreementWorksSteps;
