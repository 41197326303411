import * as React from 'react';
import { styled, Theme, Typography } from '@mui/material';

import { MilitaryTagsIcon } from 'component-library/icons/MilitaryTagsIcon';
import { StyledTextLink } from 'component-library/links/StyledLink';
import { BulletedList } from 'component-library/lists/BulletedList';
import { Bold } from 'component-library/typography/Bold';

export const MilitaryServiceProtectionEligibility = (): React.ReactElement => {
  const MilitaryServiceProtectionEligibilityWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // SmallMobile & Mobile
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
    },
    // Tablet & Desktop
    [theme.breakpoints.up('tablet')]: {
      gap: '32px',
    },
  }));

  const MilitaryServiceProtectionEligibilityRequirements = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '32px',
  });

  const InfoAndHeader = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  });

  const MilitaryPrimaryCopy = styled('span')(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: '400',
  }));

  const MilitarySecondaryCopy = styled('span')(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '16px',
    fontWeight: '400',
  }));

  const RequirementsHeading = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '22px',
    fontWeight: '600',
    lineHeight: '30px',
  }));

  const Requirements = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  });

  const Requirement = styled('div')({
    display: 'flex',
    flexDirection: 'column',
  });

  const Item = styled('li')(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
  }));

  const medicalHoldEmail = (
    <StyledTextLink href="mailto:medicalhold@dteenergy.com">
      <Bold>medicalhold@dteenergy.com</Bold>
    </StyledTextLink>
  );

  return (
    <MilitaryServiceProtectionEligibilityWrapper data-testid="military-service-protection-eligibility">
      <MilitaryTagsIcon />
      <MilitaryServiceProtectionEligibilityRequirements id="military-service-protection-eligibility-requirements">
        <InfoAndHeader id="info-and-header">
          <MilitarySecondaryCopy>
            If you or your spouse is the DTE account holder and either of you are called to full-time active military
            service during a national or state emergency or war, you may apply for shutoff protection for up to 180
            days.{' '}
          </MilitarySecondaryCopy>
          <RequirementsHeading>Requirements</RequirementsHeading>
        </InfoAndHeader>
        <Requirements id="requirements">
          <MilitarySecondaryCopy>
            Please send an email to {medicalHoldEmail} that includes the required information and documentation below to
            qualify for Military Service Protection.
          </MilitarySecondaryCopy>
          <Requirement>
            <MilitaryPrimaryCopy>
              <Bold id="email-required-information">Required information in the email:</Bold>
            </MilitaryPrimaryCopy>
            <br />
            <BulletedList aria-labelledby="email-required-information">
              <Item>Account holder’s name</Item>
              <Item>DTE account number</Item>
              <Item>Phone number for account holder</Item>
            </BulletedList>
          </Requirement>
          <Requirement>
            <MilitaryPrimaryCopy>
              <Bold id="email-required-documents">Required document copies to attach to the email:</Bold>
            </MilitaryPrimaryCopy>
            <br />
            <BulletedList aria-labelledby="email-required-documents">
              <Item>
                Military Deployment orders on official military stationary (to verify full-time active-duty status)
              </Item>
              <Item>Military ID</Item>
            </BulletedList>
          </Requirement>
          <Requirement>
            <MilitarySecondaryCopy>
              <MilitaryPrimaryCopy>
                <Bold id="military-requirements-next-steps">{`Next Steps `}</Bold>
              </MilitaryPrimaryCopy>
              following your email to {medicalHoldEmail}
              <Bold>:</Bold>
            </MilitarySecondaryCopy>
            <br />
            <BulletedList aria-labelledby="military-requirements-next-steps">
              <Item>
                You will receive an email confirming we’ve received and will review your information and documentation.
              </Item>
              <Item>
                An Energy Advocacy Group team member will contact you within two business days at the phone number and
                email address you provided.
              </Item>
            </BulletedList>
          </Requirement>
          <MilitarySecondaryCopy>Thank you for your service.</MilitarySecondaryCopy>
        </Requirements>
      </MilitaryServiceProtectionEligibilityRequirements>
    </MilitaryServiceProtectionEligibilityWrapper>
  );
};
