import { SxProps } from '@mui/material';

import { useBreakpoints } from './useBreakpoints';

export const useResponsiveSx = (
  mobileSx: SxProps,
  tabletSx: SxProps,
  desktopSx: SxProps,
  smallMobileSx: SxProps = mobileSx,
): SxProps => {
  const { isSmallMobile, isMobile, isTablet, isDesktop } = useBreakpoints();

  switch (true) {
    case isDesktop: {
      return desktopSx;
    }
    case isTablet: {
      return tabletSx;
    }
    case isMobile: {
      return mobileSx;
    }
    case isSmallMobile: {
      return smallMobileSx;
    }
    default: {
      return mobileSx;
    }
  }
};

export const useResponsiveRendering = (
  mobileComponent: JSX.Element,
  tabletComponent: JSX.Element,
  desktopComponent: JSX.Element,
  smallMobileComponent: JSX.Element = mobileComponent,
): JSX.Element => {
  const { isSmallMobile, isMobile, isTablet, isDesktop } = useBreakpoints();

  switch (true) {
    case isDesktop: {
      return desktopComponent;
    }
    case isTablet: {
      return tabletComponent;
    }
    case isMobile: {
      return mobileComponent;
    }
    case isSmallMobile: {
      return smallMobileComponent;
    }
    default: {
      return mobileComponent;
    }
  }
};
