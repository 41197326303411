import * as React from 'react';
import { useEffect } from 'react';

import { DteHeader } from 'component-library/header/DteHeader';
import { DteStyledLogo } from 'component-library/logo/DteStyledLogo';
import { NewPageTemplate } from 'component-library/page-templates/NewPageTemplate';
import { useDunningLockDetails } from 'hooks';

import { onPageViewHandler } from './handlers';
import { YLockContent } from './YLockContent';

export const YLockV2 = (): React.ReactElement => {
  const { dunningLockDetails, isDunningLockDetailsSuccess } = useDunningLockDetails({ dunningLockReason: 'Y' });

  useEffect(() => {
    if (isDunningLockDetailsSuccess) {
      onPageViewHandler();
    }
  }, [isDunningLockDetailsSuccess, onPageViewHandler]);

  const header = <DteHeader title="Service Protection" logo={<DteStyledLogo />} />;

  return (
    <NewPageTemplate
      titleTag="Income Eligible Review | DTE Energy"
      pageHeader={header}
      showContent={isDunningLockDetailsSuccess}
      content={<YLockContent endDate={dunningLockDetails[0].endDate} />}
    />
  );
};
