import { styled, Theme, Typography } from '@mui/material';

import messages from './messages';

interface IProgressCircleProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const LabelContainer = styled('div')(() => ({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
}));

const ProgressNumberText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.dteBlue.main,
  fontSize: '25px',
  fontWeight: '600',
  lineHeight: '34.05px',
  textAlign: 'center',
  variant: 'h2',
}));

const RemainingText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  lineHeight: '19.07px',
  fontSize: '16px',
  fontWeight: '400',
  textAlign: 'center',
}));

const ProgressLabel: React.FC<IProgressCircleProps> = (props: IProgressCircleProps): React.ReactElement => {
  const { numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = props;
  const progressNumber = `${numberOfInstallmentsRemaining.toString()} of ${numberOfInstallmentsOpted.toString()}`;

  return (
    <LabelContainer data-testid="monthly-payment-circle-progress-label">
      <ProgressNumberText>{progressNumber}</ProgressNumberText>
      <RemainingText>{messages.remainingText}</RemainingText>
    </LabelContainer>
  );
};

export default ProgressLabel;
