import { urls } from 'constants/';
import { googleTagManager } from 'services';

import { unitedWayPhoneNumber } from '../../collections-copy';

import {
  cLockAgencyPhoneNumberLinkOnClickEvent,
  cLockEnergyAssistanceProgramsLinkOnClickEvent,
  cLockUnitedWayLinkOnClickEvent,
  cLockViewCurrentBillButtonOnClickEvent,
  cLockViewEvent,
  cLockViewMyAccountHistoryButtonOnClickEvent,
} from './gtmEventsCLock';

export const onPageViewHandler = (showAgencyPaymentContent: boolean): void => {
  googleTagManager.trackViewEvent({
    ...cLockViewEvent,
    authenticationStatus: true,
    paymentInfo: showAgencyPaymentContent ? 'true' : 'false',
  });
};

export const viewMyAccountHistoryClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...cLockViewMyAccountHistoryButtonOnClickEvent,
    authenticationStatus: true,
  });
  window.location.href = urls.accountHistoryUrl;
};

export const viewCurrentBillClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...cLockViewCurrentBillButtonOnClickEvent,
    authenticationStatus: true,
  });
  window.location.href = urls.currentBillUrl;
};

export const agencyPhoneNumberOnClickHandler = (agencyNumber: string): void => {
  googleTagManager.trackButtonClick({
    ...cLockAgencyPhoneNumberLinkOnClickEvent,
    actionObject: agencyNumber,
    authenticationStatus: true,
  });
};

export const energyAssistanceProgramsLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...cLockEnergyAssistanceProgramsLinkOnClickEvent,
    authenticationStatus: true,
  });
};

export const unitedWayLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...cLockUnitedWayLinkOnClickEvent,
    actionObject: unitedWayPhoneNumber,
    authenticationStatus: true,
  });
};
