import { Box, SxProps, Typography, useTheme } from '@mui/material';

const WarningTenTimesOver: React.FC = (): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    container: {
      borderRadius: '5px',
      border: `2px solid ${theme.palette.dteBlue.main}`,
      backgroundColor: theme.palette.dteBlue.light,
      margin: '-10px 24px 20px 76px',
    },
    textContent: {
      color: theme.palette.secondary.main,
      padding: '20px 13.5px',
    },
  };

  return (
    <Box data-testid="warning-ten-times-over-total-amount-due-component" sx={sx.container}>
      <Typography data-testid="warning-ten-times-over-text" sx={sx.textContent} variant="body1">
        You are about to pay a significantly higher amount than what is due. Please ensure you want to make this
        payment.
      </Typography>
    </Box>
  );
};

export default WarningTenTimesOver;
