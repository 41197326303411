import { styled, Typography } from '@mui/material';

import { PAEnrollmentAssistanceIcon } from '../../component-library/icons/payment-agreement-icons';
import { theme } from '../../theme/theme';

import messages from './messages';

const NoticeContainer = styled('div')(() => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.dteGrey.light,
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  padding: '20px',
}));

const EnrollmentAssistanceNotice: React.FC = (): React.ReactElement => (
  <NoticeContainer data-testid="pa-enrollment-assistance-notice">
    <PAEnrollmentAssistanceIcon />
    <Typography color={theme.palette.primary.main}>{messages.assistanceNotice}</Typography>
  </NoticeContainer>
);

export default EnrollmentAssistanceNotice;
