import { styled, Theme } from '@mui/material';

const DocumentGuidelineRoot = styled('div')(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  display: 'flex',
  flexDirection: 'column',
}));

const AccordionWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    padding: '0px 20px 24px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '0px 30px 24px',
  },
}));

type DocumentGuidelineProps = {
  labelComponent: JSX.Element;
  accordionComponent: JSX.Element;
  dataTestId?: string;
};
export const DocumentGuideline = ({
  labelComponent,
  accordionComponent,
  dataTestId,
}: DocumentGuidelineProps): React.ReactElement => (
  <DocumentGuidelineRoot data-testid={dataTestId}>
    {labelComponent}
    <AccordionWrapper>{accordionComponent}</AccordionWrapper>
  </DocumentGuidelineRoot>
);
