import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { AdditionalPaymentsIcon } from 'component-library/icons/payment-agreement-icons';
import {
  DeadlineNoticeIcon,
  InterruptionNoticeIcon,
  PaymentDifferenceNoticeIcon,
} from 'component-library/icons/PaymentExtensionNoticeIcons';

import messages from '../messages';

const NoticeText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  variant: 'body1',
}));

const NoticeTextContainer = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  variant: 'body1',
  [theme.breakpoints.up(755)]: {
    marginTop: '10px',
  },
}));

export const PaymentAgreementNoticeStep: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    noticesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    noticeWrapperText: {
      alignItems: 'flex-start',
      color: theme.palette.secondary.main,
      display: 'flex',
      gap: '20px',
    },
    noticeWrapperFirstText: {
      alignItems: 'flex-start',
      color: theme.palette.secondary.main,
      display: 'flex',
      gap: '20px',
    },
    paymentIconNoticeWrapper: {
      color: theme.palette.secondary.main,
      display: 'flex',
      gap: '18px',
    },
  };

  return (
    <Box data-testid="payment-agreement-notice-step" sx={sx.noticesWrapper}>
      <Box sx={sx.noticeWrapperFirstText}>
        <DeadlineNoticeIcon />
        <NoticeTextContainer data-testid="first-step-msg">{messages.firstStepMessage}</NoticeTextContainer>
      </Box>
      <Box sx={sx.noticeWrapperText}>
        <AdditionalPaymentsIcon />
        <NoticeText data-testid="second-step-msg">{messages.secondStepMessage}</NoticeText>
      </Box>
      <Box sx={sx.noticeWrapperText}>
        <InterruptionNoticeIcon />
        <NoticeText data-testid="third-step-msg">{messages.thirdStepMessage}</NoticeText>
      </Box>
      <Box sx={sx.paymentIconNoticeWrapper}>
        <PaymentDifferenceNoticeIcon />
        <NoticeText data-testid="fourth-step-msg">{messages.fourthStepMessage}</NoticeText>
      </Box>
    </Box>
  );
};
