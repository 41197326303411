import { AxiosResponse } from 'axios';

import { apiPaths } from '../../constants/apiPaths';
import { mockRefactoredEligibilityResponse } from '../../testing/mocks/data';
import { azureGet } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';

const refactoredEligibilityCheck = async (): Promise<AxiosResponse<IRefactoredEligibilityResponse>> =>
  azureGet<IRefactoredEligibilityResponse>(apiPaths.refactoredEligibilityApiPath);

const localMockRefactoredEligibilityCheck = async (): Promise<AxiosResponse<IRefactoredEligibilityResponse>> =>
  localMockApiResponse(mockRefactoredEligibilityResponse);

const refactoredEligibilityApi = apiOrLocalMock(refactoredEligibilityCheck, localMockRefactoredEligibilityCheck);

export default refactoredEligibilityApi;
