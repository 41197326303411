import { CSSProperties } from 'react';

export const typographyConfig = {
  fontFamily: ['Open Sans', 'sans-serif'].join(','),
  title: {
    fontSize: '29px',
    weight: 400,
    lineHeight: '39.49px',
  } as CSSProperties,
  input: {
    fontSize: '14px',
    weight: 400,
    lineHeight: '20px',
  } as CSSProperties,
  h1: {
    fontSize: '22px',
    weight: 400,
    lineHeight: '29.96px',
  },
  h2: {
    fontSize: '23px',
    weight: 400,
    lineHeight: '31.32px',
  },
  h3: {
    fontSize: '22px',
    weight: 400,
    lineHeight: '29.96px',
  },
  body1: {
    fontSize: '16px',
    weight: 400,
    lineHeight: '21.79px',
  },
  body2: {
    fontSize: '18px',
    weight: 400,
    lineHeight: '24.51px',
  },
  button: {
    fontSize: '18px',
    weight: 400,
    lineHeight: '24.51px',
  },
  subtitle1: {
    fontSize: '14px',
    weight: 400,
    lineHeight: '28.6px',
  },
  subtitle2: {
    fontSize: '12px',
    weight: 400,
    lineHeight: '16.34px',
  },
};
