import { paymentExtension } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { urls } from 'constants/urls';
import { googleTagManager } from 'services';

import gtmConfirmPeDetailsBaseEvent from './gtmConfirmPeDetailsBaseEvent';

const onHeaderCancelButtonCLick = (): void => {
  googleTagManager.trackButtonClick({
    ...gtmConfirmPeDetailsBaseEvent,
    actionDetail: 'confirm payment extension details',
    actionObject: 'cancel',
    authenticationStatus: true,
  });
  window.location.href = urls.currentBillUrl;
};

export const mobileHeader = (
  <HeaderMobile titleText={paymentExtension} isHeaderButtonRendered onClick={onHeaderCancelButtonCLick} />
);

export const desktopHeader = (
  <HeaderDesktop titleText={paymentExtension} isHeaderButtonRendered onClick={onHeaderCancelButtonCLick} />
);
