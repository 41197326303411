import { styled, Theme, Typography } from '@mui/material';

import messages from './messages';

const Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Open Sans',
  fontSize: '28px',
  fontWeight: '400',
  lineHeight: '38.13px',
  textAlign: 'center',
}));

const Paragraph = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21.79px',
}));

const PhoneNumber = styled('a')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.dteBlue.main,
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '21.79px',
  '&:hover': {
    color: theme.palette.dteBlue.dark,
  },
}));

const GenericErrorPageDescription: React.FC = (): React.ReactElement => (
  <>
    <Heading data-testid="generic-error-heading">{messages.contactUs}</Heading>
    <Paragraph data-testid="generic-error-msg">
      {messages.genericErrorMsg1}
      <PhoneNumber href={`tel:${messages.contactNumber}`}>{messages.contactNumber}</PhoneNumber>
      {messages.genericErrorMsg2}
    </Paragraph>
  </>
);

export default GenericErrorPageDescription;
