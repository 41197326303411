import { IconProps } from '../icon-props';
import { IconContainer } from '../IconContainer';

const PaymentAgreementModalIcon = (iconProps: IconProps): React.ReactElement => {
  const { containerSx, iconHeight = '56', iconWidth = '48' } = iconProps;

  return (
    <IconContainer data-testid="pa-summary-modal-icon" sx={containerSx}>
      <svg width={iconWidth} height={iconHeight} viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.3633 34.3245C36.3633 34.3245 39.0686 39.202 39.2724 39.202C39.4761 39.202 42.1815 34.3245 42.1815 34.3245V16.911C42.1815 15.3044 40.879 14.002 39.2724 14.002C37.6657 14.002 36.3633 15.3044 36.3633 16.911V34.3245Z"
          stroke="#1E3575"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path d="M17.4541 15.4004H27.6359" stroke="#1E3575" strokeWidth="2" strokeLinecap="round" />
        <path
          d="M17.4541 21.1758H31.9996M17.4541 23.9758H31.9996M17.4541 26.7758H31.9996"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.6C0 2.5072 2.60489 0 5.81818 0H32C35.2133 0 37.8182 2.50721 37.8182 5.6V7H42.1818C45.3951 7 48 9.50721 48 12.6V50.4C48 53.4928 45.3951 56 42.1818 56H16C12.7867 56 10.1818 53.4928 10.1818 50.4V49H5.81818C2.60489 49 0 46.4928 0 43.4V5.6ZM10.1818 46.2V12.6C10.1818 9.5072 12.7867 7 16 7H34.9091V5.6C34.9091 4.0536 33.6066 2.8 32 2.8H5.81818C4.21153 2.8 2.90909 4.0536 2.90909 5.6V43.4C2.90909 44.9464 4.21154 46.2 5.81818 46.2H10.1818ZM13.0909 12.6C13.0909 11.0536 14.3934 9.8 16 9.8H42.1818C43.7885 9.8 45.0909 11.0536 45.0909 12.6V50.4C45.0909 51.9464 43.7885 53.2 42.1818 53.2H16C14.3934 53.2 13.0909 51.9464 13.0909 50.4V12.6Z"
          fill="#1E3575"
        />
        <path
          d="M17.4541 49.3931C19.6359 49.3931 22.545 44.4929 23.2723 42.393C23.9996 40.293 26.1814 47.293 26.9086 47.293C27.6359 47.293 29.2933 43.8651 29.8177 43.793C30.2508 43.7335 31.4568 46.1597 31.8636 47.0063C31.9479 47.1817 32.1241 47.293 32.3188 47.293H40.7268"
          stroke="#1E3575"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </IconContainer>
  );
};

export default PaymentAgreementModalIcon;
