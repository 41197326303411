/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { urls } from 'constants/';
import { logger } from 'services';

import { dunningLockDetailsApi } from '../api/dunning-lock-details-api/dunning-lock-details-api';
import { useUserState } from '../auth/authenticate';
import { lockStatusErrorPath } from '../urls-and-paths';

interface IDunningLockDetailsParams {
  dunningLockReason: string;
}

interface IUseDunningLockDetailsData {
  dunningLockDetails: IDunningLockDetails[];
  isDunningLockDetailsSuccess: boolean;
  isFetched?: boolean;
}

const doesDunningLockDetailsExist = (dunningLockDetails: IDunningLockDetails[]): boolean =>
  Boolean(dunningLockDetails && dunningLockDetails?.length);

const useDunningLockDetails = ({ dunningLockReason }: IDunningLockDetailsParams): IUseDunningLockDetailsData => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();
  const {
    data: dunningLockDetailsResponse,
    isFetched,
    isSuccess: isDunningLockDetailsSuccess,
  } = useQuery({
    enabled: isAuthenticated,
    queryKey: ['dunning-lock-details'],
    queryFn: dunningLockDetailsApi,
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess: (httpResponse: { data: any }) => {
      const { data } = httpResponse;

      logger.info('[Logging...] Dunning Lock Details API Call Success!', httpResponse);

      if (!doesDunningLockDetailsExist(data?.dunningLockDetails)) {
        logger.info('[Error...] Dunning Lock Details API Call Has No Data!', httpResponse);
        window.location.href = urls.currentBillUrl;
      } else if (data.dunningLockDetails[0].eligibilityFlag !== 'Y') {
        logger.info('[Error...] Dunning Lock Details API Call User Is Not Eligible!', httpResponse);
        navigate(lockStatusErrorPath);
      } else if (dunningLockReason !== data?.dunningLockDetails[0].dunningLockReason) {
        logger.info('[Error...] Dunning Lock Details Flag Is Not Same As Page Requested!', {
          httpResponse,
          dunningLockReason,
        });
        navigate(lockStatusErrorPath);
      }
    },
    onError: (error: unknown) => {
      logger.info('[Logging...] Dunning Lock Details API Call Error!', error);
      navigate(lockStatusErrorPath);
    },
  });

  if (dunningLockDetailsResponse?.data?.dunningLockDetails) {
    return {
      isFetched,
      isDunningLockDetailsSuccess,
      dunningLockDetails: dunningLockDetailsResponse.data.dunningLockDetails,
    };
  }
  return {
    isFetched,
    isDunningLockDetailsSuccess: false,
    dunningLockDetails: [
      {
        dunningLockReason: '',
        name: '',
        startDate: '',
        endDate: '',
        agencyName: '',
        agencyNumber: '',
        agencyCode: '',
      },
    ],
  };
};

export default useDunningLockDetails;
