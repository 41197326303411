import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  wrapper: {
    width: '16px',
    height: '16px',
  },
};

const RadioErrorIcon = (): React.ReactElement => (
  <Box data-testid="error-radio-icon" sx={sx.wrapper}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        fill="white"
      />
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        fill="#E31937"
        fillOpacity="0.04"
      />
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        stroke="#E31937"
      />
    </svg>
  </Box>
);

export default RadioErrorIcon;
