import { Link, SxProps, TypographyVariant, useTheme } from '@mui/material';

interface DteTextLinkProps {
  href: string;
  name: string;
  variant: TypographyVariant;
  onClick?: () => void;
}

export const DteTextLink = ({ href, name, variant, onClick }: DteTextLinkProps): JSX.Element => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    textLink: {
      color: theme.palette.dteBlue.main,
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.dteBlue.dark,
      },
      '&:focus': {
        color: theme.palette.dteBlue.dark,
        textDecoration: 'underline',
      },
    },
  };

  return (
    <Link variant={variant} sx={sx.textLink} fontWeight={600} href={href} onClick={onClick}>
      {name}
    </Link>
  );
};
