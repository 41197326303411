import { styled } from '@mui/material';

import PaymentAgreementWorksSteps from './payment-agreement-works-steps/PaymentAgreementWorksSteps';
import ContinueButton from './ContinueButton';
import HowPaymentAgreementWorksDescription from './HowPaymentAgreementWorksDescription';

const Container = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
}));

const HowPaymentAgreementWorksContent: React.FC = (): React.ReactElement => (
  <Container>
    <HowPaymentAgreementWorksDescription />
    <PaymentAgreementWorksSteps />
    <ContinueButton />
  </Container>
);

export default HowPaymentAgreementWorksContent;
