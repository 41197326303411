import { styled, Theme, Typography } from '@mui/material';

export const EligibilityTypeHeader = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  padding: '8px 8px',
}));

export const EligibleTypeText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteBlue.main,
  color: theme.palette.background.default,
  fontWeight: '700',
  margin: '0 8px',
  padding: '8px 8px',
}));

export const EligibleText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.dteGreen.main,
  fontWeight: '700',
}));

export const ScheduledOrEnrolledText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.dteBlue.main,
  fontWeight: '400',
}));

export const NotEligibleText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.dteRed.dark,
  fontWeight: '700',
}));
