import { styled, Theme, Typography } from '@mui/material';

import { CombinedEligibilityCheckResponse } from '../../../api/eligibility-api/EligibilityCheckResponse';

const AssistanceInformationContent = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '600px',
  },
}));

const AssistanceTitle = styled(Typography)({
  fontSize: '22px',
  fontFamily: 'Open Sans,sans-serif',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
});

const AssistanceCopy = styled(Typography)({
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
});

type DefaultAssistanceInformationProps = {
  eligibilityResponse: CombinedEligibilityCheckResponse;
};
export const DefaultAssistanceInformation = ({
  eligibilityResponse,
}: DefaultAssistanceInformationProps): React.ReactElement => {
  const agencyName =
    eligibilityResponse?.dunningLockDetails && eligibilityResponse?.dunningLockDetails[0].agencyName
      ? eligibilityResponse?.dunningLockDetails[0].agencyName
      : 'An agency';

  return (
    <AssistanceInformationContent data-testid="default-assistance-information-content">
      <AssistanceTitle>Assistance Information</AssistanceTitle>
      <AssistanceCopy>
        {`${agencyName} has committed to making an energy assistance payment on your account. Once this
                      payment has been applied, you will receive a notification.`}
      </AssistanceCopy>
      <AssistanceCopy>DTE Representatives do not have additional information about agency payments.</AssistanceCopy>
    </AssistanceInformationContent>
  );
};
