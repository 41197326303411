import { styled, Theme, Typography } from '@mui/material';

import messages from './messages';

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21.79px',
}));

const InstallmentAmountDescription: React.FC = (): React.ReactElement => (
  <Title data-testid="installment-description">{messages.installmentDescription}</Title>
);

export default InstallmentAmountDescription;
