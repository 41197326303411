import * as React from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { useResponsiveSx } from '../../theme/breakpoints/responsiveHooks';
import DteDisclaimerIcon from '../icons/DteDisclaimerIcon';

const PaymentProcessBanner = (): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    disclaimerWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px 30px',
      background: theme.palette.dteGrey.light,
      gap: '20px',
      marginTop: '32px',
      minWidth: '304px',
      color: theme.palette.primary.main,
    },
    disclaimerWrapperMobile: {
      flexDirection: 'column',
    },
  };

  const disclaimerMobileSx = {
    ...sx.disclaimerWrapper,
    ...sx.disclaimerWrapperMobile,
  };

  const responsiveSx = useResponsiveSx(disclaimerMobileSx, sx.disclaimerWrapper, sx.disclaimerWrapper);

  return (
    <Box data-testid="disclaimer" sx={responsiveSx}>
      <DteDisclaimerIcon />
      <Typography variant="body1">
        Any payment unable to be processed will result in DTE accepting only cash payments at a kiosk or authorized
        payment agent for the next 12 months.
      </Typography>
    </Box>
  );
};

export default PaymentProcessBanner;
