const messages = {
  accountNumberHeading: 'Account Number',
  downPaymentPopoverText:
    "Your required down payment might not match your total balance due. The required down payment is about half of your service costs and won't include any other DTE programs, like Home Protection Plus. If you enroll in Payment Agreement a second time, your down payment will be about 75% of your service costs balance.",
  downPaymentText: 'Required down payment',
  dueByText: 'Due by 2 p.m.',
  eligiblePaymentAgreementBalancePopoverText:
    'Not all charges are eligible for a Payment Agreement. If you are enrolled in any other DTE programs, like Home Protection Plus, you must continue to make those monthly payments in addition to your Payment Agreement payment.',
  eligiblePaymentAgreementBalanceText: 'Eligible Payment Agreement balance',
  heading: 'Payment Agreement',
};

export default messages;
