import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { trackServiceResponseError, trackServiceResult } from '../../gtm/gtag';

export const onRequestHandler = (config: AxiosRequestConfig): AxiosRequestConfig =>
  // this is the seam to do something with the request, like ga events
  ({
    ...config,
  });

export const onRequestError = (error: AxiosError): Promise<AxiosError> =>
  // this is the seam to do something with the request error, like ga events
  Promise.reject(error);

export const onResponseHandler = (response: AxiosResponse): AxiosResponse => {
  const requestMethod = response.config.method ? response.config.method : '';
  const requestUri = response.config.url ? response.config.url : '';

  trackServiceResult(requestUri, requestMethod.toUpperCase(), response);

  return {
    ...response,
  };
};

export const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const requestMethod = error.config?.method ? error.config.method : '';
  const requestUri = error.config?.url ? error.config?.url : '';
  const status = error.response?.status ? error.response.status.toString() : '';

  trackServiceResponseError(requestUri, requestMethod, status);

  return Promise.reject(error);
};
