import * as React from 'react';
import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

import { useResponsiveSx } from '../../theme/breakpoints/responsiveHooks';
import Footer from '../footer/Footer';

type Props = {
  children: JSX.Element;
  header: JSX.Element;
  allViewportPaddingTop: '0px' | '16px' | '40px';
  tabletDesktopContentWidth: '470px' | '630px' | '740px';
  dataTestId: string;
};

export const PageTemplate: FC<Props> = (props: Props): React.ReactElement => {
  const { children, header, allViewportPaddingTop, tabletDesktopContentWidth, dataTestId } = props;
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },
    rootWrapperSmallMobile: {
      width: 'auto',
      padding: '0px 8px',
      paddingTop: allViewportPaddingTop,
    },
    rootWrapperMobile: {
      width: 'auto',
      padding: '0px 24px',
      paddingTop: allViewportPaddingTop,
    },
    rootWrapperTablet: {
      maxWidth: tabletDesktopContentWidth,
      margin: '0px 40px',
      paddingTop: allViewportPaddingTop,
    },
    rootWrapperDesktop: {
      maxWidth: tabletDesktopContentWidth,
      margin: 'auto',
      paddingTop: allViewportPaddingTop,
    },
    stickyContent: {
      flex: '1 0 auto',
      paddingBottom: '40px',
    },
  };

  const rootWrapperResponsiveStyle = useResponsiveSx(
    sx.rootWrapperMobile,
    sx.rootWrapperTablet,
    sx.rootWrapperDesktop,
    sx.rootWrapperSmallMobile,
  );

  return (
    <Box sx={sx.rootWrapper}>
      {header}
      <Box sx={sx.stickyContent} role="main">
        <Box data-testid={dataTestId} sx={rootWrapperResponsiveStyle}>
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
