import { Box, SxProps, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { SuccessBanner } from 'component-library/banners/SuccessBanner';

import messages from './messages';

interface ActivePaymentBannerProps {
  downPaymentAmount: string;
  downPaymentPostedDate?: string;
}

const ActivePaymentBanner: React.FC<ActivePaymentBannerProps> = (props: ActivePaymentBannerProps) => {
  const theme = useTheme();
  const { downPaymentAmount, downPaymentPostedDate } = props;
  const formattedDate = dayjs(downPaymentPostedDate).format('dddd, MMMM DD, YYYY');
  const formattedAmount = `$${parseFloat(downPaymentAmount).toFixed(2)}`;

  const sx: Record<string, SxProps> = {
    mesageChunkBold: {
      fontWeight: 600,
      display: 'inline',
    },
    messageChunkNormal: {
      display: 'inline',
    },
    wrapper: {
      display: 'flex',
      [theme.breakpoints.down('mobile')]: {
        maxWidth: '327px',
        height: '150px',
      },
      [theme.breakpoints.up('mobile')]: {
        width: '100%',
        height: '150px',
      },
      [theme.breakpoints.up(755)]: {
        height: '84px',
      },
      [theme.breakpoints.up('desktop')]: {
        width: '740px',
        height: '84px',
      },
      '& > div': {
        gap: '12px',
        padding: '20px',
      },
      '& > div > p': {
        paddingLeft: '0px',
      },
    },
  };

  return (
    <Box data-testid="active-payment-banner" sx={sx.wrapper}>
      <SuccessBanner
        iconWidth={24}
        iconHeight={24}
        message={
          <>
            <Typography sx={sx.mesageChunkBold}>{`${messages.messageChunk1}${' '}`}</Typography>
            <Typography sx={sx.messageChunkNormal}>
              {`${messages.messageChunk2} ${formattedAmount}${' '} ${messages.messageChunk3} ${` `} ${formattedDate}.`}
            </Typography>
          </>
        }
      />
    </Box>
  );
};

export default ActivePaymentBanner;
