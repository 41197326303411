import { styled, Theme, Typography } from '@mui/material';

import { DteTextLink } from '../../../component-library/links/DteTextLink';
import { Bold } from '../../../component-library/typography/Bold';
import { agencyPhoneNumberOnClickHandler } from '../handlers';
import messages from '../messages';

const AssistanceInformationContent = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
}));

const AgencyContactInformation = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

const AssistanceCopy = styled(Typography)({
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
});

const PhoneNumber = styled('span')({
  overflowWrap: 'break-word',
  whiteSpace: 'nowrap',
});

type AgencyPaymentAssistanceInformationProps = {
  dunningLockDetails: IDunningLockDetails;
};
export const AgencyPaymentAssistanceInformation = ({
  dunningLockDetails,
}: AgencyPaymentAssistanceInformationProps): React.ReactElement => {
  const showAllAgencyInformation =
    dunningLockDetails &&
    dunningLockDetails.agencyName &&
    dunningLockDetails.agencyNumber &&
    dunningLockDetails.agencyPaymentAmount &&
    dunningLockDetails.agencyPaymentDate;

  const agencyName =
    showAllAgencyInformation && dunningLockDetails.agencyName ? dunningLockDetails.agencyName : 'An agency';

  const agencyPhoneNumber = dunningLockDetails && dunningLockDetails.agencyNumber;

  return (
    <AssistanceInformationContent data-testid="agency-payment-assistance-information-content">
      <AssistanceCopy>
        {`${messages.agencyPaymentAssistanceInfoCopy1} ${agencyName} ${messages.agencyPaymentAssistanceInfoCopy2}`}
      </AssistanceCopy>
      {showAllAgencyInformation ? (
        <AgencyContactInformation>
          <AssistanceCopy>{messages.agencyPaymentAssistanceInfoContactText}</AssistanceCopy>
          <AssistanceCopy>
            <Bold>{dunningLockDetails.agencyName}</Bold>
            {messages.agencyPaymentAssistanceInfoBoldText}
            <PhoneNumber>
              <DteTextLink
                href={`tel:${agencyPhoneNumber}`}
                name={agencyPhoneNumber!}
                onClick={(): void => agencyPhoneNumberOnClickHandler(dunningLockDetails.agencyNumber || '')}
                variant="body1"
              />
            </PhoneNumber>
            .
          </AssistanceCopy>
        </AgencyContactInformation>
      ) : (
        <AssistanceCopy>{messages.agencyPaymentAssistanceInfoDTEText}</AssistanceCopy>
      )}
    </AssistanceInformationContent>
  );
};
