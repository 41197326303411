import { AxiosResponse } from 'axios';

import { apiPaths } from 'constants/apiPaths';
import { mockBasePaymentExtensionSuccess } from 'testing/mocks/data';

import { azureGet } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';

const paymentExtensionDetails = async (): Promise<AxiosResponse<IPaymentExtensionDetailsResponse>> =>
  azureGet<IPaymentExtensionDetailsResponse>(apiPaths.paymentExtensionDetailsApiPath);

const localMockPaymentExtensionDetails = async (): Promise<AxiosResponse<IPaymentExtensionDetailsResponse>> => {
  const localMock = await localMockApiResponse(mockBasePaymentExtensionSuccess); // *** Need to manually adjust this to return the response body of an eligible type you desire - add to mocks and change to return to that object
  return localMock;
};

export const paymentExtensionDetailsApi = apiOrLocalMock(paymentExtensionDetails, localMockPaymentExtensionDetails);
