import * as React from 'react';
import { PatternFormat } from 'react-number-format';
import { Box, Theme, Typography } from '@mui/material';

import { AccountDetailsResponse } from '../../api/account-details-api/AccountDetailsResponse';

interface Props {
  accountInfo?: AccountDetailsResponse;
  accountNumber?: number;
}

const sx = {
  accountInfoWrapper: {
    display: 'flex',
    width: '100%',
    margin: '40px 0px',
  },
  accountInfo: {
    alignItems: 'center',
    gap: '0.2em',
    border: 1,
    borderColor: (theme: Theme) => theme.palette.dteGrey.main,
    borderRadius: '0.06em',
    padding: '20px 24px',
    width: '100%',
  },
  title: {
    color: (theme: Theme) => theme.palette.primary.main,
    fontWeight: 600,
    textAlign: 'center',
  },
  body: {
    color: (theme: Theme) => theme.palette.secondary.main,
    textAlign: 'center',
  },
} as const;

const AccountInformationMobile: React.FC<Props> = (props: Props): JSX.Element => {
  const { accountInfo, accountNumber } = props;
  const address = accountInfo?.accounts[0].serviceAddresses[0];

  return (
    <Box data-testid="account-info-mobile" sx={sx.accountInfoWrapper}>
      <Box sx={sx.accountInfo}>
        <Typography data-testid="account-info-title" sx={sx.title} variant="body2">
          {accountInfo?.customerName}
        </Typography>
        <Typography data-testid="account-info-body" sx={sx.body} variant="body1">
          {address?.addressLine1}
        </Typography>
        {address?.addressLine2 && (
          <Typography sx={sx.body} variant="body1">
            {address?.addressLine2}
          </Typography>
        )}
        <Typography sx={sx.body} variant="body1">
          {`${address?.city}, ${address?.state} ${address?.zipcode}`}
        </Typography>
        <Typography sx={sx.title} paddingTop="14px" variant="body2">
          Account Number
        </Typography>
        <Typography sx={sx.body} variant="body1">
          <PatternFormat displayType="text" value={accountNumber} format="#### ### #### #" />
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountInformationMobile;
