import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { accountDetailsApiPath } from '../../urls-and-paths';
import { awsGet } from '../adapter/aws-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

import { AccountDetailsResponse } from './AccountDetailsResponse';

const accountDetails = async (): Promise<AxiosResponse<AccountDetailsResponse>> =>
  awsGet<AccountDetailsResponse>(accountDetailsApiPath);

const localMockAccountDetails = async (): Promise<AxiosResponse<AccountDetailsResponse>> =>
  localMockApiResponse(Mocks.accountDetailsResponseBody);

export const accountDetailsApi = apiOrLocalMock(accountDetails, localMockAccountDetails);

export const useAccountDetails = ({
  queryKey = ['account-details'],
  enabled,
  onSuccess,
  onError,
}: IUseAccountDetailsQueryProps): IUseAccountDetailsQueryResponse => {
  const { isSuccess: isAccountDetailsSuccess, data: accountDetailsResponse } = useQuery({
    queryKey,
    queryFn: accountDetailsApi,
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  });

  return {
    isAccountDetailsSuccess,
    accountDetailsResponse,
  };
};
