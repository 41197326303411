import * as React from 'react';
import { useContext } from 'react';
import { styled, Theme } from '@mui/material';

import { DteButton } from 'component-library/buttons/DteButton';
import { DialogContext } from 'component-library/dialog/DialogContext';
import { SchedulePaymentDateIcon } from 'component-library/icons/SchedulePaymentDateIcon';
import { SufficientPaymentFundsIcon } from 'component-library/icons/SufficientPaymentFundsIcon';
import { BulletedList } from 'component-library/lists/BulletedList';
import { Item } from 'component-library/lists/Item';

export const SecurityDepositDialogContent: React.FC = (): React.ReactElement => {
  const { setOpen } = useContext(DialogContext);

  const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  }));

  const NoticeContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  }));

  const Notice = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    gap: '20px', // between icon and details
    // Mobile
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    // Tablet & Desktop
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
    },
  }));

  const NoticeDetails = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    color: theme.palette.secondary.main,
  }));

  const NoticeTitle = styled('div')(() => ({}));

  const ButtonsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
  });

  const closeDialogHandler = (): void => {
    setOpen(false);
  };

  return (
    <Container id="security-deposit-dialog-content">
      <NoticeContainer>
        <Notice>
          <SchedulePaymentDateIcon />
          <NoticeDetails>
            <NoticeTitle id="first-notice-title">
              A Security Deposit may be required for residential customers when any of these conditions apply:
            </NoticeTitle>
            <BulletedList aria-labelledby="first-notice-title">
              <Item>Reconnect request when service is disconnected for nonpayment.</Item>
              <Item>Unpaid utility bill in your name within the last six years.</Item>
              <Item>
                Returned payment (check, credit card, bank card, etc.) for insufficient funds within 12 months not due
                to bank error.
              </Item>
            </BulletedList>
          </NoticeDetails>
        </Notice>
        <Notice>
          <SufficientPaymentFundsIcon />
          <NoticeDetails>
            <NoticeTitle id="second-notice-title">
              A Security Deposit is held for 12 months. 5% interest is applied twice during that time.
            </NoticeTitle>
            <BulletedList aria-labelledby="second-notice-title">
              <Item>
                Following 12 consecutive months of on-time payments, the deposit and interest will be applied directly
                to your account in the 13th month and will show as a credit on your bill.
              </Item>
              <Item>Late payments will extend the release of the deposit 12 more months.</Item>
              <Item>
                If you terminate service while the deposit is held, the deposit and interest are applied to your final
                bill. Any credit balance will be returned to you.
              </Item>
            </BulletedList>
          </NoticeDetails>
        </Notice>
      </NoticeContainer>
      <ButtonsContainer>
        <DteButton variant="primary" text="Close" onClick={closeDialogHandler} />
      </ButtonsContainer>
    </Container>
  );
};
