import { AxiosResponse } from 'axios';

import { apiPaths } from 'constants/apiPaths';
import { mockBaseCLockDunningLockDetailsResponse } from 'testing/mocks/data';

import { azureGet } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';

const dunningLockDetails = async (): Promise<AxiosResponse<IDunningLockDetailsResponse>> =>
  azureGet<IDunningLockDetailsResponse>(apiPaths.dunningLockDetails);

const localMockPaymentExtensionDetails = async (): Promise<AxiosResponse<IDunningLockDetailsResponse>> => {
  const localMock = await localMockApiResponse(mockBaseCLockDunningLockDetailsResponse); // switch to desired lock type
  return localMock;
};

export const dunningLockDetailsApi = apiOrLocalMock(dunningLockDetails, localMockPaymentExtensionDetails);
