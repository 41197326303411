import { ReactNode, useContext } from 'react';
import { Dialog, DialogContent, IconButton, PaperProps, styled, Theme, Typography } from '@mui/material';

import { DteCloseIcon } from '../icons/DteCloseIcon';

import { DialogContext } from './DialogContext';

type DialogProps = {
  title?: string;
  onCloseHandler?: () => void;
  content: ReactNode;
};

export const DteDialog = ({ title, onCloseHandler, content }: DialogProps): React.ReactElement => {
  const { open, setOpen } = useContext(DialogContext);

  // mui has defined styles that start at the container, and have to be more specific to overwrite
  const DialogContainer = styled(Dialog)(({ theme }: { theme: Theme }) => ({
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
      '& .MuiDialogContent-root': {
        backgroundColor: theme.palette.common.white,
        padding: '40px 20px',
        width: '100%',
      },
    },
  }));

  const dialogContainerPaperProps: PaperProps = {
    sx: (theme: Theme) => ({
      backgroundColor: 'transparent',
      borderRadius: '0',
      boxShadow: 'none',
      margin: '0',
      padding: '20px 24px 0 24px',
      verticalAlign: 'top',
      width: '100%',
      // SmallMobile
      [theme.breakpoints.down('mobile')]: {
        padding: '20px 8px 0 8px',
      },
      // Tablet & Desktop
      [theme.breakpoints.up('tablet')]: {
        width: '650px',
      },
    }),
  };

  const dialogContainerComponentProps = { backdrop: { style: { backgroundColor: '#F5F6F6F6' } } };

  const DialogTitleContainer = styled('div')({
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '24px',
    width: '100%',
  });

  const StyledDialogTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '28px',
  }));

  const StyledIconButton = styled(IconButton)({
    borderRadius: '0',
    padding: '4px',
    '&:focus-visible': { border: '1px solid #0072CE', padding: '3px' },
  });

  const dialogOnCloseHandler = (): void => {
    if (onCloseHandler) {
      onCloseHandler();
      setOpen(false);
    }
  };

  return (
    <DialogContainer
      open={open}
      onClose={dialogOnCloseHandler}
      PaperProps={dialogContainerPaperProps}
      componentsProps={dialogContainerComponentProps}
      aria-labelledby="modal-title"
      role="dialog"
      scroll="body"
    >
      <DialogTitleContainer>
        <StyledDialogTitle id="modal-title">{title}</StyledDialogTitle>
        <StyledIconButton aria-label="close dialog" onClick={dialogOnCloseHandler} disableRipple>
          <DteCloseIcon />
        </StyledIconButton>
      </DialogTitleContainer>
      <DialogContent data-testid="dialog-content">{content}</DialogContent>
    </DialogContainer>
  );
};
