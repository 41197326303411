import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { restoreDetailsApi } from 'api/restore-details-api/restore-details-api';
import { useUserState } from 'auth/authenticate';
import routes from 'constants/routes';
import { logger } from 'services';

interface IUseRestoreDetailsResult {
  isFetched?: boolean;
  isRestoreDetailsSuccess: boolean;
  restoreDetails: IRestoreDetails;
}

const useRestoreDetails = (): IUseRestoreDetailsResult => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();
  const {
    isFetched,
    isSuccess: isRestoreDetailsSuccess,
    data: restoreDetailsResponse,
  } = useQuery({
    enabled: isAuthenticated,
    onError: (error: unknown) => {
      logger.info('[API] Restore Details API Call Error! Navigating to Error Page', error);

      navigate(routes.error.reconnect);
    },
    onSuccess: (httpResponse: AxiosResponse<IRestoreDetailsResponse>) => {
      const { messageReply, restoreDetails } = httpResponse.data;

      if (messageReply.replyCode.toLowerCase() !== 'success') {
        logger.info('[API] Restore Details API Unsuccessful! Navigating to Error Page');

        navigate(routes.error.reconnect);
      }

      if (!restoreDetails) {
        logger.info('[API] Restore Details API Call Empty! Navigating to Error Page');

        navigate(routes.error.reconnect);
      } else {
        logger.info('[API] Restore Details API Call Success!', httpResponse);
      }
    },
    retry: 0,
    refetchOnWindowFocus: false,
    queryKey: ['restore-details'],
    queryFn: restoreDetailsApi,
  });

  if (restoreDetailsResponse?.data?.restoreDetails) {
    return {
      isFetched,
      isRestoreDetailsSuccess,
      restoreDetails: restoreDetailsResponse?.data.restoreDetails,
    };
  }

  return {
    isFetched,
    isRestoreDetailsSuccess: false,
    restoreDetails: {
      depositAmount: '',
      eligibilityFlag: false,
      reconnectionFee: '',
      totalAmount: '',
      totalPastDueAmount: '',
    },
  };
};

export default useRestoreDetails;
