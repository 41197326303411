export const paletteConfig = {
  primary: {
    main: '#2B2B2B',
  },
  secondary: {
    main: '#505357',
  },
  dteGrey: {
    light: '#F6F6F6',
    main: '#D9D9D9',
    dark: '#B1B3B3',
  },
  dteBlue: {
    light: '#F5F9FD',
    main: '#0072CE',
    dark: '#1E3575',
  },
  dteRed: {
    light: '#FEFAFB',
    main: '#FCE8EB',
    dark: '#E31937',
  },
  dteGreen: {
    dark: '#8FAD15',
    light: '#F6F9F6',
    main: '#1D661A',
  },
  dteBlack: {
    main: 'rgba(0, 0, 0, 0.87)',
  },
};
