import { Link } from 'react-router-dom';
import { styled, Theme } from '@mui/material';

const StyledLink = styled(Link)(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  color: theme.palette.dteBlue.main,
  display: 'inline-flex',
  gap: '10px',
  '&:hover': {
    color: theme.palette.dteBlue.dark,
  },
  '&:focus': {
    color: theme.palette.dteBlue.dark,
  },
}));

export const StyledCtaLink = styled(StyledLink)({
  textDecoration: 'none',
  '&:focus': {
    outlineColor: 'transparent',
    textDecoration: 'underline',
  },
});

export const StyledTextLink = styled(StyledLink)({
  cursor: 'pointer',
  textDecoration: 'underline',
});
