import { useEffect, useState } from 'react';

export const getQueryValues = (key: string): string | null => {
  const queryParameters = new URLSearchParams(window.location.search);

  return queryParameters.get(key);
};

const useGetUrlParam = (key: string): string | null => {
  const [data, setData] = useState<string | null>(null);

  useEffect(() => {
    setData(getQueryValues(key));
  }, [key]);

  return data;
};

export default useGetUrlParam;
