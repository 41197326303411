import { unitedWayPhoneNumber } from '../../collections-copy';

/** reference #23 * */
export const wLockViewEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'view',
  actionObject: 'medical hold documents lock details page',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #24 * */
export const wLockMilitaryTabOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'military tab',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #25 * */
export const wLockViewCurrentBillLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #26 * */
export const wLockCompleteFormOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'complete form',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #27 * */
export const wLockUploadFormOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'upload form',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #28 * */
export const wLockViewMilitaryLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view military',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #29 * */
export const wLockMedicalTabOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'medical tab',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #30 * */
export const wLockViewMedicalLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view medical',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #33 * */
export const wLockEnergyAssistanceProgramsLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'energy assistance programs',
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};

/** reference #34 * */
export const wLockUnitedWayLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: unitedWayPhoneNumber,
  actionDetail: 'w',
  authenticationStatus: 'authenticated',
};
