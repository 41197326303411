import * as React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material';

import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { DteHeader } from 'component-library/header/DteHeader';
import { DteStyledLogo } from 'component-library/logo/DteStyledLogo';
import { NewPageTemplate } from 'component-library/page-templates/NewPageTemplate';
import { DteTabs } from 'component-library/tabs/DteTabs';
import { TabsProvider } from 'component-library/tabs/TabsContext';

import { MedicalPanel } from './medical-panel/MedicalPanel';
import { MilitaryPanel } from './military-panel/MilitaryPanel';
import { useWLockViewModel } from './useWLockViewModel';

export const WLockView = (): React.ReactElement => {
  const theme = useTheme();
  const {
    eligibilityResponse,
    onPageViewHandler,
    medicalTabOnClickHandler,
    militaryTabOnClickHandler,
    viewCurrentBillOnClickHandler,
    completeFormOnClickHandler,
    uploadFormOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
    viewMedicalLinkOnClickHandler,
    viewMilitaryLinkOnClickHandler,
  } = useWLockViewModel();
  const eligibility = eligibilityResponse?.data;
  const lockEndDate: string = eligibility?.dunningLockDetails
    ? dayjsDate(eligibility.dunningLockDetails[0].endDate).format('dddd, MMMM D, YYYY')
    : '';
  const showContent = eligibility?.eligible.dunningLock === 'Y';

  useEffect(() => {
    if (showContent) {
      onPageViewHandler();
    }
  }, [showContent, onPageViewHandler]);

  const medicalAndMilitaryPanelProps = [
    {
      tabLabel: 'Medical',
      tabAriaId: 'medical-tab',
      tabPanelAriaId: 'medical-panel',
      tabPanelPresentation: (
        <MedicalPanel
          lockEndDate={lockEndDate}
          viewCurrentBillOnClickHandler={viewCurrentBillOnClickHandler}
          energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
          unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          completeFormOnClickHandler={completeFormOnClickHandler}
          uploadFormOnClickHandler={uploadFormOnClickHandler}
          viewMilitaryLinkOnClickHandler={viewMilitaryLinkOnClickHandler}
        />
      ),
    },
    {
      tabLabel: 'Military',
      tabAriaId: 'military-tab',
      tabPanelAriaId: 'military-panel',
      tabPanelPresentation: (
        <MilitaryPanel
          lockEndDate={lockEndDate}
          viewCurrentBillOnClickHandler={viewCurrentBillOnClickHandler}
          energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
          unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          viewMedicalLinkOnClickHandler={viewMedicalLinkOnClickHandler}
        />
      ),
    },
  ];

  const header = <DteHeader title="Service Protection" logo={<DteStyledLogo />} />;

  const dteTabChangeHandler = (muiTabIndex: number): void => {
    const medicalTab = 0;
    const militaryTab = 1;
    if (muiTabIndex === medicalTab) {
      medicalTabOnClickHandler();
    }
    if (muiTabIndex === militaryTab) {
      militaryTabOnClickHandler();
    }
  };

  const content = (
    <TabsProvider
      initialTab={0}
      // eslint-disable-next-line react/no-children-prop
      children={<DteTabs tabAndPanelProps={medicalAndMilitaryPanelProps} dteTabChangeHandler={dteTabChangeHandler} />}
    />
  );

  const mainContainerSx = {
    padding: '0px 0px',
    [theme.breakpoints.down('mobile')]: {
      padding: '0px 0px',
    },
  };

  return (
    <NewPageTemplate
      titleTag="Medical Protection Review | DTE Energy"
      pageHeader={header}
      showContent={showContent}
      content={content}
      mainContainerSx={mainContainerSx}
    />
  );
};
