import { Button, ButtonProps, Typography, useTheme } from '@mui/material';

import { useResponsiveSx } from '../../theme/breakpoints/responsiveHooks';

type MuiButtonProps = Pick<ButtonProps, 'href' | 'onClick'>;

interface DteButtonProps extends MuiButtonProps {
  dataTestId?: string;
  name: string;
}

export const DtePrimaryButton = ({ dataTestId, name, onClick }: DteButtonProps): React.ReactElement => {
  const theme = useTheme();
  const primaryButtonSx = {
    background: theme.palette.dteBlue.main,
    borderRadius: 'unset',
    boxShadow: 'unset',
    color: theme.palette.common.white,
    height: '40px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      background: theme.palette.dteBlue.dark,
      boxShadow: 'unset',
    },
    '&:focus': {
      background: theme.palette.dteBlue.dark,
      color: theme.palette.common.white,
      boxShadow: 'unset',
    },
  };
  const mobileStyleSx = {
    maxWidth: '327px',
  };
  const tabletAndDesktopStyleSx = {
    maxWidth: '300px',
  };

  const mobileStyling = { ...primaryButtonSx, ...mobileStyleSx };
  const tabletAndDesktopStyling = { ...primaryButtonSx, ...tabletAndDesktopStyleSx };
  const responsiveStyling = useResponsiveSx(mobileStyling, tabletAndDesktopStyling, tabletAndDesktopStyling);

  return (
    <Button
      data-testid={`${dataTestId}-button`}
      disableFocusRipple
      id="dtePrimaryButton"
      onClick={onClick}
      sx={responsiveStyling}
      type="button"
    >
      <Typography data-testid={`${dataTestId}-text`} fontWeight={600} variant="body2">
        {name}
      </Typography>
    </Button>
  );
};
