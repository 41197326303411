import { FC, SyntheticEvent, useState } from 'react';
import { Box, ClickAwayListener, IconButton, SxProps, Tooltip, TooltipProps, useTheme } from '@mui/material';

import { useBreakpoints } from '../../theme/breakpoints/useBreakpoints';

import DteTooltipBubble from './DteTooltipBubble';
import DteTooltipClickOrHoverIcon from './DteTooltipClickOrHoverIcon';
import DteTooltipDefaultIcon from './DteTooltipDefaultIcon';
import DteTooltipKeyboardFocusIcon from './DteTooltipKeyboardFocusIcon';

type Props = {
  bubbleDataTestIdPrefix?: string;
  dataTestId?: string;
  placement: TooltipProps['placement'];
  popoverText: string;
  popoverTextTitle?: string;
  title?: string;
};

const DteTooltip: FC<Props> = ({
  bubbleDataTestIdPrefix,
  dataTestId = 'tooltip',
  placement,
  popoverText,
  popoverTextTitle,
  title = 'Tooltip Button',
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();
  const sx: Record<string, SxProps> = {
    buttonIcon: {
      padding: '6px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    popper: {
      display: 'inline',
      maxWidth: '0px', // added to enforce consistent behavior between browsers, Safari's width renders differently
      '.MuiTooltip-tooltipPlacementRight': {
        fontSize: '19.73px',
        left: '-22px',
        marginLeft: '14px !important',
      },
      '.MuiTooltip-tooltipPlacementTop': {
        fontSize: '19.73px',
        left: '-254px',
        marginBottom: '14px !important',
        marginTop: '-14px !important',
        top: '8px',
        '& .MuiTooltip-arrow': {
          left: '104px !important',
        },
        [theme.breakpoints.down(390)]: {
          left: '-242px',
        }, // SmallMobile
      },
    },
    tooltip: {
      backgroundColor: theme.palette.dteBlue.light,
      border: `2px solid ${theme.palette.dteBlue.main}`,
      borderRadius: '5px',
      color: theme.palette.primary.main,
      padding: '20px',
      whiteSpace: 'normal', // enforce whitespace from being overwritten at RadioLabelMinAmount
      width: '325px',
      '.MuiTooltip-arrow': {
        '&::before': {
          borderRadius: '2px',
          border: `2px solid ${theme.palette.dteBlue.main}`,
          backgroundColor: theme.palette.dteBlue.light,
        },
      },
      [theme.breakpoints.down(390)]: {
        maxWidth: '275px',
      }, // SmallMobile
    },
    tooltipContainer: {
      display: 'inline',
    },
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [isMouseHovered, setIsMouseHovered] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleTooltipClose = (): void => {
    setIsTooltipOpen(false);
  };

  const toggleTooltip = (event: SyntheticEvent): void => {
    if (event.nativeEvent['pointerType' as keyof typeof event.nativeEvent] === 'mouse') {
      event.preventDefault();
    }
    setIsTooltipOpen(!isTooltipOpen);
  };

  const toggleTooltipWithKeyboard = (event: SyntheticEvent): void => {
    if (event['key' as keyof typeof event] === 'Enter') {
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  const showHoverIcon = (): void => {
    // condition is a quick fix for a bug that is triggering showHoverIcon when
    // clicking close to but not on the icon, when in mobile view.
    if (!isMobile) {
      setIsMouseHovered(true);
    }
  };

  const hideHoverIcon = (): void => {
    setIsMouseHovered(false);
    setIsFocused(false);
  };

  const showKeyboardFocusedIcon = (): void => {
    setIsFocused(true);
  };

  const hideKeyboardFocusedIcon = (): void => {
    setIsFocused(false);
  };

  const getTooltipIcon = (): React.ReactElement => {
    if (isTooltipOpen || isMouseHovered) {
      return <DteTooltipClickOrHoverIcon />;
    }
    if (isFocused) {
      return <DteTooltipKeyboardFocusIcon />;
    }
    return <DteTooltipDefaultIcon />;
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Tooltip
          arrow
          componentsProps={{
            tooltip: {
              sx: sx.tooltip,
            },
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={isTooltipOpen}
          placement={placement}
          PopperProps={{
            disablePortal: true,
            sx: sx.popper,
            keepMounted: true,
          }}
          sx={sx.tooltipContainer}
          title={
            <DteTooltipBubble
              dataTestIdPrefix={bubbleDataTestIdPrefix}
              handleTooltipClose={handleTooltipClose}
              popoverText={popoverText}
              popoverTextTitle={popoverTextTitle}
            />
          }
        >
          <Box>
            <IconButton
              data-testid={dataTestId}
              onBlur={hideKeyboardFocusedIcon}
              onFocus={showKeyboardFocusedIcon}
              onKeyDown={(event: SyntheticEvent): void => toggleTooltipWithKeyboard(event)}
              onMouseDown={(event: SyntheticEvent): void => toggleTooltip(event)}
              onMouseEnter={showHoverIcon}
              onMouseLeave={hideHoverIcon}
              sx={sx.buttonIcon}
              title={title}
              value={`${dataTestId}-button-root`}
            >
              {getTooltipIcon()}
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default DteTooltip;
