import { AxiosResponse } from 'axios';

import { signInApiPath } from '../../urls-and-paths';
import { axiosPost } from '../adapter/axios-adapter';

export interface SignInRequest {
  username: string;
  password: string;
}

export interface SignInResponse {
  user: {
    uid: string;
    id: string;
    profileName: string;
    firstName: string;
    lastName: string;
    displayName: string;
    type: string;
    numberOfActiveAccounts: string;
  };
  accessToken: {
    webSecurityToken: string;
    webSecurityTokenExpiry: string;
  };
  gigyaToken: {
    name: string;
    value: string;
  };
}

export const signInApi = async (body: SignInRequest): Promise<AxiosResponse<SignInResponse>> => {
  const baseURL = process.env.REACT_APP_SIGNIN_BASE ? process.env.REACT_APP_SIGNIN_BASE : '';
  return axiosPost<SignInResponse>(signInApiPath, body, { baseURL });
};
