const getOptionalGtmEvent = (params: GtmEventProps): IGtmEventProps => {
  const {
    number,
    transactionComplete,
    transactionStepName,
    transactionStepNumber,
    transactionType,
    paymentInfo,
    userType,
  } = params;

  const optionalEvent: IGtmEventProps = {};

  if (number) {
    optionalEvent.number = number;
  }

  if (transactionComplete) {
    optionalEvent.transaction_complete = transactionComplete;
  }

  if (transactionStepName) {
    optionalEvent.transaction_step_name = transactionStepName;
  }

  if (transactionStepNumber) {
    optionalEvent.transaction_step_number = transactionStepNumber;
  }

  if (transactionType) {
    optionalEvent.transaction_type = transactionType;
  }

  if (transactionType === 'payment agreement') {
    optionalEvent.user_type = userType || 'web';
  }

  if (userType) {
    optionalEvent.user_type = userType;
  }

  if (paymentInfo) {
    optionalEvent.payment_info = paymentInfo;
  }

  return optionalEvent;
};

export default getOptionalGtmEvent;
