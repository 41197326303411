import { styled, Theme } from '@mui/material';

export const BulletedList = styled('ul')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  listStyleType: 'disc',
  margin: '0px',
  paddingLeft: '25px',
  'li::marker': {
    fontSize: '12px',
  },
  [theme.breakpoints.down('tablet')]: {
    gap: '8px',
  },
  [theme.breakpoints.up('tablet')]: {
    gap: '4px',
  },
}));
