import { AxiosResponse } from 'axios';

import { apiPaths } from '../../constants/apiPaths';
import { azureGet } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

const paymentAgreementDetails = async (): Promise<AxiosResponse<IPaymentAgreementDetailsResponse>> =>
  azureGet<IPaymentAgreementDetailsResponse>(apiPaths.paymentAgreementDetailsApiPath);

const localMockPaymentAgreementDetails = async (): Promise<AxiosResponse<IPaymentAgreementDetailsResponse>> => {
  const localMock = await localMockApiResponse(Mocks.paymentAgreementDetailsResponseBody); // *** Need to manually adjust this to return the response body of an eligible type you desire - add to mocks and change to return to that object
  return localMock;
};

export const paymentAgreementDetailsApi = apiOrLocalMock(paymentAgreementDetails, localMockPaymentAgreementDetails);
