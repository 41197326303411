import { useState } from 'react';
import { Button, SxProps } from '@mui/material';

import { logger } from 'services';

import { CopySnackbar, SnackbarState } from './CopySnackbar';

const testUserPassword = 'TEST1234';

export const CopyTestPassword = (): React.ReactElement => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    clipboardValue: '',
    isSnackbarOpen: false,
    horizontal: 'center',
    vertical: 'top',
  });

  const sx: Record<string, SxProps> = {
    formContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '5px',
      margin: '15px',
    },
  };

  const handleCopyToClipboard = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(testUserPassword);
      setSnackbarState({ ...snackbarState, isSnackbarOpen: true, clipboardValue: testUserPassword });
    } catch (err) {
      logger.info('Failed to copy to clipboard: ', err);
    }
  };

  const handleCloseSnackbar = (): void => {
    setSnackbarState({ ...snackbarState, isSnackbarOpen: false });
  };

  return (
    <>
      <Button sx={sx.formContainer} onClick={handleCopyToClipboard}>
        Copy Password
      </Button>
      <CopySnackbar snackbarState={snackbarState} handleCloseSnackbar={handleCloseSnackbar} />
    </>
  );
};
