import { routes } from '../../constants';
import { lambdaPaymentAgreementUrl, paymentCenterPaymentAgreementUrl } from '../../urls-and-paths';
import PaymentCenterTestLambdaSubmit from '../types/PaymentCenterTestLambdaSubmit';

const onSubmitPaymentCenterTestLambda = async (data: PaymentCenterTestLambdaSubmit): Promise<void> => {
  const { accounts, amount } = data;

  const formData = new URLSearchParams();

  formData.append('accounts', accounts);
  formData.append('paymentAgreementDetailsUrl', window.location.href);
  formData.append('paymentAmount', amount);
  formData.append('redirectUrl', `https://${window.location.hostname}${routes.paymentAgreement.enrolled}`);

  // use useMutation when implementing in app
  return (
    fetch(lambdaPaymentAgreementUrl, {
      body: formData.toString(),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      method: 'POST',
      mode: 'no-cors',
      redirect: 'follow',
      // eslint-disable-next-line @typescript-eslint/typedef
    })
      .then(() => {
        window.location.href = paymentCenterPaymentAgreementUrl;
      })
      // eslint-disable-next-line @typescript-eslint/typedef
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Promise Error:', error);
      })
  );
};

export default onSubmitPaymentCenterTestLambda;
