import { styled, Theme, Typography } from '@mui/material';

import { googleTagManager } from 'services';

import ViewCurrentBillLink from '../../component-library/links/ViewCurrentBillLink';

import messages from './messages';

const SubTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: 'normal',
  [theme.breakpoints.down(755)]: {
    textAlign: 'center',
  },
  [theme.breakpoints.up(755)]: {
    textAlign: 'left',
  },
}));

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Open Sans',
  fontSize: '28px',
  fontWeight: '400',
  lineHeight: 'normal',
  textAlign: 'center',
}));

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const HowPaymentAgreementWorksDescription: React.FC = (): React.ReactElement => (
  <TitleContainer>
    <ViewCurrentBillLink
      onClickHandler={(): void => {
        googleTagManager.trackButtonClick({
          actionDetail: 'return to current bill',
          actionObject: 'view current bill',
          authenticationStatus: true,
          transactionStepName: 'how it works',
          transactionType: 'payment agreement',
          userType: 'web',
        });
      }}
    />
    <Title data-testid="how-payment-agreement-works-heading">{messages.howPaymentAgreementWorksHeading}</Title>
    <SubTitle data-testid="how-payment-agreement-works-description">
      {messages.howPaymentAgreementWorksDecription}
    </SubTitle>
  </TitleContainer>
);

export default HowPaymentAgreementWorksDescription;
