import { createContext, useRef } from 'react';

export const EnrollmentContext = createContext<IEnrollmentInformationContextProps>(
  {} as IEnrollmentInformationContextProps,
);

const initialEnrollmentInformation: IEnrollmentInformation = {
  accountNumber: '',
  downPaymentAmount: '',
  downPaymentDueDate: '',
  enrollmentDate: '',
  installmentAmount: '',
  installmentDuration: '',
  userInformation: {
    name: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipcode: '',
    },
  },
};

export const EnrollmentProvider = (props: EnrollmentInformationProviderProps): React.ReactElement => {
  const { accountDetails, paymentAgreementDetails, children } = props;

  if (accountDetails && paymentAgreementDetails) {
    const { accountNumber, serviceAddresses } = accountDetails.accounts[0];
    const { cashOnly, paymentOptions, downPaymentAmount, downPaymentDueDate } = paymentAgreementDetails;

    const refWithIntegratedData = useRef({
      accountNumber,
      downPaymentAmount,
      downPaymentDueDate: downPaymentDueDate || '',
      downPaymentMethod: cashOnly === 'Y' ? 'CashOnly' : 'SchedulePayment',
      enrollmentDate: '',
      installmentAmount: paymentOptions?.length ? paymentOptions[0].installmentAmount : '',
      installmentDuration: paymentOptions?.length ? paymentOptions[0].installmentDuration : '',
      userInformation: {
        name: accountDetails.customerName,
        address: serviceAddresses[0],
      },
    });

    return (
      <EnrollmentContext.Provider value={{ enrollmentInformation: refWithIntegratedData.current }}>
        {children}
      </EnrollmentContext.Provider>
    );
  }
  const refWithBlankData = useRef(initialEnrollmentInformation);

  return (
    <EnrollmentContext.Provider value={{ enrollmentInformation: refWithBlankData.current }}>
      {children}
    </EnrollmentContext.Provider>
  );
};
