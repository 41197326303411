import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const ProofOfHouseholdMembershipIcon = ({
  containerSx,
  iconWidth = '82',
  iconHeight = '54',
}: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="proof-of-household-membership-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 82 54" fill="none">
      <path
        d="M13.4444 8.39223C13.4444 8.9105 13.2725 9.31715 12.9288 9.61216C12.585 9.90718 12.1108 10.0547 11.5062 10.0547C10.9016 10.0547 10.4067 9.95236 10.0215 9.74771V8.84671C10.2653 8.97163 10.5237 9.06997 10.7968 9.14173C11.0722 9.21349 11.3282 9.24937 11.5647 9.24937C11.9109 9.24937 12.1657 9.17761 12.329 9.03409C12.4948 8.89057 12.5777 8.69788 12.5777 8.45602C12.5777 8.23808 12.5021 8.05336 12.351 7.90186C12.1998 7.75037 11.8878 7.57097 11.4148 7.36366C10.9272 7.14838 10.5834 6.90253 10.3835 6.62612C10.1836 6.3497 10.0837 6.01748 10.0837 5.62944C10.0837 5.14306 10.2421 4.76034 10.5591 4.48127C10.876 4.2022 11.3014 4.06266 11.8353 4.06266C12.3473 4.06266 12.8568 4.18492 13.3639 4.42944L13.086 5.20685C12.6106 4.98891 12.1864 4.87994 11.8134 4.87994C11.5306 4.87994 11.316 4.94771 11.1698 5.08326C11.0235 5.21615 10.9504 5.39289 10.9504 5.61349C10.9504 5.76499 10.9796 5.89522 11.0381 6.00419C11.0966 6.1105 11.1929 6.2115 11.327 6.30718C11.4611 6.40286 11.7025 6.52911 12.0511 6.68592C12.4436 6.86399 12.7313 7.0301 12.9141 7.18426C13.097 7.33841 13.2311 7.51249 13.3164 7.70651C13.4017 7.90053 13.4444 8.12911 13.4444 8.39223Z"
        fill="#1E3575"
      />
      <path
        d="M19.226 7.05269C19.226 8.00153 19.0078 8.73907 18.5714 9.26532C18.1375 9.79156 17.5219 10.0547 16.7247 10.0547C15.9177 10.0547 15.2973 9.79422 14.8633 9.27329C14.4318 8.7497 14.216 8.00685 14.216 7.04472C14.216 6.08259 14.433 5.34372 14.8669 4.82811C15.3033 4.31249 15.925 4.05469 16.732 4.05469C17.5268 4.05469 18.1411 4.31648 18.5751 4.84007C19.0091 5.36366 19.226 6.1012 19.226 7.05269ZM15.1449 7.05269C15.1449 7.7703 15.2777 8.31515 15.5435 8.68725C15.8092 9.05668 16.203 9.2414 16.7247 9.2414C17.244 9.2414 17.6353 9.05801 17.8986 8.69123C18.1643 8.32446 18.2972 7.77828 18.2972 7.05269C18.2972 6.33774 18.1655 5.79555 17.9022 5.42612C17.6414 5.05668 17.2513 4.87196 16.732 4.87196C16.2078 4.87196 15.8117 5.05668 15.5435 5.42612C15.2777 5.79555 15.1449 6.33774 15.1449 7.05269Z"
        fill="#1E3575"
      />
      <path
        d="M22.6489 4.87994C22.1467 4.87994 21.7518 5.07396 21.4641 5.462C21.1764 5.85004 21.0326 6.38558 21.0326 7.06864C21.0326 7.78359 21.1703 8.32446 21.4458 8.69123C21.7237 9.05801 22.1248 9.2414 22.6489 9.2414C22.8757 9.2414 23.0951 9.21748 23.3072 9.16964C23.5193 9.11914 23.7399 9.05535 23.9691 8.97828V9.79555C23.5498 9.96831 23.0744 10.0547 22.5429 10.0547C21.7603 10.0547 21.1593 9.79688 20.74 9.28127C20.3207 8.76299 20.111 8.02279 20.111 7.06067C20.111 6.45469 20.2122 5.92446 20.4145 5.46997C20.6193 5.01548 20.9143 4.66731 21.2995 4.42545C21.6847 4.18359 22.137 4.06266 22.6563 4.06266C23.2024 4.06266 23.707 4.18758 24.1702 4.43741L23.8557 5.23077C23.6753 5.13774 23.484 5.05668 23.2816 4.98758C23.0817 4.91582 22.8708 4.87994 22.6489 4.87994Z"
        fill="#1E3575"
      />
      <path d="M25.121 9.97495V4.14638H25.9951V9.97495H25.121Z" fill="#1E3575" />
      <path
        d="M30.7272 9.97495L30.1932 8.35635H28.149L27.6261 9.97495H26.7045L28.7049 4.12246H29.6557L31.656 9.97495H30.7272ZM29.9629 7.53509L29.4619 5.94838C29.4253 5.84206 29.3741 5.67462 29.3083 5.44605C29.2449 5.21748 29.201 5.05004 29.1766 4.94372C29.1108 5.27063 29.0145 5.62811 28.8877 6.01615L28.405 7.53509H29.9629Z"
        fill="#1E3575"
      />
      <path d="M32.3618 9.97495V4.14638H33.2358V9.15768H35.4995V9.97495H32.3618Z" fill="#1E3575" />
      <path
        d="M41.4493 8.39223C41.4493 8.9105 41.2775 9.31715 40.9337 9.61216C40.59 9.90718 40.1158 10.0547 39.5112 10.0547C38.9065 10.0547 38.4116 9.95236 38.0264 9.74771V8.84671C38.2702 8.97163 38.5287 9.06997 38.8017 9.14173C39.0772 9.21349 39.3332 9.24937 39.5697 9.24937C39.9159 9.24937 40.1706 9.17761 40.334 9.03409C40.4998 8.89057 40.5827 8.69788 40.5827 8.45602C40.5827 8.23808 40.5071 8.05336 40.3559 7.90186C40.2048 7.75037 39.8927 7.57097 39.4197 7.36366C38.9321 7.14838 38.5884 6.90253 38.3885 6.62612C38.1886 6.3497 38.0886 6.01748 38.0886 5.62944C38.0886 5.14306 38.2471 4.76034 38.564 4.48127C38.8809 4.2022 39.3064 4.06266 39.8403 4.06266C40.3523 4.06266 40.8618 4.18492 41.3689 4.42944L41.091 5.20685C40.6156 4.98891 40.1914 4.87994 39.8183 4.87994C39.5355 4.87994 39.321 4.94771 39.1747 5.08326C39.0284 5.21615 38.9553 5.39289 38.9553 5.61349C38.9553 5.76499 38.9846 5.89522 39.0431 6.00419C39.1016 6.1105 39.1979 6.2115 39.332 6.30718C39.4661 6.40286 39.7074 6.52911 40.056 6.68592C40.4486 6.86399 40.7362 7.0301 40.9191 7.18426C41.1019 7.33841 41.236 7.51249 41.3214 7.70651C41.4067 7.90053 41.4493 8.12911 41.4493 8.39223Z"
        fill="#1E3575"
      />
      <path
        d="M45.5086 9.97495H42.4843V4.14638H45.5086V4.9517H43.3583V6.53841H45.3733V7.33575H43.3583V9.16565H45.5086V9.97495Z"
        fill="#1E3575"
      />
      <path
        d="M48.9424 4.87994C48.4402 4.87994 48.0453 5.07396 47.7576 5.462C47.4699 5.85004 47.3261 6.38558 47.3261 7.06864C47.3261 7.78359 47.4638 8.32446 47.7393 8.69123C48.0172 9.05801 48.4183 9.2414 48.9424 9.2414C49.1692 9.2414 49.3886 9.21748 49.6007 9.16964C49.8128 9.11914 50.0334 9.05535 50.2626 8.97828V9.79555C49.8433 9.96831 49.3679 10.0547 48.8364 10.0547C48.0538 10.0547 47.4528 9.79688 47.0335 9.28127C46.6142 8.76299 46.4045 8.02279 46.4045 7.06067C46.4045 6.45469 46.5057 5.92446 46.708 5.46997C46.9128 5.01548 47.2078 4.66731 47.593 4.42545C47.9782 4.18359 48.4305 4.06266 48.9498 4.06266C49.4959 4.06266 50.0005 4.18758 50.4637 4.43741L50.1492 5.23077C49.9688 5.13774 49.7775 5.05668 49.5751 4.98758C49.3752 4.91582 49.1643 4.87994 48.9424 4.87994Z"
        fill="#1E3575"
      />
      <path
        d="M55.6054 4.14638V7.91781C55.6054 8.34838 55.5201 8.72578 55.3494 9.05004C55.1812 9.37163 54.9362 9.62014 54.6144 9.79555C54.295 9.96831 53.9122 10.0547 53.4661 10.0547C52.803 10.0547 52.2873 9.86333 51.9192 9.4806C51.5511 9.09788 51.367 8.57163 51.367 7.90186V4.14638H52.2447V7.83409C52.2447 8.31515 52.3471 8.66997 52.5519 8.89854C52.7566 9.12711 53.0711 9.2414 53.4954 9.2414C54.3194 9.2414 54.7314 8.76964 54.7314 7.82612V4.14638H55.6054Z"
        fill="#1E3575"
      />
      <path
        d="M57.8471 6.85735H58.4542C58.8613 6.85735 59.1563 6.77495 59.3392 6.61017C59.522 6.44539 59.6134 6.20087 59.6134 5.87661C59.6134 5.54705 59.5147 5.3105 59.3172 5.16698C59.1198 5.02346 58.8223 4.9517 58.4249 4.9517H57.8471V6.85735ZM57.8471 7.64671V9.97495H56.9731V4.14638H58.4834C59.1734 4.14638 59.6841 4.28725 60.0157 4.56897C60.3473 4.8507 60.5131 5.27595 60.5131 5.84472C60.5131 6.5703 60.1669 7.08725 59.4745 7.39555L60.9848 9.97495H59.9901L58.7102 7.64671H57.8471Z"
        fill="#1E3575"
      />
      <path d="M61.7637 9.97495V4.14638H62.6377V9.97495H61.7637Z" fill="#1E3575" />
      <path d="M65.8997 9.97495H65.0257V4.96366H63.4532V4.14638H67.4722V4.96366H65.8997V9.97495Z" fill="#1E3575" />
      <path
        d="M69.8054 6.80552L71.0707 4.14638H72.0215L70.2405 7.71449V9.97495H69.3629V7.74638L67.5893 4.14638H68.5401L69.8054 6.80552Z"
        fill="#1E3575"
      />
      <path d="M17 34.0547H32.4615" stroke="#1E3575" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <line
        x1="35.8457"
        y1="34.0547"
        x2="45.6534"
        y2="34.0547"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="49.0381"
        y1="34.0547"
        x2="64.4996"
        y2="34.0547"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="1.05469"
        width="80"
        height="52"
        rx="3"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 41.5547L28.1936 40.071C28.606 39.5584 29.3925 39.5768 29.7804 40.1081L30.7469 41.4317C31.112 41.9318 31.8391 41.9833 32.271 41.5396L33.2033 40.582C33.5798 40.1952 34.1954 40.1771 34.5941 40.5411L36.9443 42.6869C37.2871 43 37.8004 43.0359 38.1835 42.7735L40.5263 41.1692C40.9094 40.9068 41.4227 40.9427 41.7656 41.2558L44.2457 43.5202C44.5886 43.8333 45.1018 43.8692 45.485 43.6069L48.2263 41.7296C48.3927 41.6157 48.5896 41.5547 48.7913 41.5547H57"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="1.75"
        y1="49.3047"
        x2="80.25"
        y2="49.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17.0547C34.2694 11.6555 48.2821 11.7874 73 17.0547"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="6.75"
        y1="25.5391"
        x2="6.75"
        y2="43.4508"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="3.75"
        y1="25.5391"
        x2="3.75"
        y2="43.4508"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="47.1133"
        width="12"
        height="1.94118"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="21.8789"
        width="9"
        height="25.2353"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16.0547H13C12.7612 18.6997 12.3101 20.0391 10.2903 21.8782H1V16.0547Z"
        fill="#F5F5F5"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="18.6618"
        y2="-0.75"
        transform="matrix(-3.5892e-08 1 1 5.32342e-08 76 24.7891)"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="18.6618"
        y2="-0.75"
        transform="matrix(-3.88831e-08 1 1 4.91393e-08 79 24.7891)"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        width="12"
        height="1.94118"
        transform="matrix(-1 0 0 1 81 47.1133)"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        width="9"
        height="25.2353"
        transform="matrix(-1 0 0 1 81 21.8789)"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81 16.0547H69C69.2388 18.6997 69.6899 20.0391 71.7097 21.8782H81V16.0547Z"
        fill="#F5F5F5"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="24.75"
        y1="24.8047"
        x2="24.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="37.75"
        y1="24.8047"
        x2="37.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="35.25"
        y1="26.8047"
        x2="33.75"
        y2="26.8047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="45.25"
        y1="26.8047"
        x2="43.75"
        y2="26.8047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="48.75"
        y1="24.8047"
        x2="48.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="54.75"
        y1="24.8047"
        x2="54.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="27.75"
        y1="24.8047"
        x2="27.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="40.75"
        y1="24.8047"
        x2="40.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="51.75"
        y1="24.8047"
        x2="51.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="57.75"
        y1="24.8047"
        x2="57.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="30.75"
        y1="24.8047"
        x2="30.75"
        y2="28.3047"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
);
