import React from 'react';
import { NumericFormat } from 'react-number-format';

export const ReadOnlyCurrencyMask = (value: number): React.ReactElement => (
  <NumericFormat
    decimalScale={2}
    displayType="text"
    prefix="$"
    thousandsGroupStyle="thousand"
    thousandSeparator=","
    value={value.toFixed(2)}
  />
);
