import { urls } from 'constants/urls';
import { googleTagManager } from 'services';

import gtmPeEligibleBaseEvent from './gtmPeEligibleBaseEvent';

const onCurrentBillButtonClick = (): void => {
  googleTagManager.trackButtonClick({
    ...gtmPeEligibleBaseEvent,
    actionDetail: 'pe eligible details',
    actionObject: 'view current bill',
    authenticationStatus: true,
  });
  window.location.href = urls.currentBillUrl;
};

export default onCurrentBillButtonClick;
