import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { unitedWayPhoneNumber as defaultUnitedWayPhoneNumber } from '../../collections-copy';
import UnitedWayIcon from '../icons/UnitedWayIcon';
import { DteTextLink } from '../links/DteTextLink';

type AdditionalAssistanceMessageProps = {
  unitedWayPhoneNumber?: string;
  energyAssistanceProgramsLinkOnClickHandler: () => void;
  unitedWayLinkOnClickHandler: () => void;
};

const MessageContent = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  paddingLeft: '8px',
  ...theme.typography.body1,
}));

const PhoneNumberWordWrap = styled('span')({
  overflowWrap: 'break-word',
  whiteSpace: 'nowrap',
});

export const DefaultAssistanceMessage = ({
  unitedWayPhoneNumber = defaultUnitedWayPhoneNumber,
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
}: AdditionalAssistanceMessageProps): React.ReactElement => (
  <MessageContent data-testid="additional-assistance-banner-content">
    {`Please review `}
    <DteTextLink
      href="https://www.dteenergy.com/us/en/residential/billing-and-payments/energy-assistance/general-assistance.html"
      name="DTE’s energy assistance programs"
      variant="body1"
      onClick={energyAssistanceProgramsLinkOnClickHandler}
    />
    {` and consider calling United Way at `}
    <PhoneNumberWordWrap>
      <DteTextLink
        href={`tel:${unitedWayPhoneNumber}`}
        name={unitedWayPhoneNumber}
        variant="body1"
        onClick={unitedWayLinkOnClickHandler}
      />
    </PhoneNumberWordWrap>
    {` for help in challenging times.`}
  </MessageContent>
);

export const AgencyPaymentAssistanceMessage = ({
  unitedWayPhoneNumber = defaultUnitedWayPhoneNumber,
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
}: AdditionalAssistanceMessageProps): React.ReactElement => (
  <MessageContent data-testid="additional-assistance-banner-content">
    {`If you need additional assistance, please review `}
    <DteTextLink
      href="https://www.dteenergy.com/us/en/residential/billing-and-payments/energy-assistance/general-assistance.html"
      name="DTE’s energy assistance programs"
      variant="body1"
      onClick={energyAssistanceProgramsLinkOnClickHandler}
    />
    {` and consider calling United Way at `}
    <PhoneNumberWordWrap>
      <DteTextLink
        href={`tel:${unitedWayPhoneNumber}`}
        name={unitedWayPhoneNumber}
        variant="body1"
        onClick={unitedWayLinkOnClickHandler}
      />
    </PhoneNumberWordWrap>
    {` for help in challenging times.`}
  </MessageContent>
);

type AdditionalAssistanceBannerProps = {
  message: JSX.Element;
};

export const AdditionalAssistanceBanner = ({ message }: AdditionalAssistanceBannerProps): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    additionalAssistanceBanner: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.dteGrey.light,
      padding: '20px 24px',
    },
  };

  return (
    <Box data-testid="additional-assistance-banner-container" sx={sx.additionalAssistanceBanner}>
      <UnitedWayIcon />
      {message}
    </Box>
  );
};
