import { styled, Theme } from '@mui/material';

import { CombinedEligibilityCheckResponse } from '../../../api/eligibility-api/EligibilityCheckResponse';
import {
  AdditionalAssistanceBanner,
  DefaultAssistanceMessage,
} from '../../../component-library/banners/AdditionalAssistanceBanner';
import { SuccessBanner } from '../../../component-library/banners/SuccessBanner';
import { dayjsDate } from '../../../component-library/dates/dayjsApStyleAbbreviateMonths';
import { LockStatusContent } from '../../LockStatusContent';
import { AgencyContactInformation } from '../agency-contact-information/AgencyContactInformation';
import { DefaultAssistanceInformation } from '../assistance-information/DefaultAssistanceInformation';
import { DefaultBottomContent } from '../bottom-content/DefaultBottomContent';

const TopContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const AssistanceInformationContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px 0',
  alignSelf: 'stretch',
  gap: '40px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '40px 24px',
  },
}));

type DefaultMainContentProps = {
  eligibilityResponse: CombinedEligibilityCheckResponse;
  agencyPhoneNumberOnClickHandler: () => void;
  energyAssistanceProgramsLinkOnClickHandler: () => void;
  unitedWayLinkOnClickHandler: () => void;
  viewMyAccountHistoryClickHandler: () => void;
  viewCurrentBillClickHandler: () => void;
};

export const DefaultMainContent = ({
  eligibilityResponse,
  agencyPhoneNumberOnClickHandler,
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
  viewMyAccountHistoryClickHandler,
  viewCurrentBillClickHandler,
}: DefaultMainContentProps): React.ReactElement => {
  const lockEndDate: string = eligibilityResponse?.dunningLockDetails
    ? dayjsDate(eligibilityResponse?.dunningLockDetails[0].endDate).format('dddd, MMMM D, YYYY')
    : '';

  const displayAgencyContactInformation =
    eligibilityResponse?.dunningLockDetails &&
    (eligibilityResponse?.dunningLockDetails[0].agencyName || eligibilityResponse?.dunningLockDetails[0].agencyNumber);

  return (
    <LockStatusContent sx={{ gap: '0px' }} data-testid="default-main-content">
      <TopContent data-testid="top-content">
        <SuccessBanner
          message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
        />
        <AssistanceInformationContainer data-testid="assistance-information-container">
          <DefaultAssistanceInformation eligibilityResponse={eligibilityResponse} />
          {displayAgencyContactInformation && (
            <AgencyContactInformation
              agencyName={eligibilityResponse?.dunningLockDetails![0].agencyName}
              agencyPhoneNumber={eligibilityResponse?.dunningLockDetails![0].agencyNumber}
              agencyPhoneNumberOnClickHandler={agencyPhoneNumberOnClickHandler}
            />
          )}
        </AssistanceInformationContainer>
      </TopContent>
      <DefaultBottomContent
        viewMyAccountHistoryClickHandler={viewMyAccountHistoryClickHandler}
        viewCurrentBillClickHandler={viewCurrentBillClickHandler}
      />
      <AdditionalAssistanceBanner
        message={
          <DefaultAssistanceMessage
            energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
            unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          />
        }
      />
    </LockStatusContent>
  );
};
