import { eligibilityTypes, routes } from '../constants';

const getEligibilityRoute = (
  eligibilityType: EligibilityTypes,
  paEligibility: PaymentAgreementEligibility,
  peEligibility: PaymentExtensionEligibility,
): string => {
  // eligibilityType is currently 'PaymentExtension' when PA is 'E' and PE is 'Y'. Inserting a work around until this issue is addressed
  if (paEligibility === 'E' && peEligibility === 'Y') {
    return routes.paymentAgreement.enrolled;
  }

  switch (eligibilityType) {
    case eligibilityTypes.PAEnrolled:
      return routes.paymentAgreement.enrolled;

    case eligibilityTypes.PaymentAgreement:
      return routes.paymentAgreement.enrollment;

    case eligibilityTypes.PaymentExtension:
      return routes.paymentExtension.eligible;

    case eligibilityTypes.PaymentOffers:
      return routes.paymentOffers.default;

    default:
      return routes.root;
  }
};

export default getEligibilityRoute;
