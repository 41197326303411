import { styled, Theme, Typography } from '@mui/material';

const h4HeadingStyling = {
  align: 'center',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '27.24px',
  variant: 'h4',
};

const h5HeadingStyling = {
  align: 'center',
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '24.51px',
  variant: 'h5',
};

export const H4HeadingBold = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...h4HeadingStyling,
  color: theme.palette.secondary.main,
  fontWeight: '700',
}));

export const H5Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...h5HeadingStyling,
  color: theme.palette.secondary.main,
}));

export const H5HeadingGreen = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...h5HeadingStyling,
  color: theme.palette.dteGreen.main,
}));

export const H5HeadingRegular = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...h5HeadingStyling,
  color: theme.palette.secondary.main,
  fontWeight: '400',
}));
