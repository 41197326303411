import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import PageTitles from 'constants/pageTitles';
import routes from 'constants/routes';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import { useAccountDetails, usePELock } from 'hooks';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import gtmPeScheduledBaseEvent from './gtmPeScheduledBaseEvent';
import PaymentExtensionScheduledContent from './PaymentExtensionScheduledContent';
import { desktopHeader, mobileHeader } from './PaymentExtensionScheduleHeader';
import usePEDetailsScheduled from './usePEDetailsScheduled';

const PaymentExtensionScheduledPage = (): React.ReactElement => {
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const { isAccountDetailsSuccess, accountDetails } = useAccountDetails({
    errorPagePath: routes.error.paymentExtension,
  });
  const { isPEDataLoading, isReadyForPELock, paymentExtensionDetails, refetchPaymentExtensionDetails } =
    usePEDetailsScheduled({
      enabled: isAccountDetailsSuccess,
    });
  const { isMutatePELockSuccess, mutatePELock } = usePELock();
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  useEffect((): void => {
    if (isGoogleTagManagerLoaded) {
      googleTagManager.trackViewEvent({
        ...gtmPeScheduledBaseEvent,
        actionObject: 'payment extension details',
        actionDetail: 'scheduled',
        authenticationStatus: true,
        transactionComplete: '1',
      });
    }
  }, [isGoogleTagManagerLoaded]);

  useEffect((): void => {
    if (isReadyForPELock && accountDetails.accounts[0].accountNumber) {
      const lockRequest = {
        contractAccountNumber: Number(accountDetails.accounts[0].accountNumber),
      };
      mutatePELock(lockRequest);
    }
  }, [isReadyForPELock, accountDetails]);

  useEffect((): void => {
    if (isMutatePELockSuccess) {
      refetchPaymentExtensionDetails();
    }
  }, [isMutatePELockSuccess]);

  return (
    <>
      <Helmet>
        <title>{PageTitles.PAYMENT_EXTENSION}</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="740px"
        dataTestId="payment-extension-eligible-page"
      >
        <>
          {paymentExtensionDetails?.eligibilityFlag === 'S' && (
            <PaymentExtensionScheduledContent paymentExtensionDetails={paymentExtensionDetails} />
          )}
          {isPEDataLoading && <LoadingSpinner />}
        </>
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionScheduledPage;
