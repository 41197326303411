import { useEligibility } from 'api/eligibility-api/eligibility-api';
import { googleTagManager } from 'services';

import { useUserState } from '../../auth/authenticate';
import { unitedWayPhoneNumber } from '../../collections-copy';
import { currentBillUrl } from '../../urls-and-paths';

import {
  yLockEnergyAssistanceProgramsLinkOnClickEvent,
  yLockSubmitDocumentsButtonClickEvent,
  yLockUnitedWayLinkOnClickEvent,
  yLockViewCurrentBillLinkOnClickEvent,
  yLockViewEvent,
} from './gtmEventsYLock';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useYLockViewModel = () => {
  const { isAuthenticated } = useUserState();

  const { isEligibilitySuccess, isEligibilityError, eligibilityResponse, eligibilityError } = useEligibility({
    queryKey: ['lock-status-eligibility'],
    enabled: isAuthenticated,
  });

  const onPageViewHandler = (): void => {
    googleTagManager.trackViewEvent({
      ...yLockViewEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const viewCurrentBillOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...yLockViewCurrentBillLinkOnClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  const submitDocumentsOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...yLockSubmitDocumentsButtonClickEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const energyAssistanceProgramsLinkOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...yLockEnergyAssistanceProgramsLinkOnClickEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const unitedWayLinkOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...yLockUnitedWayLinkOnClickEvent,
      actionObject: unitedWayPhoneNumber,
      authenticationStatus: isAuthenticated,
    });
  };

  return {
    isEligibilitySuccess,
    isEligibilityError,
    eligibilityResponse,
    eligibilityError,
    onPageViewHandler,
    viewCurrentBillOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
    submitDocumentsOnClickHandler,
  };
};
