import React from 'react';
import { styled, SxProps, Typography, useTheme } from '@mui/material';

import DteSuccessIcon from '../icons/DteSuccessIcon';

interface SuccessBannerProps {
  iconWidth?: number;
  iconHeight?: number;
  iconColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  message: string | JSX.Element;
  messageColor?: string;
  containerSx?: SxProps;
}

export const SuccessBanner = ({
  iconWidth = 23,
  iconHeight = 23,
  iconColor,
  borderColor,
  backgroundColor,
  message,
  messageColor,
  containerSx,
}: SuccessBannerProps): React.ReactElement => {
  const theme = useTheme();
  const successIconColor = iconColor || theme.palette.dteGreen.main;

  const Container = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    border: `2px solid ${borderColor || theme.palette.dteGreen.main}`,
    backgroundColor: `${backgroundColor || theme.palette.dteGreen.light}`,
    padding: '20px 24px',
    borderRadius: '5px',
    width: '100%',
  }));

  const Message = styled(Typography)(() => ({
    color: messageColor || theme.palette.primary.main,
    paddingLeft: '8px',
  }));

  return (
    <Container sx={containerSx} data-testid="success-banner">
      <DteSuccessIcon width={iconWidth} height={iconHeight} color={successIconColor} />
      <Message variant="body1" data-testid="success-banner-message">
        {message}
      </Message>
    </Container>
  );
};
