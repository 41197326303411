const messages = {
  actionButtonConfirmLabel: 'Confirm Enrollment',
  actionButtonGoBackLabel: 'Go Back',
  monthPaymentTitle: 'month Payment Agreement',
  modalSummaryTitle: 'Your Payment Agreement summary',
  modalTitle: 'Review and Confirm your Agreement',
  perMonth: '/Mo',
  requiredPaymentTitle: 'Required down payment',
  summaryTextDownPaymentDeadline: 'You must make your down payment by 2 p.m.',
  summaryTextNotice:
    'If your payment cannot be processed or we do not receive it by that time, your Payment Agreement will be at risk.',
};

export default messages;
