import { Box, SxProps, Typography, useTheme } from '@mui/material';

import HeaderCancelButtonDesktop from '../buttons/HeaderCancelButtonDesktop';
import DteLogo from '../logo/DteLogo';
import DteLogoInteractive from '../logo/DteLogoInteractive';

import { HeaderProps } from './header';

const HeaderDesktop = ({
  enableCenteredHeader,
  isHeaderButtonRendered,
  isInteractiveDteLogo,
  onClick,
  titleText,
}: HeaderProps): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    buttonWrapper: {
      justifySelf: 'flex-end',
      maxWidth: '138px',
    },
    headerWrapper: {
      alignItems: 'center',
      background: theme.palette.dteBlue.dark,
      display: 'flex',
      flexShrink: '0',
      height: '96px',
      justifyContent: 'space-between',
      padding: '0px 40px',
      width: '100%',
    },
    headerWrapperV2: {
      alignItems: 'center',
      background: theme.palette.dteBlue.dark,
      display: 'grid',
      flexShrink: '0',
      gridTemplateColumns: '1fr 1fr 1fr',
      height: '96px',
      justifyContent: 'space-between',
      padding: '0px 40px',
      width: '100%',
    },
    invisibleDiv: {
      visibility: 'hidden',
    },
    invisibleDivV2: {
      justifySelf: 'flex-end',
      maxWidth: '138px',
      visibility: 'hidden',
    },
    logoWrapper: {
      justifySelf: 'center',
    },
    title: {
      color: theme.palette.common.white,
      fontWeight: '400',
    },
  };

  const dteLogo = isInteractiveDteLogo ? <DteLogoInteractive /> : <DteLogo />;

  const headerCancelButtonDesktop = enableCenteredHeader ? (
    <Box sx={sx.buttonWrapper}>
      <HeaderCancelButtonDesktop onClick={onClick} />
    </Box>
  ) : (
    <HeaderCancelButtonDesktop onClick={onClick} />
  );

  const logo = enableCenteredHeader ? <Box sx={sx.logoWrapper}>{dteLogo}</Box> : <>{dteLogo}</>;

  return (
    <Box role="banner" data-testid="header-desktop" sx={enableCenteredHeader ? sx.headerWrapperV2 : sx.headerWrapper}>
      <Typography sx={sx.title} variant="h1" data-testid="header-title">
        {titleText}
      </Typography>
      {logo}
      {isHeaderButtonRendered ? (
        <>{headerCancelButtonDesktop}</>
      ) : (
        <Typography sx={enableCenteredHeader ? sx.invisibleDivV2 : sx.invisibleDiv} variant="h1">
          {titleText}
        </Typography>
      )}
    </Box>
  );
};

export default HeaderDesktop;
