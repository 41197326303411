export interface PaymentCenterLambda {
  url: string;
  formData: URLSearchParams;
}

export const paymentCenterLambda = async ({ url, formData }: PaymentCenterLambda): Promise<Response> =>
  fetch(url, {
    method: 'POST',
    body: formData.toString(),
    redirect: 'follow',
    credentials: 'include',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  });
