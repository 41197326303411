import { urls } from 'constants/urls';
import { googleTagManager } from 'services';

import gtmConfirmPeDetailsBaseEvent from './gtmConfirmPeDetailsBaseEvent';

const onCurrentBillButtonClick = (): void => {
  googleTagManager.trackButtonClick({
    ...gtmConfirmPeDetailsBaseEvent,
    actionDetail: 'confirm payment extension details',
    actionObject: 'view current bill',
    authenticationStatus: true,
  });
  window.location.href = urls.currentBillUrl;
};

export default onCurrentBillButtonClick;
