import { Box, CircularProgress, SxProps } from '@mui/material';

import { useResponsiveSx } from '../../theme/breakpoints/responsiveHooks';

// TODO - height? with flex, should just be 100% of container
const sx: Record<string, SxProps> = {
  rootWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileWrapper: {
    height: 'calc(100vh - 288px)',
    // height: '100%',
  },
  desktopWrapper: {
    height: 'calc(100vh - 312px)',
    // height: '100%',
  },
};

const LoadingSpinner = (): React.ReactElement => {
  const mobileWrapperSx = { ...sx.rootWrapper, ...sx.mobileWrapper };
  const desktopWrapperSx = { ...sx.rootWrapper, ...sx.desktopWrapper };
  const responsiveSx = useResponsiveSx(mobileWrapperSx, desktopWrapperSx, desktopWrapperSx);
  return (
    <Box sx={responsiveSx}>
      <CircularProgress aria-label="aria-progressbar-name" role="progressbar" />
    </Box>
  );
};

export default LoadingSpinner;
