import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const CompleteFormIcon = ({ containerSx }: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="complete-form-icon">
    <svg width="43" height="56" viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.7703 49.0472C41.7703 49.0472 41.7703 44.5428 41.7703 43.3267V15.5184H32.0188C29.7179 15.5184 27.7457 13.6198 27.7457 11.1629V1H6.92796C3.75051 1 1.23047 3.56864 1.23047 6.80736V49.2458C1.23047 52.4845 3.75051 55.0531 6.92796 55.0531H35.9789C38.8746 55.0531 41.7703 52.0502 41.7703 49.0472Z"
        fill="white"
      />
      <path
        d="M41.7703 15.5184V43.3267C41.7703 44.5428 41.7703 49.0472 41.7703 49.0472C41.7703 52.0502 38.8746 55.0531 35.9789 55.0531H6.92796C3.75051 55.0531 1.23047 52.4845 1.23047 49.2458V6.80736C1.23047 3.56864 3.75051 1 6.92796 1H27.7457M41.7703 15.5184H32.0188C29.7179 15.5184 27.7457 13.6198 27.7457 11.1629V1M41.7703 15.5184L27.7457 1"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87695 44.6176L7.13618 44.1343C7.37245 43.6938 8.00838 43.7062 8.22717 44.1557L8.26053 44.2243C8.45453 44.6228 9.00935 44.6569 9.25066 44.2851V44.2851C9.46796 43.9503 9.95329 43.9369 10.1887 44.2593L10.5662 44.7763C10.8974 45.23 11.5572 45.2767 11.949 44.8742V44.8742C12.3409 44.4717 13.0006 44.5184 13.3319 44.9721L13.4989 45.2008C13.864 45.7009 14.5912 45.7523 15.0231 45.3087L15.4014 44.9201C15.5896 44.7267 15.8481 44.6176 16.1179 44.6176H19.1929"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.63086"
        y1="26.9336"
        x2="30.7627"
        y2="26.9336"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.63086"
        y1="32.0664"
        x2="30.7627"
        y2="32.0664"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.63086"
        y1="37.1992"
        x2="30.7627"
        y2="37.1992"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.62695"
        y1="19.75"
        x2="18.4429"
        y2="19.75"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
);
