import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

const CheckMarkTopRight: React.FC<IconProps> = (iconProps: IconProps): React.ReactElement => {
  const { containerSx, iconHeight = '48', iconWidth = '48' } = iconProps;

  return (
    <IconContainer data-testid="checkmark-top-right-icon" id="checkmark-top-right-icon" sx={containerSx}>
      <svg width={iconWidth} height={iconHeight} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M48 0H0L22.8571 22.8L48 48V0ZM41.7365 9.67646C42.1101 9.26972 42.0832 8.63712 41.6765 8.26352C41.2697 7.88992 40.6371 7.91679 40.2635 8.32354L31.0398 18.3656L27.6671 15.3455C27.2556 14.9771 26.6234 15.012 26.255 15.4234C25.8866 15.8348 25.9215 16.467 26.3329 16.8355L30.4413 20.5142C30.849 20.8793 31.4746 20.8488 31.8448 20.4457L41.7365 9.67646Z"
          fill="#1E3575"
          fillRule="evenodd"
        />
      </svg>
    </IconContainer>
  );
};

export default CheckMarkTopRight;
