import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';

import { unitedWayPhoneNumber } from '../../collections-copy';
import UnitedWayIcon from '../icons/UnitedWayIcon';

const UnitedWayBanner = (): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    unitedWayDisclaimer: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.dteGrey.light,
      padding: '20px 24px',
    },
    unitedWayDisclaimerText: {
      color: theme.palette.primary.main,
      paddingLeft: '8px',
    },
    phoneNumber: {
      color: theme.palette.dteBlue.main,
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.dteBlue.dark,
      },
    },
    wordWrap: {
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
    },
  };

  return (
    <Box data-testid="united-way-disclaimer-container" sx={sx.unitedWayDisclaimer}>
      <UnitedWayIcon />
      <Typography data-testid="united-way-disclaimer-content" sx={sx.unitedWayDisclaimerText}>
        {`If you need further energy assistance, please call United Way at `}
        <Box component="span" sx={sx.wordWrap}>
          <Link
            variant="body2"
            href={`tel:${unitedWayPhoneNumber}`}
            data-testid="united-way-number"
            fontWeight={600}
            sx={sx.phoneNumber}
          >
            {unitedWayPhoneNumber}
          </Link>
          .
        </Box>
      </Typography>
    </Box>
  );
};

export default UnitedWayBanner;
