import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { logger } from 'services';

import { createPaymentAgreementPlanApi } from '../api/create-payment-agreement-plan-api/create-payment-agreement-plan-api';
import { routes } from '../constants';
interface IUseCreatePaymentAgreementPlanParams {
  onSuccess?: (httpResponse: AxiosResponse<ICreatePaymentAgreementPlanResponse>) => void;
}
interface IUseCreatePaymentAgreementPlan {
  createPaymentAgreementPlan: (body: ICreatePaymentAgreementPlanRequest) => void;
  documentNumber: number;
  isCreatePlanError: boolean;
  isCreatePlanSuccess: boolean;
  isLoading: boolean;
}

const useCreatePaymentAgreementPlan = (
  params: IUseCreatePaymentAgreementPlanParams,
): IUseCreatePaymentAgreementPlan => {
  const { onSuccess } = params;
  const navigate = useNavigate();

  const {
    data: createPlanResponse,
    isError: isCreatePlanError,
    isSuccess: isCreatePlanSuccess,
    isLoading,
    mutate: createPaymentAgreementPlan,
  } = useMutation({
    mutationKey: ['create-payment-agreement-plan-api'],
    mutationFn: (body: ICreatePaymentAgreementPlanRequest) => createPaymentAgreementPlanApi(body),
    onSuccess,
    onError: (error: unknown) => {
      logger.error('[Logging] - PlanCreate Error', error);
      navigate(routes.error.paymentAgreement);
    },
  });

  return {
    documentNumber: createPlanResponse?.data.documentNumber || 0,
    createPaymentAgreementPlan,
    isCreatePlanError,
    isCreatePlanSuccess,
    isLoading,
  };
};

export default useCreatePaymentAgreementPlan;
