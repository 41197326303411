import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  rootWrapper: {
    width: '41px',
    height: '32px',
  },
};

const DteDisclaimerIcon = (): React.ReactElement => (
  <Box data-testid="dte-disclaimer-icon" sx={sx.rootWrapper}>
    <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="1.44434" width="34.0103" height="21.7222" rx="2.25" stroke="#1E3575" strokeWidth="1.5" />
      <rect x="0.75" y="6.72168" width="34.0103" height="4.83333" stroke="#1E3575" strokeWidth="1.5" />
      <path
        d="M40.1429 22.8614C40.1429 27.0974 36.6382 30.5559 32.2843 30.5559C27.9305 30.5559 24.4258 27.0974 24.4258 22.8614C24.4258 18.6255 27.9305 15.167 32.2843 15.167C36.6382 15.167 40.1429 18.6255 40.1429 22.8614Z"
        fill="white"
        stroke="#1E3575"
        strokeWidth="1.5"
      />
      <path
        d="M29.0586 19.6943L32.2932 22.8668M32.2932 22.8668L35.5031 26.0152M32.2932 22.8668L35.515 19.7069M32.2932 22.8668L29.0705 26.0277"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.30469 17.5828L5.38076 15.4717L6.45683 17.5828L7.5329 15.9995L9.14701 18.1106L10.7611 16.5272L12.3752 18.6383L13.9893 17.055H17.7556"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

export default DteDisclaimerIcon;
