import { styled, Theme, Typography } from '@mui/material';

import messages from './messages';

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '28px',
  fontWeight: '400',
  fontFamily: 'Open Sans',
  lineHeight: '38.13px',
  textAlign: 'center',
  variants: 'h1',
}));

const PaymentOffersHeading: React.FC = (): React.ReactElement => (
  <Title data-testid="payment-offers-heading">{messages.paymentOffersHeading}</Title>
);

export default PaymentOffersHeading;
