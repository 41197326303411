import { useEffect } from 'react';

import { useUserState } from 'auth/authenticate';
import { googleTagManager } from 'services';

import { DteHeader } from '../../component-library/header/DteHeader';
import { serviceProtection } from '../../component-library/header/header';
import { DteStyledLogo } from '../../component-library/logo/DteStyledLogo';
import { NewPageTemplate } from '../../component-library/page-templates/NewPageTemplate';

import { AgencyPaymentMainContent } from './main-content/AgencyPaymentMainContent';
import { DefaultMainContent } from './main-content/DefaultMainContent';
import { cLockViewEvent } from './gtmEventsCLock';
import { useCLockViewModel } from './useCLockViewModel';

const CLockView = (): React.ReactElement => {
  const {
    eligibilityResponse,
    agencyPhoneNumberOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
    viewMyAccountHistoryClickHandler,
    viewCurrentBillClickHandler,
  } = useCLockViewModel();
  const eligibility = eligibilityResponse?.data;

  const showContent = eligibility?.eligible.dunningLock === 'Y';
  const showAgencyPaymentContent = !!(
    eligibility?.dunningLockDetails?.[0].agencyPaymentAmount && eligibility?.dunningLockDetails?.[0].agencyPaymentDate
  );

  const { isAuthenticated } = useUserState();

  useEffect(() => {
    if (showContent) {
      googleTagManager.trackViewEvent({
        ...cLockViewEvent,
        authenticationStatus: isAuthenticated,
        paymentInfo: showAgencyPaymentContent ? 'true' : 'false',
      });
    }
  }, [showContent, showAgencyPaymentContent]);

  const header = <DteHeader title={serviceProtection} logo={<DteStyledLogo />} />;

  const content = showAgencyPaymentContent ? (
    <AgencyPaymentMainContent
      eligibilityResponse={eligibility!}
      agencyPhoneNumberOnClickHandler={agencyPhoneNumberOnClickHandler}
      energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
      unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
      viewMyAccountHistoryClickHandler={viewMyAccountHistoryClickHandler}
      viewCurrentBillClickHandler={viewCurrentBillClickHandler}
    />
  ) : (
    <DefaultMainContent
      eligibilityResponse={eligibility!}
      agencyPhoneNumberOnClickHandler={agencyPhoneNumberOnClickHandler}
      energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
      unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
      viewMyAccountHistoryClickHandler={viewMyAccountHistoryClickHandler}
      viewCurrentBillClickHandler={viewCurrentBillClickHandler}
    />
  );

  return (
    <NewPageTemplate
      titleTag="Agency Payment Assistance | DTE Energy"
      pageHeader={header}
      showContent={showContent}
      content={content}
    />
  );
};
export default CLockView;
