import React from 'react';
import { TextField } from '@mui/material';
import { Column } from '@tanstack/react-table';
import { ITestUser } from 'admin-page/types/ITestUser';

interface TableFilterProps {
  column: Column<ITestUser, unknown>;
}

const TableFilter: React.FC<TableFilterProps> = (props: TableFilterProps): React.ReactElement => {
  const { column } = props;
  const columnFilterValue = column.getFilterValue();

  return (
    <TextField
      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
        column.setFilterValue(event.target.value)
      }
      placeholder="Filter text..."
      value={columnFilterValue || ''}
      variant="standard"
    />
  );
};

export default TableFilter;
