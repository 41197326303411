import { styled, Theme, Typography, useTheme } from '@mui/material';

import { dayjsDate } from '../../../component-library/dates/dayjsApStyleAbbreviateMonths';
import { PaymentIcon } from '../../../component-library/icons/PaymentIcon';
import { StyledTextLink } from '../../../component-library/links/StyledLink';
import { Bold } from '../../../component-library/typography/Bold';
import { accountHistoryUrl } from '../../../urls-and-paths';
import { viewMyAccountHistoryClickHandler } from '../handlers';
import messages from '../messages';

const AgencyPaymentInformationContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  color: theme.palette.primary.main,
  display: 'flex',
  gap: '12px',
  padding: '24px',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const TextContent = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    textAlign: 'center',
    alignItems: 'center',
  },
  [theme.breakpoints.up('desktop')]: {
    textAlign: 'left',
    alignItems: 'flex-start',
  },
}));

const TopLines = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
  fontWeight: 600,
}));

const AssistanceAmount = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 400,
  ...theme.typography.body1,
}));

type AgencyPaymentInformationProps = {
  dunningLockDetails: IDunningLockDetails;
};

export const AgencyPaymentInformation = ({ dunningLockDetails }: AgencyPaymentInformationProps): React.ReactElement => {
  const theme = useTheme();

  const agencyPaymentDate = dunningLockDetails.agencyPaymentDate
    ? dayjsDate(dunningLockDetails.agencyPaymentDate).format('dddd, MMMM D')
    : '';

  const agencyPaymentAmount: string = dunningLockDetails.agencyPaymentAmount ?? '';

  return (
    <AgencyPaymentInformationContainer data-testid="agency-payment-information-container">
      <PaymentIcon containerSx={{ minWidth: '100px', height: '100px' }} />
      <TextContent>
        <TopLines>
          <Title sx={{ lineHeight: '27px' }}>{messages.agencyPaymentInfoTitle}</Title>
          <Title sx={{ lineHeight: '28px' }}>{agencyPaymentDate}</Title>
        </TopLines>
        <AssistanceAmount sx={{ lineHeight: '22px' }}>
          {messages.agencyPaymentInfoAssistanceText}
          <Bold sx={{ color: theme.palette.primary.main, lineHeight: '22px' }}>${agencyPaymentAmount}</Bold>
        </AssistanceAmount>
        <StyledTextLink href={accountHistoryUrl} onClick={viewMyAccountHistoryClickHandler}>
          <Bold sx={{ lineHeight: '22px' }}>{messages.agencyPaymentInfoViewAccountHistoryText}</Bold>
        </StyledTextLink>
      </TextContent>
    </AgencyPaymentInformationContainer>
  );
};
