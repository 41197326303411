import { jsx } from '@emotion/react';
import { styled, Theme, Typography } from '@mui/material';

import { useBreakpoints } from '../../theme/breakpoints/useBreakpoints';
import JSX = jsx.JSX;

type DteHeaderProps = {
  title: string;
  logo: JSX.Element;
  cancelButton?: JSX.Element;
};
export const DteHeader = ({ title, logo, cancelButton }: DteHeaderProps): React.ReactElement => {
  const { isSmallMobile, isMobile } = useBreakpoints();

  const Header = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.dteBlue.dark,
    // SmallMobile & Mobile
    [theme.breakpoints.down('tablet')]: {
      alignItems: 'flex-start',
      minHeight: '90px',
      padding: '16px 24px 12px 24px',
    },
    // Tablet & Desktop
    [theme.breakpoints.up('tablet')]: {
      minHeight: '96px',
      padding: '0px 40px',
    },
  }));

  const LeftContent = styled('div')({
    flex: '1',
    justifySelf: 'flex-start',
  });

  const CenterContent = styled('div')(({ theme }: { theme: Theme }) => ({
    flex: '1',
    justifySelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
      flex: '2 1 auto',
      gap: '13px',
    },
  }));

  const RightContent = styled('div')({
    flex: '1',
    justifySelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  });

  const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.common.white,
    // SmallMobile & Mobile
    [theme.breakpoints.down('tablet')]: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '18px',
      lineHeight: '24px',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '24px',
      lineHeight: '30px', // figma says 'normal' but it renders at 33px in the app that way
    },
  }));

  const tabletAndDesktopContent = (
    <Header role="banner">
      <LeftContent>
        <Title variant="h1" data-testid="header-title">
          {title}
        </Title>
      </LeftContent>
      <CenterContent>{logo}</CenterContent>
      <RightContent>{cancelButton}</RightContent>
    </Header>
  );

  const mobileAndSmallMobileContent = (
    <Header role="banner">
      <LeftContent />
      <CenterContent>
        {logo}
        <Title variant="h1" data-testid="header-title">
          {title}
        </Title>
      </CenterContent>
      <RightContent>{cancelButton}</RightContent>
    </Header>
  );

  return isSmallMobile || isMobile ? mobileAndSmallMobileContent : tabletAndDesktopContent;
};
