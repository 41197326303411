import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, useTheme } from '@mui/material';

import { CombinedEligibilityCheckResponse } from 'api/eligibility-api/EligibilityCheckResponse';
import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import { DteButton } from 'component-library/buttons/DteButton';
import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import Footer from 'component-library/footer/Footer';
import { serviceProtection } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { useResponsiveRendering, useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

interface LockStatusJProps {
  eligibilityResponse?: CombinedEligibilityCheckResponse;
  onPageViewHandler: () => void;
  viewCurrentBillOnClickHandler: () => void;
}

const JLockPresentation = ({
  eligibilityResponse,
  onPageViewHandler,
  viewCurrentBillOnClickHandler,
}: LockStatusJProps): React.ReactElement => {
  const theme = useTheme();
  const mobileHeader = <HeaderMobile titleText={serviceProtection} isHeaderButtonRendered={false} />;
  const desktopHeader = <HeaderDesktop titleText={serviceProtection} isHeaderButtonRendered={false} />;
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  // includes header
  const rootContainerSx = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  };

  // includes banner & view current bill button
  const mainContentBaseSx = {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 24px',
  };
  const mainContentSx = {
    desktop: {
      ...mainContentBaseSx,
    },
    tablet: {
      ...mainContentBaseSx,
    },
    mobile: {
      ...mainContentBaseSx,
    },
    smallMobile: {
      ...mainContentBaseSx,
      padding: '40px 8px',
    },
  };
  const mainContentResponsiveSx = useResponsiveSx(
    mainContentSx.mobile,
    mainContentSx.tablet,
    mainContentSx.desktop,
    mainContentSx.smallMobile,
  );

  const assistanceInformationContainerBaseSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0',
    width: '100%',
    color: theme.palette.primary.main,
  };
  const assistanceInformationContainerSx = {
    desktop: {
      ...assistanceInformationContainerBaseSx,
    },
    tablet: {
      ...assistanceInformationContainerBaseSx,
    },
    mobile: {
      ...assistanceInformationContainerBaseSx,
    },
    smallMobile: {
      ...assistanceInformationContainerBaseSx,
    },
  };
  const assistanceInformationContainerResponsiveSx = useResponsiveSx(
    assistanceInformationContainerSx.mobile,
    assistanceInformationContainerSx.tablet,
    assistanceInformationContainerSx.desktop,
    assistanceInformationContainerSx.smallMobile,
  );

  const assistanceInformationContentBaseSx = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  };
  const assistanceInformationContentSx = {
    desktop: {
      ...assistanceInformationContentBaseSx,
      maxWidth: '600px',
    },
    tablet: {
      ...assistanceInformationContentBaseSx,
      maxWidth: '600px',
    },
    mobile: {
      ...assistanceInformationContentBaseSx,
    },
    smallMobile: {
      ...assistanceInformationContentBaseSx,
    },
  };
  const assistanceInformationContentResponsiveSx = useResponsiveSx(
    assistanceInformationContentSx.mobile,
    assistanceInformationContentSx.tablet,
    assistanceInformationContentSx.desktop,
    assistanceInformationContentSx.smallMobile,
  );

  const assistanceTitleSx = {
    fontSize: '22px',
    fontFamily: 'Open Sans,sans-serif',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
  };

  const assistanceCopySx = {
    fontFamily: 'Open Sans,sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const lockEndDate: string =
    eligibilityResponse && eligibilityResponse.dunningLockDetails
      ? dayjsDate(eligibilityResponse.dunningLockDetails[0].endDate).format('dddd, MMMM D, YYYY')
      : '';

  useEffect(() => {
    if (eligibilityResponse?.eligible.dunningLock) {
      onPageViewHandler();
    }
  }, [eligibilityResponse?.eligible.dunningLock, onPageViewHandler]);

  return (
    <>
      <Helmet>
        <title>Agency Payment Review | DTE Energy</title>
      </Helmet>
      <Box sx={rootContainerSx}>
        {pageHeader}
        <Box sx={mainContentResponsiveSx} role="main">
          {eligibilityResponse && eligibilityResponse.eligible.dunningLock === 'Y' ? (
            <>
              <SuccessBanner
                message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
              />
              <Box data-testid="assistance-information-container" sx={assistanceInformationContainerResponsiveSx}>
                <Box data-testid="assistance-information-content" sx={assistanceInformationContentResponsiveSx}>
                  <Typography sx={assistanceTitleSx}>Assistance Information</Typography>
                  <Typography sx={assistanceCopySx}>
                    An agency is reviewing and will provide updates on your application. If the agency advised that a
                    co-payment must be made to DTE, please make the payment as soon as possible.
                  </Typography>
                  <Typography sx={assistanceCopySx}>
                    DTE Representatives do not have additional information about your application or when payments will
                    be applied to your DTE account.
                  </Typography>
                </Box>
              </Box>
              <DteButton variant="primary" text="View Current Bill" onClick={viewCurrentBillOnClickHandler} />
            </>
          ) : (
            <LoadingSpinner />
          )}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default JLockPresentation;
