import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';

import { useResponsiveSx } from '../../theme/breakpoints/responsiveHooks';

interface ViewCurrentBillProps {
  onClick: () => void;
}

const ViewCurrentBillButton = ({ onClick }: ViewCurrentBillProps): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
    },
    rootWrapperMobile: {
      justifyContent: 'center',
    },
    rootWrapperDesktop: {
      justifyContent: 'flex-start',
    },
    button: {
      color: theme.palette.dteBlue.main,
      textTransform: 'none',
      height: '44px',
      padding: '11px 0px',
      borderRadius: '0px',
      margin: '-11px 0px 13px 0px',
      alignItems: 'flex-start',
      '.MuiButton-startIcon': {
        margin: '0px 3px 0px 0px',
      },
      '&:hover': {
        color: theme.palette.dteBlue.dark,
      },
      '&:focus': {
        color: theme.palette.dteBlue.dark,
      },
    },
  };

  const mobileRootWrapper = { ...sx.rootWrapper, ...sx.rootWrapperMobile };
  const desktopRootWrapper = { ...sx.rootWrapper, ...sx.rootWrapperDesktop };
  const responsiveSx = useResponsiveSx(mobileRootWrapper, desktopRootWrapper, desktopRootWrapper);

  return (
    <Box data-testid="view-current-bill-responsive-style" sx={responsiveSx}>
      <Typography
        component={Button}
        data-testid="view-current-bill"
        onClick={onClick}
        startIcon={<ArrowBackIosNewRoundedIcon data-testid="back-arrow-icon" />}
        sx={sx.button}
        fontWeight={600}
        variant="body1"
      >
        View Current Bill
      </Typography>
    </Box>
  );
};

export default ViewCurrentBillButton;
