import React from 'react';
import { Box, styled, Theme, Typography } from '@mui/material';

import CheckMarkIcon from '../icons/CheckMarkIcon';

interface CheckMarkListItemProps {
  text: string;
}

export const CheckMarkListItem: React.FC<CheckMarkListItemProps> = (props: CheckMarkListItemProps) => {
  const { text } = props;

  const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    color: theme.palette.secondary.main,
  }));

  const ListItemIconContainer = styled(Box)(() => ({
    display: 'flex',
    marginRight: '8px',
  }));

  const ListItemText = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: '400',
    fontSize: '16px',
  }));

  const checkMarkListItem = 'checkmark-list-item';

  return (
    <Container
      data-testid={checkMarkListItem}
      sx={{ display: 'flex', alignItems: 'flex-start', alignContent: 'flex-start' }}
    >
      <ListItemIconContainer className="list-icon-container">
        <CheckMarkIcon containerSx={{ width: '32px', height: '32px', paddingBottom: '0.7rem' }} />
      </ListItemIconContainer>
      <ListItemText>{text}</ListItemText>
    </Container>
  );
};
