import { ReactNode } from 'react';
import { styled, Theme, Typography } from '@mui/material';

type StepProps = {
  title: string;
  subTitle: string;
  icon: ReactNode;
  instructions: string;
  dataTestId?: string;
};
export const Step = ({ title, subTitle, icon, instructions, dataTestId }: StepProps): React.ReactElement => {
  const Container = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    maxWidth: '300px',
    flexBasis: '100%',
    [theme.breakpoints.down('tablet')]: {
      maxWidth: '100%',
    },
  }));
  const TitleContainer = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    color: theme.palette.primary.main,
  }));
  const Title = styled(Typography)({
    fontSize: '25px',
    fontWeight: '600',
    lineHeight: 'normal',
  });
  const SubTitle = styled(Typography)({
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: 'normal',
  });
  const Instructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
    fontSize: '16px',
    color: theme.palette.secondary.main,
  }));

  return (
    <Container data-testid={dataTestId}>
      <TitleContainer>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </TitleContainer>
      {icon}
      <Instructions>{instructions}</Instructions>
    </Container>
  );
};
