import { MutationKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { experianApiPath } from '../../urls-and-paths';
import { azurePost } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

import ExperianRequest from './ExperianRequest';
import ExperianResponse from './ExperianResponse';

const checkExperian = async (body: ExperianRequest): Promise<AxiosResponse<ExperianResponse>> =>
  azurePost<ExperianResponse>(experianApiPath, body);

const localMockCheckExperian = async (): Promise<AxiosResponse<ExperianResponse>> =>
  localMockApiResponse(Mocks.experianResponseBody);

export const checkExperianApi = apiOrLocalMock(checkExperian, localMockCheckExperian);

interface ExperianMutationProps {
  mutationKey?: MutationKey | undefined;
  onSuccess?: (httpResponse: AxiosResponse<ExperianResponse>) => void;
  onError: (error: unknown) => void;
}

interface IUseExperianResponse {
  isExperianSuccess: boolean;
  experianResponse: AxiosResponse<ExperianResponse> | undefined;
  mutateExperian: UseMutateFunction<AxiosResponse<ExperianResponse, unknown>, unknown, ExperianRequest, unknown>;
}

export const useExperian = ({
  mutationKey = ['experian-check'],
  onSuccess,
  onError,
}: ExperianMutationProps): IUseExperianResponse => {
  const {
    isSuccess: isExperianSuccess,
    data: experianResponse,
    mutate: mutateExperian,
  } = useMutation({
    mutationKey,
    mutationFn: (experianRequest: ExperianRequest) => checkExperianApi(experianRequest),
    onSuccess,
    onError,
  });

  return {
    isExperianSuccess,
    experianResponse,
    mutateExperian,
  };
};
