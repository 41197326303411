import { styled, SxProps, Theme, Typography } from '@mui/material';

import { DueDateIcon } from 'component-library/icons/payment-agreement-icons';

import messages from './messages';

interface IProgressCircleProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const sx: Record<string, SxProps> = {
  dueDateIcon: {
    padding: '3.38px 0px',
    paddingLeft: '2px',
  },
};

const LabelContainer = styled('div')(() => ({
  padding: '30px 34px 29px 33px',
}));

const RemainingText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '19.07px',
  marginTop: '8px',
  textAlign: 'center',
}));

const ProgressLabel: React.FC<IProgressCircleProps> = (): React.ReactElement => (
  <LabelContainer data-testid="monthly-payment-circle-progress-label">
    <DueDateIcon containerSx={sx.dueDateIcon} />
    <RemainingText>{messages.remainingText}</RemainingText>
  </LabelContainer>
);

export default ProgressLabel;
