import { styled, Theme } from '@mui/material';

import routes from 'constants/routes';
import { googleTagManager } from 'services';

import { BreadcrumbRouterLink } from './BreadcrumbRouterLink';
import messages from './messages';

const LinkContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  fontWeight: '600',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'center',
  },
}));

interface ViewPaymentOffersLinkProps {
  isAuthenticated?: boolean;
  isCashOnly?: boolean;
}

const ViewPaymentOffersLink = ({ isAuthenticated, isCashOnly }: ViewPaymentOffersLinkProps): React.ReactElement => {
  const handleClick = (): void => {
    const gtmOnViewCurrentBillClick = {
      actionDetail: 'return to offers page',
      actionObject: 'view payment offers',
      transactionStepName: 'pa detail page',
      transactionType: 'payment agreement',
      userType: isCashOnly ? 'cash only' : 'web',
    };

    googleTagManager.trackButtonClick({ ...gtmOnViewCurrentBillClick, authenticationStatus: isAuthenticated });
  };

  return (
    <LinkContainer>
      <BreadcrumbRouterLink
        href={routes.paymentOffers.default}
        text={messages.viewPaymentOffersLabel}
        dataTestId="view-payment-offers-link"
        onClickHandler={handleClick}
      />
    </LinkContainer>
  );
};

export default ViewPaymentOffersLink;
