import { Button, ButtonProps, Typography, useTheme } from '@mui/material';

type MuiButtonProps = Pick<ButtonProps, 'href' | 'onClick'>;

interface DteButtonProps extends MuiButtonProps {
  dataTestId?: string;
  name: string;
}

export const DtePrimaryButtonPA = ({ dataTestId, name, onClick }: DteButtonProps): React.ReactElement => {
  const theme = useTheme();
  const primaryButtonSx = {
    background: theme.palette.dteBlue.main,
    borderRadius: 'unset',
    boxShadow: 'unset',
    color: theme.palette.common.white,
    height: '40px',
    maxWidth: '300px',
    padding: '8px 40px',
    textTransform: 'none',
    width: '300px',
    '&:hover': {
      background: theme.palette.dteBlue.dark,
      boxShadow: 'unset',
    },
    '&:focus': {
      background: theme.palette.dteBlue.dark,
      color: theme.palette.common.white,
      boxShadow: 'unset',
    },
  };

  return (
    <Button
      data-testid={`${dataTestId}-button`}
      disableFocusRipple
      id="dtePrimaryButton"
      onClick={onClick}
      sx={primaryButtonSx}
      type="button"
    >
      <Typography data-testid={`${dataTestId}-text`} fontWeight={600} variant="body2">
        {name}
      </Typography>
    </Button>
  );
};
