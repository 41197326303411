export const reconnectBaseProperties = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'reconnect',
  authenticationStatus: 'authenticated',
};

// Reconnect Header
export const reconnectHeaderCancelClickEvent = {
  ...reconnectBaseProperties,
  transactionStepName: 'reconnect details',
  transactionStepNumber: '1',
  actionType: 'click',
  actionObject: 'cancel',
  actionDetail: 'cancel',
};

interface IReconnectDetailsReturnType {
  actionDetail: string;
  actionObject: string;
  actionType: string;
  authenticationStatus: string;
  eventName: string;
  transactionAttempt?: string;
  transactionName: string;
  transactionStepName: string;
  transactionStepNumber: string;
  transactionType: string;
}

// Reconnect Details view
export const reconnectDetailsViewEvent = (actionDetail: string): IReconnectDetailsReturnType => ({
  ...reconnectBaseProperties,
  transactionStepName: 'reconnect details',
  transactionStepNumber: '1',
  actionType: 'view',
  actionObject: 'min due',
  actionDetail,
});

export const reconnectDetailsViewCurrentBillClickEvent = {
  ...reconnectBaseProperties,
  transactionStepName: 'reconnect details',
  transactionStepNumber: '1',
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'reconnect details page',
};

export const reconnectDetailsAccordionClickEvent = (
  ariaIdLabel: string,
  isExpanded: boolean,
): IReconnectDetailsReturnType => ({
  ...reconnectBaseProperties,
  transactionStepName: 'reconnect details',
  transactionStepNumber: '1',
  actionType: 'click',
  actionObject: ariaIdLabel === 'total-amount-due' ? 'total due details' : 'min due details',
  actionDetail: isExpanded ? 'hide' : 'unhide',
});

export const reconnectDetailsMakeAPaymentClickEvent = (
  actionObject: string,
  actionDetail: string,
): IReconnectDetailsReturnType => ({
  ...reconnectBaseProperties,
  transactionAttempt: '1',
  transactionStepName: 'reconnect details',
  transactionStepNumber: '1',
  actionType: 'click',
  actionObject,
  actionDetail,
});

// Reconnect Confirmation view
export const reconnectConfirmationPageViewEvent = {
  ...reconnectBaseProperties,
  transactionComplete: '1',
  transactionStepName: 'reconnect confirmation',
  transactionStepNumber: '3',
  actionType: 'view',
  actionObject: 'reconnect confirmation',
};

export const reconnectConfirmationViewCurrentBillClickEvent = {
  ...reconnectBaseProperties,
  transactionStepName: 'reconnect confirmation',
  transactionStepNumber: '3',
  actionType: 'click',
  actionObject: 'go to current bill',
  actionDetail: 'reconnect confirmation',
};

// Reconnect Probate view
export const probateCallUsClickEvent = {
  ...reconnectBaseProperties,
  actionType: 'click',
  actionObject: 'call us',
  actionDetail: 'probate page',
};

export const probateBackToCurrentBillClickEvent = {
  ...reconnectBaseProperties,
  actionType: 'click',
  actionObject: 'back to current bill',
  actionDetail: 'probate page',
};

export const probateViewEvent = {
  ...reconnectBaseProperties,
  actionType: 'view',
  actionObject: 'contact us',
  actionDetail: 'probate page',
};
// Reconnect Error Page

export const ReconnectErrorBase = {
  ...reconnectBaseProperties,
  transactionStepName: 'error',
};

export const ReconnectErrorViewEvent = {
  ...ReconnectErrorBase,
  actionType: 'view',
  actionObject: 'error page',
  actionDetail: 'reconnect error',
};

export const ReconnectErrorPhoneClickEvent = {
  ...ReconnectErrorBase,
  actionType: 'click',
  actionObject: 'phone number',
  actionDetail: 'phone number',
};

export const ReconnectErrorContactUsClickEvent = {
  ...ReconnectErrorBase,
  actionType: 'click',
  actionObject: 'contact us',
  actionDetail: 'contact us',
};

export const ReconnectErrorViewCurrentBillClickEvent = {
  ...ReconnectErrorBase,
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'view current bill',
};

/** reference #20 * */
export const ReconnectSecurityDepositDetailsClickEvent = {
  ...reconnectBaseProperties,
  transactionStepName: 'reconnect details',
  transactionStepNumber: '1',
  actionType: 'click',
  actionObject: 'security deposit details',
  actionDetail: 'security deposit modal',
};
