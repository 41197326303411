import { useEffect } from 'react';
import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import UnitedWayBanner from 'component-library/banners/UnitedWayBanner';
import { reconnectConfirmationSuccessBannerDate } from 'component-library/dates/dayjsDefaultAbbreviateMonths';
import { Bold } from 'component-library/typography/Bold';
import {
  reconnectConfirmationPageViewEvent,
  reconnectConfirmationViewCurrentBillClickEvent,
} from 'gtm/gtmEventsReconnect';
import { googleTagManager } from 'services';
import { currentBillUrl } from 'urls-and-paths';

import ConfirmationMainContent from './ConfirmationMainContent';

const ReconnectConfirmationContent: React.FC = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    contentWrapper: {
      marginTop: '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    viewCurrentBillButton: {
      background: theme.palette.dteBlue.main,
      textTransform: 'none',
      color: theme.palette.common.white,
      height: '40px',
      borderRadius: 'unset',
      width: '100%',
      maxWidth: '300px',
      boxShadow: 'unset',
      fontWeight: '600px',
      marginBottom: '32px',
      '&:hover': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
      '&:focus': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
    },
  };

  useEffect(() => {
    googleTagManager.trackViewEvent({
      ...reconnectConfirmationPageViewEvent,
      authenticationStatus: isAuthenticated,
    });
  }, []);

  const redirectToCurrentBillHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...reconnectConfirmationViewCurrentBillClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  const webUserId = localStorage.getItem('webUserId');
  const amount = localStorage.getItem(`paymentAmount_${webUserId}`);

  const message = (
    <Typography data-testid="success-message-text" sx={sx.successMessageText}>
      <Bold>Your request to reconnect service is complete. </Bold>
      {amount
        ? `We received your most recent payment of $${amount} on ${reconnectConfirmationSuccessBannerDate}.`
        : `We received your most recent payment.`}
    </Typography>
  );

  return (
    <Box sx={sx.contentWrapper}>
      <SuccessBanner message={message} containerSx={{ marginBottom: '40px' }} />
      <ConfirmationMainContent />
      <Button
        onClick={redirectToCurrentBillHandler}
        sx={sx.viewCurrentBillButton}
        data-testid="view-current-bill-button"
      >
        <Typography fontWeight={600} variant="body2" data-testid="view-current-bill-button-text">
          View Current Bill
        </Typography>
      </Button>
      <UnitedWayBanner />
    </Box>
  );
};

export default ReconnectConfirmationContent;
