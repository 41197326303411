import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  wrapper: {
    width: '32px',
    height: '32px',
  },
};

const UnitedWayIcon = (): React.ReactElement => (
  <Box data-testid="united-way-icon" sx={sx.wrapper}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0469 5.95565C17.4691 4.48899 19.4247 3.6001 21.6024 3.6001C25.8691 3.6001 29.3358 7.06676 29.3358 11.3334C29.3358 13.5112 28.4469 15.4668 27.0247 16.8445"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5.15686 17.0668C3.64575 15.6445 2.66797 13.6001 2.66797 11.3334C2.66797 7.06676 6.13464 3.6001 10.4013 3.6001C12.5791 3.6001 14.5791 4.53343 16.0013 6.0001"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.047 5.91113L12.2247 9.7778C11.5136 10.4889 11.5136 11.6 12.2247 12.2667C12.9359 12.9778 14.047 12.9778 14.7136 12.2667L18.2247 8.75558C18.4025 8.5778 18.7136 8.5778 18.9359 8.75558L26.7136 16.5334"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.2227 15.4225L24.356 19.5559C25.1115 20.3114 26.3115 20.3114 27.0227 19.5559C27.7782 18.8003 27.7782 17.6003 27.0227 16.8892L22.8893 12.7559"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.5586 18.089L21.6919 22.2224C22.4475 22.9779 23.6475 22.9779 24.3586 22.2224C25.1141 21.4668 25.1141 20.2668 24.3586 19.5557L20.2253 15.4224"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M19.0253 24.8889C19.7808 25.6444 20.9808 25.6444 21.6919 24.8889C22.4475 24.1333 22.4475 22.9333 21.6919 22.2222L17.5586 18.0889"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5.51198 19.4667C4.75642 18.7112 4.75642 17.4223 5.51198 16.6667L7.37865 14.8001C8.1342 14.0445 9.42309 14.0445 10.1786 14.8001C10.9342 15.5556 10.9342 16.8445 10.1786 17.6001L8.31198 19.4667C7.51198 20.2667 6.26753 20.2667 5.51198 19.4667Z"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.31276 22.2665C7.5572 21.511 7.5572 20.2221 8.31276 19.4665L10.1794 17.5999C10.935 16.8443 12.2239 16.8443 12.9794 17.5999C13.735 18.3554 13.735 19.6443 12.9794 20.3999L11.1128 22.2665C10.3128 23.0221 9.06832 23.0221 8.31276 22.2665Z"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.0667 25.0224C10.3111 24.2668 10.3111 22.978 11.0667 22.2224L12.9333 20.3557C13.6889 19.6002 14.9778 19.6002 15.7333 20.3557C16.4889 21.1113 16.4889 22.4002 15.7333 23.1557L13.8667 25.0224C13.1111 25.8224 11.8222 25.8224 11.0667 25.0224Z"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.8674 27.8222C13.1119 27.0666 13.1119 25.7778 13.8674 25.0222L15.7341 23.1555C16.4897 22.4 17.7786 22.4 18.5341 23.1555C19.2897 23.9111 19.2897 25.2 18.5341 25.9555L16.6674 27.8222C15.8674 28.5778 14.623 28.5778 13.8674 27.8222Z"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </Box>
);

export default UnitedWayIcon;
