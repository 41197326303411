import gtmBaseEvent from 'constants/gtmBaseEvent';

import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import getOptionalGtmEvent from './getOptionalGtmEvent';
import pushDataLayer from './pushDataLayer';

type TrackEventProps = GtmEventPropsWithActionType;

const trackEvent = (params: TrackEventProps): void => {
  const { actionDetail, actionObject, actionType, authenticationStatus } = params;
  const authStatus = getAuthenticationStatus(authenticationStatus);

  const event: IGtmEventProps = {
    ...gtmBaseEvent,
    action_detail: actionDetail,
    action_object: actionObject,
    action_type: actionType,
    authentication_status: authStatus,
  };

  const optionalEvent = getOptionalGtmEvent(params);

  const gtmEvent: IGtmEventProps = {
    ...event,
    ...optionalEvent,
  };

  logger.info(`[Google Tag Manager] Sending tags for event ${actionType}`, gtmEvent);

  pushDataLayer(gtmEvent);
};

export default trackEvent;
