import { unitedWayPhoneNumber } from '../../collections-copy';

/** reference #15 * */
export const cLockViewEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'view',
  actionObject: 'agency 30 day commitment lock details page',
  actionDetail: 'c',
  authenticationStatus: 'authenticated',
  paymentInfo: 'true/false', // is overridden by only one option
};

/** reference #16 * */
export const cLockEnergyAssistanceProgramsLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'energy assistance programs',
  actionDetail: 'c',
  authenticationStatus: 'authenticated',
};

/** reference #17 * */
export const cLockAgencyPhoneNumberLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: '[###-###-####]', // is overridden by real number
  actionDetail: 'c',
  authenticationStatus: 'authenticated',
};

/** reference #18 * */
export const cLockViewMyAccountHistoryButtonOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view my account history',
  actionDetail: 'c',
  authenticationStatus: 'authenticated',
};

/** reference #19 * */
export const cLockViewCurrentBillButtonOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: 'view current bill',
  actionDetail: 'c',
  authenticationStatus: 'authenticated',
};

/** reference #12 * */
export const cLockUnitedWayLinkOnClickEvent = {
  eventName: 'transaction_event',
  transactionName: 'collections',
  transactionType: 'lock status',
  transactionStepName: 'lock status details page',
  actionType: 'click',
  actionObject: unitedWayPhoneNumber,
  actionDetail: 'c',
  authenticationStatus: 'authenticated',
};
