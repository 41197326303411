import { styled, Theme } from '@mui/material';

import ActionButtons from './action-buttons/ActionButtons';
import SummaryText from './SummaryText';
import { YourPaymentAgreementSummary } from './your-pa-summary';

interface ReviewPaymentSummaryDetailsProps extends IDownPaymentMethod {
  isLoading?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  margin: '0 auto',
  [theme.breakpoints.down(376)]: {
    width: '282px',
  },
  [theme.breakpoints.up(376)]: {
    padding: '0 24px',
  },
  [theme.breakpoints.up(755)]: {
    padding: 'unset',
    width: '498px',
  },
}));

const ReviewPaymentSummaryDetails: React.FC<ReviewPaymentSummaryDetailsProps> = ({
  downPaymentMethod,
  isLoading,
  setIsLoading,
}: ReviewPaymentSummaryDetailsProps): React.ReactElement => (
  <Container data-testid="review-payment-summary-details">
    <YourPaymentAgreementSummary />
    <SummaryText />
    <ActionButtons downPaymentMethod={downPaymentMethod} isLoading={isLoading} setIsLoading={setIsLoading} />
  </Container>
);

export default ReviewPaymentSummaryDetails;
