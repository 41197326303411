import React, { CSSProperties } from 'react';
import { styled } from '@mui/material';

import { IconContainer } from './IconContainer';

type IconProps = {
  containerSx?: CSSProperties;
  iconSx?: CSSProperties;
};

export const SchedulePaymentDateIcon = ({ containerSx, iconSx }: IconProps): React.ReactElement => {
  const sizeStyle = {
    width: '36px',
    height: '36px',
  };
  const LogoContainer = styled(IconContainer)({
    ...sizeStyle,
  });

  const StyledSvg = styled('svg')({
    ...sizeStyle,
  });

  return (
    <LogoContainer sx={containerSx} data-testid="schedule-payment-date-icon-container">
      <StyledSvg sx={iconSx} viewBox="0 0 36 39" fill="none" role="img" aria-label="schedule-payment-date-icon">
        <path
          d="M8.85714 11.8957L5 11.8957C3.34315 11.8957 2 13.2389 2 14.8957L2 28C2 29.6569 3.34315 31 5 31L10 31M8.85714 11.8957L6.69308 9M8.85714 11.8957L6.69308 14.7903"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9219 13.5395V10.3979"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9219 32.5453V29.4038"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2871 25.398C13.2871 25.398 13.6013 29.2463 17.7638 29.4034C21.9263 29.639 22.3975 26.4189 22.3975 25.7906C22.3975 25.1623 22.476 22.6491 17.8423 21.3925C13.2086 20.1359 13.6013 17.3871 13.6798 17.073C13.7583 16.6803 14.0725 13.8529 17.6852 13.5388C21.298 13.2246 22.7116 16.7588 22.7116 17.3871"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.1429 30.1062H31C32.6569 30.1062 34 28.7631 34 27.1062V14C34 12.3431 32.6569 11 31 11H26M27.1429 30.1062L29.3069 33M27.1429 30.1062L29.3069 27.2102"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledSvg>
    </LogoContainer>
  );
};
