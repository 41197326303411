export interface GA4TagManagerAttributes {
  eventName?: string;
  transactionName?: string;
  transactionType?: string;
  transactionComplete?: string;
  transactionAttempt?: string;
  transactionStepName?: string;
  transactionStepNumber?: string;
  actionType?: string;
  actionObject?: string;
  actionDetail?: string;
  authenticationStatus?: string;
  paymentInfo?: string;
}

export const pushAnalytics = (params: unknown): void => {
  if (process.env.REACT_APP_DEVELOPMENT_MODE !== 'true') {
    if (window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push(params);
    }
  }
};

export const trackGA4Event = ({
  eventName,
  transactionName,
  transactionType,
  transactionComplete,
  transactionAttempt,
  transactionStepName,
  transactionStepNumber,
  actionType,
  actionObject,
  actionDetail,
  authenticationStatus,
  paymentInfo,
}: GA4TagManagerAttributes): void => {
  if (process.env.REACT_APP_DEVELOPMENT_MODE !== 'true') {
    pushAnalytics({
      event: eventName,
      transaction_name: transactionName,
      transaction_type: transactionType,
      transaction_complete: transactionComplete,
      transaction_attempt: transactionAttempt,
      transaction_step_name: transactionStepName,
      transaction_step_number: transactionStepNumber,
      action_type: actionType,
      action_object: actionObject,
      action_detail: actionDetail,
      authentication_status: authenticationStatus,
      payment_info: paymentInfo,
    });
  }
};

export const pushError = (maskedUri: string, method: string, status: string): void => {
  if (process.env.REACT_APP_DEVELOPMENT_MODE !== 'true') {
    pushAnalytics({
      event: 'webServiceErrors',
      eventCategory: 'Service Errors',
      eventAction: method,
      eventLabel: JSON.stringify([
        {
          [maskedUri]: status,
        },
      ]),
    });
  }
};

const maskUri = (uri: string): string => uri.replace(/[0-9]/g, 'X');

export const trackServiceRequest = (uri: string, method: string): void => {
  const maskedUri = maskUri(uri);
  pushAnalytics({
    event: 'requestAPI',
    eventCategory: maskedUri.split('?')[0],
    eventAction: method,
  });
};

export const trackServiceResult = (uri: string, method: string, response: { status: number }): void => {
  const maskedUri = maskUri(uri);

  if (response.status === 200) {
    pushAnalytics({
      event: 'successAPI',
      eventCategory: maskedUri.split('?')[0],
      eventAction: method,
    });
  } else {
    pushError(maskedUri, method, String(response.status));
  }
};

export const trackServiceResponseError = (uri: string, method: string, status: string): void => {
  const maskedUri = maskUri(uri);

  pushError(maskedUri, method, status);
};
