import { styled, Theme, Typography } from '@mui/material';

import { PaymentAgreementNoticeStep } from './PaymentAgreementNoticeStep';
import { PaymentAgreementNoticeTitle } from './PaymentAgreementNoticeTitle';

const Container = styled(Typography)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '24px',
  [theme.breakpoints.down('tablet')]: {
    padding: '24px 8px',
  },
}));

export const PaymentAgreementNotice: React.FC = (): React.ReactElement => (
  <Container data-testid="payment-agreement-notice">
    <PaymentAgreementNoticeTitle />
    <PaymentAgreementNoticeStep />
  </Container>
);
