import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  rootWrapper: {
    width: '38px',
    height: '36px',
  },
};

const DteDisclaimerIconPaymentExtension = (): React.ReactElement => (
  <Box data-testid="dte-disclaimer-icon" sx={sx.rootWrapper}>
    <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.66016" y="3.75" width="29.2241" height="18.9828" rx="2.25" stroke="#1E3575" strokeWidth="1.5" />
      <rect x="1.66016" y="8.40527" width="29.2241" height="4.08621" stroke="#1E3575" strokeWidth="1.5" />
      <circle cx="29.0918" cy="22" r="7.25" fill="white" stroke="#1E3575" strokeWidth="1.5" />
      <path
        d="M26.0918 19L29.0977 22.0055M29.0977 22.0055L32.0807 24.9881M29.0977 22.0055L32.0918 19.0119M29.0977 22.0055L26.1029 25"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </Box>
);

export default DteDisclaimerIconPaymentExtension;
