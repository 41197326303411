import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

export const UploadDocumentIcon = ({
  containerSx,
  iconWidth = '68',
  iconHeight = '62',
}: IconProps): React.ReactElement => (
  <IconContainer sx={containerSx} data-testid="upload-document-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 70 62" fill="none">
      <path
        d="M68.3801 60.9998V45.1901M68.3801 45.1901H52.3214C48.5323 45.1901 45.2844 42.0636 45.2844 38.0175V21.2812M68.3801 45.1901L45.2844 21.2812M45.2844 21.2812H39.5082M1.61914 60.9998V30.8448C1.61914 25.5113 5.76915 21.2812 11.0018 21.2812H27.8165"
        stroke="#1E3575"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7312 31.4227V1M33.7312 1L20.21 14.5212M33.7312 1L47.2524 14.5212"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="12.7598"
        y1="52.3945"
        x2="56.3939"
        y2="52.3945"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="11.9146"
        y1="40.5625"
        x2="30.1964"
        y2="40.5625"
        stroke="#1E3575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
);
