import { CircularProgress, useTheme } from '@mui/material';

import getProgressValue from './getProgressValue';

interface IProgressCircleProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const ProgressCircle: React.FC<IProgressCircleProps> = (props: IProgressCircleProps): React.ReactElement => {
  const { palette } = useTheme();
  const { numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = props;
  const progressValue = getProgressValue(numberOfInstallmentsOpted, numberOfInstallmentsRemaining);

  return (
    <>
      <CircularProgress
        style={{
          color: palette.dteBlue.dark,
          left: -3,
          position: 'absolute',
          top: -3,
          zIndex: '1',
        }}
        size={149}
        thickness={4}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        style={{
          color: palette.dteBlue.dark,
          left: 0,
          position: 'absolute',
          top: 0,
          zIndex: '1',
        }}
        size={143}
        thickness={4}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        style={{
          color: palette.dteGrey.main,
          left: 0,
          position: 'absolute',
          top: 0,
          zIndex: '2',
        }}
        size={143}
        thickness={3}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        data-testid="monthly-payment-circle-progress"
        style={{
          color: palette.dteGreen.dark,
          left: 0,
          position: 'absolute',
          top: 0,
          zIndex: '3',
        }}
        size={143}
        thickness={3}
        value={progressValue}
        variant="determinate"
      />
    </>
  );
};

export default ProgressCircle;
