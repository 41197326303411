import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { PaymentExtensionDetails } from 'api/eligibility-api/EligibilityCheckResponse';
import { ReadOnlyCurrencyMask } from 'component-library/currency/ReadOnlyCurrencyMask';
import { offeredPeScheduledDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

import { PaymentExtensionDate } from './PaymentExtensionDate';

interface Props {
  paymentExtensionDetails?: PaymentExtensionDetails;
}

export const PaymentExtensionPastDueBalance = ({ paymentExtensionDetails }: Props): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.dteGrey.light,
      color: theme.palette.primary.main,
      padding: '30px',
      gap: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '185px',
    },
    rootWrapperMobile: {
      width: '100%',
    },
    rootWrapperTabletAndDesktop: {
      width: '50%',
    },
    pastDueAmount: {
      fontSize: '26px',
      lineHeight: '35.41px',
      color: theme.palette.primary.main,
    },
  };

  const rootWrapperMobileSx = { ...sx.rootWrapper, ...sx.rootWrapperMobile };
  const rootWrapperTabletAndDesktopSx = { ...sx.rootWrapper, ...sx.rootWrapperTabletAndDesktop };
  const rootWrapperResponsiveSx = useResponsiveSx(
    rootWrapperMobileSx,
    rootWrapperTabletAndDesktopSx,
    rootWrapperTabletAndDesktopSx,
  );

  const pastDueBalance = Number(paymentExtensionDetails?.pastDueAmount);

  return (
    <Box data-testid="pe-past-due-balance-root-wrapper" sx={rootWrapperResponsiveSx}>
      <Typography data-testid="pe-account-past-due-balance-sub-title" fontWeight={600} variant="body1">
        Past-due Balance
      </Typography>
      <Typography data-testid="pe-account-past-due-balance-amount" fontWeight={600} sx={sx.pastDueAmount}>
        {ReadOnlyCurrencyMask(pastDueBalance)}
      </Typography>
      <PaymentExtensionDate date={offeredPeScheduledDate()} fontVariant="body2" />
    </Box>
  );
};
