import { IconProps } from './icon-props';
import { IconContainer } from './IconContainer';

const CheckMarkIcon: React.FC<IconProps> = (iconProps: IconProps): React.ReactElement => {
  const { containerSx, iconHeight = '13', iconWidth = '16' } = iconProps;
  return (
    <IconContainer data-testid="checkmark-icon" id="checkmark-icon" sx={containerSx}>
      <svg fill="none" height={iconHeight} viewBox="0 0 18 16" width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 1.50024L6.74359 14.5002L1 9.05838"
          stroke="#1E3575"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconContainer>
  );
};

export default CheckMarkIcon;
