import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled, SxProps, Theme } from '@mui/material';

import Footer from '../footer/Footer';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

/** the Root & Main containers create the height and sticky footer for this template * */
const StickyContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100dvh',
}));

const MainContainer = styled('main')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: '40px 24px',
  [theme.breakpoints.down('mobile')]: {
    padding: '40px 8px',
  },
}));

type NewPageTemplateProps = {
  titleTag: string;
  pageHeader: ReactNode;
  stickyFooter?: ReactNode;
  showContent: boolean;
  content: ReactNode;
  rootContainerSx?: SxProps;
  mainContainerSx?: SxProps;
};

export const NewPageTemplate = ({
  titleTag,
  pageHeader,
  stickyFooter = <Footer />,
  showContent,
  content,
  rootContainerSx,
  mainContainerSx,
}: NewPageTemplateProps): React.ReactElement => (
  <>
    <Helmet>
      <title>{titleTag}</title>
    </Helmet>
    <StickyContent sx={rootContainerSx}>
      {pageHeader}
      <MainContainer sx={mainContainerSx}>{showContent ? content : <LoadingSpinner />}</MainContainer>
      {stickyFooter}
    </StickyContent>
  </>
);
