import { SvgIcon } from '@mui/material';

const DteErrorIcon = (): React.ReactElement => (
  <SvgIcon data-testid="dte-error-icon" inheritViewBox>
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.1 19V5H16.96L23 12L16.96 19H1.1ZM2.54 17.5677H16.22L21.06 12L16.22 6.43228H2.54V17.5677ZM6.32 16.781L5.26 15.7723L8.82 12L5.26 8.22766L6.3 7.21902L9.82 10.951L13.34 7.21902L14.38 8.22766L10.82 12L14.38 15.7723L13.32 16.781L9.82 13.0692L6.32 16.781Z"
        fill="#E31937"
      />
    </svg>
  </SvgIcon>
);

export default DteErrorIcon;
