import { styled, Theme, Typography } from '@mui/material';

import messages from './messages';

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'open Sans',
  fontSize: '22px',
  fontWeight: '600',
  gap: '24px',
  lineHeight: '29.96px',
  textAlign: 'center',
}));

const ReviewPaymentModalSummaryTitle: React.FC = (): React.ReactElement => (
  <Title data-testid="review-payment-modal-summary-title">{messages.modalSummaryTitle}</Title>
);

export default ReviewPaymentModalSummaryTitle;
