import { MouseEvent, useState } from 'react';
import { Button } from '@mui/material';

import { logger } from 'services';

import { CopySnackbar, SnackbarState } from './CopySnackbar';

interface Props {
  bpId: string | unknown;
}

export const CopyUsername = ({ bpId }: Props): React.ReactElement => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    clipboardValue: '',
    isSnackbarOpen: false,
    horizontal: 'center',
    vertical: 'top',
  });

  const handleCopyToClipboard = async (
    event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    const username = `BP${event.currentTarget.textContent}@DTEACCEPT.COM`;

    try {
      await navigator.clipboard.writeText(username);
      setSnackbarState({ ...snackbarState, isSnackbarOpen: true, clipboardValue: username });
    } catch (err) {
      logger.info('Failed to copy to clipboard: ', err);
    }
  };

  const handleCloseSnackbar = (): void => {
    setSnackbarState({ ...snackbarState, isSnackbarOpen: false });
  };

  return (
    <>
      <Button onClick={handleCopyToClipboard}>{bpId as string}</Button>
      <CopySnackbar snackbarState={snackbarState} handleCloseSnackbar={(): void => handleCloseSnackbar()} />
    </>
  );
};
