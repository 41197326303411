import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { PaymentExtensionConfirmDetailsSubtitle, PaymentExtensionSubtitle, ReconnectSubtitle } from './Subtitle';

const PrimaryTitle = (title: string, subtitleComponent: JSX.Element): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    titleWrapper: {
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
  };

  return (
    <Box data-testid="title-container" sx={sx.titleWrapper}>
      <Typography variant="title" data-testid="title">
        {title}
      </Typography>
      {subtitleComponent}
    </Box>
  );
};

export const PaymentExtensionTitle = (): React.ReactElement =>
  PrimaryTitle('Payment Extension', <PaymentExtensionSubtitle />);

export const PaymentExtensionConfirmDetailsTitle = ({
  shutoffDate,
}: PaymentExtensionConfirmTitleProps): React.ReactElement =>
  PrimaryTitle('Payment Extension', <PaymentExtensionConfirmDetailsSubtitle shutoffDate={shutoffDate} />);

export const ReconnectTitle = (): React.ReactElement => PrimaryTitle('Payment Options', <ReconnectSubtitle />);
