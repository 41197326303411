import { ButtonBase, Typography, useTheme } from '@mui/material';

import { HeaderCancelButtonProps } from '../header/header';

const HeaderCancelButtonDesktop = ({ onClick }: HeaderCancelButtonProps): React.ReactElement => {
  const theme = useTheme();
  const buttonSx = {
    color: theme.palette.common.white,
    textTransform: 'none',
    height: '40px',
    padding: '0px 40px',
    border: `1px solid ${theme.palette.common.white}`,
    '&:hover': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.dteBlue.dark}`,
    },
    '&:focus': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.dteBlue.dark}`,
    },
  };

  return (
    <Typography
      component={ButtonBase}
      data-testid="header-cancel-button-desktop"
      id="headerCancelButtonDesktop"
      onClick={onClick}
      sx={buttonSx}
      variant="button"
      fontWeight={600}
    >
      Cancel
    </Typography>
  );
};

export default HeaderCancelButtonDesktop;
