import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { awsGet } from '../adapter/aws-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

import { BillComparisonResponse } from './BillComparisonResponse';

const billComparison = async (
  premiseId: number,
  accountNumber: string,
): Promise<AxiosResponse<BillComparisonResponse>> =>
  awsGet<BillComparisonResponse>(`/api/account/${accountNumber}/site/${premiseId}/billComparison`);

const localMockBillComparison = async (): Promise<AxiosResponse<BillComparisonResponse>> =>
  localMockApiResponse(Mocks.billComparisonResponseBody);

export const billComparisonApi = apiOrLocalMock(billComparison, localMockBillComparison);

interface BillComparisonQueryProps {
  queryKey?: (string | number)[] | undefined;
  enabled: boolean;
  onError: (error: unknown) => void;
  premise: number | undefined;
  accountNumber: string | undefined;
}

interface IUseBillComparisonResponse {
  isBillComparisonSuccess: boolean;
  billComparisonResponse: AxiosResponse<BillComparisonResponse> | undefined;
}

export const useBillComparison = ({
  queryKey,
  enabled,
  onError,
  premise,
  accountNumber,
}: BillComparisonQueryProps): IUseBillComparisonResponse => {
  const { isSuccess: isBillComparisonSuccess, data: billComparisonResponse } = useQuery({
    queryKey,
    queryFn: () => billComparisonApi(premise, accountNumber),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    onError,
  });

  return {
    isBillComparisonSuccess,
    billComparisonResponse,
  };
};
