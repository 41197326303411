import { AxiosResponse } from 'axios';

import { axiosGet, axiosPost } from './axios-adapter';

interface AzureConfigProps {
  baseURL: string | undefined;
  headers: {
    Authorization: string;
  };
}

// sets bearer token
const azureConfig = (): AzureConfigProps => ({
  baseURL: process.env.REACT_APP_BACKEND_HOST,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('JWT')}`,
  },
});

export const azureGet = async <T>(url: string): Promise<AxiosResponse<T>> => axiosGet(url, azureConfig());

export const azurePost = async <T>(url: string, body: unknown): Promise<AxiosResponse<T>> =>
  axiosPost(url, body, azureConfig());
