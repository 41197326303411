import { styled, Theme, Typography } from '@mui/material';

import AccountInformation from './account-information/AccountInformation';
import EnrollmentDetails from './EnrollmentDetails';
import InstallmentAmountDescription from './InstallmentAmountDescription';
import messages from './messages';

const AccountDetailsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  borderTop: `1px solid ${theme.palette.dteGrey.main}`,
  padding: '24px auto',
}));

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const EnrollDetailsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  borderTop: `1px solid ${theme.palette.dteGrey.main}`,
  display: 'flex',
  height: '100%',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
  },
}));

const HeadingContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  alignContent: 'center',
  backgroundColor: theme.palette.dteBlue.dark,
  color: theme.palette.common.white,
  display: 'flex',
  height: '52px',
  justifyContent: 'center',
}));

const PASummaryHeading = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '27.24px',
  textAlign: 'center',
  variant: 'h4',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '18px',
    lineHeight: '24.51px',
  },
}));

const SummaryContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  widht: '100%',
}));

interface IPaymentAgreementSummaryProps {
  accountInformation: IAccountDetailsResponse;
  downPaymentAmount: string;
  downPaymentStatus: string;
  enrollDetails: IEnrollDetails;
}

export const PaymentAgreementSummary: React.FC<IPaymentAgreementSummaryProps> = (
  props: IPaymentAgreementSummaryProps,
): React.ReactElement => {
  const { accountInformation, downPaymentAmount, downPaymentStatus, enrollDetails } = props;
  const { installmentAmount, numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = enrollDetails;
  const { accounts, customerName } = accountInformation;
  const { accountNumber, serviceAddresses } = accounts[0];

  return (
    <Container data-testid="payment-agreement-summary">
      <SummaryContainer>
        <HeadingContainer>
          <PASummaryHeading>{messages.heading}</PASummaryHeading>
        </HeadingContainer>
        <AccountDetailsContainer>
          <AccountInformation accountNumber={accountNumber} address={serviceAddresses[0]} name={customerName} />
        </AccountDetailsContainer>
        <EnrollDetailsContainer data-testid="enrollment-details">
          <EnrollmentDetails
            downPaymentAmount={downPaymentAmount}
            downPaymentStatus={downPaymentStatus}
            installmentAmount={installmentAmount}
            numberOfInstallmentsOpted={numberOfInstallmentsOpted}
            numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
          />
        </EnrollDetailsContainer>
      </SummaryContainer>
      <InstallmentAmountDescription />
    </Container>
  );
};
