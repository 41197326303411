import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  monthsShort: ['Jan.', 'Feb.', 'March', 'April', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
});

export const offeredPeScheduledDate = (): dayjs.Dayjs => dayjs().add(10, 'day');

export const dayjsDate = (date: string): dayjs.Dayjs => dayjs(date);
