import { Box, styled, Theme, Typography } from '@mui/material';

import {
  PayLaterFiveDaysIcon,
  ScheduleDownPaymentIcon,
} from '../../../component-library/icons/payment-agreement-icons';

import messages from './messages';
import PAEnrollmentCard from './PAEnrollmentCard';

const CardContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '327px',
  },
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
    flexDirection: 'column',
  },
}));

const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '600',
  variant: 'body1',
}));

const DownPaymentSelection: React.FC = (): React.ReactElement => (
  <ContentWrapper data-testid="down-payment-selection">
    <Heading>{messages.enrollmentHeading}</Heading>
    <CardContainer>
      <PAEnrollmentCard
        buttonLabel={messages.scheduleDownPaymentButtonLabel}
        buttonValue="SchedulePayment"
        description={messages.scheduleDownPaymentDescription}
        icon={<ScheduleDownPaymentIcon />}
        id="schedule-down-payment-enrollment-card"
      />
      <PAEnrollmentCard
        buttonLabel={messages.payLaterButtonLabel}
        buttonValue="PayLater"
        description={messages.payLaterDescription}
        icon={<PayLaterFiveDaysIcon />}
        id="pay-later-five-days-enrollment-card"
      />
    </CardContainer>
  </ContentWrapper>
);

export default DownPaymentSelection;
