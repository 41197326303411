import React from 'react';
import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import DteSuccessIcon from '../../../component-library/icons/DteSuccessIcon';
import { useResponsiveSx } from '../../../theme/breakpoints/responsiveHooks';

import messages from './messages';

const IconWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    marginTop: '3px',
  },
}));

const DefaultSuccessMessage: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    mobileWrapper: {
      justifyContent: 'flex-start',
      textAlign: 'center',
      [theme.breakpoints.down('tablet')]: {
        justifyContent: 'center',
        width: '100%',
      },
      [theme.breakpoints.down('mobile')]: {
        justifyContent: 'center',
        width: '304px',
      },
    },
    rootWrapper: {
      alignItems: 'flex-start',
      display: 'flex',
      gap: '12px',
    },
    tabletAndDesktopWrapper: {
      justifyContent: 'center',
    },
    textWrapper: {
      [theme.breakpoints.down(376)]: {
        width: '244px',
      },
    },
  };

  const mobileStyling = { ...sx.rootWrapper, ...sx.mobileWrapper };
  const tabletAndDesktopStyling = { ...sx.rootWrapper, ...sx.tabletAndDesktopWrapper };
  const responsiveStyles = useResponsiveSx(mobileStyling, tabletAndDesktopStyling, tabletAndDesktopStyling);

  return (
    <Box data-testid="default-success-message" sx={responsiveStyles}>
      <IconWrapper>
        <DteSuccessIcon color={theme.palette.dteBlue.dark} height={24} width={24} />
      </IconWrapper>
      <Typography
        color={theme.palette.primary.main}
        fontSize="20px"
        fontWeight={600}
        lineHeight="27.24px"
        sx={sx.textWrapper}
      >
        {messages.defaultSuccessMessage}
      </Typography>
    </Box>
  );
};

export default DefaultSuccessMessage;
