import { ReactNode } from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { ReadOnlyCurrencyMask } from 'component-library/currency/ReadOnlyCurrencyMask';

type Props = {
  label: ReactNode;
  calculatedTotal: number;
};

const RadioLabelAdapter: React.FC<Props> = (props: Props): JSX.Element => {
  const { label, calculatedTotal } = props;
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '22px',
      color: theme.palette.primary.main,
    },
    labelDescription: {
      paddingTop: '8px',
    },
  };

  const formattedTotal: JSX.Element = ReadOnlyCurrencyMask(calculatedTotal);

  return (
    <Box sx={sx.rootWrapper}>
      {label}
      <Typography data-testid="radio-label-amount-due" sx={sx.labelDescription} variant="title">
        {formattedTotal}
      </Typography>
    </Box>
  );
};

export default RadioLabelAdapter;
