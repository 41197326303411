import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useUserState } from 'auth/authenticate';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import PageTitles from 'constants/pageTitles';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import { googleTagManager } from 'services';

import HowPaymentAgreementWorksContent from './components/HowPaymentAgreementWorksContent';
import PaymentAgreementHeader from './components/PaymentAgreementHeader';

const HowPaymentAgreementWorksPage: React.FC = (): React.ReactElement => {
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const { isAuthenticated } = useUserState();

  useEffect((): void => {
    if (isGoogleTagManagerLoaded && isAuthenticated) {
      googleTagManager.trackViewEvent({
        actionDetail: 'pa process overview',
        actionObject: 'how pa works',
        authenticationStatus: isAuthenticated,
        transactionStepName: 'how it works',
        transactionType: 'payment agreement',
        userType: 'web',
      });
    }
  }, [isGoogleTagManagerLoaded, isAuthenticated]);

  return (
    <>
      <Helmet>
        <title>{PageTitles.HOW_PAYMENT_AGREEMENT_WORKS}</title>
      </Helmet>
      <PageTemplate
        header={<PaymentAgreementHeader displayCancelButton transactionStepName="how it works" userType="web" />}
        allViewportPaddingTop="16px"
        tabletDesktopContentWidth="740px"
        dataTestId="how-payment-agreement-works-page"
      >
        <HowPaymentAgreementWorksContent />
      </PageTemplate>
    </>
  );
};

export default HowPaymentAgreementWorksPage;
