import * as React from 'react';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { styled, SxProps } from '@mui/material';

import { Bold } from '../typography/Bold';

import { StyledCtaLink } from './styledLinkReactRouterDom';

type BreadcrumbRouterLinkProps = {
  containerSx?: SxProps;
  dataTestId?: string;
  href: string;
  linkSx?: SxProps;
  onClickHandler?: () => void;
  text: string;
};

export const BreadcrumbRouterLink = ({
  containerSx,
  dataTestId = 'breadcrumb',
  href,
  linkSx,
  onClickHandler,
  text,
}: BreadcrumbRouterLinkProps): React.ReactElement => {
  const Container = styled('div')({});

  const ctaLinkSx = {
    cursor: 'pointer',
    ...linkSx,
  };

  const logoSx = { fontSize: '18px' };

  // the container here is to limit the anchor that can stretch on its own
  return (
    <Container sx={{ ...containerSx }} data-testid={dataTestId}>
      <StyledCtaLink to={href} sx={ctaLinkSx} onClick={onClickHandler}>
        <ArrowBackIosRounded sx={logoSx} />
        <Bold>{text}</Bold>
      </StyledCtaLink>
    </Container>
  );
};
