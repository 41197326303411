import { AxiosResponse } from 'axios';

import { apiPaths } from 'constants/apiPaths';
import { mockBaseSuccessRestoreDetails } from 'testing/mocks/data';

import { azureGet } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';

const restoreDetails = async (): Promise<AxiosResponse<IRestoreDetailsResponse>> =>
  azureGet<IRestoreDetailsResponse>(apiPaths.restoreDetailsApiPath);

const localMockRestoreDetails = async (): Promise<AxiosResponse<IRestoreDetailsResponse>> => {
  const localMock = await localMockApiResponse(mockBaseSuccessRestoreDetails);
  return localMock;
};

export const restoreDetailsApi = apiOrLocalMock(restoreDetails, localMockRestoreDetails);
