import { styled, Theme, Typography } from '@mui/material';

import { CombinedEligibilityCheckResponse } from '../../../api/eligibility-api/EligibilityCheckResponse';
import { DteTextLink } from '../../../component-library/links/DteTextLink';
import { Bold } from '../../../component-library/typography/Bold';

const AssistanceInformationContent = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
  color: theme.palette.secondary.main,
}));

const AgencyContactInformation = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

const AssistanceCopy = styled(Typography)({
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
});

const PhoneNumber = styled('span')({
  overflowWrap: 'break-word',
  whiteSpace: 'nowrap',
});

type AgencyPaymentAssistanceInformationProps = {
  eligibilityResponse: CombinedEligibilityCheckResponse;
  agencyPhoneNumberOnClickHandler: () => void;
};
export const AgencyPaymentAssistanceInformation = ({
  eligibilityResponse,
  agencyPhoneNumberOnClickHandler,
}: AgencyPaymentAssistanceInformationProps): React.ReactElement => {
  const showAllAgencyInformation =
    eligibilityResponse?.dunningLockDetails &&
    eligibilityResponse?.dunningLockDetails[0].agencyName &&
    eligibilityResponse?.dunningLockDetails[0].agencyNumber &&
    eligibilityResponse?.dunningLockDetails[0].agencyPaymentAmount &&
    eligibilityResponse?.dunningLockDetails[0].agencyPaymentDate;

  const agencyName =
    showAllAgencyInformation &&
    eligibilityResponse?.dunningLockDetails &&
    eligibilityResponse?.dunningLockDetails[0].agencyName
      ? eligibilityResponse?.dunningLockDetails[0].agencyName
      : 'An agency';

  const agencyPhoneNumber =
    eligibilityResponse?.dunningLockDetails && eligibilityResponse?.dunningLockDetails[0].agencyNumber;

  return (
    <AssistanceInformationContent data-testid="agency-payment-assistance-information-content">
      <AssistanceCopy>
        {`Great News! ${agencyName} has made an energy assistance payment on your account.`}
      </AssistanceCopy>
      {showAllAgencyInformation ? (
        <AgencyContactInformation>
          <AssistanceCopy>If you have questions, please contact:</AssistanceCopy>
          <AssistanceCopy>
            <Bold>
              {eligibilityResponse?.dunningLockDetails && eligibilityResponse?.dunningLockDetails[0].agencyName}
            </Bold>
            {` at `}
            <PhoneNumber>
              <DteTextLink
                href={`tel:${agencyPhoneNumber}`}
                name={agencyPhoneNumber!}
                variant="body1"
                onClick={agencyPhoneNumberOnClickHandler}
              />
            </PhoneNumber>
            .
          </AssistanceCopy>
        </AgencyContactInformation>
      ) : (
        <AssistanceCopy>
          If you have questions, please contact your agency. DTE Representatives do not have additional information
          about agency payments.
        </AssistanceCopy>
      )}
    </AssistanceInformationContent>
  );
};
