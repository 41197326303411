import React, { KeyboardEvent, useContext } from 'react';
import { styled, Theme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DialogContext } from 'component-library/dialog/DialogContext';
import { DteDialog } from 'component-library/dialog/DteDialog';
import { StyledTextLink } from 'component-library/links/StyledLink';
import { ReconnectSecurityDepositDetailsClickEvent } from 'gtm/gtmEventsReconnect';
import { googleTagManager } from 'services';

import { SecurityDepositDialogContent } from './SecurityDepositDialogContent';

export const SecurityDepositNote: React.FC = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const { setOpen } = useContext(DialogContext);

  const securityDepositOnClickHandler = (): void => {
    setOpen(true);
    googleTagManager.trackButtonClick({
      ...ReconnectSecurityDepositDetailsClickEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const securityDepositOnCloseHandler = (): void => {
    setOpen(false);
  };

  const securityDepositKeyboardHandler = (event: KeyboardEvent): void => {
    if (event.code === 'Enter') {
      securityDepositOnClickHandler();
    }
  };

  const Note = styled('div')(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '16px',
    fontWeight: '600',
    padding: '4px 0 32px 0',
  }));

  return (
    <Note>
      {'* A Security Deposit may be required when service is disconnected for nonpayment. Learn more about '}
      <StyledTextLink
        tabIndex={0}
        role="link"
        onClick={securityDepositOnClickHandler}
        onKeyDown={securityDepositKeyboardHandler}
      >
        Security Deposit and refund details
      </StyledTextLink>
      .
      <DteDialog
        content={<SecurityDepositDialogContent />}
        onCloseHandler={securityDepositOnCloseHandler}
        title="Security Deposit"
      />
    </Note>
  );
};
