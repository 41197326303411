import { styled, Theme, Typography } from '@mui/material';

import messages from '../messages';

const AssistanceInformationContent = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '600px',
  },
}));

const AssistanceTitle = styled(Typography)({
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
});

const AssistanceCopy = styled(Typography)({
  fontFamily: 'Open Sans,sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
});

type DefaultAssistanceInformationProps = {
  dunningLockDetails: IDunningLockDetails;
};
export const DefaultAssistanceInformation = ({
  dunningLockDetails,
}: DefaultAssistanceInformationProps): React.ReactElement => {
  const agencyName = dunningLockDetails.agencyName || 'An agency';

  return (
    <AssistanceInformationContent data-testid="default-assistance-information-content">
      <AssistanceTitle>{messages.defaultAssistanceTitle}</AssistanceTitle>
      <AssistanceCopy>{`${agencyName} ${messages.defaultAssistancePaymentText}`}</AssistanceCopy>
      <AssistanceCopy>{messages.defaultAssistanceRepresentativeText}</AssistanceCopy>
    </AssistanceInformationContent>
  );
};
