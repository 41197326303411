import React from 'react';
import { Box } from '@mui/material';

export const DeadlineNoticeIcon = (): React.ReactElement => (
  <Box data-testid="deadline-notice-icon">
    <svg width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85714 11.8957L5 11.8957C3.34315 11.8957 2 13.2389 2 14.8957L2 28C2 29.6569 3.34315 31 5 31L10 31M8.85714 11.8957L6.69308 9M8.85714 11.8957L6.69308 14.7903"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9219 13.5395V10.3979"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9219 32.5453V29.4038"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2871 25.398C13.2871 25.398 13.6013 29.2463 17.7638 29.4034C21.9263 29.639 22.3975 26.4189 22.3975 25.7906C22.3975 25.1623 22.476 22.6491 17.8423 21.3925C13.2086 20.1359 13.6013 17.3871 13.6798 17.073C13.7583 16.6803 14.0725 13.8529 17.6852 13.5388C21.298 13.2246 22.7116 16.7588 22.7116 17.3871"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1429 30.1062H31C32.6569 30.1062 34 28.7631 34 27.1062V14C34 12.3431 32.6569 11 31 11H26M27.1429 30.1062L29.3069 33M27.1429 30.1062L29.3069 27.2102"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

export const InterruptionNoticeIcon = (): React.ReactElement => (
  <Box data-testid="interruption-notice-icon">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.9521 8.42318C25.3553 6.13409 21.8531 4.73079 18 4.73079C14.4946 4.73079 11.2798 5.89222 8.77273 7.82541C5.5524 10.3086 3.5 14.0653 3.5 18.2695M27.9521 8.42318L27.9521 3.5M27.9521 8.42318L23.2727 9.65397M7.45455 27.5619C10.0982 30.1765 13.845 31.8083 18 31.8083C21.8531 31.8083 25.3553 30.405 27.9521 28.1159C30.7524 25.6475 32.5 22.1491 32.5 18.2695M7.45455 27.5619L7.45455 32.5M7.45455 27.5619L11.8576 26.6295"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6426 13.7781L17.9193 18.0542M17.9193 18.0542L22.1634 22.2977M17.9193 18.0542L22.1791 13.795M17.9193 18.0542L13.6583 22.3146"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </Box>
);

export const PaymentDifferenceNoticeIcon = (): React.ReactElement => (
  <Box data-testid="payment-difference-notice-icon">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.5"
        y="9.90186"
        width="25.3"
        height="17.3105"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x="1.5"
        y="4.57593"
        width="25.3"
        height="5.32632"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <line
        x1="8.57422"
        y1="2.66284"
        x2="8.57422"
        y2="6.48916"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="19.7246"
        y1="6.48901"
        x2="19.7246"
        y2="2.6627"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="24.7371" cy="24.325" r="9.0125" fill="#F6F6F6" stroke="#1E3575" strokeWidth="1.5" />
      <path
        d="M24.6956 19.9316V18.2239M24.6956 30.2631V28.5554M22.1777 26.3771C22.1777 26.3771 22.3485 28.469 24.6112 28.5544C26.8739 28.6825 27.13 26.9321 27.13 26.5905C27.13 26.249 27.1727 24.8829 24.6539 24.1998C22.135 23.5167 22.3485 22.0225 22.3912 21.8517C22.4339 21.6383 22.6047 20.1014 24.5685 19.9306C26.5323 19.7598 27.3008 21.681 27.3008 22.0225"
        stroke="#1E3575"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);
