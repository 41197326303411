import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useUserState } from 'auth/authenticate';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import googleTagManager from 'services/googleTagManager';

import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import { GenericErrorPageContent } from '../component-library/templates/generic-error';
import { PageTitles } from '../constants';

import PaymentOffersHeader from './components/PaymentOffersHeader';

const PaymentOffersErrorPage: React.FC = (): React.ReactElement => {
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const { isAuthenticated } = useUserState();
  const gtmPOErrorPageEvent = {
    transactionStepName: 'payment offers error',
    transactionType: 'payment agreement',
    userType: '',
  };

  useEffect((): void => {
    if (isGoogleTagManagerLoaded && isAuthenticated) {
      googleTagManager.trackViewEvent({
        ...gtmPOErrorPageEvent,
        actionDetail: 'contact us page',
        actionObject: 'contact us',
        authenticationStatus: isAuthenticated,
      });
    }
  }, [isGoogleTagManagerLoaded, isAuthenticated]);

  const handleContactUsButtonClickGtm = (): void => {
    googleTagManager.trackButtonClick({
      ...gtmPOErrorPageEvent,
      actionDetail: 'contact us page',
      actionObject: 'contact us',
      authenticationStatus: isAuthenticated,
    });
  };

  const handleViewCurrentBillButtonClickGtm = (): void => {
    googleTagManager.trackButtonClick({
      ...gtmPOErrorPageEvent,
      actionDetail: 'contact us page',
      actionObject: 'view current bill',
      authenticationStatus: isAuthenticated,
    });
  };

  return (
    <>
      <Helmet>
        <title>{PageTitles.ERROR}</title>
      </Helmet>
      <PageTemplate
        header={<PaymentOffersHeader displayCancelButton={false} />}
        allViewportPaddingTop="40px"
        tabletDesktopContentWidth="470px"
        dataTestId="payment-offers-error-page"
      >
        <GenericErrorPageContent
          onContactUsButtonClick={handleContactUsButtonClickGtm}
          onViewCurrentBillButtonClick={handleViewCurrentBillButtonClickGtm}
        />
      </PageTemplate>
    </>
  );
};

export default PaymentOffersErrorPage;
