import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  rootWrapper: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const DteTooltipKeyboardFocusIcon: React.FC = (): React.ReactElement => (
  <Box data-testid="dte-tooltip-keyboard-focus-icon" sx={sx.rootWrapper}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="8" fill="#0072CE" />
      <circle r="0.914286" transform="matrix(-1 0 0 1 9.99978 6.34287)" fill="white" />
      <rect width="1.82857" height="7.31429" rx="0.914286" transform="matrix(-1 0 0 1 10.9141 8.17145)" fill="white" />
      <circle cx="10" cy="10" r="9.5" stroke="#1E3575" />
    </svg>
  </Box>
);

export default DteTooltipKeyboardFocusIcon;
