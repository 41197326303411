import { Box, SxProps, Typography, useTheme } from '@mui/material';

import HeaderCancelButtonMobile from '../buttons/HeaderCancelButtonMobile';
import DteLogo from '../logo/DteLogo';
import DteLogoInteractive from '../logo/DteLogoInteractive';

import { HeaderProps } from './header';

const HeaderMobile = ({
  titleText,
  isHeaderButtonRendered,
  onClick,
  isInteractiveDteLogo,
}: HeaderProps): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    headerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '90px',
      background: theme.palette.dteBlue.dark,
      padding: '0px 24px',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      paddingTop: '16px',
      paddingBottom: '13px',
      flexShrink: '0',
    },
    headerTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    invisibleDiv: {
      width: '76px',
    },
    title: {
      color: theme.palette.common.white,
      display: 'flex',
      fontSize: '18px',
      fontWeight: '400',
      justifyContent: 'center',
      lineHeight: '24px',
    },
  };
  const dteLogo = isInteractiveDteLogo ? (
    <DteLogoInteractive width={71} height={24} />
  ) : (
    <DteLogo width={71} height={24} />
  );

  return (
    <Box role="banner" data-testid="header-mobile" sx={sx.headerWrapper}>
      <Box sx={sx.headerTop}>
        <Box sx={sx.invisibleDiv} />
        {dteLogo}
        {isHeaderButtonRendered ? <HeaderCancelButtonMobile onClick={onClick} /> : <Box sx={sx.invisibleDiv} />}
      </Box>
      <Typography sx={sx.title} variant="h1" data-testid="header-title">
        {titleText}
      </Typography>
    </Box>
  );
};

export default HeaderMobile;
