import gtmBaseEvent from 'constants/gtmBaseEvent';

const mockActionDetails = 'mock action details';
const mockActionObject = 'mock action object';
const mockActionType = 'mock action type';
const mockTransactionType = 'payment agreement';
const mockTransactionStepName = 'mock transaction step';
const mockUserType = 'web';

export const mockGtmEvent = {
  actionDetail: mockActionDetails,
  actionObject: mockActionObject,
  actionType: mockActionType,
  transactionStepName: mockTransactionStepName,
  transactionType: mockTransactionType,
  userType: mockUserType,
};

export const expectedGtmEvent = {
  ...gtmBaseEvent,
  action_detail: mockActionDetails,
  action_object: mockActionObject,
  action_type: mockActionType,
  transaction_step_name: mockTransactionStepName,
  transaction_type: mockTransactionType,
  user_type: 'web',
};
