const accountHistoryPath = `${process.env.REACT_APP_ACCOUNT_HISTORY_PATH}`;
const currentBillBasePath = `${process.env.REACT_APP_CURRENT_BILL_BASE_PATH}`;
const currentBillBaseUrl = `${process.env.REACT_APP_CURRENT_BILL_BASE_URL}`;
const currentBillPath = `${process.env.REACT_APP_CURRENT_BILL_PATH}`;
const documentSubmissionBaseUrl = `${process.env.REACT_APP_DOCUMENT_SUBMISSION_BASE_URL}`;
const paymentCenterBaseUrl = `${process.env.REACT_APP_PAYMENT_CENTER_BASE_URL}`;
const gigyaBaseUrl = `${process.env.REACT_APP_GIGYA_BASE_URL}`;
const gigyaKey = `${process.env.REACT_APP_GIGYA_SITE_KEY}`;

const medicalEmergencyHoldRequestFormUrl =
  'https://www.dteenergy.com/content/dam/dteenergy/deg/website/common/billing-and-payment/energy-assistance/general-assistance/Medical-emergency-hold-request.pdf';

const gigyaRoute = '/JS/gigya.js';
const lambdaReconnectPath = '/api/sessions/reconnect-service-accounts';
const lambdaPaymentExtensionPath = '/api/sessions/payment-extension';
const lambdaPaymentAgreementPath = '/api/sessions/payment-agreement';

const loginPath = '/login';
const paymentCenterReconnectPath = '/pay-bill/reconnect';
const paymentCenterPaymentExtensionPath = '/pay-bill/payment-extension';
const paymentCenterPaymentAgreementPath = '/pay-bill/payment-agreement';
const documentSubmissionPath = '/quicklinks/web-form';

const currentBillBaseUrlString = `${currentBillBaseUrl}${currentBillBasePath}`;
const accountHistoryUrl = `${currentBillBaseUrlString}${accountHistoryPath}`;

const contactUsLink = 'https://dteenergy.com/us/en/quicklinks/contact-us.html';

const kioskUrl = 'https://www.dteenergy.com/us/en/residential/billing-and-payments/billing/kiosks.html';

const paymentLocationUrl = 'https://newlook.dteenergy.com/wps/wcm/connect/dte-web/quicklinks/payment-locations';

export const urls = {
  accountHistoryUrl,
  accountHistoryUrlFromSchedueledPe: `${accountHistoryUrl}?pescheduled=true`,
  accountHistoryUrlFromSchedueledPa: `${accountHistoryUrl}?pascheduled=true`,
  contactUsLink,
  currentBillBaseUrl,
  currentBillUrl: `${currentBillBaseUrlString}${currentBillPath}`,
  dteHomepageUrl: `${currentBillBaseUrl}`,
  documentSubmissionUrl: `${documentSubmissionBaseUrl}${documentSubmissionPath}`,
  loginUrl: `${currentBillBaseUrlString}${loginPath}`,
  medicalEmergencyHoldRequestFormUrl,
  paymentCenterPaymentExtensionUrl: `${paymentCenterBaseUrl}${paymentCenterPaymentExtensionPath}`,
  paymentCenterPaymentAgreementUrl: `${paymentCenterBaseUrl}${paymentCenterPaymentAgreementPath}`,
  paymentCenterReconnectUrl: `${paymentCenterBaseUrl}${paymentCenterReconnectPath}`,
  gigyaUrl: `${gigyaBaseUrl}${gigyaRoute}?apiKey=${gigyaKey}`,
  kioskUrl,
  lambdaPaymentAgreementUrl: `${paymentCenterBaseUrl}${lambdaPaymentAgreementPath}`,
  lambdaPaymentExtensionUrl: `${paymentCenterBaseUrl}${lambdaPaymentExtensionPath}`,
  lambdaReconnectUrl: `${paymentCenterBaseUrl}${lambdaReconnectPath}`,
  paymentLocationUrl,
};
