import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  wrapper: {
    width: '184px',
    height: '100px',
  },
};

const PageNotFoundIcon = (): React.ReactElement => (
  <Box data-testid="page-not-found-error-icon" sx={sx.wrapper}>
    <svg width="184" height="100" viewBox="0 0 184 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.971 83.2748C126.828 93.2224 113.673 99.4621 99.053 99.4621C85.643 99.4621 73.4658 94.2125 64.486 85.6671C59.2733 90.4782 52.307 93.4174 44.6541 93.4174C28.5008 93.4174 15.4061 80.3226 15.4061 64.1694C15.4061 53.933 20.6647 44.9248 28.628 39.6989C31.286 21.3271 47.0976 7.21274 66.2058 7.21274C68.2842 7.21274 70.3236 7.37972 72.3112 7.701C80.0452 2.82394 89.2175 0 99.053 0C116.35 0 131.595 8.73345 140.574 22.0082C153.746 22.0152 164.422 32.6951 164.422 45.8684C164.422 49.8889 163.427 53.6771 161.671 57.0006C164.957 60.4195 166.993 65.1668 166.993 70.4147C166.993 80.8306 158.972 89.2744 149.077 89.2744C143.903 89.2744 139.242 86.9662 135.971 83.2748Z"
        fill="#59BEC9"
      />
      <path
        d="M53.2062 63.0841C48.9689 64.9053 44.1726 65.356 39.6468 64.4184C35.8783 63.6431 32.4524 60.722 32.5245 56.6109C32.5606 54.5374 33.3179 52.5539 34.2014 50.6607C35.3193 48.2445 37.2667 45.3956 36.5815 42.6008C36.1668 40.9239 34.8325 39.6076 33.3179 38.8142C25.5825 34.7392 17.1259 39.0486 10.0938 42.5106C6.90229 44.0973 3.87307 45.5759 0.952026 46.4775"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.906 36.4327C140.134 35.4229 143.163 33.6739 146.264 32.3036C149.69 30.807 153.098 29.2383 156.741 28.2646C160.203 27.345 164.151 27.0926 167.74 27.7236C171.111 28.3187 174.447 30.0136 176.304 32.8805C178.919 36.9015 177.548 41.229 175.24 44.9434C174.123 46.7465 173.167 48.2971 173.491 50.1904C173.78 51.9034 175.258 53.1475 176.827 53.7786C178.901 54.6261 180.83 55.185 183.048 55.4194"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.5864 62.199L71.1839 65.1201C69.5071 65.6971 67.6679 64.8135 67.0729 63.1367L63.8633 53.9228C63.2863 52.2459 64.1698 50.4067 65.8467 49.8117L74.2492 46.8906L79.5864 62.199Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5047 64.2544L62.8527 65.8772C61.8249 66.2379 60.6709 65.6428 60.2923 64.561L57.6778 57.042C57.2991 55.9601 57.8401 54.7881 58.8678 54.4275L63.5199 52.8047"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.4202 45.2679L75.0957 45.3761C74.4285 45.6105 74.0679 46.3498 74.3023 47.0169L79.5313 62.0549C79.7657 62.722 80.505 63.0827 81.1721 62.8483L81.4967 62.7401C82.1639 62.5057 82.5245 61.7664 82.2901 61.0992L77.0611 46.0613C76.8447 45.3941 76.1054 45.0335 75.4202 45.2679Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-7-inside-1_3564_45251" fill="white">
        <path d="M61.987 56.4107C62.1493 56.3567 62.3476 56.4468 62.4017 56.6091L64.2409 61.8742C64.295 62.0365 64.2048 62.2348 64.0426 62.2889C63.8803 62.343 63.6819 62.2528 63.6279 62.0905L61.7887 56.8255C61.7346 56.6632 61.8247 56.4648 61.987 56.4107Z" />
      </mask>
      <path
        d="M61.987 56.4107C62.1493 56.3567 62.3476 56.4468 62.4017 56.6091L64.2409 61.8742C64.295 62.0365 64.2048 62.2348 64.0426 62.2889C63.8803 62.343 63.6819 62.2528 63.6279 62.0905L61.7887 56.8255C61.7346 56.6632 61.8247 56.4648 61.987 56.4107Z"
        fill="#1E3575"
      />
      <path
        d="M62.4017 56.6091L61.0961 57.0443C61.0982 57.0505 61.1003 57.0567 61.1024 57.0629L62.4017 56.6091ZM64.2409 61.8742L65.5466 61.439C65.5445 61.4327 65.5424 61.4265 65.5402 61.4203L64.2409 61.8742ZM63.6279 62.0905L64.9335 61.6553C64.9314 61.6491 64.9293 61.6429 64.9271 61.6367L63.6279 62.0905ZM61.7887 56.8255L60.483 57.2607C60.4851 57.2669 60.4872 57.2731 60.4894 57.2793L61.7887 56.8255ZM62.4222 57.7164C62.1299 57.8138 61.8542 57.7706 61.6489 57.6741C61.442 57.5767 61.2066 57.3759 61.0961 57.0443L63.7074 56.1739C63.3936 55.2326 62.3759 54.8304 61.5518 55.1051L62.4222 57.7164ZM61.1024 57.0629L62.9416 62.328L65.5402 61.4203L63.701 56.1552L61.1024 57.0629ZM62.9353 62.3094C62.8378 62.017 62.881 61.7413 62.9776 61.5361C63.075 61.3292 63.2758 61.0938 63.6073 60.9832L64.4778 63.5945C65.4191 63.2808 65.8212 62.263 65.5466 61.439L62.9353 62.3094ZM63.6073 60.9832C63.8997 60.8858 64.1754 60.929 64.3807 61.0256C64.5876 61.1229 64.823 61.3237 64.9335 61.6553L62.3222 62.5258C62.636 63.467 63.6537 63.8692 64.4778 63.5945L63.6073 60.9832ZM64.9271 61.6367L63.088 56.3716L60.4894 57.2793L62.3286 62.5444L64.9271 61.6367ZM63.0943 56.3902C63.1918 56.6826 63.1486 56.9583 63.052 57.1636C62.9546 57.3705 62.7538 57.6059 62.4222 57.7164L61.5518 55.1051C60.6105 55.4188 60.2083 56.4366 60.483 57.2607L63.0943 56.3902Z"
        fill="#1E3575"
        mask="url(#path-7-inside-1_3564_45251)"
      />
      <mask id="path-9-inside-2_3564_45251" fill="white">
        <path d="M60.436 56.9522C60.5983 56.8982 60.7966 56.9883 60.8507 57.1506L62.6899 62.4157C62.744 62.578 62.6538 62.7763 62.4915 62.8304C62.3293 62.8845 62.1309 62.7943 62.0768 62.6321L60.2376 57.367C60.1836 57.1867 60.2737 57.0063 60.436 56.9522Z" />
      </mask>
      <path
        d="M60.436 56.9522C60.5983 56.8982 60.7966 56.9883 60.8507 57.1506L62.6899 62.4157C62.744 62.578 62.6538 62.7763 62.4915 62.8304C62.3293 62.8845 62.1309 62.7943 62.0768 62.6321L60.2376 57.367C60.1836 57.1867 60.2737 57.0063 60.436 56.9522Z"
        fill="#1E3575"
      />
      <path
        d="M60.8507 57.1506L59.5451 57.5858C59.5471 57.592 59.5493 57.5983 59.5514 57.6045L60.8507 57.1506ZM62.6899 62.4157L63.9955 61.9805C63.9935 61.9742 63.9913 61.968 63.9892 61.9618L62.6899 62.4157ZM62.0768 62.6321L63.3825 62.1968C63.3804 62.1906 63.3783 62.1844 63.3761 62.1782L62.0768 62.6321ZM60.2376 57.367L58.9194 57.7624C58.9253 57.782 58.9316 57.8015 58.9384 57.8208L60.2376 57.367ZM60.8712 58.2579C60.5789 58.3553 60.3032 58.3121 60.0979 58.2156C59.891 58.1182 59.6556 57.9174 59.5451 57.5858L62.1564 56.7154C61.8426 55.7741 60.8248 55.3719 60.0008 55.6466L60.8712 58.2579ZM59.5514 57.6045L61.3906 62.8695L63.9892 61.9618L62.15 56.6967L59.5514 57.6045ZM61.3842 62.8509C61.2868 62.5585 61.33 62.2828 61.4266 62.0776C61.5239 61.8707 61.7247 61.6353 62.0563 61.5247L62.9268 64.136C63.868 63.8223 64.2702 62.8045 63.9955 61.9805L61.3842 62.8509ZM62.0563 61.5247C62.3487 61.4273 62.6244 61.4705 62.8296 61.5671C63.0365 61.6645 63.2719 61.8653 63.3825 62.1968L60.7712 63.0673C61.0849 64.0085 62.1027 64.4107 62.9268 64.136L62.0563 61.5247ZM63.3761 62.1782L61.5369 56.9131L58.9384 57.8208L60.7775 63.0859L63.3761 62.1782ZM61.5559 56.9715C61.6952 57.4358 61.48 58.055 60.8712 58.2579L60.0008 55.6466C59.0674 55.9577 58.6719 56.9375 58.9194 57.7624L61.5559 56.9715Z"
        fill="#1E3575"
        mask="url(#path-9-inside-2_3564_45251)"
      />
      <path
        d="M54.7561 59.9093L58.2 58.7012L59.6605 62.8844L56.2166 64.0925C55.0806 64.4892 53.8545 63.8941 53.4578 62.7582L53.4218 62.668C53.0251 61.5321 53.6201 60.3059 54.7561 59.9093Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.309 34.1067L125.997 32.52C127.169 32.1233 128.431 32.7544 128.828 33.9264L131.911 43.0682C132.308 44.2402 131.677 45.5024 130.505 45.899L125.817 47.4858"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.23 39.1912L131.101 40.5796L129.694 36.4144L133.823 35.026C134.977 34.6473 136.222 35.2604 136.6 36.3964C136.997 37.5504 136.384 38.7945 135.23 39.1912Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.752 49.381L109.942 53.6746L104.861 38.6826L117.671 34.4067"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.259 42.5784L107.244 44.9419L108.073 47.4026L115.088 45.0391L114.259 42.5784Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.141 44.7271L106.199 42.6895L105.55 40.7422L99.4917 42.7797C98.9868 42.942 98.7344 43.4829 98.8966 43.9878L98.9507 44.132C99.113 44.6369 99.6539 44.9074 100.141 44.7271Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.135 53.5801L109.194 51.5426L108.545 49.5952L102.486 51.6327C101.981 51.795 101.729 52.3359 101.891 52.8408L101.945 52.9851C102.089 53.4719 102.63 53.7424 103.135 53.5801Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.664 49.9736H124.682C125.439 49.7212 125.854 48.8917 125.601 48.1344L120.769 33.7817C120.516 33.0244 119.687 32.6097 118.93 32.8621H118.912C118.154 33.1145 117.74 33.944 117.992 34.7013L122.824 49.036C123.077 49.8113 123.906 50.226 124.664 49.9736Z"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-19-inside-3_3564_45251" fill="white">
        <path d="M125.907 36.2525C126.07 36.1984 126.268 36.2886 126.322 36.4509L128.125 41.734C128.179 41.8963 128.089 42.0946 127.927 42.1487C127.764 42.2028 127.566 42.1127 127.512 41.9504L125.709 36.6673C125.655 36.4869 125.745 36.3066 125.907 36.2525Z" />
      </mask>
      <path
        d="M125.907 36.2525C126.07 36.1984 126.268 36.2886 126.322 36.4509L128.125 41.734C128.179 41.8963 128.089 42.0946 127.927 42.1487C127.764 42.2028 127.566 42.1127 127.512 41.9504L125.709 36.6673C125.655 36.4869 125.745 36.3066 125.907 36.2525Z"
        fill="#1E3575"
      />
      <path
        d="M126.322 36.4509L125.016 36.8861L125.019 36.8954L126.322 36.4509ZM128.125 41.734L129.431 41.2988L129.428 41.2895L128.125 41.734ZM127.512 41.9504L128.818 41.5151L128.815 41.5058L127.512 41.9504ZM125.709 36.6673L124.391 37.0627C124.396 37.0792 124.401 37.0955 124.406 37.1118L125.709 36.6673ZM126.342 37.5582C126.05 37.6556 125.774 37.6124 125.569 37.5159C125.362 37.4185 125.127 37.2177 125.016 36.8861L127.628 36.0157C127.314 35.0744 126.296 34.6722 125.472 34.9469L126.342 37.5582ZM125.019 36.8954L126.823 42.1785L129.428 41.2895L127.624 36.0063L125.019 36.8954ZM126.819 42.1692C126.722 41.8769 126.765 41.6012 126.862 41.3959C126.959 41.189 127.16 40.9536 127.492 40.8431L128.362 43.4544C129.303 43.1406 129.705 42.1228 129.431 41.2988L126.819 42.1692ZM127.492 40.8431C127.784 40.7456 128.06 40.7888 128.265 40.8854C128.472 40.9828 128.707 41.1836 128.818 41.5152L126.206 42.3856C126.52 43.3269 127.538 43.7291 128.362 43.4544L127.492 40.8431ZM128.815 41.5058L127.011 36.2227L124.406 37.1118L126.21 42.3949L128.815 41.5058ZM127.027 36.2718C127.166 36.7361 126.951 37.3553 126.342 37.5582L125.472 34.9469C124.539 35.258 124.143 36.2378 124.391 37.0627L127.027 36.2718Z"
        fill="#1E3575"
        mask="url(#path-19-inside-3_3564_45251)"
      />
      <mask id="path-21-inside-4_3564_45251" fill="white">
        <path d="M124.375 36.7755C124.537 36.7214 124.735 36.8116 124.79 36.9738L126.593 42.2569C126.647 42.4192 126.557 42.6176 126.394 42.6717C126.232 42.7258 126.034 42.6356 125.98 42.4733L124.176 37.1902C124.104 37.0279 124.194 36.8296 124.375 36.7755Z" />
      </mask>
      <path
        d="M124.375 36.7755C124.537 36.7214 124.735 36.8116 124.79 36.9738L126.593 42.2569C126.647 42.4192 126.557 42.6176 126.394 42.6717C126.232 42.7258 126.034 42.6356 125.98 42.4733L124.176 37.1902C124.104 37.0279 124.194 36.8296 124.375 36.7755Z"
        fill="#1E3575"
      />
      <path
        d="M124.375 36.7755L124.77 38.0937C124.784 38.0897 124.797 38.0855 124.81 38.0811L124.375 36.7755ZM124.79 36.9738L123.484 37.4091L123.487 37.4184L124.79 36.9738ZM126.593 42.2569L127.898 41.8217L127.895 41.8124L126.593 42.2569ZM125.98 42.4733L127.285 42.0381L127.282 42.0288L125.98 42.4733ZM124.176 37.1902L125.479 36.7457C125.466 36.7069 125.451 36.6687 125.434 36.6312L124.176 37.1902ZM124.81 38.0811C124.518 38.1786 124.242 38.1354 124.037 38.0388C123.83 37.9414 123.594 37.7406 123.484 37.4091L126.095 36.5386C125.781 35.5974 124.764 35.1951 123.94 35.4698L124.81 38.0811ZM123.487 37.4184L125.29 42.7015L127.895 41.8124L126.092 36.5293L123.487 37.4184ZM125.287 42.6922C125.19 42.3998 125.233 42.1241 125.329 41.9189C125.427 41.712 125.627 41.4765 125.959 41.366L126.829 43.9773C127.771 43.6635 128.173 42.6458 127.898 41.8217L125.287 42.6922ZM125.959 41.366C126.251 41.2686 126.527 41.3118 126.732 41.4084C126.939 41.5057 127.175 41.7065 127.285 42.0381L124.674 42.9085C124.988 43.8498 126.005 44.252 126.829 43.9773L125.959 41.366ZM127.282 42.0288L125.479 36.7457L122.874 37.6347L124.677 42.9179L127.282 42.0288ZM125.434 36.6312C125.709 37.2501 125.359 37.917 124.77 38.0937L123.979 35.4573C123.03 35.7422 122.5 36.8058 122.919 37.7492L125.434 36.6312Z"
        fill="#1E3575"
        mask="url(#path-21-inside-4_3564_45251)"
      />
      <path
        d="M90.2244 21.5029L89.5753 31.6364"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.4203 65.6069L103.315 74.4061"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.2319 26.5879L82.0018 33.7643"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.82 60.0894L113.195 63.912"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.9637 76.1554L91.3423 67.4824"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.3151 73.4147L83.7327 66.5088"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.1313 33.9443L104.577 27.958"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.6192 39.606L67.5052 36.6128"
        stroke="#1E3575"
        strokeWidth="1.37628"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

export default PageNotFoundIcon;
