import { SxProps, Typography, useTheme } from '@mui/material';

const ConfirmationMainContent: React.FC = (): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    confirmationMainContent: {
      alignSelf: 'flex-start',
      color: theme.palette.secondary.main,
      marginBottom: '32px',
    },
    confirmationSemiBoldContent: {
      display: 'inline',
      fontWeight: '600',
    },
    phoneNumber: {
      color: theme.palette.dteBlue.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    wordWrap: {
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
    },
  };

  const confirmationMessageStart = 'We will remotely reconnect your service ';
  const confirmationMessageEnd = '. Thank you for your patience.';

  return (
    <Typography variant="body1" data-testid="confirmation-main-content" sx={sx.confirmationMainContent}>
      {confirmationMessageStart}
      <Typography sx={sx.confirmationSemiBoldContent}>within 15 minutes to an hour</Typography>
      {confirmationMessageEnd}
    </Typography>
  );
};

export default ConfirmationMainContent;
