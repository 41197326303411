import { AxiosResponse } from 'axios';

import { apiPaths } from '../../constants/apiPaths';
import { azurePost } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

const cretePaymentAgreementPlan = async (
  body: ICreatePaymentAgreementPlanRequest,
): Promise<AxiosResponse<ICreatePaymentAgreementPlanResponse>> =>
  azurePost<ICreatePaymentAgreementPlanResponse>(apiPaths.paymentAgreementPlanCreationApiPath, body);

const localMockCretePaymentAgreementApiResponse = async (): Promise<
  AxiosResponse<ICreatePaymentAgreementPlanResponse>
> => {
  const localMock = await localMockApiResponse(Mocks.createPAPlan);
  return localMock;
};

export const createPaymentAgreementPlanApi = apiOrLocalMock(
  cretePaymentAgreementPlan,
  localMockCretePaymentAgreementApiResponse,
);
