import { Box, styled, Theme } from '@mui/material';

import getFlagsConfig from 'config/getFlagsConfig';

import DownPaymentSummary from './DownPaymentSummary';
import InstallmentSummary from './InstallmentSummary';

const { enablePAMonthlyRedesign } = getFlagsConfig();

interface IPaymentDetailsProps {
  downPaymentAmount: string;
  downPaymentStatus: string;
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
}

const PaymentDetailsContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('tablet')]: {
    padding: '24px',
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: enablePAMonthlyRedesign ? '79.5px 24px' : '49px 24px',
    width: '50%',
  },
}));

export const PaymentDetails: React.FC<IPaymentDetailsProps> = (props: IPaymentDetailsProps) => {
  const { downPaymentStatus, downPaymentAmount, installmentAmount, numberOfInstallmentsOpted } = props;

  return (
    <PaymentDetailsContainer data-testid="payment-details">
      <Box display="flex" flexDirection="column" gap="24px">
        <InstallmentSummary
          installmentAmount={installmentAmount}
          numberOfInstallmentsOpted={numberOfInstallmentsOpted}
        />
        <DownPaymentSummary downPaymentAmount={downPaymentAmount} downPaymentStatus={downPaymentStatus} />
      </Box>
    </PaymentDetailsContainer>
  );
};
