import { Box, styled, Theme } from '@mui/material';

import { AccountNumber, IAccountNumberProps } from '../../../component-library/templates/account-number/AccountNumber';
import {
  IUserInformationProps,
  UserInformation,
} from '../../../component-library/templates/user-information/UserInformation';

interface Props {
  accountInfo: IAccountDetailsResponse;
}

const InformationSectionContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.dteGrey.main}`,
  gridArea: 'informationSection',
  padding: '52.5px 30px',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '304px',
    textAlign: 'center',
    padding: '43px 16px',
  }, // SmallMobile
  [theme.breakpoints.between('mobile', 'tablet')]: {
    textAlign: 'center',
    padding: '43px 16px',
  }, // Mobile
  [theme.breakpoints.between('tablet', 'desktop')]: {
    textAlign: 'center',
    padding: '24px 0',
  }, // Tablet
}));

export interface IAccountInformationProps {
  accountNumber: IAccountNumberProps;
  userInformation: IUserInformationProps;
}

const InformationSection = ({ accountInfo }: Props): React.ReactElement => {
  const account = accountInfo?.accounts[0];
  const address = account?.serviceAddresses[0];

  return (
    <InformationSectionContainer>
      <Box data-testid="pae-account-info-border">
        <UserInformation name={accountInfo?.customerName} address={address} />
        <AccountNumber accountNumber={account?.accountNumber} />
      </Box>
    </InformationSectionContainer>
  );
};

export default InformationSection;
