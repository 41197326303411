/* eslint-disable no-nested-ternary */
import { SyntheticEvent, useState } from 'react';
import { Box, ClickAwayListener, IconButton, SxProps, Tooltip, TooltipProps, useTheme } from '@mui/material';

import DteTooltipClickOrHoverIcon from 'component-library/tooltip/DteTooltipClickOrHoverIcon';
import DteTooltipDefaultIcon from 'component-library/tooltip/DteTooltipDefaultIcon';
import DteTooltipKeyboardFocusIcon from 'component-library/tooltip/DteTooltipKeyboardFocusIcon';
import { useBreakpoints } from 'theme/breakpoints/useBreakpoints';

import TooltipMinAmountBubble from './TooltipMinAmountBubble';

type Props = {
  placement: TooltipProps['placement'];
};

const tooltipMinAmountCopy = 'By paying only the minimum amount due, your current bill is still due on the due date.';

const TooltipMinAmount: React.FC<Props> = (props: Props): JSX.Element => {
  const { placement } = props;
  const theme = useTheme();
  const { isMobile } = useBreakpoints();
  const sx: Record<string, SxProps> = {
    buttonIcon: {
      top: '1px',
      left: '-8px',
      padding: '12px',
    },
    tooltip: {
      whiteSpace: 'normal', // enforce whitespace from being overwritten at RadioLabelMinAmount
      width: '325px',
      backgroundColor: theme.palette.dteBlue.light,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.dteBlue.main}`,
      borderRadius: '5px',
      padding: '20px',
      '.MuiTooltip-arrow': {
        '&::before': {
          borderRadius: '2px',
          border: `2px solid ${theme.palette.dteBlue.main}`,
          backgroundColor: theme.palette.dteBlue.light,
        },
      },
    },
    popper: {
      maxWidth: '0px', // added to enforce consistent behavior between browsers, Safari's width renders differently
      '.MuiTooltip-tooltipPlacementTop': {
        fontSize: '19.73px',
        marginBottom: '14px !important',
        marginTop: '-14px !important',
        left: '-262px',
        top: '14px',
        '& .MuiTooltip-arrow': {
          left: '104px !important',
        },
      },
      '.MuiTooltip-tooltipPlacementRight': {
        fontSize: '19.73px',
        marginLeft: '14px !important',
        left: '-22px',
      },
    },
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [isMouseHovered, setIsMouseHovered] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleTooltipClose = (event: SyntheticEvent | Event): void => {
    if ((event.target as HTMLElement).innerText === tooltipMinAmountCopy) {
      event.preventDefault();
      return;
    }
    setIsTooltipOpen(false);
  };

  const toggleTooltip = (event: SyntheticEvent): void => {
    if (event.nativeEvent['pointerType' as keyof typeof event.nativeEvent] === 'mouse') {
      event.preventDefault();
    }
    setIsTooltipOpen(!isTooltipOpen);
  };

  const toggleTooltipWithKeyboard = (event: SyntheticEvent): void => {
    if (event['key' as keyof typeof event] === 'Enter') {
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  const showHoverIcon = (): void => {
    // condition is a quick fix for a bug that is triggering showHoverIcon when
    // clicking close to but not on the icon, when in mobile view.
    if (!isMobile) {
      setIsMouseHovered(true);
    }
  };

  const hideHoverIcon = (): void => {
    setIsMouseHovered(false);
    setIsFocused(false);
  };

  const showKeyboardFocusedIcon = (): void => {
    setIsFocused(true);
  };

  const hideKeyboardFocusedIcon = (): void => {
    setIsFocused(false);
  };

  return (
    <ClickAwayListener onClickAway={(event: MouseEvent | TouchEvent): void => handleTooltipClose(event)}>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: sx.tooltip,
          },
        }}
        PopperProps={{
          disablePortal: true,
          sx: sx.popper,
          keepMounted: true,
        }}
        onClose={(event: Event | SyntheticEvent<Element, Event>): void => handleTooltipClose(event)}
        open={isTooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<TooltipMinAmountBubble handleTooltipClose={handleTooltipClose} />}
        placement={placement}
      >
        <Box>
          <IconButton
            title="Minimum Amount Tooltip"
            sx={sx.buttonIcon}
            data-testid="min-amount-due-tooltip"
            onMouseDown={(event: SyntheticEvent): void => toggleTooltip(event)}
            onKeyDown={(event: SyntheticEvent): void => toggleTooltipWithKeyboard(event)}
            onMouseEnter={showHoverIcon}
            onMouseLeave={hideHoverIcon}
            onFocus={showKeyboardFocusedIcon}
            onBlur={hideKeyboardFocusedIcon}
            value="min-amount-tooltip-button-root"
          >
            {isTooltipOpen || isMouseHovered ? (
              <DteTooltipClickOrHoverIcon />
            ) : isFocused ? (
              <DteTooltipKeyboardFocusIcon />
            ) : (
              <DteTooltipDefaultIcon />
            )}
          </IconButton>
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipMinAmount;
