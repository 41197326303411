import React from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import DteSuccessIcon from 'component-library/icons/DteSuccessIcon';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

export const PaymentExtensionScheduledHeading = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      gap: '12px',
      alignItems: 'flex-start',
    },
    mobileWrapper: {
      justifyContent: 'flex-start',
    },
    tabletAndDesktopWrapper: {
      justifyContent: 'center',
    },
    scheduledHeading: {
      color: theme.palette.primary.main,
      fontSize: '20px',
    },
  };

  const mobileStyling = { ...sx.rootWrapper, ...sx.mobileWrapper };
  const tabletAndDesktopStyling = { ...sx.rootWrapper, ...sx.tabletAndDesktopWrapper };
  const responsiveStyles = useResponsiveSx(mobileStyling, tabletAndDesktopStyling, tabletAndDesktopStyling);

  return (
    <Box sx={responsiveStyles}>
      <DteSuccessIcon color={theme.palette.dteBlue.dark} width={25} height={25} />
      <Typography fontWeight={600} sx={sx.scheduledHeading}>
        A Payment Extension is active on your account and your account is protected from service interruption.
      </Typography>
    </Box>
  );
};
