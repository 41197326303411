import { CSSProperties } from 'react';
import { styled } from '@mui/material';

import { IconContainer } from './IconContainer';

type IconProps = {
  containerSx?: CSSProperties;
  iconSx?: CSSProperties;
};

export const MilitaryTagsIcon = ({ containerSx, iconSx }: IconProps): React.ReactElement => {
  const LogoContainer = styled(IconContainer)(() => ({
    width: '76px',
    height: '108px',
  }));

  const StyledSvg = styled('svg')(() => ({
    width: '76px',
    height: '108px',
  }));

  return (
    <LogoContainer sx={containerSx} data-testid="military-tags-logo">
      <StyledSvg sx={iconSx} viewBox="0 0 76 108" fill="none">
        <path
          d="M41.9998 40C48.0751 32.4023 51.5 15.5 49.4998 2.5M26 28C22.5001 24 21.4983 13.5 22.9998 1"
          stroke="#1E3575"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="1 4"
        />
        <mask id="path-2-inside-1_3087_32916" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.9391 84.5864L59.802 49.7875L59.8022 49.7876C63.0621 42.6363 59.9075 34.1963 52.7562 30.9364L40.6168 25.4027C33.4655 22.1428 25.0255 25.2974 21.7656 32.4487L21.7654 32.4486L5.90216 67.2481L5.9027 67.2484C2.64325 74.3996 5.7979 82.8392 12.949 86.099L25.0884 91.6327C32.2397 94.8926 40.6797 91.738 43.9397 84.5866L43.9391 84.5864Z"
          />
        </mask>
        <path
          d="M59.802 49.7875L60.6316 47.9677L58.8118 47.1381L57.9822 48.958L59.802 49.7875ZM43.9391 84.5864L42.1193 83.7568L41.2897 85.5767L43.1096 86.4062L43.9391 84.5864ZM59.8022 49.7876L58.9727 51.6074L60.7925 52.437L61.6221 50.6172L59.8022 49.7876ZM21.7656 32.4487L20.936 34.2685L22.7558 35.0981L23.5854 33.2783L21.7656 32.4487ZM21.7654 32.4486L22.595 30.6288L20.7751 29.7992L19.9455 31.6191L21.7654 32.4486ZM5.90216 67.2481L4.08232 66.4186L3.25275 68.2384L5.07259 69.068L5.90216 67.2481ZM5.9027 67.2484L7.72257 68.0779L8.55202 66.2581L6.73226 65.4285L5.9027 67.2484ZM12.949 86.099L13.7786 84.2792L12.949 86.099ZM25.0884 91.6327L24.2588 93.4525L25.0884 91.6327ZM43.9397 84.5866L45.7595 85.4162L46.5891 83.5964L44.7692 82.7668L43.9397 84.5866ZM57.9822 48.958L42.1193 83.7568L45.759 85.416L61.6219 50.6171L57.9822 48.958ZM60.6318 47.9678L60.6316 47.9677L58.9725 51.6074L58.9727 51.6074L60.6318 47.9678ZM51.9266 32.7562C58.0729 35.558 60.7841 42.8118 57.9824 48.958L61.6221 50.6172C65.3401 42.4608 61.7422 32.8346 53.5857 29.1165L51.9266 32.7562ZM39.7872 27.2225L51.9266 32.7562L53.5857 29.1165L41.4464 23.5828L39.7872 27.2225ZM23.5854 33.2783C26.3872 27.132 33.641 24.4208 39.7872 27.2225L41.4464 23.5828C33.29 19.8648 23.6638 23.4627 19.9457 31.6191L23.5854 33.2783ZM20.9358 34.2685L20.936 34.2685L22.5951 30.6289L22.595 30.6288L20.9358 34.2685ZM7.722 68.0777L23.5852 33.2782L19.9455 31.6191L4.08232 66.4186L7.722 68.0777ZM6.73226 65.4285L6.73173 65.4283L5.07259 69.068L5.07313 69.0682L6.73226 65.4285ZM13.7786 84.2792C7.63251 81.4775 4.92122 74.224 7.72257 68.0779L4.08282 66.4189C0.365281 74.5752 3.96328 84.2009 12.1195 87.9188L13.7786 84.2792ZM25.918 89.8128L13.7786 84.2792L12.1195 87.9188L24.2588 93.4525L25.918 89.8128ZM42.1198 83.7571C39.3181 89.9033 32.0642 92.6146 25.918 89.8128L24.2588 93.4525C32.4153 97.1706 42.0414 93.5726 45.7595 85.4162L42.1198 83.7571ZM43.1096 86.4062L43.1101 86.4065L44.7692 82.7668L44.7687 82.7665L43.1096 86.4062Z"
          fill="#1E3575"
          mask="url(#path-2-inside-1_3087_32916)"
        />
        <circle
          cx="41.793"
          cy="39.9764"
          r="3.29927"
          transform="rotate(114.506 41.793 39.9764)"
          stroke="#1E3575"
          strokeWidth="2"
        />
        <path d="M45.1621 56.1699L35.9393 76.4022" stroke="#1E3575" strokeWidth="2" strokeLinecap="round" />
        <path d="M36.2598 52.1133L29.6193 66.6805" stroke="#1E3575" strokeWidth="2" strokeLinecap="round" />
        <path d="M27.3574 48.0547L15.5522 73.952" stroke="#1E3575" strokeWidth="2" strokeLinecap="round" />
        <path
          d="M62.4249 100.116L62.7501 101.062L62.7501 101.062L62.4249 100.116ZM49.1434 104.684L48.8182 103.738L48.8182 103.738L49.1434 104.684ZM60.9531 48.6572C60.7637 48.1384 60.1897 47.8713 59.6709 48.0606C59.152 48.2499 58.8849 48.824 59.0743 49.3428L60.9531 48.6572ZM71.7198 81.0772L72.6654 80.752L72.6624 80.7432L72.6592 80.7344L71.7198 81.0772ZM30.1045 95.3888L29.1539 95.6993L29.1563 95.7067L29.1588 95.714L30.1045 95.3888ZM62.0997 99.1705L48.8182 103.738L49.4686 105.629L62.7501 101.062L62.0997 99.1705ZM59.0743 49.3428L70.7804 81.4201L72.6592 80.7344L60.9531 48.6572L59.0743 49.3428ZM31.055 95.0783L29.9506 91.6973L28.0494 92.3183L29.1539 95.6993L31.055 95.0783ZM48.8182 103.738C41.5163 106.249 33.5612 102.366 31.0501 95.0636L29.1588 95.714C32.0292 104.06 41.1222 108.5 49.4686 105.629L48.8182 103.738ZM62.7501 101.062C71.0965 98.1915 75.5358 89.0985 72.6654 80.752L70.7741 81.4024C73.2853 88.7043 69.4016 96.6594 62.0997 99.1705L62.7501 101.062Z"
          fill="#1E3575"
        />
      </StyledSvg>
    </LogoContainer>
  );
};
