import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { TextField } from '@mui/material';

interface GlobalFilterProps {
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
}

export const GlobalFilter = (props: GlobalFilterProps): React.ReactElement => {
  const { filter, setFilter } = props;
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setFilter(event.target.value);
  };

  return (
    <TextField
      value={filter || ''}
      onChange={onChangeHandler}
      placeholder="filter"
      variant="outlined"
      sx={{ width: '100%' }}
    />
  );
};
