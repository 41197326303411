import { IconProps } from '../icon-props';
import { IconContainer } from '../IconContainer';

const AdditionalPaymentsIcon = (iconProps: IconProps): React.ReactElement => {
  const { containerSx, iconHeight = '36', iconWidth = '36' } = iconProps;

  return (
    <IconContainer data-testid="additional-payments-icon" sx={containerSx}>
      <svg fill="none" height={iconHeight} viewBox="0 0 24 31" width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.1816 18.7857C18.1816 18.7857 19.5343 21.3194 19.6362 21.3194C19.7381 21.3194 21.0907 18.7857 21.0907 18.7857V9.68306C21.0907 8.87974 20.4395 8.22852 19.6362 8.22852C18.8329 8.22852 18.1816 8.87974 18.1816 9.68306V18.7857Z"
          stroke="#1E3575"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path d="M8.72754 8.95508H13.8184" stroke="#1E3575" strokeLinecap="round" strokeWidth="2" />
        <path
          d="M8.72754 11.9551H16.0003M8.72754 13.4096H16.0003M8.72754 14.8642H16.0003"
          stroke="#1E3575"
          strokeLinecap="round"
        />
        <path
          clipRule="evenodd"
          d="M0 3.86417C0 2.25752 1.30244 0.955078 2.90909 0.955078H16C17.6066 0.955078 18.9091 2.25752 18.9091 3.86417V4.59144H21.0909C22.6976 4.59144 24 5.89389 24 7.50053V27.1369C24 28.7435 22.6976 30.046 21.0909 30.046H8C6.39335 30.046 5.09091 28.7435 5.09091 27.1369V26.4096H2.90909C1.30244 26.4096 0 25.1072 0 23.5005V3.86417ZM5.09091 24.9551V7.50053C5.09091 5.89389 6.39335 4.59144 8 4.59144H17.4545V3.86417C17.4545 3.06085 16.8033 2.40962 16 2.40962H2.90909C2.10577 2.40962 1.45455 3.06085 1.45455 3.86417V23.5005C1.45455 24.3039 2.10577 24.9551 2.90909 24.9551H5.09091ZM6.54545 7.50053C6.54545 6.69721 7.19668 6.04599 8 6.04599H21.0909C21.8942 6.04599 22.5455 6.69721 22.5455 7.50053V27.1369C22.5455 27.9402 21.8942 28.5914 21.0909 28.5914H8C7.19668 28.5914 6.54545 27.9402 6.54545 27.1369V7.50053Z"
          fill="#1E3575"
          fillRule="evenodd"
        />
        <path
          d="M8.72754 26.614C9.81845 26.614 11.273 24.0685 11.6366 22.9776C12.0003 21.8867 13.0912 25.523 13.4548 25.523C13.8184 25.523 14.6472 23.7423 14.9094 23.7049C15.1054 23.6769 15.6181 24.7071 15.8644 25.2288C15.9486 25.4072 16.1273 25.523 16.3246 25.523H20.3639"
          stroke="#1E3575"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </svg>
    </IconContainer>
  );
};

export default AdditionalPaymentsIcon;
