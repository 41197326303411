import { styled, Theme } from '@mui/material';

import {
  AdditionalAssistanceBanner,
  AgencyPaymentAssistanceMessage,
  dayjsDate,
  SuccessBanner,
} from '../../../component-library';
import { LockStatusContent } from '../../LockStatusContent';
import { AgencyPaymentInformation } from '../agency-payment-information/AgencyPaymentInformationV2';
import { AgencyPaymentAssistanceInformation } from '../assistance-information/AgencyPaymentAssistanceInformationV2';
import { AgencyPaymentBottomContent } from '../bottom-content/AgencyPaymentBottomContent';
import {
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
  viewCurrentBillClickHandler,
} from '../handlers';

const TopContent = styled('div')({
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
});

const AssistanceInformationContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  gap: '40px',
  padding: '40px 0',
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '40px 24px',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
  },
}));

type AgencyPaymentMainContentProps = {
  dunningLockDetails: IDunningLockDetails;
};

export const AgencyPaymentMainContent = ({ dunningLockDetails }: AgencyPaymentMainContentProps): React.ReactElement => {
  const lockEndDate: string = dunningLockDetails
    ? dayjsDate(dunningLockDetails.endDate).format('dddd, MMMM D, YYYY')
    : '';

  return (
    <LockStatusContent sx={{ gap: '0px' }} data-testid="agency-payment-main-content">
      <TopContent data-testid="top-content">
        <SuccessBanner
          message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
        />
        <AssistanceInformationContainer data-testid="assistance-information-container">
          <AgencyPaymentAssistanceInformation dunningLockDetails={dunningLockDetails} />
          <AgencyPaymentInformation dunningLockDetails={dunningLockDetails} />
        </AssistanceInformationContainer>
      </TopContent>
      <AgencyPaymentBottomContent viewCurrentBillClickHandler={viewCurrentBillClickHandler} />
      <AdditionalAssistanceBanner
        message={
          <AgencyPaymentAssistanceMessage
            energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
            unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          />
        }
      />
    </LockStatusContent>
  );
};
