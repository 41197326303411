import { styled, Theme } from '@mui/material';

import {
  AdditionalAssistanceBanner,
  DefaultAssistanceMessage,
} from '../../../component-library/banners/AdditionalAssistanceBanner';
import { SuccessBanner } from '../../../component-library/banners/SuccessBanner';
import { dayjsDate } from '../../../component-library/dates/dayjsApStyleAbbreviateMonths';
import { LockStatusContent } from '../../LockStatusContent';
import { AgencyContactInformation } from '../agency-contact-information/AgencyContactInformationV2';
import { DefaultAssistanceInformation } from '../assistance-information/DefaultAssistanceInformationV2';
import { DefaultBottomContent } from '../bottom-content/DefaultBottomContentV2';
import { energyAssistanceProgramsLinkOnClickHandler, unitedWayLinkOnClickHandler } from '../handlers';

const TopContent = styled('div')({
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
});

const AssistanceInformationContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
  padding: '40px 0',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '40px 24px',
  },
}));

type DefaultMainContentProps = {
  dunningLockDetails: IDunningLockDetails;
};

export const DefaultMainContent = ({ dunningLockDetails }: DefaultMainContentProps): React.ReactElement => {
  const lockEndDate: string = dunningLockDetails
    ? dayjsDate(dunningLockDetails.endDate).format('dddd, MMMM D, YYYY')
    : '';

  const displayAgencyContactInformation =
    dunningLockDetails && (dunningLockDetails.agencyName || dunningLockDetails.agencyNumber);

  return (
    <LockStatusContent sx={{ gap: '0px' }} data-testid="default-main-content">
      <TopContent data-testid="top-content">
        <SuccessBanner
          message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
        />
        <AssistanceInformationContainer data-testid="assistance-information-container">
          <DefaultAssistanceInformation dunningLockDetails={dunningLockDetails} />
          {displayAgencyContactInformation && (
            <AgencyContactInformation
              agencyName={dunningLockDetails!.agencyName}
              agencyPhoneNumber={dunningLockDetails!.agencyNumber}
            />
          )}
        </AssistanceInformationContainer>
      </TopContent>
      <DefaultBottomContent />
      <AdditionalAssistanceBanner
        message={
          <DefaultAssistanceMessage
            energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
            unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          />
        }
      />
    </LockStatusContent>
  );
};
