import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import { PageTitles } from '../constants';

import PaymentAgreementContent from './components/PaymentAgreementContent';
import PaymentAgreementHeader from './components/PaymentAgreementHeader';

const PaymentAgreementPage: React.FC = (): React.ReactElement => (
  <>
    <Helmet>
      <title>{PageTitles.PAYMENT_AGREEMENT}</title>
    </Helmet>
    <PageTemplate
      header={<PaymentAgreementHeader displayCancelButton={false} />}
      allViewportPaddingTop="40px"
      tabletDesktopContentWidth="740px"
      dataTestId="payment-agreement-page"
    >
      <PaymentAgreementContent />
    </PageTemplate>
  </>
);

export default PaymentAgreementPage;
