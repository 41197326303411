const messages = {
  popoverText: 'Please note: The number of monthly payments remaining will be updated in the next billing cycle.',
  popoverTextTitle: 'Please note: ',
  popoverTextV2: 'The number of monthly payments remaining will be updated in the next billing cycle.',
  remainingText: 'Remaining Payments',
  title: 'Monthly Payments',
  totalPaymentsText: 'Total Payments',
};

export default messages;
