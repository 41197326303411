const getInstallationArray = (installations?: number | string | number[] | string[]): string[] => {
  if (!installations) {
    return [];
  }

  if (Array.isArray(installations)) {
    return installations.map((installation: number | string) => installation.toString());
  }
  return [installations.toString()];
};

export default getInstallationArray;
