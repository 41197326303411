import { useEffect } from 'react';

import { useDunningLockDetails } from 'hooks';

import { DteHeader } from '../../component-library/header/DteHeader';
import { serviceProtection } from '../../component-library/header/header';
import { DteStyledLogo } from '../../component-library/logo/DteStyledLogo';
import { NewPageTemplate } from '../../component-library/page-templates/NewPageTemplate';

import { AgencyPaymentMainContent } from './main-content/AgencyPaymentMainContentV2';
import { DefaultMainContent } from './main-content/DefaultMainContentV2';
import { onPageViewHandler } from './handlers';
import messages from './messages';

const CLockView = (): React.ReactElement => {
  const { dunningLockDetails, isDunningLockDetailsSuccess } = useDunningLockDetails({ dunningLockReason: 'C' });

  const showAgencyPaymentContent = !!(
    dunningLockDetails[0].agencyPaymentAmount && dunningLockDetails[0].agencyPaymentDate
  );

  useEffect(() => {
    if (isDunningLockDetailsSuccess) {
      onPageViewHandler(showAgencyPaymentContent);
    }
  }, [isDunningLockDetailsSuccess, showAgencyPaymentContent, onPageViewHandler]);

  const header = <DteHeader title={serviceProtection} logo={<DteStyledLogo />} />;

  const content = showAgencyPaymentContent ? (
    <AgencyPaymentMainContent dunningLockDetails={dunningLockDetails[0]} />
  ) : (
    <DefaultMainContent dunningLockDetails={dunningLockDetails[0]} />
  );

  return (
    <NewPageTemplate
      content={content}
      pageHeader={header}
      showContent={isDunningLockDetailsSuccess}
      titleTag={messages.clockTitle}
    />
  );
};
export default CLockView;
