import { useContext, useState } from 'react';
import { Box, styled, Theme } from '@mui/material';

import { EnrollmentContext } from '../EnrollmentContext';

import PlanButton from './PlanButton';
import PlanSelectionInstruction from './PlanSelectionHeading';
import PlanNotice from './PlanSelectionNotice';

const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const PlansContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  gap: '12px',
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.between('mobile', 'tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
  },
}));

interface IPlanSelectionProps {
  paymentOptions: IPaymentOption[];
}

const PlanSelectionSection: React.FC<IPlanSelectionProps> = (props: IPlanSelectionProps): React.ReactElement => {
  const { paymentOptions } = props;
  const [selectedPlan, setSelectedPlan] = useState<string>('3');
  const [focusedPlan, setFocusedPlan] = useState<string>('');
  const { enrollmentInformation } = useContext(EnrollmentContext);

  const handleClick = (plan: string): void => {
    setFocusedPlan(plan);
    setSelectedPlan(plan);
  };

  const handleFocus = (plan: string): void => {
    setFocusedPlan(plan);
  };

  const onBlurHandler = (): void => {
    setFocusedPlan('');
  };

  const setEnrollmentState = (paymentOption: IPaymentOption): void => {
    const { installmentAmount, installmentDuration } = paymentOption;
    enrollmentInformation.installmentAmount = installmentAmount;
    enrollmentInformation.installmentDuration = installmentDuration;
  };

  return (
    <ContentWrapper data-testid="plan-selection">
      <PlanSelectionInstruction />
      <PlansContainer data-testid="payment-options-container">
        {paymentOptions.map((paymentOption: IPaymentOption) => (
          <PlanButton
            data-testid={`option-${paymentOption.installmentDuration}-months-button`}
            isFocus={focusedPlan === paymentOption.installmentDuration}
            isSelected={selectedPlan === paymentOption.installmentDuration}
            key={paymentOption.installmentDuration}
            onBlurHandler={onBlurHandler}
            onClick={(): void => {
              setEnrollmentState(paymentOption);
              handleClick(paymentOption.installmentDuration);
            }}
            onFocusVisible={(): void => {
              handleFocus(paymentOption.installmentDuration);
            }}
            paymentOption={paymentOption}
          />
        ))}
      </PlansContainer>
      <PlanNotice />
    </ContentWrapper>
  );
};

export default PlanSelectionSection;
