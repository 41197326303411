import { useContext } from 'react';
import { styled, Theme, Typography } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../../component-library/currency/ReadOnlyCurrencyMask';
import { H5HeadingRegular } from '../../../../component-library/typography';
import { EnrollmentContext } from '../../EnrollmentContext';
import messages from '../messages';

const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
  padding: '0 11px',
  textAlign: 'center',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    width: '450px',
    height: '100%',
  },
}));

const PaidAmount = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'open sans',
  fontSize: '18px',
  fontWeight: '600',
}));

const PerMonth = styled('span')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: 'open sans',
  fontSize: '16px',
  fontWeight: '400',
}));

export const MonthPaymentAgreement: React.FC = () => {
  const { enrollmentInformation } = useContext(EnrollmentContext);
  const { installmentAmount, installmentDuration } = enrollmentInformation;

  return (
    <Container data-testid="month-payment-agreement">
      <H5HeadingRegular data-testid="month-payment-agreement-heading">
        <span>{`${installmentDuration} ${messages.monthPaymentTitle}`}</span>
      </H5HeadingRegular>
      <PaidAmount data-testid="month-payment-agreement-amount">
        {ReadOnlyCurrencyMask(parseFloat(installmentAmount))}
        <PerMonth>{messages.perMonth}</PerMonth>
      </PaidAmount>
    </Container>
  );
};
