import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { PageTemplate } from '../component-library/page-templates/PageTemplate';
import { PageTitles } from '../constants';

import PaymentCenterTestContent from './components/PaymentCenterTestContent';
import PaymentOffersHeader from './components/PaymentCenterTestHeader';

const PaymentCenterTestPage: React.FC = (): React.ReactElement => (
  <>
    <Helmet>
      <title>{PageTitles.PAYMENT_CENTER_TEST}</title>
    </Helmet>
    <PageTemplate
      allViewportPaddingTop="16px"
      dataTestId="payment-center-test-page"
      header={<PaymentOffersHeader />}
      tabletDesktopContentWidth="740px"
    >
      <PaymentCenterTestContent />
    </PageTemplate>
  </>
);

export default PaymentCenterTestPage;
