import { CSSProperties } from 'react';
import { styled } from '@mui/material';

import { IconContainer } from './IconContainer';

type IconProps = {
  containerSx?: CSSProperties;
  iconSx?: CSSProperties;
};

export const DteCloseIcon = ({ containerSx, iconSx }: IconProps): React.ReactElement => {
  const sizeStyle = {
    width: '20px',
    height: '21px',
  };

  const LogoContainer = styled(IconContainer)({
    ...sizeStyle,
  });

  const StyledSvg = styled('svg')({
    ...sizeStyle,
  });

  return (
    <LogoContainer sx={containerSx}>
      <StyledSvg sx={iconSx} viewBox="0 0 20 21" fill="none" role="img" aria-label="dte-close-icon">
        <path
          id="Active-Close-Icon"
          d="M1 1.5L10.0178 10.5166M10.0178 10.5166L18.9668 19.4644M10.0178 10.5166L19 1.53558M10.0178 10.5166L1.03318 19.5"
          stroke="#0072CE"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </StyledSvg>
    </LogoContainer>
  );
};
