import React, { CSSProperties } from 'react';
import { styled } from '@mui/material';

import { IconContainer } from './IconContainer';

type IconProps = {
  containerSx?: CSSProperties;
  iconSx?: CSSProperties;
};

export const SufficientPaymentFundsIcon = ({ containerSx, iconSx }: IconProps): React.ReactElement => {
  const sizeStyle = {
    width: '36px',
    height: '36px',
  };
  const LogoContainer = styled(IconContainer)({
    ...sizeStyle,
  });

  const StyledSvg = styled('svg')({
    ...sizeStyle,
  });

  return (
    <LogoContainer sx={containerSx} data-testid="sufficient-payment-funds-icon-container">
      <StyledSvg sx={iconSx} viewBox="0 0 36 36" fill="none" role="img" aria-label="sufficient-payment-funds-icon">
        <rect
          x="1.5"
          y="9.90186"
          width="25.3"
          height="17.3105"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <rect
          x="1.5"
          y="4.57593"
          width="25.3"
          height="5.32632"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <line
          x1="8.57422"
          y1="2.66284"
          x2="8.57422"
          y2="6.48916"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="19.7246"
          y1="6.48901"
          x2="19.7246"
          y2="2.6627"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="24.7371" cy="24.325" r="9.0125" fill="#F6F6F6" stroke="#1E3575" strokeWidth="1.5" />
        <path
          d="M24.6956 19.9316V18.2239M24.6956 30.2631V28.5554M22.1777 26.3771C22.1777 26.3771 22.3485 28.469 24.6112 28.5544C26.8739 28.6825 27.13 26.9321 27.13 26.5905C27.13 26.249 27.1727 24.8829 24.6539 24.1998C22.135 23.5167 22.3485 22.0225 22.3912 21.8517C22.4339 21.6383 22.6047 20.1014 24.5685 19.9306C26.5323 19.7598 27.3008 21.681 27.3008 22.0225"
          stroke="#1E3575"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledSvg>
    </LogoContainer>
  );
};
