import messages from './messages';
import PlanSelectionBodyText from './PlanSelectionBodyText';

const PlanSelectionNotice: React.FC = (): React.ReactElement => (
  <PlanSelectionBodyText data-testid="plan-selection-notice" id="plan-selection-notice" fontWeight={400}>
    {messages.planNotice}
  </PlanSelectionBodyText>
);

export default PlanSelectionNotice;
