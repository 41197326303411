import { styled, SxProps, Theme, Typography } from '@mui/material';

import { NoIcon } from 'component-library/icons/NoIcon';
import { PhotoIdentificationIcon } from 'component-library/icons/PhotoIdentificationIcon';
import { ProofOfHouseholdMembershipIcon } from 'component-library/icons/ProofOfHouseholdMembershipIcon';
import { ProofOfIncomeIcon } from 'component-library/icons/ProofOfIncomeIcon';
import { Bold } from 'component-library/typography/Bold';

const RequiredDocumentLabelRoot = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  maxWidth: '100%',
  padding: '20px 20px 16px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px',
  },
}));

const RequiredDocumentTitle = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
}));

export const RequiredDocumentInstructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  paddingLeft: '30px',
}));

const UnacceptedDocumentInstructions = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.secondary.main,
  paddingLeft: '0px',
}));

const TitleAndInstructionsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '8px',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));

const IconContainer = styled('div')(() => ({
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '95px',
  height: '65px',
}));

export const PhotoIdentificationInstructions = (): React.ReactElement => (
  <RequiredDocumentInstructions variant="body1">
    {`Please provide one of these documents for `}
    <Bold>each adult member of the household</Bold>
    {` (18 years or older).`}
  </RequiredDocumentInstructions>
);

export const ProofOfHouseholdMembersInstructions = (): React.ReactElement => (
  <RequiredDocumentInstructions variant="body1">
    {`Please provide document copies for `}
    <Bold>each household member.</Bold>
  </RequiredDocumentInstructions>
);

export const ProofOfIncomeInstructions = (): React.ReactElement => (
  <RequiredDocumentInstructions variant="body1">
    {`Please provide `}
    <Bold>applicable documents for each adult household member.</Bold>
  </RequiredDocumentInstructions>
);

export const DocumentsNotAcceptedInstructions = (): React.ReactElement => (
  <UnacceptedDocumentInstructions variant="body1">
    Please do not submit any of the following documents. They may delay processing your request.
  </UnacceptedDocumentInstructions>
);

type RequiredDocumentLabelProps = {
  title: string;
  instructions: JSX.Element;
  icon: JSX.Element;
  dataTestId?: string;
  requiredDocumentTitleSx?: SxProps;
  iconWrapperSx?: SxProps;
};
export const DocumentGuidelinesLabel = ({
  title,
  instructions,
  icon,
  dataTestId,
  requiredDocumentTitleSx,
  iconWrapperSx,
}: RequiredDocumentLabelProps): React.ReactElement => (
  <RequiredDocumentLabelRoot data-testid={dataTestId}>
    <TitleAndInstructionsContainer>
      <RequiredDocumentTitle sx={requiredDocumentTitleSx}>
        <Bold>{title}</Bold>
      </RequiredDocumentTitle>
      {instructions}
    </TitleAndInstructionsContainer>
    <IconContainer sx={iconWrapperSx}>{icon}</IconContainer>
  </RequiredDocumentLabelRoot>
);

export const PhotoIdentificationGuidelineLabel = (): React.ReactElement => (
  <DocumentGuidelinesLabel
    title="1. Photo Identification"
    instructions={<PhotoIdentificationInstructions />}
    icon={<PhotoIdentificationIcon />}
    dataTestId="photo-identification-guideline-label"
    requiredDocumentTitleSx={{ paddingLeft: '7px' }}
    iconWrapperSx={{ padding: '5px 8px 8px 7px' }}
  />
);

export const ProofOfHouseholdMembersGuidelineLabel = (): React.ReactElement => (
  <DocumentGuidelinesLabel
    title="2. Proof of Household Members"
    instructions={<ProofOfHouseholdMembersInstructions />}
    icon={<ProofOfHouseholdMembershipIcon />}
    dataTestId="proof-of-household-members-guideline-label"
    requiredDocumentTitleSx={{ paddingLeft: '7px' }}
    iconWrapperSx={{ padding: '5px 8px 8px 7px' }}
  />
);

export const ProofOfIncomeGuidelineLabel = (): React.ReactElement => (
  <DocumentGuidelinesLabel
    title="3. Proof of Income"
    instructions={<ProofOfIncomeInstructions />}
    icon={<ProofOfIncomeIcon />}
    dataTestId="proof-of-income-guideline-label"
    requiredDocumentTitleSx={{ paddingLeft: '7px' }}
    iconWrapperSx={{ padding: '7px 3px 10px 2px' }}
  />
);

export const DocumentsNotAcceptedGuidelineLabel = (): React.ReactElement => (
  <DocumentGuidelinesLabel
    title="Documents Not Accepted"
    instructions={<DocumentsNotAcceptedInstructions />}
    icon={<NoIcon iconWidth="40px" iconHeight="40px" />}
    dataTestId="documents-not-accepted-guideline-label"
    iconWrapperSx={{ height: '40px' }}
  />
);
