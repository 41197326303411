import { ReactNode } from 'react';
import { styled, Theme, Typography } from '@mui/material';

type StepProps = {
  dataTestId?: string;
  icon: ReactNode;
  instructions: string;
  subTitle: string;
  title: string;
};

export const PaymentAgreementStep = ({
  dataTestId,
  icon,
  instructions,
  subTitle,
  title,
}: StepProps): React.ReactElement => {
  const Container = styled('div')(({ theme }: { theme: Theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.up(755)]: {
      width: '33.33%',
    },
  }));

  const ContainerWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
    gap: '10px',
    padding: '0 8px',
    [theme.breakpoints.down(755)]: {
      textAlign: 'center',
    },
  }));

  const Instructions = styled('span')(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontfamily: 'Open Sans',
    fontSize: '16px',
    fontstyle: 'normal',
    fontWeight: '400',
    gap: '10px',
    lineHeight: 'normal',
  }));

  const SubTitle = styled('span')(({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontfamily: 'Open Sans',
    fontSize: '16px',
    fontstyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
  }));

  const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    fontfamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: 'normal',
    [theme.breakpoints.down('desktop')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: '22px',
    },
  }));

  const TitleContainer = styled('div')(({ theme }: { theme: Theme }) => ({
    alignItems: 'center',
    alignSelf: 'stretch',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
  }));

  return (
    <Container data-testid={dataTestId}>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {icon}
      <ContainerWrapper>
        <SubTitle>{subTitle}</SubTitle>
        <Instructions>{instructions}</Instructions>
      </ContainerWrapper>
    </Container>
  );
};
