import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { ReadOnlyCurrencyMask } from '../../../component-library/currency/ReadOnlyCurrencyMask';
import { dayjsDate } from '../../../component-library/dates/dayjsApStyleAbbreviateMonths';
import getAPStyleMonth from '../../../component-library/dates/getAPStyleMonth';
import ResponsiveTooltip from '../../../component-library/tooltip/ResponsiveTooltip';

import messages from './messages';

const DownPaymentRequiredSectionContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  borderLeft: `1px solid ${theme.palette.dteGrey.main}`,
  borderRight: `1px solid ${theme.palette.dteGrey.main}`,
  borderBottom: `1px solid ${theme.palette.dteGrey.main}`,
  gridArea: 'downPaymentRequiredSection',
  padding: '24px 16px',
  textAlign: 'center',
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '304px',
  },
  [theme.breakpoints.up('desktop')]: {
    borderLeft: '0',
  },
}));

interface Props {
  downPaymentAmount?: string;
  downPaymentDueDate?: string;
  downPaymentDueDayOfWeek?: string;
}

const DownPaymentRequiredSection = ({
  downPaymentAmount = '0',
  downPaymentDueDate = String(Date.now()),
  downPaymentDueDayOfWeek = 'Undefined',
}: Props): React.ReactElement => {
  const theme = useTheme();
  const downPaymentDueDateFormatted = dayjsDate(downPaymentDueDate);

  const sx: Record<string, SxProps> = {
    labelEndDescription: {
      display: 'inline-flex',
      alignItems: 'center',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      height: '24.5px',
    }, // wraps icon to text
    title: {
      color: theme.palette.primary.main,
      textWrap: 'nowrap',
    },
    tooltipContainer: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('mobile')]: {
        paddingLeft: '13px',
      }, // SmallMobile
      [theme.breakpoints.between('mobile', 'tablet')]: {
        paddingLeft: '13px',
      }, // Mobile
    },
    toolTipWrapper: {
      // overwrite tooltip css
      ' > div > button ': {
        margin: '3px 0px 3px 5px',
        padding: '0px',
      },
    },
  };

  const DownPaymentTooltipContainer = (): React.ReactElement => (
    <Box sx={sx.tooltipContainer}>
      <Box component="div" sx={sx.labelEndDescription}>
        <Typography
          data-testid="pae-account-info-downpayment-popover"
          fontSize="18px"
          fontWeight="600"
          sx={sx.title}
          variant="body1"
        >
          {messages.downPaymentText}
        </Typography>
        <Box component="span" sx={sx.toolTipWrapper}>
          <ResponsiveTooltip popoverText={messages.downPaymentPopoverText} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <DownPaymentRequiredSectionContainer>
      <DownPaymentTooltipContainer />
      <Typography
        data-testid="pae-account-info-downpayment-due-date"
        fontSize="16px"
        fontWeight="400"
        paddingTop="4px"
        sx={sx.title}
        variant="body1"
      >
        {`${messages.dueByText} ${downPaymentDueDayOfWeek}, ${getAPStyleMonth(
          downPaymentDueDateFormatted.month(),
        )} ${downPaymentDueDateFormatted.date()}`}
      </Typography>
      <Typography
        data-testid="pae-account-info-down-payment-amount"
        fontSize="22px"
        fontWeight="600"
        lineHeight="29.96px"
        paddingTop="8px"
        sx={sx.title}
        variant="body1"
      >
        {ReadOnlyCurrencyMask(parseFloat(downPaymentAmount))}
      </Typography>
    </DownPaymentRequiredSectionContainer>
  );
};

export default DownPaymentRequiredSection;
