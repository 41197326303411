import { ReactNode, SyntheticEvent, useContext } from 'react';
import { Box, Tabs } from '@mui/material';

import { DteTab } from './DteTab';
import { TabContext } from './TabsContext';

type DteTabPanelProps = {
  index: number;
  value: number;
  id: string;
  ariaLabelledby: string;
  presentationComponent: ReactNode;
};

type TabAndPanelProps = {
  tabLabel: string;
  tabAriaId: string;
  tabPanelAriaId: string;
  tabPanelPresentation: ReactNode;
};

type DteTabsProps = {
  tabAndPanelProps: TabAndPanelProps[];
  dteTabChangeHandler?: (muiTabIndex: number) => void;
};

export const DteTabs = ({ tabAndPanelProps, dteTabChangeHandler }: DteTabsProps): React.ReactElement => {
  const { selectedTab, tabChangeHandler } = useContext(TabContext);

  const DteTabPanel = ({
    value,
    index,
    id,
    ariaLabelledby,
    presentationComponent,
    ...otherProps
  }: DteTabPanelProps): React.ReactElement => (
    <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabelledby} {...otherProps}>
      {value === index && presentationComponent}
    </div>
  );

  const muiTabHandler = (event: SyntheticEvent, muiTabIndex: number): void => {
    tabChangeHandler(event, muiTabIndex);
    if (dteTabChangeHandler) {
      dteTabChangeHandler(muiTabIndex);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          onChange={muiTabHandler}
          value={selectedTab}
          aria-label="Tabs between medical and military lock details"
          variant="fullWidth"
          data-testid="tabs-root-container"
          centered
          selectionFollowsFocus
        >
          {tabAndPanelProps.map((tabAndPanel: TabAndPanelProps) => {
            const { tabLabel, tabAriaId, tabPanelAriaId } = tabAndPanel;
            return <DteTab key={tabAriaId} label={tabLabel} id={tabAriaId} ariaControls={tabPanelAriaId} />;
          })}
        </Tabs>
      </Box>
      {tabAndPanelProps.map((tabAndPanel: TabAndPanelProps, index: number) => {
        const { tabAriaId, tabPanelAriaId, tabPanelPresentation } = tabAndPanel;
        return (
          <DteTabPanel
            key={tabPanelAriaId}
            index={index}
            value={selectedTab}
            id={tabPanelAriaId}
            ariaLabelledby={tabAriaId}
            presentationComponent={tabPanelPresentation}
          />
        );
      })}
    </>
  );
};
