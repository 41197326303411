import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useAccountDetails } from 'api/account-details-api/account-details-api';
import { useBillComparison } from 'api/bill-comparison-api/bill-comparison-api';
import { useEligibility } from 'api/eligibility-api/eligibility-api';
import { useExperian } from 'api/experian-api/experian-api';
import ExperianResponse from 'api/experian-api/ExperianResponse';
import { useUserState } from 'auth/authenticate';
import PaymentProcessBanner from 'component-library/banners/PaymentProcessBanner';
import ViewCurrentBillButton from 'component-library/buttons/ViewCurrentBillButton';
import { DialogProvider } from 'component-library/dialog/DialogContext';
import { reconnectService } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { ReconnectTitle } from 'component-library/header/ServiceTitle';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { reconnectDetailsViewCurrentBillClickEvent, reconnectHeaderCancelClickEvent } from 'gtm/gtmEventsReconnect';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';
import {
  currentBillUrl,
  reconnectConfirmationPath,
  reconnectGenericErrorPath,
  reconnectProbatePath,
} from 'urls-and-paths';

import AccountInformationDesktop from './components/accountInformationDesktop';
import AccountInformationMobile from './components/accountInformationMobile';
import PaymentOptionsContainer from './components/paymentOptions/PaymentOptionsContainer';
import { PaymentOptionsProvider } from './components/PaymentOptionsContext';

const headerCancelButtonHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...reconnectHeaderCancelClickEvent,
    authenticationStatus: true,
  });
  window.location.href = currentBillUrl;
};
const mobileHeader = (
  <HeaderMobile titleText={reconnectService} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
const desktopHeader = (
  <HeaderDesktop titleText={reconnectService} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);

const ReconnectServicePage: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onError: (): void => {
      /* navigate to error page */
      navigate(reconnectGenericErrorPath);
    },
  });

  const { isEligibilitySuccess, eligibilityResponse } = useEligibility({
    queryKey: ['reconnect-eligibility-check'],
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<CombinedEligibilityCheckResponse>): void => {
      if (httpResponse.data?.restoreDetails) {
        const { eligibilityFlag, premise } = httpResponse.data.restoreDetails;

        // Not Eligible for Restore via WebReconnect, Eligibility
        if (!eligibilityFlag) {
          window.location.href = currentBillUrl;
        }

        // ExperianCheck -dependent on premiseId
        const experianRequest = {
          processId: 'WRE',
          premiseId: `${premise}`,
          webUserId: localStorage.getItem('webUserId') ?? '',
          businessPartner: 123,
        };

        // Triggering mutateExperian here has a high possibility of happening BEFORE the response is set -adding timeout

        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          mutateExperian(experianRequest);
        }, 500);
      } else {
        /* navigate to error page */
        navigate(reconnectGenericErrorPath);
      }
    },
    onError: () => {
      /* navigate to error page */
      navigate(reconnectGenericErrorPath);
    },
  });

  const { isExperianSuccess, experianResponse, mutateExperian } = useExperian({
    onSuccess: (response: AxiosResponse<ExperianResponse>) => {
      if (eligibilityResponse?.data.restoreDetails) {
        const {
          eligibilityFlag,
          totalPastDueAmount: totalPastDueAmountString,
          depositAmount: depositAmountString,
          reconnectionFee: reconnectionFeeString,
        } = eligibilityResponse.data.restoreDetails;
        const totalPastDueAmount = parseFloat(totalPastDueAmountString);
        const depositAmount = parseFloat(depositAmountString);
        const reconnectionFee = parseFloat(reconnectionFeeString);

        // R887 -> only BPEM to route user to probate view
        // ignore all other errors and proceed loading page
        if (response.data.bpemCategory === 'R887') {
          navigate(reconnectProbatePath);
        }

        // Interrupted Flow - now triggers AFTER experian is successful
        // TODO - Bug #52155 - restore code below once the eligibility service is fixed i.e. return correct value for reconnection fee
        if (eligibilityFlag && totalPastDueAmount + depositAmount === 0) {
          // + reconnectionFee
          navigate(reconnectConfirmationPath, { state: { previousPath: 'reconnect-payment-options' } });
        }

        const webUserId = localStorage.getItem('webUserId');
        localStorage.setItem(`depositAmount_${webUserId}`, depositAmount.toFixed(2));
        localStorage.setItem(`totalAmountPastDue_${webUserId}`, totalPastDueAmount.toFixed(2));
        localStorage.setItem(`reconnectionFee_${webUserId}`, reconnectionFee.toFixed(2));
      } else {
        /* navigate to error page */
        navigate(reconnectGenericErrorPath);
      }
    },
    onError: () => {
      /* navigate to error page */
      navigate(reconnectGenericErrorPath);
    },
  });

  const premise = eligibilityResponse?.data.restoreDetails?.premise;
  const accountNumber = eligibilityResponse?.data.restoreDetails?.contractAccountNo.toString();
  const eligibilityFlag = eligibilityResponse?.data.restoreDetails?.eligibilityFlag;
  const totalPastDueAmount = eligibilityResponse?.data.restoreDetails?.totalPastDueAmount;
  const depositAmount = eligibilityResponse?.data.restoreDetails?.depositAmount;
  const notZero =
    !!totalPastDueAmount && !!depositAmount && parseFloat(totalPastDueAmount) + parseFloat(depositAmount) !== 0;
  const enableBillComparisonQuery = isAuthenticated && !!premise && !!accountNumber && !!eligibilityFlag && notZero;

  const { isBillComparisonSuccess, billComparisonResponse } = useBillComparison({
    queryKey: ['bill-comparison', premise!, accountNumber!],
    enabled: enableBillComparisonQuery,
    onError: () => {
      /* navigate to error page */
      navigate(reconnectGenericErrorPath);
    },
    premise,
    accountNumber,
  });

  const desktopAccountInfo = (
    <AccountInformationDesktop
      accountInfo={accountDetailsResponse?.data}
      accountNumber={eligibilityResponse?.data.restoreDetails?.contractAccountNo}
    />
  );
  const mobileAccountInfo = (
    <AccountInformationMobile
      accountInfo={accountDetailsResponse?.data}
      accountNumber={eligibilityResponse?.data.restoreDetails?.contractAccountNo}
    />
  );

  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);
  const pageAccountInfo = useResponsiveRendering(mobileAccountInfo, desktopAccountInfo, desktopAccountInfo);

  const redirectToCurrentBillHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...reconnectDetailsViewCurrentBillClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  const showRenderPageContent =
    isAccountDetailsSuccess &&
    isEligibilitySuccess &&
    eligibilityResponse?.data.restoreDetails &&
    isBillComparisonSuccess &&
    isExperianSuccess;

  const ReconnectServicePageContent = showRenderPageContent ? (
    <>
      <ViewCurrentBillButton onClick={redirectToCurrentBillHandler} />
      <ReconnectTitle />
      {pageAccountInfo}
      <PaymentOptionsProvider>
        <DialogProvider>
          <PaymentOptionsContainer
            restoreDetails={eligibilityResponse.data.restoreDetails}
            experianResponse={experianResponse!.data}
            billComparisonResponse={billComparisonResponse!.data}
          />
        </DialogProvider>
      </PaymentOptionsProvider>
      <PaymentProcessBanner />
    </>
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <Helmet>
        <title>Reconnect Service Payment Options | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="16px"
        tabletDesktopContentWidth="740px"
        dataTestId="reconnect-service-container"
      >
        {ReconnectServicePageContent}
      </PageTemplate>
    </>
  );
};

export default ReconnectServicePage;
