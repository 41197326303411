import getLogLevel from 'config/getLogLevel';

import ConsoleLogWriter from './ConsoleLogWriter';

const Logger = (): ILogger => {
  let instance: ILogger | undefined;

  const getLogger = (): ILogger => {
    if (!instance) {
      const logLevel = getLogLevel();

      instance = ConsoleLogWriter(logLevel);
    }
    return instance;
  };

  const debug = (message: string, ...optionalParams: unknown[]): void => getLogger().debug(message, ...optionalParams);
  const error = (message: string, ...optionalParams: unknown[]): void => getLogger().error(message, ...optionalParams);
  const info = (message: string, ...optionalParams: unknown[]): void => getLogger().info(message, ...optionalParams);
  const warn = (message: string, ...optionalParams: unknown[]): void => getLogger().warn(message, ...optionalParams);
  return {
    debug,
    error,
    info,
    warn,
  };
};

export default Logger;
