import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { PaymentCenterLambda, paymentCenterLambda } from 'api/payment-center-lambda-api/payment-center-lambda-api';
import routes from 'constants/routes';
import { urls } from 'constants/urls';
import { logger } from 'services';

interface IUsePaymentExtensionMutateLambdaResult {
  mutateLambda: (lambdaRequest: PaymentCenterLambda) => void;
}

const usePaymentExtensionMutateLambda = (): IUsePaymentExtensionMutateLambdaResult => {
  const navigate = useNavigate();
  const { mutate: mutateLambda } = useMutation({
    mutationKey: ['payment-extension-lambda'],
    mutationFn: (lambdaRequest: PaymentCenterLambda) => paymentCenterLambda(lambdaRequest),
    onSuccess: () => {
      logger.info(
        '[API Success] - Lambda Call success. Navigating to Payment Center',
        urls.paymentCenterPaymentExtensionUrl,
      );
      window.location.href = urls.paymentCenterPaymentExtensionUrl;
    },
    onError: (error: unknown) => {
      logger.info('[API Error] - Lambda Call Not Successful. Navigating to PE Error Page', error);
      navigate(routes.error.paymentExtension);
    },
  });

  return { mutateLambda };
};

export default usePaymentExtensionMutateLambda;
