import { styled } from '@mui/material';

import PaymentAgreementModalIcon from '../../../../component-library/icons/payment-agreement-icons/PaymentAgreementModalIcon';
import ReviewPaymentModalSummaryTitle from '../ReviewPaymentModalSummaryTitle';

import { TotalBalanceDue } from './TotalBalanceDue';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const YourPaymentAgreementSummary: React.FC = (): React.ReactElement => (
  <Container data-testid="your-payment-agreement-summary">
    <PaymentAgreementModalIcon />
    <ReviewPaymentModalSummaryTitle />
    <TotalBalanceDue />
  </Container>
);

export default YourPaymentAgreementSummary;
